/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { DiagnosticError, testVideoInputDevice, VideoInputTest } from '@twilio/rtc-diagnostics'

import { useState, useRef, useCallback } from 'react'

export function useCameraTest() {
  const [videoTest, setVideoTest] = useState<VideoInputTest>()
  const videoElementRef = useRef<HTMLVideoElement>(null!)
  const [videoTestError, setVideoTestError] = useState<DiagnosticError>()

  const stopVideoTest = useCallback(() => {
    setVideoTest(undefined)
    videoTest?.stop()
  }, [videoTest])

  const startVideoTest = useCallback(
    (deviceId: string) => {
      stopVideoTest()
      const test = testVideoInputDevice({ element: videoElementRef.current, deviceId })
      setVideoTest(test)
      test.on(VideoInputTest.Events.Error, (err) => setVideoTestError(err))
      test.on(VideoInputTest.Events.End, (report) => console.log('video test report', report))
    },
    [stopVideoTest]
  )

  return { startVideoTest, stopVideoTest, videoElementRef, videoTest, videoTestError } as const
}
