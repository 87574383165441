import { FC } from 'react'
import { useStore } from 'types/store'
import { observer } from 'mobx-react-lite'
import { MSTForm } from 'generic_components/MstForm'
import { StringInput } from 'generic_components/MstFormStringInput'
import { Checkbox } from 'generic_components/MstFormCheckbox'
import { Parent } from 'common/types/parent'
import { Instance, IAnyModelType } from 'mobx-state-tree'
import { Country } from 'common/types/basic/country'
import { StringSelector } from 'generic_components/MstFormStringSelector'

interface Props {
  initial_profile: Instance<IAnyModelType>
}

const CountrySelector = StringSelector((Country as any)._types)

export const ParentDetails: FC<Props> = observer(({ initial_profile }) => {
  const { parent_data: { profile, save_profile, create_profile, busy } } = useStore()

  return (
    <MSTForm
      title='Your profile'
      initial={initial_profile}
      model={Parent}
      submit={profile === undefined ? create_profile : save_profile}
      force_edit_mode={profile === undefined}
      cancel_text={profile === undefined ? 'Clear' : 'Cancel'}
      fields={[
        {
          name: 'first_name',
          label: 'First name',
          Component: StringInput
        },
        {
          name: 'last_name',
          label: 'Last name',
          Component: StringInput
        },
        {
          name: 'email',
          label: 'Email address',
          Component: StringInput,
          disabled: true
        },
        {
          name: 'country',
          label: 'Country',
          Component: CountrySelector,
        },
        {
          name: 'postcode',
          label: 'Postcode',
          Component: StringInput,
          required: true
        },
        {
          name: 'phone_number',
          label: 'Phone number',
          Component: StringInput,
          required: true
        },
        {
          name: 'email_opt_in',
          label: 'Email opt in',
          Component: Checkbox
        }
      ]}
    />
  )
})
