import { FC, useEffect, useState } from 'react'
import { get_unread_notes } from 'util/api/notes/read'
import { Session } from 'components/sessions/useUpcomingSessions'
import {
  IonBadge,
} from '@ionic/react'
import { StudentId } from 'types/student/id'
import { SubjectId } from 'common/types/subject/id'
import { Instance } from 'mobx-state-tree'

interface Props {
  session?:Session
  student: StudentId,
  subject: Instance<SubjectId>
}

export const UnreadIndicator:FC<Props> = ({session, student, subject}) => {
  const [unread, set_unread] = useState(0)
  useEffect(() => {
    set_unread(0)
    get_unread_notes({params: {
      subject,
      student,
    }}).then(unread => {
      set_unread(unread.count)
    })
  },[session, student, subject])

  return unread
    ? <IonBadge color='danger'>{unread}</IonBadge>
    : null
}
