import { DifficultyLevel } from 'types/Difficulty'
import { IonRadioGroup, IonRadio, IonItem, IonLabel, IonRow, IonCol, IonList } from '@ionic/react'
import jewels_svg from 'images/speed_game/jewels.svg'
import styled from 'styled-components'
export type { DifficultyLevel }

interface DifficultySelectorProps {
  level: DifficultyLevel
  select: (level: DifficultyLevel) => void,
  disabled?: boolean,
  game_in_play?: boolean
}

const JewelsIcon = styled.img`
  width: 1.56rem;
  height: 1.56rem;
  max-width: 1.56rem;
  max-height: 1.56rem;
`

const JewelsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const jewelsMarginLookup = {
  0: 0,
  1: '0 0 0 2.6rem',
  2: '0 0 0 1.3rem',
  3: '0 0 0 2rem',
}

export const DifficultySelector = ({ level, select, disabled = false, game_in_play = false }: DifficultySelectorProps) => {
  const generateJewelIcons = (num) => {
    const icons = []
    for (let i = 0; i < num; i++) {
      icons.push(<JewelsIcon src={jewels_svg} style={{ margin: '0 0 0 4px' }} />)
    }
    return icons
  }
  return (
    <div id={'difficulty'}>
      <IonItem lines='none' disabled={disabled}>
        <IonRadioGroup value={level} onIonChange={e => select(e.detail.value as DifficultyLevel)}>
          <IonRow>
            {['MEDIUM', 'HARD', 'SUPER_HARD'].map((l, idx) => (
              <IonCol key={l}>
                <IonItem lines='none' id={`difficulty_selector_${l}`}>
                  <IonLabel style={{ marginRight: '0.125rem' }}>{l.replace('_', ' ')}</IonLabel>
                  <IonRadio value={l}></IonRadio>
                </IonItem>
                {game_in_play && (
                  <JewelsContainer>
                    <div style={{ margin: jewelsMarginLookup[idx+1] }}>
                      {generateJewelIcons(idx+1)}
                    </div>
                  </JewelsContainer>
                )}

              </IonCol>
            ))}
          </IonRow>
        </IonRadioGroup>
      </IonItem>
    </div >
  )
}
