import { useEffect, useState } from 'react'
import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonToolbar,
  useIonActionSheet,
  useIonModal
} from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'types/store'
import { DateTime } from 'luxon'
import { ellipsisHorizontalCircleOutline } from 'ionicons/icons'
import { CreateSubscriptionWizard } from 'components/subscription/CreateSubscriptionWizard'
import { EditSubscriptionWizard } from 'components/subscription/EditSubscriptionWizard'
import { SessionOptionEnum } from 'common/types/session/options'

interface IProps {
  roles: Roles[]
}

export default observer(({ roles }: IProps) => {
  const { parent_data: { subscriptions, get_subscriptions, cancel_subscription } } = useStore()

  useEffect( () => { get_subscriptions() }, [])

  const close_create_modal = () => {
    dismiss_create()
    get_subscriptions()
  }
  const [open_create_modal, dismiss_create] = useIonModal(CreateSubscriptionWizard, { close_create_modal })

  const [subscription, setSubscription] = useState(undefined)
  const close_edit_modal = () => {
    dismiss_edit()
    get_subscriptions()
  }
  const [open_edit_modal, dismiss_edit] = useIonModal(EditSubscriptionWizard, { close_edit_modal, subscription })
  const [open_actions] = useIonActionSheet()

  return (
    <PlytimePage roles={roles}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Subscriptions
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow style={{height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast'}}>
              <IonCol size='1'>
                <IonLabel style={{height: '100%'}}>
                  Student
                </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel>
                  Subject
                </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel>
                  Tutor
                </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel>
                  Weekly Sessions
                </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel>
                  Start
                </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel>
                  Last Payment
                </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel>
                  Amount
                </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel>
                  Status
                </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel>
                  Actions
                </IonLabel>
              </IonCol>
            </IonRow>
            {subscriptions.length === 0 &&
              <IonRow className='alternate-light-medium'>
                <IonCol size='12'>
                  <IonLabel>
                  You have no subscriptions
                  </IonLabel>
                </IonCol>
              </IonRow>
            }
            {subscriptions.map(subscription =>
              <IonRow key={subscription._id} className='alternate-light-medium'>
                <IonCol size='1'>
                  {subscription.student.name}
                </IonCol>
                <IonCol size='1'>
                  {subscription.subject.name}
                </IonCol>
                <IonCol size='1'>
                  {subscription.tutor.name}
                </IonCol>
                <IonCol size='1'>
                  {
                    subscription.session_option === SessionOptionEnum.PlatformOnly ?
                      '0 x 15' :
                      subscription.session_option.substring(0, 6)
                  }
                </IonCol>
                <IonCol size='2'>
                  {DateTime.fromISO(subscription.start_date).toFormat('yyyy/MM/dd')}
                </IonCol>
                <IonCol size='2'>
                  {subscription.payment_date === null ? '-' : DateTime.fromISO(subscription.payment_date).toFormat('yyyy/MM/dd')}
                </IonCol>
                <IonCol size='1'>
                  £{subscription.payment_amount}
                </IonCol>
                <IonCol size='2'>
                  {subscription.cancelled ? 'Cancelled' :
                    subscription.payment_date === null ? 'Pending' :
                      'Active'}
                </IonCol>
                <IonCol size="1">
                  {!subscription.cancelled &&
                      <IonIcon
                        icon={ellipsisHorizontalCircleOutline}
                        onClick={() => open_actions({
                          buttons: [{
                            text: 'Edit Subscription',
                            handler: () => {
                              setSubscription({...subscription})
                              open_edit_modal()
                            }
                          },
                          {
                            text: 'Cancel Subscription',
                            handler: () => {
                              cancel_subscription(subscription._id)
                            }
                          }
                          ],
                          header: 'Subscription actions',
                          cssClass: 'subscription-actions'
                        })}
                      />
                  }
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
          <IonToolbar>
            <IonButtons slot='end'>
              <IonButton
                fill='solid'
                size='small'
                color='primary'
                className="ion-margin-top"
                onClick={() => open_create_modal()}
              >
                Add subscription
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCardContent>
      </IonCard>
    </PlytimePage>
  )
})
