import { types, Instance, applySnapshot, flow, getSnapshot } from 'mobx-state-tree'
import { Question } from 'types/question_management/question/question'
import { ListMetadata, QuestionSearchFilter } from 'common/types/question/search'
import { question_search } from 'util/api/admin/question/search'

export const AdminStore = types
  .model('AdminStore',{
    questions: types.array(Question),
    questions_filter: types.optional(QuestionSearchFilter, {
      status: ['live'],
      _from: 0,
      _limit: 50
    }),
    metadata: types.maybe(ListMetadata)
  })
  .volatile(() => ({
    busy: false,
    changed: true
  }))
  .actions(self => ({
    search: flow(function* (_from=0) {
      if( self.busy ){
        throw new Error('Cannot search when busy')
      }
      self.questions_filter._from = _from
      self.busy = true
      self.changed = false
      try {
        const response = yield question_search({ query: getSnapshot(self.questions_filter) })
        applySnapshot(self.questions, response.data)
        if( self.metadata === undefined ) {
          self.metadata = ListMetadata.create(response.metadata)
        } else {
          applySnapshot(self.metadata, response.metadata)
        }
      } catch (error) {
        console.error('Failed question search', error)
        throw (error)
      }
      finally {
        self.busy = false
      }
    }),
    set_filter_status: (statuses:string[]) => {
      applySnapshot(self.questions_filter.status, statuses)
      self.changed = true
    },
    set_filter_ref: (ref:string) => {
      self.questions_filter.ref = ref
      self.changed = true
    }
  }))
export type AdminStore = Instance<typeof AdminStore>
