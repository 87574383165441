import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { Game } from 'components/game/gameplay/speed_standard'

interface IProps {
  location: Location,
  roles: Roles[]
}

export default ({ location, roles }: IProps) => {
  const query = new URLSearchParams(location.search)
  const game_duration = parseInt(query.get('time'))
  const game_id = query.get('game_id')
  const game_mode = query.get('game_mode') as 'multiplayer' | 'singleplayer'
  return (
    <PlytimePage roles={roles} >
      <Game game_id={game_id} duration={game_duration} game_mode={game_mode} />
    </PlytimePage>
  )
}
