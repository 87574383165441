import { FC, ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { ImageComponent } from './image'
import { InputComponent } from './input'
import { McTick } from './mctick'
import { P } from './p'
import { Element, AnswerElement } from 'common/types/question/question'
import { AllowedAnswers, SubmittedAnswer } from 'common/types/question/answer/answer'
import { CoordinateInputComponent } from './coordinate'
import { RatioXYInputComponent } from './ratioXY'
import { RatioXYZInputComponent } from './ratioXYZ'
import { FractionInputComponent } from './fraction'
import { MixedFractionInputComponent } from './mixed_fraction'
import { TimeInputComponent } from './time'
import { GbpInputComponent } from './gbp'
import { NumberComponent } from './number'

const mc_result = (answers) => (ref, value) => {
  if (answers.length === 0) return undefined

  const correct_answer = answers.find(accpetable_answer =>
    accpetable_answer.find(answer_part =>
      answer_part.ref === ref && answer_part.values.includes(value.toLowerCase())
    ) !== undefined
  )

  return correct_answer !== undefined
}

interface Props {
  line_index: number
  element_index: number,
  element: Instance<Element>,
  handleInputChange: (answer_key: string, ref: string, selected?: boolean) => (e: ChangeEvent) => void,
  student_answer: Instance<SubmittedAnswer>,
  correct_answers: Instance<AllowedAnswers>,
  answer_decision: string,
  auto_focus_element?: Instance<AnswerElement>,
}

const get_component_for = (elementType: string) => {
  switch(elementType) {
    case 'RatioXYZ': 
      return RatioXYZInputComponent
    case 'RatioXY':
      return RatioXYInputComponent
    case 'Coordinate':
      return CoordinateInputComponent
    case 'Fraction':
      return FractionInputComponent
    case 'MixedFraction':
      return MixedFractionInputComponent
    case 'Time':
      return TimeInputComponent
    case 'GBP':
      return GbpInputComponent
    case 'Whole Number':
      return NumberComponent
    case 'Text input':
    case 'Textcs':
      return InputComponent
    case '1dp':
    case '2dp':
    case '3dp':
    case '4dp':
      return NumberComponent
    default:
      return null
  }
}

export const ElementComponent: FC<Props> = observer(({ line_index, element_index, element, handleInputChange, correct_answers, student_answer, answer_decision, auto_focus_element }) => {
  const answer_key = `${line_index}-${element_index}`
  const element_id = `question_${line_index}_${element_index}`
  
  const answer_part = student_answer.size > 0 ? student_answer.get(answer_key) : null
  const value = answer_part?.value || ''

  const selected = student_answer.has(answer_key)

  const Component = get_component_for(element.type)
  
  switch (element.type) {
    case 'Text':
      return (
        <P id={element_id} style={{ whiteSpace: 'pre-wrap', marginRight: '1ch', textAlign: 'center' }}>
          {element.text}
        </P>
      )
    case 'Image':
      return (
        <ImageComponent image={element} />
      )
    case 'MC input':
      return (
        <McTick
          id={element_id}
          type='button'
          value={element.value}
          selected={selected}
          expected={mc_result(correct_answers)(element.ref, element.value)}
          onClick={handleInputChange(`${line_index}-${element_index}`, element.ref, selected)}
        >
          {element.value}
        </McTick>
      )
    default: 
      return (
        <Component
          element_id={element_id}
          element={element}
          handleChange={handleInputChange(answer_key, element.ref)}
          answer_decision={answer_decision}
          value={value}
          correct_answers={correct_answers}
          auto_focus_element={auto_focus_element}
        />
      )
  }

})
