import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { listAllTutors } from 'util/api/tutor'
import { useEffect, useState } from 'react'
import { FilteredAvailability } from 'components/tutor_availability/FilteredAvailability'
import { TutorList } from 'common/types/tutor/tutor'
import { SnapshotOut } from 'mobx-state-tree'
import { InputChangeEventDetail } from '@ionic/core'
import { IonSelect, IonSelectOption, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonLabel, IonGrid, IonRow, IonCol } from '@ionic/react'
import { Hours } from 'components/tutor_availability/SlotTimes'

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  const [tutors, setTutors] = useState<SnapshotOut<typeof TutorList>>([])
  const [filterDay, setFilterDay] = useState<number>(-1)
  const [filterStartTime, setFilterStartTime] = useState<number>(0)
  const [filterEndTime, setFilterEndTime] = useState<number>(24*60)
  const [filterSessionStatus, setFilterSessionStatus] = useState<string>('All')

  useEffect(() => {
    const f = async () => {
      const allTutors = await listAllTutors()
      setTutors(allTutors)
    }
    f()
  }, [])

  const update_day_filter = (e:CustomEvent<InputChangeEventDetail>) => {
    const as_number = Number.parseInt(e.detail.value)
    if( !Number.isNaN(as_number) ) {
      setFilterDay(as_number)
    }
    else {
      setFilterDay(-1)
    }
  }

  const update_start_filter = (e:CustomEvent<InputChangeEventDetail>) => {
    const as_number = Number.parseInt(e.detail.value)
    if( !Number.isNaN(as_number) ) {
      setFilterStartTime(as_number)
    }
    else {
      setFilterStartTime(0)
    }
  }

  const update_end_filter = (e:CustomEvent<InputChangeEventDetail>) => {
    const as_number = Number.parseInt(e.detail.value)
    if( !Number.isNaN(as_number) ) {
      setFilterEndTime(as_number)
    }
    else {
      setFilterEndTime(24*60)
    }
  }

  const update_status_filter = (e:CustomEvent<InputChangeEventDetail>) => {
    setFilterSessionStatus(e.detail.value)
  }

  return (
    <PlytimePage roles={roles}>
      <h3>Availabilities for all tutors</h3>
      <IonCard color='tertiary'>
        <IonCardHeader>
          <IonCardTitle>
                Filter
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size='5'>
                <IonItem>
                  <IonLabel position='floating'>
                        Day
                  </IonLabel>
                  <IonSelect
                    value={filterDay}
                    onIonChange={update_day_filter}
                  >
                    <IonSelectOption value={-1}>All days</IonSelectOption>
                    <IonSelectOption value={0}>Monday</IonSelectOption>
                    <IonSelectOption value={1}>Tuesday</IonSelectOption>
                    <IonSelectOption value={2}>Wednesday</IonSelectOption>
                    <IonSelectOption value={3}>Thursday</IonSelectOption>
                    <IonSelectOption value={4}>Friday</IonSelectOption>
                    <IonSelectOption value={5}>Saturday</IonSelectOption>
                    <IonSelectOption value={6}>Sunday</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>
                        From
                  </IonLabel>
                  <IonSelect
                    value={filterStartTime}
                    onIonChange={update_start_filter}
                  >
                    <Hours/>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>
                        until
                  </IonLabel>
                  <IonSelect
                    value={filterEndTime}
                    onIonChange={update_end_filter}
                  >
                    <Hours/>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <IonLabel position='floating'>
                        Status
                  </IonLabel>
                  <IonSelect
                    value={filterSessionStatus}
                    onIonChange={update_status_filter}
                  >
                    <IonSelectOption>All</IonSelectOption>
                    <IonSelectOption>Available</IonSelectOption>
                    <IonSelectOption>Booked</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      {tutors.filter(t => t.uid !== undefined).map(t =>
        <FilteredAvailability
          key={t.uid}
          tutor={t}
          filter_day={filterDay}
          filter_start_time={filterStartTime * 60}
          filter_end_time={filterEndTime * 60}
          filter_session_status={ filterSessionStatus === 'Available' ? false : filterSessionStatus === 'Booked' ? true : undefined }
        />)}
    </PlytimePage>
  )
}
