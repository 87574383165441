

import { FC } from 'react'
import { RemoteVideoTrack } from 'twilio-video'
import { observer } from 'mobx-react-lite'
import { Card, Content, ProfilePicture, SideVideo } from 'components/sessions/focus15'
import { Video } from 'components/sessions/focus15/Video'

interface Props {
  track: RemoteVideoTrack,
  name: string
}

export const RemoteVideoCard: FC<Props> = observer(({track, name}) => {
  return (
    <Card>
      <Content>
        <SideVideo>
          {track
            ? <Video track={track} style={{ borderRadius:'50%', objectFit:'contain', maxHeight: '100%', maxWidth: '100%'}}/>
            : <ProfilePicture/>
          }
        </SideVideo>
        <p>{name}</p>
      </Content>
    </Card>
  )
})
