import { Instance, types, SnapshotOut } from 'mobx-state-tree'
import { DateTimeString } from 'common/types/basic/date_time_string'
import { SubjectId } from '../subject/id'
import { SchoolYearInteger } from 'common/types/basic/school_year_int'
import { RelativeLevels } from 'common/types/student/relative_levels'

const SessionStudent = types.model({
  first_name: types.string,
  school_year: SchoolYearInteger
})

const SessionTutor = types.model({
  first_name: types.string,
})

export const HydratedSession = types.model('HydratedSession', {
  _id: types.maybeNull(types.string),
  recurring_session_id: types.string,
  subscription_id: types.string,
  student_id: types.string,
  parent_id: types.string,
  tutor_id: types.string,
  day_of_week: types.number,
  session_time: types.number,
  session_length: types.number,
  session_date_time: DateTimeString,
  session_date_time_end: types.maybe(DateTimeString),
  subject: types.model({
    _id: SubjectId,
    name: types.string
  }),
  participants: types.array(types.string),
  paid: types.boolean,
  cancelled: types.boolean,
  relative_level: RelativeLevels,
  rescheduled: types.boolean,
  substituted: types.boolean,
  student: types.maybeNull(SessionStudent),
  tutor: types.maybeNull(SessionTutor),
  register: types.maybeNull(types.array(types.model({
    type: types.string,
    time: DateTimeString,
    user: types.string,
  })))
})
export type HydratedSession = typeof HydratedSession

export const GetSessionRequest = types.model({
  params: types.model({
    session_id: types.string
  })
})
export type GetSessionRequest = SnapshotOut<typeof GetSessionRequest>

export const GetUpcomingSessionsRequest = types.model({
  query: types.model({
    after_time: types.string,
    tutor_id: types.maybe(types.string)
  })
})
export type GetUpcomingSessionsRequest = SnapshotOut<typeof GetUpcomingSessionsRequest>

export const GetUpcomingSessionsResponse = types.array(HydratedSession)
export type GetUpcomingSessionsResponse = SnapshotOut<typeof GetUpcomingSessionsResponse>

export const GetPastSessionsRequest = types.model({
  query: types.model({
    before_time: types.string,
    tutor_id: types.maybe(types.string)
  })
})
export type GetPastSessionsRequest = SnapshotOut<typeof GetPastSessionsRequest>

export const GetPastSessionsResponse = types.array(HydratedSession)
export type GetPastSessionsResponse = SnapshotOut<typeof GetPastSessionsResponse>

export const ModifySessionRequest = types.model({
  query: types.model({
    subscription_id: types.maybeNull(types.string),
    session_id: types.maybeNull(types.string),
    session_date_time: types.maybeNull(DateTimeString)
  })
})
export type ModifySessionRequest = SnapshotOut<typeof ModifySessionRequest>

export const ModifySessionResponse = types.model({
  success: types.literal(true)
})
export type ModifySessionResponse = SnapshotOut<typeof ModifySessionResponse>

export const RescheduleSessionRequest = types.model({
  query: types.model({
    subscription_id: types.maybeNull(types.string),
    session_id: types.maybeNull(types.string),
    session_date_time: types.maybeNull(DateTimeString),
    new_session_date_time: DateTimeString
  })
})
export type RescheduleSessionRequest = SnapshotOut<typeof RescheduleSessionRequest>

export const MetaSession = types.model('MetaSession', {
  _id: types.string,
  day_of_week: types.number,
  session_time: types.number,
  session_length: types.number,
})

export type MetaSession = Instance<typeof MetaSession>
