import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLoading,
  IonToolbar,
} from '@ionic/react'
import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import styled from 'styled-components'
import { uniq } from 'lodash'

import { Days } from 'common/types/basic/day'
import SubscriptionWizardState from './SubscriptionWizardState'
import { TableCells } from './TableCells'

interface Props {
  state: Instance<typeof SubscriptionWizardState>
  close: () => void,
  shouldShowBack?: boolean,
  onClickConfirm: () => void,
  isLoading?: boolean;
}

export const SlotSelection: FC<Props> = observer(({ state, close, shouldShowBack = false, onClickConfirm, isLoading=false }) => {
  const { find_availability, finding_availability } = state
  useEffect(() => {
    find_availability()
  }, [])

  const unique_days = uniq(state.tutor_availabilities.map(slot => slot.day_of_week).sort((a, b) => a - b))
  const day_col = day_of_week => unique_days.findIndex(d => d === day_of_week)

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle> Please select your preferred Focus 15&apos;s</IonCardTitle>
          {finding_availability
            ? <IonCardSubtitle>
              {state.referral_code === ''
                ? 'Please wait while we find the best tutor for you.'
                : 'Please wait while we find the tutor\'s slots'}
            </IonCardSubtitle>
            : state.available_slots(state?.existing_subscription?.tutor._id).length === 0
              ? <IonCardSubtitle>
                {state.referral_code === ''
                  ? 'We\'re sorry. There are no tutors with availability matching your requirements.'
                  : ( state.is_invalid_referral_code
                    ? 'We\'re sorry. The code you used was invalid. Please go back and try again with a different code.'
                    : 'We\'re sorry. The tutor had no available slots that matched your requirements. Please untick the checkbox in the previous step if you\'d like to find what slots are available for you.'
                  )
                }
              </IonCardSubtitle>
              : <IonCardSubtitle>You have {state.session_count()} Focus 15&apos;s to select</IonCardSubtitle>
          }
        </IonCardHeader>
        {isLoading ?
          <IonLoading
            isOpen={isLoading}
            message={'Please wait...'}
          /> :
          (
            <IonCardContent>
              <ScrollableContent>
                <Grid unique_days={unique_days}>
                  {unique_days.map(day_of_week =>
                    <Head key={day_of_week} col={day_col(day_of_week)}>{Days[day_of_week]}</Head>
                  )}
                  <TableCells
                    slots={state.available_slots(state?.existing_subscription?.tutor._id)}
                    unique_days={unique_days}
                    update_slot_selection={state.update_slot_selection}
                    selected_slots={state.selected_slots}
                  />
                </Grid>
                <IonItem lines='inset'>
                </IonItem>
              </ScrollableContent>
            </IonCardContent>
          )
        }
      </IonCard>
      <IonToolbar>
        <IonButtons slot='end'>
          <IonButton
            fill='solid'
            size='small'
            color='primary'
            className="ion-margin-top"
            onClick={onClickConfirm}
            disabled={state.session_count() !== state.selected_slots.length}
          >
            Confirm
          </IonButton>
        </IonButtons>
        { shouldShowBack ?
          <IonButtons slot='start'>
            <IonButton
              fill='outline'
              size='small'
              color='tertiary'
              className="ion-margin-top"
              onClick={state.back}
            >
                Back
            </IonButton>
          </IonButtons> :
          undefined
        }
      </IonToolbar>
    </>
  )
})

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.unique_days.length}, 1fr);
  justify-items: center;
`

const Head = styled.h4`
  grid-column: ${props => props.col + 1} / span 1;
  grid-row: 1 / span 1;
`

const ScrollableContent = styled.div`
  max-height: 425px;
  overflow-y: auto;
`
