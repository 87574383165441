import styled from 'styled-components'
import { DateTime } from 'luxon'
import { IonText, IonIcon } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import { FC } from 'react'
import { AvailabilitySnapshot } from 'common/types/tutor/availability_status'

interface Props {
  availability_times: AvailabilitySnapshot
  remove_handler?: (day:number,time:number) => () => void
}
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

const ZeroTime = DateTime.now().startOf('day')
const slotFormat = (slot_start_minute_of_day:number) => `${
  ZeroTime.plus({minutes: slot_start_minute_of_day}).toFormat('HH:mm')} - ${
  ZeroTime.plus({minutes: slot_start_minute_of_day + 15}).toFormat('HH:mm')
}`

export const Availability:FC<Props> = ({ availability_times, remove_handler }) => {
  return (
    <Grid>
      {daysOfWeek.map((day, col) =>
        <Head key={day} col={col}>{day}</Head>
      )}
      {daysOfWeek.flatMap((day, col) =>
        availability_times[col].map(slot =>
          <Cell key={`${col}.${slot.time}`} col={col} row={Math.floor(slot.time / 15)}>
            <IonText color={slot.busy ? 'secondary' : 'success'}>
              {slotFormat(slot.time)}
              {!slot.busy && remove_handler !== undefined &&
                <Icon icon={closeCircle} onClick={remove_handler(slot.day_of_week, slot.time)}/>
              }
            </IonText>
          </Cell>
        ))}
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`

const Head = styled.h4`
  grid-column: ${props => props.col + 1} / span 1;
  grid-row: 1 / span 1;
`

const Cell = styled.div`
  grid-column: ${props => props.col + 1} / span 1;
  grid-row: ${props => props.row + 1} / span 1;
`

const Icon = styled(IonIcon)`
  padding-left: 0.5em;
`
