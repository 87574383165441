import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Layout, Main, Sidebar, Messages } from 'components/sessions/focus15'
import { FC, useState } from 'react'
import { IonCardContent, IonSegment, IonSegmentButton, IonIcon, IonLabel } from '@ionic/react'
import { trendingUpOutline, optionsOutline, bookOutline } from 'ionicons/icons'
import { Notes } from 'components/notes'
import { CurrentStudentPerformance } from 'components/stats/CurrentStudentPerformance'
import { StudentTrendPerformance } from 'components/stats/StudentTrendPerformance'
import { QuestionReview, TutorDetailView } from 'components/question_review'

interface IProps {
  roles: Roles[]
}

export const TutorScheduledSessionPage: FC<IProps> = observer(function ({ roles }) {

  const { student_id, subject_id } = useParams<{ student_id: string, subject_id: string }>()
  const [tab, set_tab] = useState<'trend' | 'focus' | 'answers'>('trend')
  const [initialAnswerFilters, setInitialAnswerFilters] = useState([])

  return (
    <PlytimePage roles={roles} fullwidth>
      <Layout>
        <Main>
          {student_id !== undefined
            ? <>
              <IonSegment
                onIonChange={e => set_tab(e.detail.value as any)}
                value={tab}
              >
                <IonSegmentButton value={'trend'} style={{ minWidth: 0 }}>
                  <IonIcon icon={trendingUpOutline} />
                  <IonLabel>Trend</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={'focus'} style={{ minWidth: 0 }}>
                  <IonIcon icon={optionsOutline} />
                  <IonLabel>Focus</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={'answers'} style={{ minWidth: 0 }}>
                  <IonIcon icon={bookOutline} />
                  <IonLabel>Answers</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              {tab === 'trend' &&
                <StudentTrendPerformance student_id={student_id} subject_id={subject_id} changeTab={set_tab} setInitialAnswerFilters={setInitialAnswerFilters}/>
              }
              {tab === 'focus' &&
                <CurrentStudentPerformance student_id={student_id} />
              }
              {tab === 'answers' &&
                <QuestionReview student_id={student_id} AnswerDetailComponent={TutorDetailView} initialFilters={initialAnswerFilters}/>
              }
            </>
            : <p>Loading...</p>
          }
        </Main>
        <Sidebar>
          <Messages>
            <IonCardContent>
              {student_id && subject_id && <Notes student={student_id} subject={subject_id} />}
            </IonCardContent>
          </Messages>
        </Sidebar>
      </Layout>
    </PlytimePage>
  )
})

export default TutorScheduledSessionPage
