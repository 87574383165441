import { types, SnapshotIn } from 'mobx-state-tree'
import { QuestionId } from 'common/types/question/id'
import { EndcodeId } from 'common/types/subject/id'
import { TextElement } from 'common/types/question/elements/text'
import { TextInputElement } from 'common/types/question/elements/text_input'
import { ImageElement } from 'common/types/question/elements/image'
import { McElement } from 'common/types/question/elements/mc'
import { AllowedAnswers } from 'common/types/question/answer/answer'
import { BaseSubject } from 'common/types/subject/subject'
import { Score } from 'common/types/question/score'
import { PositiveInteger } from 'common/types/basic/positive_int'
import { Status } from './status'
import { CoordinateInputElement } from './elements/coordinate'
import { RatioXYInputElement } from './elements/ratioXY'
import { RatioXYZInputElement } from './elements/ratioXYZ'
import { FractionInputElement } from './elements/fraction'
import { MixedFractionInputElement } from './elements/mixed_fraction'
import { TimeInputElement } from './elements/time'
import { GbpInputElement } from './elements/gbp'
import { NumberInputElement } from './elements/number'

export const ElementTypes = [TextElement, TextInputElement, ImageElement, McElement]
export const Element = types.union(TextElement, TextInputElement,TimeInputElement, ImageElement, McElement, CoordinateInputElement,
  RatioXYInputElement, RatioXYZInputElement, FractionInputElement, MixedFractionInputElement, GbpInputElement, NumberInputElement ) 
export type Element = typeof Element
export const AnswerElement = types.union(TextInputElement, McElement, TimeInputElement, CoordinateInputElement, RatioXYInputElement, 
  RatioXYZInputElement, FractionInputElement, MixedFractionInputElement, GbpInputElement, NumberInputElement)
export type AnswerElement = typeof AnswerElement


export const Line = types.array(Element)
export type Line = typeof Line

export const Lines = types.array(Line)
export type Lines = typeof Lines

const AskedQuestionBase_ = types.model('AskedQuestionBase_', {
  _id: QuestionId,
  ref: types.string,
  score: Score,
  maximum_time: PositiveInteger,
  standard_time: PositiveInteger,
})

export const AskedQuestionBase = types.refinement(
  'AskedQuestionBase',
  AskedQuestionBase_,
  (snapshot:SnapshotIn<typeof AskedQuestionBase_>) => snapshot.maximum_time > snapshot.standard_time,
  () => 'Maximum time must be longer than standard time'
)

export const AskedQuestion = AskedQuestionBase_
  .named('AskedQuestion')
  .props({
    lines: Lines,
  })
export type AskedQuestion = typeof AskedQuestion

export const AdminQuestionBase = AskedQuestionBase_
  .named('AdminQuestionBase')
  .props({
    status: Status,
    endcode: BaseSubject,
    topic: BaseSubject,
    theme: BaseSubject,
    subject: BaseSubject
  })

export const AdminQuestion = AdminQuestionBase
  .named('AdminQuestion')
  .props({
    lines: Lines,
    answers: AllowedAnswers,
  })
export type AdminQuestion = typeof AdminQuestion

export const QuestionUpsertRequest = types.model({
  params: types.model({
    endcode_id: EndcodeId
  }),
  body: AdminQuestion
})
