import { ProfilePicturePostRequest, ProfilePictureResponse, ProfilePictureGetRequest } from 'common/types/profile/picture'
import { PlytimeUser } from 'common/types/profile/user'
import { api } from 'util/api'
import { types, Instance } from 'mobx-state-tree'

const MeGetRequest = types.model({
})
export type MeGetRequest = Instance<typeof MeGetRequest>

export const getMe = api(MeGetRequest, PlytimeUser, 'GET', 'profile/me')

export const updateProfilePicture = api(ProfilePicturePostRequest, ProfilePictureResponse, 'POST', 'profile/user_id/picture')

export const getProfilePictureUri = api(ProfilePictureGetRequest, ProfilePictureResponse, 'GET', 'profile/user_id/picture')
