import { useState, useEffect } from 'react'
import { useStore } from 'types/store'
import { useUser } from 'context/userContext'
import { observer } from 'mobx-react-lite'
import { ParentDetails } from 'components/parent/ParentDetails'
import { getMe } from 'util/api/profile/me'
import { ButtonWithImage } from 'generic_components/ButtonWithImage'
import { IAnyModelType, Instance, types } from 'mobx-state-tree'
import styled from 'styled-components'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from '@ionic/react'
import { PlytimePage } from 'components/plytime_page'
import { Roles, useSignout } from 'util/auth/helper'
import { useUpcomingSessions } from 'components/sessions/useUpcomingSessions'
import { SessionActions } from 'components/parent/session/actions'
import { usePastSessions } from 'components/sessions/usePastSessions'

interface IProps {
  roles: Roles[]
}

export default observer(({roles}: IProps) => {
  const { user, change_role, set_username } = useUser()
  const parentExistsInDB = user?._id !== undefined
  const { parent_data: { profile, get_profile, busy } } = useStore()
  const [newParent, setNewParent] = useState<undefined | { parent: Instance<IAnyModelType> }>()

  const signout = useSignout()
  const loginAsStudent = async (studentUsername: string) => {
    console.log('login as student:' + studentUsername)
    try {
      change_role(Roles.STUDENT)
      set_username(studentUsername)
      await signout()
    } catch (error) {
      console.log('error signing out: ', error)
    }
  }

  const { sessions, busy: loading_sessions, refresh, load_next_week } = useUpcomingSessions()
  const { past_sessions, past_busy: loading_past_sessions, past_refresh,  load_previous_week } = usePastSessions()

  useEffect(() => {
    const f = async () => {
      try {
        if (!busy && !parentExistsInDB) {
          const { username: email, uid } = await getMe()
          const new_parent = types.model({
            _id: types.string,
            uid: types.string,
            email: types.string,
            email_opt_in: types.boolean
          }).create({ _id: uid, uid, email, email_opt_in: false })

          setNewParent(() => ({ parent: new_parent }))
        }
        if (user.role !== 'PARENT') {
          console.log(`unexpected mounting Parent home page for ${user.role}`)
        }
        if (!busy && parentExistsInDB && user.role === 'PARENT') {
          //role check is a hack around a bug where the parent page unexpectedly remounts then unmounts after switching to student account
          console.log('get parent from parent home')
          console.log(JSON.stringify(user, null, 2))
          get_profile()
        }
      } catch (err) {
        console.error(err)
      }
    }
    f()
    return () => { console.log('unmount parent page') }
  }, [user])

  return (
    <PlytimePage roles={roles}>
      {newParent && !parentExistsInDB && <ParentDetails initial_profile={newParent.parent} />}
      {parentExistsInDB && profile && (
        <>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                Upcoming sessions
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow style={{ height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast' }}>
                  <IonCol size='2'>
                    <IonLabel style={{ height: '100%' }}> Date </IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                    <IonLabel style={{ height: '100%' }}> Time </IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                    <IonLabel style={{ height: '100%' }}> Length </IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                    <IonLabel style={{ height: '100%' }}> Subject </IonLabel>
                  </IonCol>
                  <IonCol size='2'>
                    <IonLabel style={{ height: '100%' }}> Tutor </IonLabel>
                  </IonCol>
                  <IonCol size='2'>
                    <IonLabel style={{ height: '100%' }}> Student </IonLabel>
                  </IonCol>
                  <IonCol size='2'>
                    <IonLabel style={{ height: '100%' }}> Status </IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                    <IonLabel style={{ height: '100%' }}> Actions </IonLabel>
                  </IonCol>
                </IonRow>
                {sessions.map(session => {
                  return (
                    <IonRow key={session.session_date_time} className='alternate-light-medium'>
                      <IonCol size='2'>
                        {session.day}
                      </IonCol>
                      <IonCol size='1'>
                        {session.time}
                      </IonCol>
                      <IonCol size='1'>
                        {session.session_length}
                      </IonCol>
                      <IonCol size='1'>
                        {session.subject.name}
                      </IonCol>
                      <IonCol size='2'>
                        {session.tutor.first_name}
                      </IonCol>
                      <IonCol size='2'>
                        {session.student.first_name}
                      </IonCol>
                      <IonCol size='2'>
                        {session.sessionStatus}
                      </IonCol>
                      <IonCol size='1'>
                        <SessionActions session={session} refresh={refresh} />
                      </IonCol>
                    </IonRow>
                  )
                })}
                {loading_sessions
                  ? <IonRow className='alternate-light-medium'>
                    Loading...
                  </IonRow>
                  : <IonRow
                    className='alternate-light-medium'
                    onClick={load_next_week}
                  >
                    {sessions.length === 0 ? 'No upcoming sessions this week, click here to try loading the next week' : 'Load Next Week'}
                  </IonRow>
                }
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                Past sessions
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow style={{ height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast' }}>
                  <IonCol size='2'>
                    <IonLabel style={{ height: '100%' }}> Date </IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                    <IonLabel style={{ height: '100%' }}> Time </IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                    <IonLabel style={{ height: '100%' }}> Length </IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                    <IonLabel style={{ height: '100%' }}> Subject </IonLabel>
                  </IonCol>
                  <IonCol size='2'>
                    <IonLabel style={{ height: '100%' }}> Tutor </IonLabel>
                  </IonCol>
                  <IonCol size='2'>
                    <IonLabel style={{ height: '100%' }}> Student </IonLabel>
                  </IonCol>
                  <IonCol size='2'>
                    <IonLabel style={{ height: '100%' }}> Status </IonLabel>
                  </IonCol>
                  <IonCol size='1'>
                    <IonLabel style={{ height: '100%' }}> Actions </IonLabel>
                  </IonCol>
                </IonRow>
                {past_sessions.map(session => {
                  return (
                    <IonRow key={session.session_date_time} className='alternate-light-medium'>
                      <IonCol size='2'>
                        {session.day}
                      </IonCol>
                      <IonCol size='1'>
                        {session.time}
                      </IonCol>
                      <IonCol size='1'>
                        {session.session_length}
                      </IonCol>
                      <IonCol size='1'>
                        {session.subject.name}
                      </IonCol>
                      <IonCol size='2'>
                        {session.tutor.first_name}
                      </IonCol>
                      <IonCol size='2'>
                        {session.student.first_name}
                      </IonCol>
                      <IonCol size='2'>
                        {session.sessionStatus}
                      </IonCol>
                      <IonCol size='1'>
                        <SessionActions session={session} refresh={past_refresh} />
                      </IonCol>
                    </IonRow>
                  )
                })}
                {loading_past_sessions
                  ? <IonRow className='alternate-light-medium'>
                    Loading...
                  </IonRow>
                  : <IonRow
                    className='alternate-light-medium'
                    onClick={load_previous_week}
                  >
                    {sessions.length === 0 ? 'No past sessions this week, click here to try loading the previous week' : 'Load Previous Week'}
                  </IonRow>
                }
              </IonGrid>
            </IonCardContent>
          </IonCard>
          <OuterContainer>
            <Container>
              <ButtonWithImage ion_icon='pencil' text='View/edit profile' ion_icon_color='green' routerLink='/parent/profile' />
              <ButtonWithImage ion_icon='syncOutline' text='Add/Manage subscriptions' ion_icon_color='blue' routerLink='/parent/subscriptions' />
              <ButtonWithImage ion_icon='syncOutline' text='View student performance' ion_icon_color='red' routerLink='/parent/student_performance' />
              {profile.students.map((student, i) =>
                <ButtonWithImage
                  key={student._id}
                  ion_icon='switchUser'
                  text={`Login as ${student.first_name}`}
                  ion_icon_color={['blue', 'yellow', 'red'][i % 3] as any}
                  onClick={() => loginAsStudent(student.username)}
                />
              )}
              <ButtonWithImage text='Audio/Video Check' routerLink='/parent/diagnostics' ion_icon='cameraOutline' ion_icon_color='green' />

            </Container>
          </OuterContainer>
        </>
      )}
    </PlytimePage>
  )
})

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 350px;
`

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`
