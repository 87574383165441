import { types, Instance } from 'mobx-state-tree'

export const relative_level_lookup = {
  'A year behind': -12,
  '6 Months behind': -6,
  '3 Months behind': -3,
  'On track': 0,
  '3 months ahead': 3,
  '6 Months ahead': 6,
  'A year ahead': 12,
}

export const relative_levels = Object.keys(relative_level_lookup) as unknown as Array<keyof typeof relative_level_lookup>

export const RelativeLevels = types.enumeration('RelativeLevels', relative_levels)
export type RelativeLevel = Instance <typeof RelativeLevels>
