import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'

import { getPastSessions } from 'util/api/sessions'
import { useUser } from 'context/userContext'
import { Session, decorate } from './useUpcomingSessions'
import { Roles } from 'util/auth/helper'

export function usePastSessions (tutor:string = undefined) {
  const { user } = useUser()
  const [past_sessions, set_past_sessions] = useState<Session[]>([])
  const [past_busy, set_past_busy] = useState(true)
  const [weeks, set_weeks] = useState(1)

  const past_refresh = async () => {
    if( user === undefined ) {
      set_past_sessions([])
      return
    }
    const selector = user.role === Roles.STUDENT ? { student_id: user._id }
      : user.role === Roles.PARENT ? {parent_id: user._id }
        : user.role === Roles.ADMIN ? {tutor_id: tutor }
          : { tutor_id: user._id }
    try{
      set_past_busy(true)
      const sessions = await Promise.all(Array.from({length: weeks}).map((_,week) =>
        getPastSessions(selector, DateTime.now().minus({week}).toISO())
      ))
      set_past_sessions(sessions.flat().map(decorate))
    } finally {
      set_past_busy(false)
    }
  }

  useEffect(
    () => { past_refresh() },
    [user, weeks]
  )

  const load_previous_week = async () => {
    if( past_busy ) { throw new Error('busy') }
    set_weeks(weeks => weeks + 1)
  }

  return { past_sessions, past_refresh, past_busy, load_previous_week }
}
