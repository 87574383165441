import { useEffect } from 'react'
import { useIonViewWillLeave } from '@ionic/react'

export const useOnLeavePage = (action:()=>void, dependancies:unknown[]) => {
  useEffect(() => {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault()
      ev.returnValue = action()
      return ev
    })
    return () => {
      action()
      window.removeEventListener('beforeunload', (action))
    }
  }, dependancies)

  useIonViewWillLeave(() => {
    window.removeEventListener('beforeunload', action) // prevents double posting game summary
    action()
  })
}
