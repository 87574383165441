import { FC } from 'react'
import {
  IonIcon,
  useIonActionSheet,
} from '@ionic/react'
import { ellipsisHorizontalCircleOutline } from 'ionicons/icons'
import { Session } from 'components/sessions/useUpcomingSessions'
import { Messages } from 'components/tutor/session/messages'
import { useSessionActionModal } from 'components/tutor/session/useActionModal'
import { UnreadIndicator } from 'components/notes/unread_indicator'
import { DateTime } from 'luxon'

interface Props {
  session: Session
  refresh: () => Promise<void>
}

export const SessionActions: FC<Props> = ({ session, refresh }) => {
  const [open_actions] = useIonActionSheet()
  const is_past_session = session.session_date_time_end && DateTime.fromISO(session.session_date_time_end) < DateTime.now()
  
  const messages_modal = useSessionActionModal(Messages, session, refresh)

  return <div
    onClick={() => open_actions({
      buttons: !is_past_session ? [
        {
          text: 'Messages',
          handler: messages_modal
        },
      ] : [],
      header: 'Session actions',
      cssClass: 'session-actions',
    })}
  >
    <IonIcon
      icon={ellipsisHorizontalCircleOutline}
    />
    <UnreadIndicator session={session} student={session.student_id} subject={session.subject._id}/>
  </div>
}
