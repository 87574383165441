import { PlytimePage } from 'components/plytime_page'
import { ManageQuestions } from 'components/manage_questions'
import { Roles } from 'util/auth/helper'

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  return (
    <PlytimePage roles={roles}>
      <ManageQuestions />
    </PlytimePage>
  )
}
