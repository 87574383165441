import { FC } from 'react'
import { useStore } from 'types/store'
import { observer } from 'mobx-react-lite'
import { SnapshotOut, types } from 'mobx-state-tree'
import { MSTForm } from 'generic_components/MstForm'
import { NewStudent, Student } from 'common/types/student'
import { StringInput } from 'generic_components/MstFormStringInput'
import { NumberInput } from 'generic_components/MstFormNumberInput'
import { StringSelector } from 'generic_components/MstFormStringSelector'
import { DateInput } from 'generic_components/MstFormDate'
import { Gender } from 'common/types/basic/gender'

const blank_student = types.model({}).create({})

const GenderSelector = StringSelector((Gender as any)._types)

interface Props {
  close_create_student_modal: () => void
  on_saved: (student: SnapshotOut<Student>) => void
}

export const CreateStudent: FC<Props> = observer(({ close_create_student_modal, on_saved }) => {
  const { parent_data } = useStore()

  const submit = async (student: SnapshotOut<NewStudent>) => {
    const saved_student = await parent_data.add_student(student)
    on_saved(saved_student)
    close_create_student_modal()
  }

  return (
    <MSTForm
      title='New student details'
      initial={blank_student}
      model={NewStudent}
      submit={submit}
      force_edit_mode
      on_reset={close_create_student_modal}
      submit_text='Create student'
      success_text='Student created. Please check your email for their one-time password.'
      fields={[
        {
          name: 'first_name',
          label: 'First name',
          Component: StringInput
        },
        {
          name: 'last_name',
          label: 'Last name',
          Component: StringInput
        },
        {
          name: 'username',
          label: 'Username',
          Component: StringInput
        },
        {
          name: 'dob',
          label: 'Date of birth',
          Component: DateInput
        },
        {
          name: 'gender',
          label: 'Gender',
          Component: GenderSelector
        },
        {
          name: 'school',
          label: 'School attended',
          Component: StringInput
        },
        {
          name: 'school_year',
          label: 'Student current school year',
          Component: NumberInput
        },
      ]}
    />
  )
})
