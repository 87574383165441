import { types } from 'mobx-state-tree'
import { StudentId } from '../student/id'
import { DateTimeString } from '../basic/date_time_string'
import { SubjectId } from '../subject/id'

export const ReadTime = types.model('ReadTime', {
  timestamp: DateTimeString,
})
export type ReadTime = typeof ReadTime

export const PutLastReadRequest = types.model('PutLastReadRequest',{
  params: types.model({
    student: StudentId,
    subject: SubjectId
  }),
  body: types.model({
    timestamp: DateTimeString
  })
})
export type PutLastReadRequest = typeof PutLastReadRequest

export const GetUnreadNotesRequest = types.model('GetUnreadNotesRequest',{
  params: types.model({
    student: StudentId,
    subject: SubjectId
  }),
})
export type GetUnreadNotesRequest = typeof GetUnreadNotesRequest

export const GetUnreadNotesResponse = types.model('GetUnreadNotesResponse',{
  timestamp: DateTimeString,
  count: types.number
})
export type GetUnreadNotesResponse = typeof GetUnreadNotesResponse
