import logo from '../images/plytime-logo.png'
import styled from 'styled-components'

export const Logo = () =>
  <Img src={logo}/>

const Img = styled.img`
  height: 60px;
  margin: 15px;
`
