import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { getAllSubjects } from '../../util/api/subject'
import { Subject } from './subject'

export const Focus = types.model('Focus', {
  subjects: types.array(Subject)
})
  .actions(self => ({
    refresh: flow(function* () {
      const subjects = yield getAllSubjects()
      applySnapshot(self, { subjects })
    })
  }))

export type Focus = Instance<typeof Focus>
