import { Instance, types } from 'mobx-state-tree'
import { MetaSession } from 'common/types/session'
import { StudentId } from 'common/types/student/id'
import { SubjectId } from 'common/types/subject/id'
import { TutorId } from 'common/types/tutor/id'
import { RelativeLevels } from 'common/types/student/relative_levels'
import { SessionOptions } from '../session/options'
import { ReferralCode } from '../referral_code'
import { DateTimeString } from 'common/types/basic/date_time_string'

export const Subscription = types.model('Subscription', {
  _id: types.identifier,
  student: types.model({
    _id: StudentId,
    name: types.string,
  }),
  subject: types.model({
    _id: SubjectId,
    name: types.string,
  }),
  tutor: types.model({
    _id: types.maybe(TutorId),
    name: types.maybe(types.string),
  }),
  session_option: SessionOptions,
  payment_amount: types.string,
  payment_date: types.maybeNull(DateTimeString),
  start_date: DateTimeString,
  updated_start_date: DateTimeString,
  stripe_subscription_id: types.string,
  assigned_sessions: types.array(MetaSession),
  cancelled: types.optional(types.boolean, false),
  referralCode: types.maybeNull(ReferralCode),
  slots: types.array(types.string)
})
export type Subscription = Instance<typeof Subscription>

export const GetSubscriptionsRequest = types.model({})
export type GetSubscriptionsRequest = Instance<typeof GetSubscriptionsRequest>

export const GetSubscriptionsResponse = types.array(Subscription)
export type GetSubscriptionsResponse = Instance<typeof GetSubscriptionsResponse>

export const GetDeleteSubscriptionByIdRequest = types.model({
  params: types.model({
    subscription_id: types.string
  })
})
export type GetDeleteSubscriptionByIdRequest = Instance<typeof GetDeleteSubscriptionByIdRequest>

export const DeleteSubscriptionResponse = types.model({
  subscription_id: types.string
})
export type DeleteSubscriptionResponse = Instance<typeof DeleteSubscriptionResponse>

export const SelectedSlot = types.model({
  day_of_week: types.number,
  time: types.number,
})
export type SelectedSlot = Instance<typeof SelectedSlot>

export const SelectedSlots = types.array(SelectedSlot)
export type SelectedSlots = Instance<typeof SelectedSlots>

export const CreateSubscriptionRequest = types.model({
  body: types.model({
    student: StudentId,
    subject: SubjectId,
    session_option: SessionOptions,
    slots: SelectedSlots,
    relative_level: RelativeLevels,
    success_url: types.string,
    error_url: types.string,
    referral_code: types.maybeNull(types.string),
    tutor_id: types.maybe(types.string),
  })
})
export type CreateSubscriptionRequest = Instance<typeof CreateSubscriptionRequest>

export const CreateSubscriptionResponse = types.model({
  redirect_url: types.string
})
export type CreateSubscriptionResponse = typeof CreateSubscriptionResponse

export const RescheduleSubscriptionRequest = types.model({
  params: types.model({
    subscription_id: types.string
  }),
  body: types.model({
    old_day_of_week: types.number,
    old_time: types.number,
    new_day_of_week: types.number,
    new_time: types.number,
    session_length: types.number,
  })
})
export type RescheduleSubscriptionRequest = typeof RescheduleSubscriptionRequest

export const SubstituteSubscriptionRequest = types.model({
  params: types.model({
    subscription_id: types.string
  })
})
export type SubstituteSubscriptionRequest = typeof SubstituteSubscriptionRequest
