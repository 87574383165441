import { observer } from 'mobx-react-lite'
import { IonSelect, IonSelectOption } from '@ionic/react'
import { FieldComponent } from 'generic_components/MstForm'
import { InputChangeEventDetail } from '@ionic/core'

interface IOptions {
    key?: string,
    value: string,
}

export const StringSelector = <T extends string>(options: IOptions[]): FieldComponent<string> =>
  observer(({value, onChange, disabled}) => {
    const onIonChange = (e: CustomEvent<InputChangeEventDetail>) =>
      onChange(e.detail.value)

    return (
      <IonSelect
        value={value}
        onIonChange={onIonChange}
        disabled={disabled}
      >
        {options.map(({key, value}) =>
          <IonSelectOption
            key={value}
            value={value}
          >
            {key ?? value}
          </IonSelectOption>
        )}
      </IonSelect>
    )
  })
