import { useEffect, useState, FC } from 'react'
import axios from 'axios'
import { observer } from 'mobx-react-lite'

import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'

const BASE_URL = '/api'
const getApiStatus = async () => {
  try {
    const res = await axios.get(`${BASE_URL}`)

    const payload = res.data

    console.log('GET: Here\'s the api status', JSON.stringify(payload))

    return payload
  } catch (e) {
    console.error(e)
  }
}

interface IProps {
  roles: Roles[]
}

export const Home: FC<IProps> = observer(({ roles }) => {
  const [status, setStatus] = useState(undefined as any)
  useEffect(() => {
    getApiStatus().then(setStatus)
  }, [])
  return (
    <PlytimePage roles={roles}>
      <p> API Status: {status?.message} </p>
    </PlytimePage>
  )
})


