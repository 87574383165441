import { types, Instance } from 'mobx-state-tree'
import { SubjectId } from 'common/types/subject/id'
import { Theme } from './theme'

export const Subject = types
  .model('Subject', {
    _id: SubjectId,
    name: types.string,
    themes: types.array(Theme),
    selected: types.optional(types.boolean, false)
  })
  .actions(self => ({
    select(selected: boolean) {
      self.selected = selected
      if (selected) {
        self.themes.forEach(theme => {
          theme.select(false)
          theme.topics.forEach(topic => {
            topic.select(false)
          })
        })
      }
    }
  }))

export type Subject = Instance<typeof Subject>
