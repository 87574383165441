import { observer } from 'mobx-react-lite'
import { IonInput } from '@ionic/react'
import { FieldComponent } from 'generic_components/MstForm'

export const NumberInput:FieldComponent<number> = observer(({value, onChange, disabled}) => {
  const onIonChange = (e:CustomEvent<KeyboardEvent>) => {
    const parsed = Number.parseInt((e.target as any).value)
    onChange(parsed)
  }
  return (
    <IonInput
      type='number'
      value={value}
      onIonInput={onIonChange}
      disabled={disabled}
    />) 
})
