import { PlytimePage } from 'components/plytime_page'
import { useEffect } from 'react'
import { Roles } from 'util/auth/helper'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonLabel,
  IonInput,
  IonItem,
} from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'types/store'
import StudentScoreRow from './StudentScoreRow'

interface IProps {
  roles: Roles[]
}

export default observer(function ({ roles }: IProps) {

  const { admin_student_score_list: { studentSubjectScores, refresh, busy, changed, filter, set_student_id_filter } } = useStore()

  useEffect(() => {
    refresh()
  },[])

  return (
    <PlytimePage roles={roles} title='Students'>
      <IonCard color='tertiary'>
        <IonCardHeader>
          <IonCardTitle>
            Student Score Search
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size='5'>
                <IonItem>
                  <IonLabel position='stacked'>
                    Student ID
                  </IonLabel>
                  <IonInput
                    type='text'
                    value={filter.studentId}
                    onIonChange={e => set_student_id_filter(e.detail.value)}
                  />
                </IonItem>
              </IonCol>
              <IonCol>
                <IonButton
                  fill='solid'
                  size='small'
                  color='primary'
                  className="ion-margin-top"
                  disabled={busy || changed}
                  onClick={() => refresh(Math.max(filter._from - 50, 0))}
                >
                  Previous
                </IonButton>
                <IonButton
                  fill='solid'
                  size='small'
                  color='primary'
                  className="ion-margin-top"
                  disabled={busy || !changed}
                  onClick={() => refresh()}
                >
                  Search
                </IonButton>
                <IonButton
                  fill='solid'
                  size='small'
                  color='primary'
                  className="ion-margin-top"
                  disabled={busy || changed}
                  onClick={() => refresh(filter._from + filter._limit)}
                >
                  Next
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonItem>{busy && 'Loading...'}</IonItem>
      <IonGrid>
        <IonRow color={'light'}>
          <IonCol size='2'><h4>Student ID</h4></IonCol>
          <IonCol size='1.5'><h4>Name</h4></IonCol>
          <IonCol size='2'><h4>Parent Email</h4></IonCol>
          <IonCol size='2'><h4>Tutor</h4></IonCol>
          <IonCol size='1.5'><h4>Subject</h4></IonCol>
          <IonCol size='1'><h4>Score</h4></IonCol>
          <IonCol size='2'><h4></h4></IonCol>
        </IonRow>
        {studentSubjectScores.map(studentScore => (
          <StudentScoreRow
            key={studentScore._id}
            studentScore={studentScore}
            resetCallback={refresh}
          />
        ))}
      </IonGrid>
    </PlytimePage>
  )
})

