import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { IonButton } from '@ionic/react'

import { useUser } from '../context/userContext'
import { useStore } from 'types/store'
import { Roles } from 'util/auth/helper'

export const homeLookup = (role?: Roles) => {
  switch(role) {
    case Roles.STUDENT: return '/student'
    case Roles.PARENT: return '/parent'
    case Roles.TUTOR: return '/tutor'
    case Roles.ADMIN: return '/admin'
    case Roles.TEST: return '/admin'
  }
}

const HomeButton: FC = observer(() => {
  const { user } = useUser()
  const store = useStore()
  const { game: { gameInPlay, terminate_game } } = store
  return (
    user && (
      <IonButton routerLink={homeLookup(user.role)} onClick={terminate_game}>
        {gameInPlay ? 'Quit game' : 'Home'}
      </IonButton>
    ))
})

export default HomeButton
