import { types, Instance, flow, getSnapshot, applySnapshot, getParent, SnapshotIn } from 'mobx-state-tree'
import { Topic } from 'types/question_management/topic/topic'
import { getTheme, postSubjectTheme } from 'util/api/subject/theme'
import { getThemeTopics } from 'util/api/subject/theme/topic'
import { AdminTheme as BaseTheme } from 'common/types/subject/theme'
import { omit } from 'lodash'

export const Theme = BaseTheme.props({
  topics: types.array(Topic)
})
  .actions(self => ({
    update_name (name:string) {
      self.name = name
    },
    update_min_score (score:number) {
      self.min_score = score
    },
    update_max_score (score:number) {
      self.max_score = score
    },
    load_children: flow(function*() {
      try {
        self.topics = yield getThemeTopics({ params: { theme_id: self._id } })
      } catch (error) {
        console.error('Failed to fetch topics', error)
      }
    }),
    add_topic (topic:Topic) {
      self.topics.push(topic)
    },
    save: flow(function*(update:SnapshotIn<BaseTheme>) {
      const old = getSnapshot(self)
      try {
        const parent = getParent<any>(self, 2) //TODO this is a risky approach there is nothing to stop someone instantiation a Question in another store
        applySnapshot(self, {
          ...update,
          topics: self.topics
        })
        const updated_self = yield postSubjectTheme({
          params: {
            subject_id: parent._id 
          },
          body: omit(update, 'topics')
        })
        applySnapshot(self, {
          ...updated_self,
          topics: self.topics
        })
      } catch (error) {
        applySnapshot(self, old)
        throw error
      }
    }),
    refresh: flow(function*() {
      try {
        const updated_self = yield getTheme({ params: { theme_id: self._id }})
        applySnapshot(self, {
          ...updated_self,
          topics: self.topics
        })
        const subject = getParent<any>(self, 2) //TODO this is a risky approach there is nothing to stop someone instantiating a Question in another store
        yield subject.refresh()
      } catch (error) {
        console.error('Failed to refesh theme', error)
      }
    })
  }))

export type Theme = Instance<typeof Theme>
