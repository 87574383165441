import { FC } from 'react'
import { IonText } from '@ionic/react'

interface Props {
  error: string
  field: string
}

export const FormInputError: FC<Props> = ({ error, field }) =>
  <IonText color="danger" className="ion-padding-start">
    <small>
      <span role="alert" id={`${field}Error`}>
        {error}
      </span>
    </small>
  </IonText>
