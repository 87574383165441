import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { StoreProvider, InitialStore } from 'types/store'
import { IonApp } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={InitialStore}>
      <IonApp>
        <IonReactRouter>
          <App />
        </IonReactRouter>
      </IonApp>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
