import { api } from 'util/api'
import { Subscription, CreateSubscriptionRequest, GetDeleteSubscriptionByIdRequest, DeleteSubscriptionResponse, GetSubscriptionsRequest, GetSubscriptionsResponse, RescheduleSubscriptionRequest, CreateSubscriptionResponse, SubstituteSubscriptionRequest } from 'common/types/subscription'
import { ModifySessionResponse } from 'common/types/session'

export const createSubscription = api(CreateSubscriptionRequest, CreateSubscriptionResponse, 'POST', 'subscription')

const _getSubscription = api(GetDeleteSubscriptionByIdRequest, Subscription, 'GET', 'subscription/subscription_id')
export const getSubscription = async (subscription_id) => {
  const data = await _getSubscription({ params: {subscription_id}})
  return data
}

const _deleteSubscription = api(GetDeleteSubscriptionByIdRequest, DeleteSubscriptionResponse, 'DELETE', 'subscription/subscription_id')
export const deleteSubscription = async (subscription_id) => {
  const data = await _deleteSubscription({ params: {subscription_id}})
  return data
}

const _listSubscriptions = api(GetSubscriptionsRequest, GetSubscriptionsResponse, 'GET', 'subscription/list')
export const listSubscriptions = async () => {
  const data = await _listSubscriptions()
  return data
}

export const rescheduleSubscription = api(RescheduleSubscriptionRequest, ModifySessionResponse, 'POST', 'subscription/subscription_id/reschedule')
export const substituteSubscription = api(SubstituteSubscriptionRequest, ModifySessionResponse, 'POST', 'subscription/subscription_id/substitute')
