import { FC, ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { Question } from 'types/question_management/question/question'
import styled from 'styled-components'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonInput,
  IonItem,
  IonLabel,
  IonToolbar,
} from '@ionic/react'

interface Props {
  question: Question
}

export const AnswerEditor:FC<Props> = observer(({question}) => {

  const add_answer_part = (answer:number) => () => question.add_answer_part(answer)
  const remove_answer = (answer:number) => () => question.remove_answer(answer)
  const add_answer_value = (answer:number, part:number) => () => question.add_answer_value(answer, part)
  const remove_answer_part = (answer:number, part:number) => () => question.remove_answer_part(answer, part)

  const update_ref = (answer:number, part:number) => (e:any) => {
    question.update_answer_ref(answer, part, e.target.value)
  }

  const update_value = (answer:number, part:number, value:number) => (e:any) => {
    question.update_answer_value(answer, part, value, e.target.value)
  }

  return (
    <Lines>
      {question.answers.map((answer, row) =>
        <IonCard key={row}>
          <IonCardHeader>
            <IonCardSubtitle>
              Answer {row + 1}
            </IonCardSubtitle>
          </IonCardHeader>
          <Content>
            {answer.map((part, col) =>
              <IonCard key={col}>
                <IonCardHeader>
                  <IonCardSubtitle>
                    Part {col + 1}
                  </IonCardSubtitle>
                </IonCardHeader>
                <Content>
                  <IonItem>
                    <IonLabel position='floating'>Answer ref</IonLabel>
                    <IonInput
                      type='text'
                      value={part.ref}
                      onIonInput={update_ref(row, col)}
                    />
                  </IonItem>
                  {part.values.map((value, v) =>
                    <IonItem key={v}>
                      <IonLabel position='floating'>Answer value</IonLabel>
                      <IonInput
                        type='text'
                        value={value}
                        onIonInput={update_value(row, col, v)}
                      />
                    </IonItem>
                  )}
                  <IonToolbar>
                    <IonButtons slot='end'>
                      <IonButton
                        fill='outline'
                        size='small'
                        color='danger'
                        className="ion-margin-top"
                        type="button"
                        disabled={answer.length < 2}
                        onClick={remove_answer_part(row, col)}
                      >
                        Delete ref part
                      </IonButton>
                      <IonButton
                        fill='outline'
                        size='small'
                        color='primary'
                        className="ion-margin-top"
                        type="button"
                        onClick={add_answer_value(row, col)}
                      >
                        Add alternate value
                      </IonButton>
                    </IonButtons>
                  </IonToolbar>
                </Content>
              </IonCard>
            )}
            <IonToolbar>
              <IonButtons slot='end'>
                <IonButton
                  fill='outline'
                  size='small'
                  color='danger'
                  className="ion-margin-top"
                  type="button"
                  disabled={question.answers.length < 2}
                  onClick={remove_answer(row)}
                >
                  Delete answer
                </IonButton>
                <IonButton
                  fill='outline'
                  size='small'
                  color='primary'
                  className="ion-margin-top"
                  type="button"
                  onClick={add_answer_part(row)}
                >
                  Add Ref Part
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </Content>
        </IonCard>
      )}
      <IonToolbar>
        <IonButtons slot='end'>
          <IonButton
            fill='outline'
            size='small'
            color='primary'
            className="ion-margin-top"
            type="button"
            onClick={question.add_answer}
          >
            Add Answer
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </Lines>
  )
})

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`

const Content = styled(IonCardContent)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`
