import { FC, useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonInput,
  IonItem,
  IonLabel,
  IonToolbar,
} from '@ionic/react'
import { rescheduleSession } from 'util/api/sessions'
import { Session } from 'components/sessions/useUpcomingSessions'
import { DateTime } from 'luxon'

interface Props {
  session: Session
  close: () => void
}

export const RescheduleSession: FC<Props> = ({ session, close }) => {
  const [busy, set_busy] = useState(false)
  const [new_time, set_new_time] = useState('')
  const time = DateTime.fromISO(new_time)
  const valid = time.isValid && time > DateTime.now()

  const reschedule_session = async () => {
    set_busy(true)
    try {
      await rescheduleSession(session._id, session.subscription_id, session.session_date_time, time.toISO())
      close()
    } finally {
      set_busy(false)
    }
  }

  return (
    <IonCard style={{ margin: 0, height: '25rem' }}>
      <IonCardHeader>
        <IonCardTitle>Reschedule single session</IonCardTitle>
        <IonCardSubtitle>{session.subject.name} with {session.student.first_name} on {session.day} at {session.time}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <ul>
          <li>
            <p>Do not bring a session forward by more than 48 hours.</p>
          </li>
          <li>
            <p>Make sure you have agreed the new time with the parent.</p>
          </li>
        </ul>
        <IonItem lines='inset'>
          <IonLabel position='stacked'>New date/time</IonLabel>
          <IonInput
            type='datetime-local'
            value={new_time}
            onIonInput={e => set_new_time((e.target as any).value)}
          />
        </IonItem>
        <p>Would you like to reschedule the session?</p>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton
              fill='solid'
              size='small'
              color='primary'
              className="ion-margin-top"
              onClick={reschedule_session}
              disabled={busy || !valid}
            >
              Yes
            </IonButton>
          </IonButtons>
          <IonButtons slot='start'>
            <IonButton
              fill='outline'
              size='small'
              color='tertiary'
              className="ion-margin-top"
              onClick={close}
              disabled={busy}
            >
              No
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardContent>
    </IonCard>
  )
}
