import { types, getRoot, flow, applySnapshot, destroy } from 'mobx-state-tree'
import { Mix } from 'common/types/student/mixes'
import { postMix, getMixes, putMix, deleteMix } from 'util/api/student/mixes'
import { StudentStats } from 'common/types/student/current_stats'

export const Mix_Extended = Mix
  .named('Mix_')
  .actions(self => ({
    add_subject: (subject: string) => {
      self.subjects.push(subject)
    },
    remove_subjects: (subjects: string[]) => {
      self.subjects = self.subjects.filter(_id => !subjects.includes(_id)) as any
    },
    update_name: (name: string) => {
      self.name = name
    },
    save: flow(function* () {
      try {
        yield putMix(self.student, self._id, self.name, self.subject, self.subjects)
      } catch (err) {
        console.error('Failed to save mixes', err)
      }
    })
  }))
export type Mix_Extended = typeof Mix_Extended

export const MixesList = types.model(
  'MixesList', {
    mixes: types.array(Mix_Extended),
    hierarchy: StudentStats,
  }
).actions(self => ({
  refresh: flow(function* (student: string) {
    try {
      const mixes = yield getMixes(student)
      if (mixes) {
        applySnapshot(self.mixes, mixes)
      }
    } catch (err) {
      console.error('Failed to load mixes', err)
    }
  }),
  add_mix: flow(function* (student: string, subject: string) {
    const root: any = getRoot(self)
    if (root.subject_list.subjects.length === 0) {
      yield root.subject_list.refresh()
    }
    const subject_full = root.subject_list.subjects.find(sub => sub._id === subject)
    const mix_name = `${subject_full?.name} ${self.mixes.length + 1}`
    const mix: Mix_Extended = yield postMix(student, mix_name, subject)
    self.mixes.push(mix)
  }),
  delete_mix: flow(function* (mix: Mix_Extended) {
    try {
      const {mix_id} = yield deleteMix(mix.student, mix._id)
      if (mix_id) {
        destroy(mix)
      }
    } catch (err) {
      console.error('Failed to delete mix', err)
    }
  }),
  set_hierarchy: (hierarchy) => {
    applySnapshot(self.hierarchy, hierarchy)
  },
}))
