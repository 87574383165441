import { IonCheckbox, IonLabel } from '@ionic/react'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { FC, useCallback } from 'react'
import { observer } from 'mobx-react-lite'

const ZeroTime = DateTime.now().startOf('day')
const slotFormat = (slot_start_minute_of_day: number) => `${ZeroTime.plus({ minutes: slot_start_minute_of_day }).toFormat('HH:mm')}`

interface Props {
    slots
    unique_days
    update_slot_selection
    selected_slots
}

export const TableCells: FC<Props> = observer(({slots, unique_days, update_slot_selection, selected_slots}) =>
  slots.map((slot) => (
    <TableCell
      key={`${slot.day_of_week}-${slot.time}`}
      unique_days={unique_days}
      update_slot_selection={update_slot_selection}
      selected_slots={selected_slots}
      slot={slot}
    />
  )))

const TableCell = ({slot, unique_days, update_slot_selection, selected_slots}: any) => {
  const onUpdateSlotSelection = useCallback(
    () => {
      update_slot_selection({
        day_of_week: slot.day_of_week,
        time: slot.time
      })
    }, [slot])

  return (
    <Cell
      col={unique_days.findIndex(d => d === slot.day_of_week)}
      row={Math.floor(slot.time / 15)}
    >
      <IonLabel>{slotFormat(slot.time)}</IonLabel>
      <IonCheckbox
        style={{marginLeft: '0.2rem'}}
        checked={
          selected_slots.some(
            ({day_of_week, time}) => day_of_week === slot.day_of_week && time === slot.time
          )
        }
        onIonChange={onUpdateSlotSelection}
      />
    </Cell>
  )
}

const Cell = styled.div`
  grid-column: ${props => props.col + 1} / span 1;
  grid-row: ${props => props.row + 2} / span 1;
`
