import { FC } from 'react'
import {
  IonButton,
  IonIcon,
  useIonActionSheet,
  useIonModal,
} from '@ionic/react'
import { ellipsisHorizontalCircleOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Session } from 'components/sessions/useUpcomingSessions'
import { CancelSession } from 'components/tutor/session/cancel'
import { RescheduleSession } from 'components/tutor/session/reschedule'
import { SubstituteSession } from 'components/tutor/session/substitute'
import { SubstituteSubscription } from 'components/tutor/session/substitute_subscription'
import { useSessionActionModal } from 'components/tutor/session/useActionModal'
import { Messages } from 'components/tutor/session/messages'
import { UnreadIndicator } from 'components/notes/unread_indicator'
import { EditSubscriptionWizard } from 'components/subscription/EditSubscriptionWizard'
import { Subscription } from 'common/types/subscription'

interface Props {
  session: Session
  subscriptions: Subscription[]
  scheduledSession?: Session
  refresh: () => Promise<void>
}

export const SessionActions: FC<Props> = ({ session, subscriptions, refresh, scheduledSession }) => {
  const session_is_active = session._id &&
    !session.cancelled &&
    DateTime.fromISO(session.session_date_time).minus({ minutes: 2 }) < DateTime.now() &&
    DateTime.fromISO(session.session_date_time).plus({ minutes: session.session_length }) > DateTime.now()

  const is_past_session = session.session_date_time_end && DateTime.fromISO(session.session_date_time_end) < DateTime.now()
  const [open_actions] = useIonActionSheet()

  const router = useHistory()
  const open_session = () => {
    router.push(`tutor/session/${session._id}`)
  }
  const open_scheduled_session = () => {
    router.push(`tutor/session/${session.student_id}/${session.subject._id}`)
  }

  const cancel_modal = useSessionActionModal(CancelSession, session, refresh)
  const reschedule_modal = useSessionActionModal(RescheduleSession, session, refresh)
  const substitute_modal = useSessionActionModal(SubstituteSession, session, refresh)
  const close_edit_modal = () => {
    dismiss_edit()
    return refresh()
  }
  const [open_edit_modal, dismiss_edit] = useIonModal(EditSubscriptionWizard, { close_edit_modal, subscription: subscriptions.find(({_id}) => _id === session.subscription_id) })
  const substitute_subscription_modal = useSessionActionModal(SubstituteSubscription, session, refresh)
  const messages_modal = useSessionActionModal(Messages, session, refresh)

  return session_is_active
    ? <IonButton onClick={open_session}>Open</IonButton>
    : <div
      onClick={() => open_actions({
        buttons: [
          ...session.cancelled ? [] : [
            ... !is_past_session ? [...session._id ? [{
              text: 'Open',
              handler: open_session,
            }] : [{
              text: 'Open',
              handler: open_scheduled_session
            }]] : [],
            // ...DateTime.fromISO(session.session_date_time) > DateTime.now().plus({ hours: 48 }) && !is_past_session
            //   ? [{
            //     text: 'Substitute Single Session',
            //     handler: substitute_modal,
            //   }]
            //   : [],
            ...!is_past_session
              ? [{
                text: 'Cancel Single Session',
                handler: cancel_modal
              }]
              : [],
            {
              text: 'Reschedule Subscription',
              handler: open_edit_modal,
            },
            ...!is_past_session
              ? [{
                text: 'Substitute Subscription Tutor',
                handler: substitute_subscription_modal,
              }]
              : [],
            ...!is_past_session
              ? [{
                text: 'Messages',
                handler: messages_modal
              }]
              : [],
            {
              text: 'Reschedule Single Session',
              handler: reschedule_modal,
            }
          ],
        ],
        header: 'Session actions',
        cssClass: 'session-actions',
      })}
    >
      <IonIcon
        icon={ellipsisHorizontalCircleOutline}
      />
      <UnreadIndicator session={session} student={session.student_id} subject={session.subject._id} />
    </div>
}
