import { types, Instance, flow, applySnapshot, SnapshotIn } from 'mobx-state-tree'
import { NewTutor } from 'common/types/tutor/tutor'
import { Tutor } from 'types/admin/tutor'
import { listAllTutors, postNewTutor } from 'util/api/tutor'

export const TutorList = types
  .model('TutorList', {
    tutors: types.array(Tutor),
  })
  .views(self => ({
    email_used: (email:string) => self.tutors.find(tutor => tutor.email === email) !== undefined,
    get_tutor: (id:string) => self.tutors.find(tutor => tutor._id === id)
  }))
  .volatile(self => ({
    busy: false
  }))
  .actions(self => ({
    refresh: flow(function* () {
      if( self.busy ){
        throw new Error('Cannot refresh TutorList when busy')
      }
      self.busy = true
      try {
        const tutors = yield listAllTutors()
        applySnapshot(self.tutors, tutors)
      } catch (error) {
        console.error('Failed list tutors', error)
      }
      finally {
        self.busy = false
      }
    }),
    add: flow(function* (tutor:SnapshotIn<NewTutor>) {
      if( self.busy ){
        throw new Error('Cannot add tutor when busy')
      }
      self.busy = true
      try {
        const new_tutor = yield postNewTutor({ body: tutor })
        self.tutors.push(new_tutor)
        self.busy = false
      } catch (e) {
        self.busy = false
        throw e
      }
    })
  })
  )

export type TutorList = Instance<typeof TutorList>
