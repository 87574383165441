import { Instance, types } from 'mobx-state-tree'
import { EndcodeId, TopicId } from './id'
import { BaseSubject } from './subject'
import { ScoreDistribution } from 'common/types/question/score_distribution'
import { PositiveInteger } from 'common/types/basic/positive_int'
import { Score } from 'common/types/question/score'

export const AdminEndcode = BaseSubject
  .named('AdminTopic')
  .props({
    min_score: Score,
    max_score: Score,
    question_distribution: ScoreDistribution,
    question_count: PositiveInteger,
  })
export type AdminEndcode = Instance<typeof AdminEndcode>

export const GetTopicEndcodesRequest = types.model(
  'GetTopicEndcodesRequest',
  {
    params: types.model({
      topic_id: TopicId
    }),
  }
)
export type GetTopicEndcodesRequest = typeof GetTopicEndcodesRequest

export const GetEndcodeRequest = types.model(
  'GetEndcodeRequest',
  {
    params: types.model({
      endcode_id: EndcodeId
    }),
  }
)
export type GetEndcodeRequest = typeof GetEndcodeRequest

export const PostTopicEndcodeRequest = types.model(
  'PostTopicEndcodeRequest',
  {
    params: types.model({
      topic_id: TopicId
    }),
    body: AdminEndcode
  }
)
export type PostTopicEndcodeRequest = typeof PostTopicEndcodeRequest

export const TopicEndcodes = types.array(AdminEndcode)
