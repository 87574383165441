import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { IonSelect, IonSelectOption } from '@ionic/react'
import { useStore } from 'types/store'
import { FieldComponent } from 'generic_components/MstForm'

interface Subject {
  _id: string,
  name: string
}

export const SubjectSelector: FieldComponent<Subject> = observer(({ value, onChange, disabled }) => {
  const { subject_list: { subjects, refresh } } = useStore()

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (subjects.length > 0 && value === undefined) {
      onChange({ _id: subjects[0]._id, name: subjects[0].name })
    }
  }, [value, subjects])

  return (
    <IonSelect
      value={value?._id}
      onIonChange={e => {
        const subject = subjects.find(subject => subject._id === e.detail.value)
        if (subject) {
          onChange({ _id: subject._id, name: subject.name })
        }
      }}
      disabled={disabled}
    >
      {subjects.map(subject =>
        <IonSelectOption
          key={subject._id}
          value={subject._id}
        >
          {subject.name}
        </IonSelectOption>
      )}
    </IonSelect>
  )
})
