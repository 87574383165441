import { FC, useEffect } from 'react'
import { IonContent, IonHeader, IonPage, IonToolbar, IonButtons, IonButton, IonTitle, IonLoading } from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { useUser } from 'context/userContext'
import { Logo } from 'components/Logo'

export const PublicPlytimePage: FC = observer(({ children }) => {
  const { user, in_progress } = useUser()
  useEffect(() => { console.log('reload public page plytime for user:' + JSON.stringify(user)) }, [])

  return !in_progress &&
    <IonPage>
      <IonHeader>
        <IonToolbar color='primary'>
          <IonButtons slot='start' color='primary'>
            <Logo />
          </IonButtons>
          <IonTitle class='ion-text-center'>Welcome to PLYTIME</IonTitle>
          <IonButtons slot='end' color='primary'>
            <IonButton href='https://plytime.com'>Website</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={in_progress}
        message={'Please wait...'}
      />
      <IonContent className="ion-padding">
        {children}
      </IonContent>
    </IonPage>
})
