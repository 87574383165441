import { FC, useEffect, useRef, CSSProperties } from 'react'
import { VideoTrack } from 'twilio-video'

interface Props {
  track: VideoTrack,
  style?: CSSProperties
}

export const Video: FC<Props> = ({track, style={}}) => {
  const video = useRef<HTMLVideoElement>()

  useEffect(() => {
    console.log(`Video track effect ${track}`)
    if(track !== null){
      track.attach(video.current)
      return () => {
        track.detach(video.current)
      }
    }
  },[track])
  return (
    <video
      ref={video}
      style={style}
    />
  )
}
