import { types, Instance, flow, applySnapshot, getSnapshot, SnapshotIn } from 'mobx-state-tree'
import { AdminSubject as BaseSubject } from 'common/types/subject/subject'
import { Theme } from 'types/question_management/theme/theme'
import { postSubject, getSubject } from 'util/api/subject'
import { getSubjectThemes } from 'util/api/subject/theme'
import { omit } from 'lodash'

export const Subject = BaseSubject.props({
  themes: types.array(Theme)
})
  .actions(self => ({
    update_name (name:string) {
      self.name = name
    },
    update_min_score (score:number) {
      self.min_score = score
    },
    update_max_score (score:number) {
      self.max_score = score
    },
    load_children: flow(function*() {
      try {
        self.themes = yield getSubjectThemes({ params: { subject_id: self._id } })
      } catch (error) {
        console.error('Failed to fetch subjects', error)
      }
    }),
    add_theme (theme:Theme) {
      self.themes.push(theme)
    },
    save: flow(function*(update:SnapshotIn<BaseSubject>) {
      const old = getSnapshot(self)
      try {
        applySnapshot(self, {
          ...update,
          themes: self.themes
        })
        const updated_self = yield postSubject({
          body: omit(update, 'themes')
        })
        applySnapshot(self, {
          ...updated_self,
          themes: self.themes
        })
      } catch (error) {
        applySnapshot(self, old)
        throw error
      }
    }),
    refresh: flow(function*() {
      try {
        const updated_self = yield getSubject({ params: { subject_id: self._id }})
        applySnapshot(self, {
          ...updated_self,
          themes: self.themes
        })
      } catch (error) {
        console.error('Failed to refesh subject', error)
      }
    })
  }))

export type Subject = Instance<typeof Subject>
