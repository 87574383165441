import { FC } from 'react'
import { useStore } from 'types/store'
import { MSTForm } from 'generic_components/MstForm'
import { SubjectSelector } from 'components/common/SubjectSelector'
import { StringInput } from 'generic_components/MstFormStringInput'
import { NewTutor } from 'common/types/tutor/tutor'
import { types } from 'mobx-state-tree'

const blank_tutor = types.model({}).create({})

export const CreateTutor: FC = () => {

  const { admin_tutor_list: { add } } = useStore()

  return (
    <MSTForm
      title='Add a new tutor'
      initial={blank_tutor}
      model={NewTutor}
      submit={add}
      submit_text='Create Tutor'
      cancel_text='Clear'
      success_text='Tutor created - one-time password sent to tutor&#39;s email address'
      force_edit_mode
      fields={[{
        name: 'first_name',
        label: 'First Name',
        Component: StringInput,
        width_percent: 100,
      }, {
        name: 'last_name',
        label: 'Last Name',
        Component: StringInput,
        width_percent: 100,
      }, {
        name: 'email',
        label: 'Email Address',
        Component: StringInput,
        width_percent: 100,
      }, {
        name: 'subject',
        label: 'Subject',
        Component: SubjectSelector,
        width_percent: 50,
      }]}
    />
  )
}
