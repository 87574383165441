import { IonSelectOption } from '@ionic/react'

export const Hours = () => <>
  {/* <IonSelectOption value={6}>06</IonSelectOption>
  <IonSelectOption value={7}>07</IonSelectOption> */}
  <IonSelectOption value={8}>08</IonSelectOption>
  <IonSelectOption value={9}>09</IonSelectOption>
  <IonSelectOption value={10}>10</IonSelectOption>
  <IonSelectOption value={11}>11</IonSelectOption>
  <IonSelectOption value={12}>12</IonSelectOption>
  <IonSelectOption value={13}>13</IonSelectOption>
  <IonSelectOption value={14}>14</IonSelectOption>
  <IonSelectOption value={15}>15</IonSelectOption>
  <IonSelectOption value={16}>16</IonSelectOption>
  <IonSelectOption value={17}>17</IonSelectOption>
  <IonSelectOption value={18}>18</IonSelectOption>
  <IonSelectOption value={19}>19</IonSelectOption>
  <IonSelectOption value={20}>20</IonSelectOption>
  <IonSelectOption value={21}>21</IonSelectOption>
  <IonSelectOption value={22}>22</IonSelectOption>
  <IonSelectOption value={23}>23</IonSelectOption>
</>

export const Minutes = () => <>
  <IonSelectOption value={0}>00</IonSelectOption>
  <IonSelectOption value={15}>15</IonSelectOption>
  <IonSelectOption value={30}>30</IonSelectOption>
  <IonSelectOption value={45}>45</IonSelectOption>
</>
