import styled from 'styled-components'
import { DateTime } from 'luxon'
import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { IonButton } from '@ionic/react'
import { SnapshotOut } from 'mobx-state-tree'
import disableBrowserBackButton from 'disable-browser-back-navigation'

import { useStore } from 'types/store'
import { useUser } from 'context/userContext'
import LeaderBoard from '../gameplay/leaderboard'
import coins_svg from 'images/speed_game/coins.svg'
import jewels_svg from 'images/speed_game/jewels.svg'
import ruby from 'images/speed_game/rubies/ruby.png'
import { GameReview, StudentDetailView } from 'components/question_review'
import { DisplayToken } from 'common/types/student/tokens'
import { UserModel } from 'types/user/model'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const TextContainer = styled.div`
    margin-right: 1rem;
`

const IconsContainer = styled.div`
    display: flex;
    flex-direction: column;
  `

const PointsIcon = styled.img`
  width: 1.56rem;
  height: 1.56rem;
  margin-top: 1.5rem;
  max-width: 1.56rem;
  max-height: 1.56rem;
`

const CoinsIcon = styled(PointsIcon)`
  margin-top: 1rem;
`

const PointsText = styled.p`
  margin-bottom: 28px;
`

const QuestionReviewContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 28px;
  overflow-y: scroll;
`

export const GameSummary: FC = observer(() => {
  const { user } = useUser()
  const { game, game: {
    error,
    score,
    total_questions,
    questions_correct,
    bonusPoints,
    highestStreak,
    game_start_time,
    totalPoints,
    opponents,
    game_mode,
    highscore,
  },
  student_tokens: {
    fetch_tokens,
    tokens
  }} = useStore()

  //disable back button
  useEffect(() => {
    disableBrowserBackButton()
  }, [])

  useEffect(() => {
    fetch_tokens(user._id)
  }, [])

  if (user === null) {
    return <div>No user</div>
  }

  const userWonGame = () => {
    let highestScore = true
    if (totalPoints() === 0) highestScore = false
    opponents.forEach(o => {
      if ((o.score + o.difficultyBonus * 5 + o.streakBonus * 20) >= totalPoints()) {
        highestScore = false
      }
    })
    return highestScore
  }
  const personalBest = totalPoints() > highscore

  const multiPlayerEndGameMessage = () => {
    const userWon = userWonGame()
    if (personalBest && userWon) {
      return 'You won with a new record score!'
    } if (userWon) {
      return 'You won!'
    } else {
      return 'Good try, keep practicing.'
    }
  }
  const singlePlayerEndGameMessage = () => {
    if (personalBest) {
      return 'Well done, you achieved a new record score!'
    } else {
      return `Good try, keep practicing to beat your highest score of ${highscore}.`
    }

  }
  const getLeaderBoardData = () => {
    const scores = []
    scores.push({
      id: '0',
      name: 'You',
      value: game.totalPoints(),
      colour: 'green'
    })
    opponents.forEach(o => {
      const opScore = {
        id: o.id,
        name: o.name,
        value: o.score + o.difficultyBonus * 5 + o.streakBonus * 20,
        colour: o.colour
      }
      scores.push(opScore)
    })

    return scores.sort((a, b) => a.value <= b.value ? 1 : -1)
  }

  const token_result = tokens.find(({game_summary_id}) => game_summary_id === game._id)

  return (
    <Container id='game_end_message'>
      {(game_mode === 'multiplayer') && <h2>{multiPlayerEndGameMessage()}</h2>}
      {(game_mode === 'singleplayer') && <h2>{singlePlayerEndGameMessage()}</h2>}
      <StatsContainer>
        <TextContainer>
          <PointsText>Total points:</PointsText>
          <PointsText>Gold coins:</PointsText>
          <PointsText>Jewels:</PointsText>
          <PointsText>Highest Streak:</PointsText>
          <PointsText>Questions correct:</PointsText>
          <PointsText>Questions attempted:</PointsText>

        </TextContainer>
        <TextContainer>
          <PointsText>{totalPoints()}</PointsText>
          <PointsText>{score}</PointsText>
          <PointsText>{bonusPoints}</PointsText>
          <PointsText>{highestStreak}</PointsText>
          <PointsText>{questions_correct}</PointsText>
          <PointsText>{total_questions}</PointsText>
        </TextContainer>
        <IconsContainer>
          <CoinsIcon src={coins_svg} />
          <CoinsIcon src={coins_svg} />
          <PointsIcon src={jewels_svg} />
          <PointsIcon src={ruby} />
        </IconsContainer>
      </StatsContainer>

      {game_mode === 'multiplayer' && <LeaderBoard data={getLeaderBoardData()} />}

      {
        token_result ? (
          <TokenResult
            token_result={token_result}
            user={user}
            game_start_time={game_start_time}
          />
        ): null
      }
      <IonButton routerLink='/game/step1_setup' style={{ width: '13.85rem' }}>Play another game</IonButton>
      <IonButton color='tertiary' routerLink='/student'>Go back to homepage</IonButton>
      {error && <div id='error'>{error}</div>}
    </Container>
  )
})

interface ITokenResultProps {
  token_result: DisplayToken;
  user: SnapshotOut<typeof UserModel>;
  game_start_time: Date;
}

const TokenResult: FC<ITokenResultProps> = observer(({ token_result, user, game_start_time }) => {
  const {
    student_subscriptions: { subscriptions }
  } = useStore()

  const current_subscription = subscriptions.find(({ cancelled }) => !cancelled)

  return (
    <>
      {
        !current_subscription ?
          <h3>You would have earned {token_result.without_subscription_count} tokens if you had a Plytime Learning subscription!</h3> :
          null
      }
      {
        token_result.token_count > 0 ?
          <h2>Well done, you&apos;ve earned {token_result.token_count} tokens!</h2> :
          null
      }
      {
        current_subscription && token_result.pending_token_count > 0 ?
          (
            <>
              <h3>Review your answers to earn {token_result.pending_token_count} more!</h3>
              <QuestionReviewContainer>
                <GameReview
                  student_id={user._id}
                  AnswerDetailComponent={StudentDetailView}
                  game_start_time={DateTime.fromJSDate(game_start_time)}
                />
              </QuestionReviewContainer>
            </>
          ) :
          null
      }
    </>
  )
})
