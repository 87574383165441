import { FC } from 'react'
import { Roles } from 'util/auth/helper'
import { PlytimePage } from './plytime_page'
import { useUser } from '../context/userContext'
import styled from 'styled-components'

interface Props {
  roles: Roles[]
}

const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  ${props => props.user_role !== 'TEST' && 'background-image: url("/room-background.jpeg");'}
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const PlytimePageStudentBackground: FC<Props> = ({ roles, children }) => {
  const { user } = useUser()
  return (
    <PlytimePage roles={roles} fullwidth={true} content_padding='none'>
      <OuterContainer user_role={user?.role}>
        {children}
      </OuterContainer>
    </PlytimePage>)
}
