import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonToolbar,
  IonGrid,
  IonRow,
  IonLabel,
  IonCol,
  IonCheckbox,
} from '@ionic/react'
import { Day } from 'common/types/basic/day'
import { ApproxTime, SessionOptions } from 'common/types/session/options'
import { StringSelector } from 'generic_components/MstFormStringSelector'
import SubscriptionWizardState from './SubscriptionWizardState'
import { SessionOptionEnum } from 'common/types/session/options'

interface Props {
  state: Instance<typeof SubscriptionWizardState>
  close: () => void,
  shouldShowPreferred?: boolean,
  shouldShowBack?: boolean,
}

export const Availability: FC<Props> = observer((
  {
    state,
    close,
    shouldShowPreferred = true,
    shouldShowBack = true
  }
) => {
  return (<>
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Choose your Subscription</IonCardTitle>
        <IonCardSubtitle>
          <p>
            Select 24/7 access to the PLYTIME Learning platform only or add any number of FOCUS15® sessions.
          </p>
          <p>
            Spread your sessions across a week or join them together to make longer sessions.
          </p>
          <p>
            You can easily add or remove sessions by editing your subscription in future.
          </p>
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent style={{
        display: 'flex',
        flexFlow: 'row nowrap'
      }}>
        <IonItem lines='inset'>
          <IonLabel position='stacked'>Select from the drop down list</IonLabel>
          <SessionOptionsSelector value={state.selected_session_option} onChange={state.update_session_count} disabled={false} />
        </IonItem>
        <IonItem lines='inset'>
          <IonLabel position='stacked'>Weekly cost</IonLabel>
          {state.cost()}
        </IonItem>
      </IonCardContent>
    </IonCard>
    {
      shouldShowPreferred && state.selected_session_option !== SessionOptionEnum.PlatformOnly && (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{'Preferred Days & Times'}</IonCardTitle>
            <IonCardSubtitle>Please select all that apply and choose your specific time slots in the next screen.</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size='5' />
                {(Day as unknown as MstEnum)._types.map(({ value: day }) =>
                  <IonCol key={day} size='1'>{day.slice(0, 3)}</IonCol>
                )}
              </IonRow>
              {(ApproxTime as any)._types.map(({ value: approx_time }) =>
                <IonRow key={approx_time}>
                  <IonCol size='5'>{approx_time}</IonCol>
                  {(Day as any)._types.map(({ value: day }) =>
                    <IonCol key={day} size='1'>
                      <IonCheckbox
                        checked={state.is_available(day, approx_time)}
                        onIonChange={e => {
                          state.update_availability(day, approx_time, !e.detail.checked)
                        }}
                      />
                    </IonCol>
                  )}
                </IonRow>
              )}
            </IonGrid>
          </IonCardContent>
        </IonCard>
      )
    }
    <IonToolbar>
      <IonButtons slot='end'>
        <IonButton
          fill='solid'
          size='small'
          color='primary'
          className="ion-margin-top"
          onClick={state.next}
          disabled={state.availability.length === 0 && shouldShowPreferred && state.selected_session_option !== SessionOptionEnum.PlatformOnly}
        >
          Next
        </IonButton>
      </IonButtons>
      { shouldShowBack ?
        <IonButtons slot='start'>
          <IonButton
            fill='outline'
            size='small'
            color='tertiary'
            className="ion-margin-top"
            onClick={state.back}
          >
              Back
          </IonButton>
        </IonButtons> :
        undefined
      }
    </IonToolbar>
  </>)
})


const SessionOptionsSelector = StringSelector((SessionOptions as any)._types.map(({value}) => {
  if (value === SessionOptionEnum.PlatformOnly) {
    return {
      key: 'Plytime Learning',
      value
    }
  }
  if (value === SessionOptionEnum.OneFifteenMin) {
    return {
      key: 'Plytime Learning + 1 Focus 15',
      value
    }
  }
  if (value === SessionOptionEnum.TwoFifteenMin) {
    return {
      key: 'Plytime Learning + 2 Focus 15\'s',
      value
    }

  }
  if (value === SessionOptionEnum.ThreeFifteenMin) {
    return {
      key: 'Plytime Learning + 3 Focus 15\'s',
      value
    }
  }
  if (value === SessionOptionEnum.FourFifteenMin) {
    return {
      key: 'Plytime Learning + 4 Focus 15\'s',
      value
    }
  }
  if (value === SessionOptionEnum.FiveFifteenMin) {
    return {
      key: 'Plytime Learning + 5 Focus 15\'s',
      value
    }
  }
  if (value === SessionOptionEnum.SixFifteenMin) {
    return {
      key: 'Plytime Learning + 6 Focus 15\'s',
      value
    }
  }
  if (value === SessionOptionEnum.SevenFifteenMin) {
    return {
      key: 'Plytime Learning + 7 Focus 15\'s',
      value
    }
  }

  throw new Error('Unrecognised option found')
}))

interface MstEnum {
  _types: { value: string }[]
}
