import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { IonContent } from '@ionic/react'

import { Availability } from './Availability'
import { SlotSelection } from './SlotSelection'
import { Payment } from './Payment'
import SubscriptionWizardState from './SubscriptionWizardState'
import { useStore } from 'types/store'
import { Subscription } from 'common/types/subscription'
import { SessionOptionEnum } from 'common/types/session/options'
import { AlreadyPlatformOnly } from './AlreadyPlatformOnly'

interface Props {
  close_edit_modal: () => void,
  subscription: Subscription
}

export const EditSubscriptionWizard: FC<Props> = observer(({ close_edit_modal, subscription }) => {
  const store = useStore()
  const state = useRef(SubscriptionWizardState.create({})).current
  store.setSubscriptionWizard(state)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    state.get_products()
    state.update_subject({ _id: subscription.subject._id, name: subscription.subject.name })
    state.update_student({ _id: subscription.student._id, first_name: subscription.student.name })
    state.update_session_count(subscription.session_option)
    state.update_existing_subscription(subscription._id)
    state.find_availability().then(() => {
      state.replace_selected_slots(
        state.tutor_availabilities
          .filter(({_id}: any) => subscription.slots.includes(_id))
          .map(({ day_of_week, time }: any) => ({ day_of_week, time}))
      )
      setIsLoading(false)
    })
  }, [])

  return (
    <IonContent>
      <StepComponent state={state} subscription={subscription} role={store.auth.role} isLoading={isLoading} />
    </IonContent>
  )
})

// Todo - consider using a state machine here.
const StepComponent = observer<any>(({ state, role, subscription, isLoading}) => {
  if (role === 'PARENT') {
    if (state.step === 0) {
      return (
        <Availability
          state={state}
          close={state.close_edit_modal}
          shouldShowPreferred={false}
          shouldShowBack={false}
        />
      )
    }
    if (state.step === 1 && state.selected_session_option !== SessionOptionEnum.PlatformOnly) {
      return (
        <SlotSelection
          state={state}
          close={state.close_edit_modal}
          onClickConfirm={state.next}
          shouldShowBack
        />
      )
    }
    if (state.step === 2 && state.selected_session_option !== SessionOptionEnum.PlatformOnly) {
      return (
        <Payment
          state={state}
          on_success={state.close_edit_modal}
          close={state.close_edit_modal}
        />
      )
    }
    if (
      subscription.session_option === SessionOptionEnum.PlatformOnly &&
      state.selected_session_option === SessionOptionEnum.PlatformOnly
    ) {
      return (
        <AlreadyPlatformOnly
          state={state}
        />
      )
    }
    if (state.step === 1 && state.selected_session_option === SessionOptionEnum.PlatformOnly) {
      return (
        <Payment
          state={state}
          on_success={state.close_edit_modal}
          close={state.close_edit_modal}
        />
      )
    }

    if (role === 'TUTOR') {
      if (state.step === 0) {
        const onClickConfirmSubscribe = useCallback(async () => {
          const {redirect_url} = await state.subscribe(window.location.href)
          window.location.assign(redirect_url)
        }, [])

        return (
          <SlotSelection
            state={state}
            close={state.close_edit_modal}
            shouldShowBack={false}
            onClickConfirm={onClickConfirmSubscribe}
            isLoading={isLoading}
          />
        )
      }
    }
  }
})
