import {  types, Instance, getParent } from 'mobx-state-tree'
import { TopicId } from '../question_management/topic/id'

export const Topic = types.model('Topic', {
  _id: TopicId,
  name: types.string,
  selected: types.optional(types.boolean, false)
})
  .actions(self => ({
    select (selected:boolean) {
      self.selected = selected
      if( selected ) {
        const theme = getParent(self, 2) as any
        theme?.select?.call(theme, false)
        const subject = getParent(self, 4) as any
        subject?.select?.call(subject, false)
      }
    }
  }))

export type Topic = Instance<typeof Topic>