import { FC, ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { getSnapshot, Instance } from 'mobx-state-tree'
import { Question } from 'types/question_management/question/question'
import styled from 'styled-components'
import { ElementTypes, Element } from 'common/types/question/question'
import { TextElement } from 'common/types/question/elements/text'
import { TextInputElement } from 'common/types/question/elements/text_input'
import { ImageElement } from 'common/types/question/elements/image'
import { McElement } from 'common/types/question/elements/mc'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonInput,
  IonItem,
  IonLabel,
} from '@ionic/react'

interface Props {
  question: Question
}

export const QuestionEditor:FC<Props> = observer(({question}) => {

  const handle_action = (row, col, element) => (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault()
    switch (event.target.value) {
      case 'delete': {
        try {
          question.remove_element(element)
        } catch {
          alert('Cannot remove last question element')
        }
        break
      }
      case 'insert_above': {
        question.insert_new_line_element(row, TextElement.create({ type: 'Text' }))
        break
      }
      case 'insert_below': {
        question.insert_new_line_element(row+1, TextElement.create({ type: 'Text' }))
        break
      }
      case 'insert_left': {
        question.insert_element(row, col, TextElement.create({ type: 'Text' }))
        break
      }
      case 'insert_right': {
        question.insert_element(row, col+1, TextElement.create({ type: 'Text' }))
        break
      }
      case 'change_to_Text': {
        question.replace_element(row, col, TextElement.create({ type: 'Text' }))
        break
      }
      case 'change_to_Text input': {
        question.replace_element(row, col, TextInputElement.create({ type: 'Text input' }))
        break
      }
      case 'change_to_MC input': {
        question.replace_element(row, col, McElement.create({ type: 'MC input' }))
        break
      }
      case 'change_to_Image': {
        question.replace_element(row, col, ImageElement.create({ type: 'Image' }))
        break
      }
      default: {
        throw new Error(`Unknown action: ${event.target.value}`)
      }
    }
  }

  const replace_element = (row:number, col:number, element:Instance<Element>, model:Element) => (snapshot:Record<string,unknown>) =>
    question.replace_element(row, col, model.create({
      ...getSnapshot(element),
      ...snapshot
    }))

  return (
    <Lines>
      {question.lines.map((line, row) =>
        <Elements key={row}>
          {line.map((element, col) =>
            <IonCard key={col}>
              <IonCardHeader>
                <IonCardSubtitle>
                  {element.type}
                  <Actions
                    value='Actions'
                    name="element_actions"
                    id={`element_actions_${row}_${col}`}
                    onChange={handle_action(row, col, element)}
                  >
                    <option value={'Actions'} disabled>Actions</option>
                    <optgroup label='Change type'>
                      {ElementTypes.map(({name}) => (
                        <option value={`change_to_${name}`} key={name}>
                          {name}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label='Insert'>
                      <option value={'insert_above'}>Above</option>
                      <option value={'insert_below'}>Below</option>
                      <option value={'insert_left'}>Left</option>
                      <option value={'insert_right'}>Right</option>
                    </optgroup>
                    <optgroup label='Or'>
                      <option value={'delete'}>Delete</option>
                    </optgroup>
                  </Actions>
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                {
                  element.type === 'Text' ? <TextElementComponent element={element} update={replace_element(row, col, element, TextElement)}/> :
                    element.type === 'Text input' ? <TextInputElementComponent element={element} update={replace_element(row, col, element, TextInputElement)}/> :
                      element.type === 'MC input' ? <McInputElementComponent element={element} update={replace_element(row, col, element, McElement)}/> :
                        element.type === 'Image' ? <ImageElementComponent element={element} update={replace_element(row, col, element, ImageElement)}/> :
                          <p>Unknown element type</p>
                }
              </IonCardContent>
            </IonCard>
          )}
        </Elements>
      )}
    </Lines>
  )
})

const TextElementComponent: FC<{update:any, element:Instance<TextElement>}> = observer(({ update, element }) => {
  return (
    <IonItem>
      <IonLabel position='floating'>Question Text</IonLabel>
      <IonInput
        type='text'
        value={element.text}
        onIonInput={(e:any) => update({ text: e.target.value })}
      />
    </IonItem>
  )
})

const TextInputElementComponent: FC<{update:any, element:Instance<TextInputElement>}> = observer(({ update, element }) => {
  return (
    <IonItem>
      <IonLabel position='floating'>Answer ref</IonLabel>
      <IonInput
        type='text'
        value={element.ref}
        onIonInput={(e:any) => update({ ref: e.target.value })}
      />
    </IonItem>
  )
})

const McInputElementComponent: FC<{update:any, element:Instance<McElement>}> = observer(({ update, element }) => {
  return (
    <IonItem>
      <IonLabel position='floating'>Answer ref</IonLabel>
      <IonInput
        type='text'
        value={element.ref}
        onIonInput={(e:any) => update({ ref: e.target.value })}
      />
    </IonItem>
  )
})

const ImageElementComponent: FC<{update:any, element:Instance<ImageElement>}> = observer(({ update, element }) => {
  return (
    <IonItem>
      <IonLabel position='floating'>Image id</IonLabel>
      <IonInput
        type='text'
        value={element.image_id}
        onIonInput={(e:any) => update({ image_id: e.target.value })}
      />
    </IonItem>
  )
})

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
`

const Elements = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
`

const Actions = styled.select`
  border-radius: 0.3rem;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  margin-left: 1em;
`
