import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Endcode } from 'types/question_management/endcode/endcode'
import { ScoreDistributionPlot } from './question_distribution_plot'
import { MSTForm } from 'generic_components/MstForm'
import { StringInput } from 'generic_components/MstFormStringInput'
import { NumberInput } from 'generic_components/MstFormNumberInput'
import { IonButton } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import { useStore } from 'types/store'

interface Props {
  endcode: Endcode
}
export const EndcodeDetail: FC<Props> = observer(({ endcode }) => {

  const { search, set_filter_status, set_filter_ref } = useStore().admin
  const history = useHistory()

  const search_questions = () => {
    set_filter_status(['draft','live','review','approved'])
    set_filter_ref(endcode.name)
    search()
    history.push('/admin/questions')
  }

  return (
    <>
      <MSTForm
        title='Endcode Details'
        initial={endcode}
        model={Endcode}
        submit={endcode.save}
        fields={[{
          name: 'name',
          label: 'Name',
          Component: StringInput,
          width_percent: 50,
        },{
          name: 'min_score',
          label: 'Min score',
          Component: NumberInput,
          width_percent: 25,
        },{
          name: 'max_score',
          label: 'Max score',
          Component: NumberInput,
          width_percent: 25,
        },{
          name: 'question_count',
          label: 'Question Count',
          Component: NumberInput,
          disabled: true,
          width_percent: 25,
        }]}
        right_buttons={
          <IonButton
            fill='solid'
            size='small'
            color='tertiary'
            className="ion-margin-top"
            onClick={search_questions}
            type='button'
          >
            Search questions
          </IonButton>
        }
      />
      {endcode.question_count > 0 && <ScoreDistributionPlot score_distribution={endcode.question_distribution} />}
    </>
  )
})
