import { FC } from 'react'
import styled from 'styled-components'
import { SnapshotOut } from 'mobx-state-tree'
import { Note } from 'common/types/notes'
import { DayOrTime, Head, Name, Time, Msg } from './common'

interface Props {
  note: SnapshotOut<Note>
}

export const Incoming:FC<Props> = ({note}) => <Container>
  <Head>
    <Name>{note.sender_name}</Name>
    <Time>{DayOrTime(note.timestamp)}</Time>
  </Head>
  <Msg disabled>{note.message}</Msg>
</Container>

const Container = styled.div`
  align-self: flex-end;
  width: 80%;
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--ion-color-primary);
  border-radius: 1rem 0rem 1rem 1rem;
  padding: 0.3rem;
  margin-top: 0.3rem;
`
