import { IonText, IonList, IonItem } from '@ionic/react'
import { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const HowToPlay: FC = () => {
  return (
    <Container>
      <strong> How to play: </strong>
      <ul>
        <li>Wait for the 1st question to appear</li>
        <li>Only correct answers earn points</li>
        <ul>
          <li>A streak of 5 correct answers in a row = +20 bonus points</li>
          <li>Hard questions answered correctly = +5 bonus points</li>
          <li>Super hard questions answered correctly = +10 bonus points</li>
          <li>The faster you answer the more points you can earn</li>
        </ul>
        <li>If you can not answer you can pass a question when the button lights up</li>
        <li>After each answer, you can change the difficulty level before selecting the next question</li>
        <li>You can pause the game between questions</li>
        <li>Get your pen and paper ready - and be your best!</li>
      </ul>
    </Container>
  )
}

export default HowToPlay
