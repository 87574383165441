import { Instance, types, SnapshotOut } from 'mobx-state-tree'
import { NonEmptyString } from 'common/types/basic/non_empty_string'

export const ProfilePicture = types.model('ProfilePicture', {
  content_base64: NonEmptyString,
  content_type: NonEmptyString,
  user_role: NonEmptyString
})
export type ProfilePicture = Instance<typeof ProfilePicture>

export const ProfilePicturePostRequest = types.model(
  'ProfilePicturePostRequest',
  {
    params: types.model({
      user_id: types.string
    }),
    body: ProfilePicture
  }
)
export type ProfilePicturePostRequest = SnapshotOut<typeof ProfilePicturePostRequest>

export const ProfilePictureResponse = types.model('ProfilePictureResponse', {
  path: types.maybe(types.string)
})
export type ProfilePictureResponse = Instance<typeof ProfilePictureResponse>

export const ProfilePictureGetRequest = types.model({
  params: types.model({
    user_id: types.string
  })
})
export type ProfilePictureGetRequest = SnapshotOut<typeof ProfilePictureGetRequest>

export const MyProfilePicture = types.model('MyProfilePicture', {
  content_base64: NonEmptyString,
  content_type: NonEmptyString,
})

export type MyProfilePicture = Instance<typeof MyProfilePicture>
export const MyProfilePicturePostRequest = types.model(
  'MyProfilePicturePostRequest',
  {
    body: MyProfilePicture
  }
)
export type MyProfilePicturePostRequest = SnapshotOut<typeof MyProfilePicturePostRequest>

export const MyProfilePictureGetRequest = types.model({

})
export type MyProfilePictureGetRequest = SnapshotOut<typeof MyProfilePictureGetRequest>
