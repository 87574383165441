import { useState, useCallback } from 'react'
import {
  AudioInputTest,
  AudioOutputTest,
  DiagnosticError,
  testAudioInputDevice,
  testAudioOutputDevice,
  WarningName,
} from '@twilio/rtc-diagnostics'

let audioInputTest: AudioInputTest
let audioOutputTest: AudioOutputTest

const getErrorMessage = (error: DiagnosticError) => {
  let message = 'An unknown error has occurred'
  if (error) {
    message = error.domError ? error.domError.toString() : error.message
  }
  return message
}

export default function useAudioTest() {
  const [isRecording, setIsRecording] = useState(false)
  const [isAudioInputTestRunning, setIsAudioInputTestRunning] = useState(false)
  const [isAudioOutputTestRunning, setIsAudioOutputTestRunning] = useState(false)
  const [inputLevel, setInputLevel] = useState(0)
  const [outputLevel, setOutputLevel] = useState(0)
  const [playbackURI, setPlaybackURI] = useState('')
  const [error, setError] = useState('')
  
  const playAudio = useCallback(
    (options: AudioOutputTest.Options) => {
  
      options = { doLoop: false, ...options }
      audioOutputTest = testAudioOutputDevice(options)
      setIsAudioOutputTestRunning(true)

      audioOutputTest.on(AudioOutputTest.Events.Volume, (value: number) => {
        setOutputLevel(value)
      })

      audioOutputTest.on(AudioOutputTest.Events.End, (report: AudioOutputTest.Report) => {
        setIsAudioOutputTestRunning(false)
        setOutputLevel(0)

      })

      audioOutputTest.on(AudioOutputTest.Events.Error, (diagnosticError: DiagnosticError) => {
        setError(getErrorMessage(diagnosticError))
      })
    },
    []
  )

  const readAudioInput = useCallback(
    (options: AudioInputTest.Options) => {
      if (audioInputTest) {
        audioInputTest.stop()
      }

      const duration = options.enableRecording ? 4000 : 20000
      options = { duration, ...options }
      audioInputTest = testAudioInputDevice(options)

      setIsAudioInputTestRunning(true)
      if (options.enableRecording) {
        console.log('Recording audio')
        setIsRecording(true)
      }

      audioInputTest.on(AudioInputTest.Events.Volume, (value: number) => {
        setInputLevel(value)
      })

      audioInputTest.on(AudioInputTest.Events.End, (report: AudioInputTest.Report) => {
        if (playbackURI && report.recordingUrl) {
          URL.revokeObjectURL(playbackURI)
        }

        if (report.recordingUrl) {
          setPlaybackURI(report.recordingUrl)
        }

        setIsRecording(false)
        setIsAudioInputTestRunning(false)
        console.log('AudioInputTest ended', report)
        console.log({ type: 'set-audio-input-test-report', report: report })
      })

      audioInputTest.on(AudioInputTest.Events.Error, (diagnosticError: DiagnosticError) => {
        console.log('error', diagnosticError)
        setError(getErrorMessage(diagnosticError))
      })
      audioInputTest.on(AudioInputTest.Events.Warning, (name: WarningName) => {
        console.log('warning', name)
      })
      audioInputTest.on(AudioInputTest.Events.WarningCleared, (name: WarningName) => {
        console.log('warning-cleared', name)
      })
    },
    [playbackURI]
  )

  const stopAudioTest = useCallback(() => {
    if (audioInputTest) {
      audioInputTest.stop()
      setInputLevel(0)
    }
    if (audioOutputTest) {
      audioOutputTest.stop()
      setOutputLevel(0)
    }
  }, [])

  return {
    error,
    setError,
    isRecording,
    isAudioInputTestRunning,
    isAudioOutputTestRunning,
    playAudio,
    playbackURI,
    readAudioInput,
    stopAudioTest,
    inputLevel,
    outputLevel,
  }
}
