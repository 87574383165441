import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Subject } from 'types/question_management/subject/subject'
import { ScoreDistributionPlot } from './question_distribution_plot'
import { MSTForm } from 'generic_components/MstForm'
import { StringInput } from 'generic_components/MstFormStringInput'
import { NumberInput } from 'generic_components/MstFormNumberInput'

interface Props {
  subject: Subject
}
export const SubjectDetail: FC<Props> = observer(({ subject }) => {

  return (
    <>
      <MSTForm
        title='Subject Details'
        initial={subject}
        model={Subject}
        submit={subject.save}
        fields={[{
          name: 'name',
          label: 'Name',
          Component: StringInput,
          width_percent: 50,
        },{
          name: 'min_score',
          label: 'Min score',
          Component: NumberInput,
          width_percent: 25,
        },{
          name: 'max_score',
          label: 'Max score',
          Component: NumberInput,
          width_percent: 25,
        },{
          name: 'theme_count',
          label: 'Theme Count',
          Component: NumberInput,
          disabled: true,
          width_percent: 25,
        },{
          name: 'topic_count',
          label: 'Topic Count',
          Component: NumberInput,
          disabled: true,
          width_percent: 25,
        },{
          name: 'endcode_count',
          label: 'Endcode Count',
          Component: NumberInput,
          disabled: true,
          width_percent: 25,
        },{
          name: 'question_count',
          label: 'Question Count',
          Component: NumberInput,
          disabled: true,
          width_percent: 25,
        }]}
      />
      {subject.question_count > 0 && <ScoreDistributionPlot score_distribution={subject.question_distribution} />}
    </>
  )
})
