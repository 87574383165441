import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'types/store'
import { DifficultySelector } from 'components/difficulty'
import { SubjectSelector } from 'components/subject_selector'
import { useUser } from 'context/userContext'
import { IonButton, IonSelect, IonSelectOption, IonItem } from '@ionic/react'
import styled from 'styled-components'
import { getGames } from 'util/api/game'
import { Game } from 'common/types/game/game_library'
import HowToPlay from './HowToPlay'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const GameSetup: FC = observer(() => {
  const { user } = useUser()
  const { difficulty, set_difficulty, error, selected_subjects, ask_initial_questions } = useStore().game

  const [games, setGames] = useState([])
  const [selectedGameType, setSelectedGameType] = useState('')
  const [playButtonEnabled, setPlayButtonEnabled] = useState(false)

  const handle_game_selection = (e) => {
    setSelectedGameType(e.detail.value)
  }

  if (user === null) {
    return <div>No user</div>
  }

  useEffect(() => {
    if (selected_subjects.subjects.length > 0) {
      ask_initial_questions(user._id)
      setPlayButtonEnabled(true)
    } else {
      setPlayButtonEnabled(false)
    }
  }, [JSON.stringify(selected_subjects.subjects)])

  useEffect(() => {
    const f = async () => {
      const games = await getGames()
      if (games.length > 0) {
        setGames(games)
        setSelectedGameType(games[0]._id)
      }
    }
    f()
  }, [])

  const getGameUrl = () => {
    const game: Game = games.find(g => g._id === selectedGameType)
    const game_url = `step2_${game.game_type}?game_id=${game._id}&time=${game.duration}&game_mode=${game.game_mode}`
    return game_url
  }

  return (
    <Container>
      <SubjectSelector user_id={user._id} />
      <h4>Select the question difficulty:</h4>
      <DifficultySelector level={difficulty} select={set_difficulty} />

      {error && <div id='error'>{error}</div>}

      {games &&
        <>
          <h4>Select the game type:</h4>
          <IonItem lines='none' cy-data='game_select'>
            <IonSelect
              name="game_select"
              id="game_select"
              value={selectedGameType}
              onIonChange={handle_game_selection}
              interfaceOptions={{
                cssClass: 'game-type-selector-options'
              }}>
              {games.map(game => <IonSelectOption key={game._id} value={game._id} class='wide'>{game.description}</IonSelectOption>)}
            </IonSelect>
          </IonItem>
        </>
      }

      <IonButton disabled={(!playButtonEnabled || !selectedGameType)} routerLink={selectedGameType && getGameUrl()}>Start Game</IonButton>
      <HowToPlay/>
    </Container>
  )
})
