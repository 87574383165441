import { observer } from 'mobx-react-lite'
import { IonInput } from '@ionic/react'
import { FieldComponent } from 'generic_components/MstForm'

export const StringInput: FieldComponent<string> = observer(({ value, onChange, disabled, required }) => {
  const onIonChange = (e: CustomEvent<KeyboardEvent>) => {
    onChange((e.target as any).value?.trim())
  }
  return (
    <IonInput
      type='text'
      value={value}
      onIonInput={onIonChange}
      disabled={disabled}
      required={required}
    />)
})
