import { api } from 'util/api'
import {
  GetTokensRequest,
  GetTokensResponse,
  PostPrizeRequest,
  PostPrizeResponse,
  GetPrizesResponse,
  GetPrizesRequest
} from 'common/types/student/tokens'

export const getTokens = async (student_id: string) =>
  api(GetTokensRequest, GetTokensResponse, 'GET', 'student/student_id/tokens')({
    params: {student_id}
  })

export const getPrizes = async () =>
  api(GetPrizesRequest, GetPrizesResponse, 'GET', 'student/prizes')({})

export const postPrize = async (prize_id: string) =>
  api(PostPrizeRequest, PostPrizeResponse, 'POST', 'student/prize')({ body: { prize_id } })
