import { types, Instance } from 'mobx-state-tree'
import { NonEmptyString } from 'common/types/basic/non_empty_string'
import { GAME_TYPE, GAME_MODE } from 'common/types/game'
export const NewGame = types.model('NewGame', {
  game_type: types.enumeration<GAME_TYPE>(Object.values(GAME_TYPE)),
  description: types.string,
  duration: types.number,
  game_mode: types.enumeration<GAME_MODE>(Object.values(GAME_MODE))
})
export type NewGame = Instance<typeof NewGame>

export const GameLibraryPostRequest = types.model('GameLibrary', {
  body: NewGame
})
export type GameLibraryPostRequest = Instance<typeof GameLibraryPostRequest>

export const Game = NewGame
  .named('Game')
  .props({
    _id: NonEmptyString,
  })
export type Game = Instance<typeof Game>

export const GetGameLibraryRequest = types.model('GameLibrary', {
})
export type GetGameLibraryRequest = Instance<typeof GetGameLibraryRequest>

export const GetGameLibraryResponse = types.array(Game)
export type GetGameLibraryResponse = Instance<typeof GetGameLibraryResponse>
