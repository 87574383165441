import styled from 'styled-components'

export const McTick = styled.button`
  margin: 0 5px;
  background-color: ${props => props.selected ? 'var(--ion-color-light-shade)' : 'white'};
  border: ${(props) => {
    if (props.selected && props.expected === undefined) return 'var(--ion-color-light-shade)'
    if (props.expected) return 'var(--ion-color-success)'
    if (props.selected && props.expected === false) return 'var(--ion-color-danger)'
    return 'gray'
  }} solid 2.5px;
  :focus {
    outline: 0;
  }
  padding: 0.25rem;
`
