import { api } from 'util/api'
import { GetUpcomingSessionsRequest, GetUpcomingSessionsResponse, GetPastSessionsRequest, GetPastSessionsResponse, ModifySessionRequest, RescheduleSessionRequest, GetSessionRequest, HydratedSession, ModifySessionResponse } from 'common/types/session'

const _getUpcomingSessions = api(GetUpcomingSessionsRequest, GetUpcomingSessionsResponse, 'GET', 'session/list/upcoming')
export const getUpcomingSessions = async (idParam: { parent_id?: string, tutor_id?: string, student_id?: string }, after_time: string) => {
  const req = { query: { ...idParam, after_time } }
  const data = await _getUpcomingSessions(req)
  return data
}

const _getPastSessions = api(GetPastSessionsRequest, GetPastSessionsResponse, 'GET', 'session/list/past')
export const getPastSessions = async (idParam: { parent_id?: string, tutor_id?: string, student_id?: string }, before_time: string) => {
  const req = { query: { ...idParam, before_time } }
  const data = await _getPastSessions(req)
  return data
}

const _cancelSession = api(ModifySessionRequest, ModifySessionResponse, 'PUT', 'session/cancel')
export const cancelSession = async (session_id: string, subscription_id: string, session_date_time: string) => {
  const data = await _cancelSession({ query: { session_id, subscription_id, session_date_time } })
  return data
}

const _substituteSession = api(ModifySessionRequest, ModifySessionResponse, 'POST', 'session/substitute')
export const substituteSession = async (session_id: string, subscription_id: string, session_date_time: string) => {
  const data = await _substituteSession({ query: { session_id, subscription_id, session_date_time } })
  return data
}

const _rescheduleSession = api(RescheduleSessionRequest, ModifySessionResponse, 'POST', 'session/reschedule')
export const rescheduleSession = async (session_id: string, subscription_id: string, session_date_time: string, new_session_date_time: string) => {
  const data = await _rescheduleSession({ query: { session_id, subscription_id, session_date_time, new_session_date_time } })
  return data
}

export const getSession = async (session_id: string) => api(GetSessionRequest, HydratedSession, 'GET', 'session/session_id')(
  { params: { session_id } }
)
