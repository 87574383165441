
import { getProfilePictureUri } from '../../util/api/profile/admin'
import imageCompression from 'browser-image-compression'
import { Storage } from 'aws-amplify'

export const imagesBaseUrl = process.env.REACT_APP_IMAGES_BASE_URL || ''
export const defaultProfileImage = '/default_profile_image.jpg'
export const missingQuestionImage = '/missing-image.png'
export const profile_image_path = async (user_id: string): Promise<string> => {
  if (!user_id) {
    return undefined
  }

  const profilePic = await getProfilePictureUri({ params: { user_id } })
  return (profilePic.path ? profilePic.path : undefined)

}

export const profile_image_url = async (image_path: string): Promise<string> => {
  if (!image_path) {
    return defaultProfileImage
  }
  try {
    const img_url = await Storage.get(image_path, { expires: 3600 }) as string
    return img_url
  } catch (e) {
    return image_path
  }
}

export const question_image_url = async (image_id: string): Promise<string> => {
  if (!image_id) {
    return missingQuestionImage
  }
  try {
    const img_url = await Storage.get(`static-data/attachments/${image_id}`, { expires: 600 }) as string
    return img_url
  } catch (e) {
    console.log(`Error fetching question image ${image_id}: ${e}`)
    return missingQuestionImage
  }
}

export const resize_image = async (imageFile) => {
  const options = {
    maxSizeMB: 0.8,
    maxWidthOrHeight: 800,
    useWebWorker: true
  }
  const compressedFile = await imageCompression(imageFile, options)
  console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`) // smaller than maxSizeMB
  return compressedFile

}

