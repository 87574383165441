import { FC } from 'react'
import {
  useIonModal,
} from '@ionic/react'
import { Session } from 'components/sessions/useUpcomingSessions'

export const useSessionActionModal = (modal: FC<any>, session: Session, onClose: () => Promise<void>) => {
  const close_modal = () => {
    dismiss_modal()
    onClose()
  }
  const [open_modal, dismiss_modal] = useIonModal(modal, { session, close: close_modal })
  return () => open_modal({ backdropDismiss: false, cssClass: 'card-modal' })
}
