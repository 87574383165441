import { FC, useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonToolbar,
} from '@ionic/react'
import { cancelSession } from 'util/api/sessions'
import { Session } from 'components/sessions/useUpcomingSessions'

interface Props {
  session: Session
  close: () => void
}

export const CancelSession: FC<Props> = ({ session, close }) => {
  const [busy, set_busy] = useState(false)

  const cancel_session = async () => {
    set_busy(true)
    try {
      await cancelSession(session._id, session.subscription_id, session.session_date_time)
      close()
    } finally {
      set_busy(false)
    }
  }

  return (
    <IonCard style={{ margin: 0, height: '25rem' }}>
      <IonCardHeader>
        <IonCardTitle>Cancel session</IonCardTitle>
        <IonCardSubtitle>{session.subject.name} with {session.student.first_name} on {session.day} at {session.time}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <ul>
          <li>
            <p>If cancelling a session at the parents request, make sure they have sent a message to record the instruction.</p>
          </li>
          <li>
            <p>If cancelling a session with more than 48hrs notice, check with the parents if they would prefer to reschedule or substitute.</p>
          </li>
          <li>
            <p>Cancelling sessions should be done in line with T&#38;C&apos;s and Tutor Rules.</p>
          </li>
        </ul>
        <p>Would you like to cancel the session?</p>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton
              fill='solid'
              size='small'
              color='primary'
              className="ion-margin-top"
              onClick={cancel_session}
              disabled={busy}
            >
              Yes
            </IonButton>
          </IonButtons>
          <IonButtons slot='start'>
            <IonButton
              fill='outline'
              size='small'
              color='tertiary'
              className="ion-margin-top"
              onClick={close}
              disabled={busy}
            >
              No
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardContent>
    </IonCard>
  )
}
