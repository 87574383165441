import { FC, useEffect, useState } from 'react'
import { clone, getSnapshot, applySnapshot } from 'mobx-state-tree'
import { Question } from 'types/question_management/question/question'
import { Question as QuestionDisplay } from 'components/question'
import { QuestionForm } from 'components/admin/questions/QuestionForm'
import { SubmittedAnswer } from 'common/types/question/answer/answer'
import { IonCardContent, IonCardHeader, IonCardTitle, IonGrid, IonRow, IonCol, IonCard } from '@ionic/react'
import { observer } from 'mobx-react-lite'

interface Props {
  question: Question,
  dismiss: () => void
}

export const QuestionDetail: FC<Props> = observer(({ question, dismiss }) => {

  const [draft, setDraft] = useState<Question>(clone(question))

  const save_draft = async () => {
    return await question.save(getSnapshot(draft))
  }

  const reset_draft = () => {
    applySnapshot(draft, getSnapshot(question))
  }

  useEffect(
    () => setDraft(clone(question)),
    [question]
  )

  return (
    <IonGrid>
      <IonRow>
        <IonCol sizeLg='60%' sizeMd='100%'>
          <QuestionForm
            title='Question Editor'
            initial={draft}
            model={Question}
            submit={save_draft}
            reset_draft={reset_draft}
            dismiss={dismiss}
          />
        </IonCol>
        <IonCol sizeLg='40%' sizeMd='100%'>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Question Preview</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <QuestionDisplay
                question={draft}
                student_answer={empty_answer}
                correct_answers={draft.answers}
                answer_decision={'PREVIEW'}
                update_answer={noop}
              />
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  )
})
const empty_answer = SubmittedAnswer.create({})
const noop = () => undefined
