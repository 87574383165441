import { FC, FormEvent } from 'react'
import { Instance, SnapshotOut } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import styles from './question.module.css'
import { LineComponent } from 'components/question/line'
import { AskedQuestion } from 'common/types/question/question'
import { AllowedAnswers, SubmittedAnswer } from 'common/types/question/answer/answer'
import { Difficulty } from 'types/Difficulty'

interface Props {
  question: Instance<AskedQuestion>,
  student_answer: Instance<SubmittedAnswer>,
  correct_answers: Instance<AllowedAnswers>,
  answer_decision: string,
  update_answer: (line_elem_key: string, ref: string, value: string, selected: boolean) => void
}

export const Question: FC<Props> = observer(({ question: { lines }, student_answer, correct_answers, answer_decision, update_answer}) => {
  const handleInputChange = (line_element: string, ref: string, selected = false) => (e) => {
    e.preventDefault()
    const value = e.target.value
    update_answer(line_element, ref, value, selected)
  }

  const none_auto_focus_elements = ['Text', 'Image', 'MC input']

  const first_input_element = lines.reduce((acc, line) =>
    line.reduce((acc, element) => acc !== undefined
      ? acc
      : none_auto_focus_elements.includes(element.type)
        ? undefined
        : element,
    acc),
  undefined) as any

  return (
    <div className={styles.question_and_result}>
      <form
        onSubmit={override_submit}
        className={styles.questionContainer}
      >
        {lines.map((line, line_index) => (
          <LineComponent
            key={line_index}
            line_index={line_index}
            line={line}
            handleInputChange={handleInputChange}
            correct_answers={correct_answers}
            student_answer={student_answer}
            answer_decision={answer_decision}
            auto_focus_element={first_input_element}
          />
        ))}
      </form>
      {answer_decision && <p id="message" className={styles.message}>{answerDecisionMessage[answer_decision]}</p>}
    </div >
  )
})

const override_submit = (e: FormEvent) => {
  e.preventDefault()
  console.error('Question form submit blocked')
}

const answerDecisionMessage = {
  'INCORRECT': 'Sorry, that is not the correct answer. The correct answer is shown above.',
  'PASSED': 'You passed. The correct answer is shown above.',
  'CORRECT': 'Correct, well done!',
}
