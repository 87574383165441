import { Roles } from '../../util/auth/helper'

interface IProps {
    roles: Roles[]
}

export default function ({ roles }: IProps) {
  console.log(roles)

  return (
    <div>
            Hello Gold
    </div>
  )
}
