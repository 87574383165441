import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Layout, Main, Sidebar, Remote, Local, Messages, Time } from 'components/sessions/focus15'
import { FC, useEffect, useState } from 'react'
import { IonCardContent } from '@ionic/react'
import { Status, StudentSessionStore } from 'types/session/student_session'
import { SessionTimer } from 'components/sessions/focus15/Timer'
import { LocalVideoCard } from 'components/sessions/focus15/LocalVideoCard'
import { Video } from 'components/sessions/focus15/Video'
import { Audio } from 'components/sessions/focus15/Audio'
import { RemoteVideoCard } from 'components/sessions/focus15/RemoteVideoCard'
import { Notes } from 'components/notes'
import { useOnLeavePage } from 'generic_components/useOnLeavePage'

interface IProps {
  roles: Roles[]
}

export const StudentSessionPage: FC<IProps> = observer( function ({ roles }) {

  const { session_id } = useParams<{ session_id: string }>()

  const [{
    disconnect,
    get_session,
    join_session,
    local_audio_enabled,
    local_video,
    local_video_enabled,
    remote_audio,
    remote_screen,
    remote_video,
    session,
    student_status,
    toggle_local_audio,
    toggle_local_video,
  }] = useState(StudentSessionStore.create({}))

  useEffect(() => {
    console.log('Student connecting')
    get_session(session_id)
      .then(() => join_session(session_id))
  }, [session_id])

  useOnLeavePage(disconnect, [session_id])

  return (
    <PlytimePage roles={roles} fullwidth>
      <Layout>
        <Main>
          {student_status === Status.LOADING
            ? <p>Loading...</p>
            :student_status === Status.EARLY
              ? <p>Early...</p>
              :student_status === Status.READY
                ? <p>Ready...</p>
                :student_status === Status.AWAITING_TUTOR
                  ? <p>Waiting for Tutor...</p>
                  :student_status === Status.FINISHED
                    ? <p>Finished</p>
                    :student_status === Status.ERROR_NO_SESSION
                      ? <p>Error: No such session</p>
                      :student_status === Status.CONNECTING
                        ? <p>Connecting...</p>
                        :student_status === Status.ACTIVE
                          ? <>
                            {remote_screen ? <Video track={remote_screen} />
                              :remote_video ? <Video track={remote_video} />
                                : <p>No tutor video</p>
                            }
                          </>
                          : <p>Error unhandled state</p>
          }
        </Main>
        <Sidebar>
          <Time>
            <SessionTimer session={session} remote_name={session => session.tutor.first_name}/>
          </Time>
          {remote_screen &&
          <Remote>
            <RemoteVideoCard
              name={session ? session.tutor.first_name : 'Loading...'}
              track={remote_video}
            />
          </Remote>
          }
          <Local>
            <LocalVideoCard
              track={local_video}
              audio_enabled={local_audio_enabled}
              toggle_audio={toggle_local_audio}
              toggle_video={toggle_local_video}
              video_enabled={local_video_enabled}
            />
          </Local>
          <Audio
            track={remote_audio}
          />
          <Messages>
            <IonCardContent>
              {session && <Notes student={session.student_id} subject={session.subject._id} />}
            </IonCardContent>
          </Messages>
        </Sidebar>
      </Layout>
    </PlytimePage>
  )
})

export default StudentSessionPage
