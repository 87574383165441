import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { IonSelect, IonSelectOption, useIonModal } from '@ionic/react'
import { useStore } from 'types/store'
import { FieldComponent } from 'generic_components/MstForm'
import { CreateStudent } from 'components/CreateStudent'

interface Student {
  _id: string,
  first_name: string
}

export const StudentSelector: FieldComponent<Student> = observer(({ value, onChange, disabled }) => {
  const { parent_data: { profile, get_profile, subscriptions } } = useStore()
  const studentsWithExistingSubscriptions = subscriptions
    .map(({cancelled, student}) => !cancelled && student._id)
    .filter(Boolean)

  const students = profile?.students.filter((student) => !studentsWithExistingSubscriptions.includes(student._id)) || []

  useEffect(() => {
    if (students.length > 0 && value === undefined) {
      onChange({ _id: students[0]._id, first_name: students[0].first_name })
    }
  }, [value, students])

  useEffect(() => {
    get_profile()
  }, [])

  const close_create_student_modal = () => {
    dismiss_create_student_modal()
  }
  const [open_create_student_modal, dismiss_create_student_modal] = useIonModal(CreateStudent, { close_create_student_modal, on_saved: onChange })

  const handle_change = (e: CustomEvent<any>) => {
    const selection = e.detail.value
    if( selection === '+' ) {
      open_create_student_modal()
    } else {
      const student = students.find(student => student._id === e.detail.value)
      if (student) {
        onChange({ _id: student._id, first_name: student.first_name })
      }
    }
  }

  return (
    <IonSelect
      value={value?._id}
      onIonChange={handle_change}
      disabled={disabled}
      interfaceOptions={{
        cssClass: 'student-selector-options'
      }}
    >
      {students.map(student =>
        <IonSelectOption
          key={student._id}
          value={student._id}
        >
          {student.first_name}
        </IonSelectOption>
      )}
      <IonSelectOption
        value={'+'}
        class='add'
      >
        Add new Student
      </IonSelectOption>
      <IonSelectOption
        value={undefined}
        disabled={true}
        class='hidden'
      >
      </IonSelectOption>
    </IonSelect>
  )
})
