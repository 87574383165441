import { FC } from 'react'
import { IonIcon, IonButton } from '@ionic/react'
import { analytics, calendarOutline, pencil, pencilOutline, repeat, syncOutline, cameraOutline } from 'ionicons/icons'
import styled from 'styled-components'

interface Props {
  ion_icon?: 'analytics' | 'calendarOutline' | 'pencil' | 'pencilOutline' | 'repeat' | 'syncOutline' | 'cameraOutline',
  ion_icon_color?: 'blue' | 'grey' | 'green' | 'red' | 'white' | 'yellow',
  text: string,
  routerLink: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: 100%;
`

const IonIconStyled = styled(IonIcon)`
  align-self: start;
`

const Span = styled.span`
  width: 100%;
  color: var(--ion-color-tertiary);
  margin-top: 1rem;
`

export const BlockButtonWithImage: FC<Props> = ({ ion_icon, ion_icon_color = 'yellow', text, routerLink }) => {
  const ionIconLookup = {
    'analytics': analytics,
    'calendarOutline': calendarOutline,
    'pencil': pencil,
    'pencilOutline': pencilOutline,
    'repeat': repeat,
    'syncOutline': syncOutline,
    'cameraOutline': cameraOutline
  }
  const colorLookup = {
    yellow: '--ion-color-tertiary',
    green: '--ion-color-success',
    blue: '--ion-color-secondary',
    red: '--ion-color-danger',
    grey: '--ion-color-medium',
    white: '--ion-color-light'
  }
  return (
    <IonButton size='large' routerLink={routerLink} fill='solid' style={{ 'textTransform': 'capitalize', 'height': '7rem' }}>
      <Container>
        <IonIconStyled icon={ionIconLookup[ion_icon]} style={{ fontSize: '2.5rem', color: `var(${colorLookup[ion_icon_color]})`, 'width': '100%' }} />
        <Span>{text}</Span>
      </Container>
    </IonButton>
  )
}
