import { FC, ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import styled from 'styled-components'
import { ElementComponent } from 'components/question/element'
import { Line, AnswerElement } from 'common/types/question/question'
import { AllowedAnswers, SubmittedAnswer } from 'common/types/question/answer/answer'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  flex-wrap: wrap;
`

interface Props {
  line_index: number
  line: Instance<Line>,
  handleInputChange: (answer_key: string, ref: string, selected?: boolean) => (e: ChangeEvent) => void,
  student_answer: Instance<SubmittedAnswer>,
  correct_answers: Instance<AllowedAnswers>,
  answer_decision: string,
  auto_focus_element?: Instance<AnswerElement>,
}

export const LineComponent: FC<Props> = observer(({ line_index, line, handleInputChange, student_answer, answer_decision, correct_answers, auto_focus_element }) => {
  return (
    <Container>
      {line.map((element, element_index) => (
        <ElementComponent
          key={element_index}
          element_index={element_index}
          line_index={line_index}
          element={element}
          handleInputChange={handleInputChange}
          student_answer={student_answer}
          answer_decision={answer_decision}
          correct_answers={correct_answers}
          auto_focus_element={auto_focus_element}
        />
      ))
      }
    </Container>
  )
})
