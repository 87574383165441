import { types } from 'mobx-state-tree'

const TextElementBase = types
  .model('Text',
    {
      type: types.literal('Text'),
      text: types.optional(types.string, '')
    })


export const TextElement = TextElementBase
export type TextElement = typeof TextElementBase
