import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'types/store'
import { Subject } from 'types/question_management/subject/subject'
import { Theme } from 'types/question_management/theme/theme'
import { Topic } from 'types/question_management/topic/topic'
import { Endcode } from 'types/question_management/endcode/endcode'
import { SubjectDetail } from './subject_detail'
import { ThemeDetail } from './theme_detail'
import { TopicDetail } from './topic_detail'
import { EndcodeDetail } from './endcode_detail'
import styles from './manage_questions.module.css'

export const ManageQuestions: FC = observer(() => {
  const store = useStore().question_management
  const { subjects } = store

  const [subject, set_subject] = useState<Subject | undefined>()
  const [theme, set_theme] = useState<Theme | undefined>()
  const [topic, set_topic] = useState<Topic | undefined>()
  const [endcode, set_endcode] = useState<Endcode | undefined>()

  useEffect(() => {
    store.refresh()
  }, [])

  const select_subject = (s: Subject) => () => {
    set_subject(s)
    set_theme(undefined)
    set_topic(undefined)
    set_endcode(undefined)
    if (s.themes.length === 0) {
      s.load_children()
    }
  }

  const select_theme = (t: Theme) => () => {
    set_theme(t)
    set_topic(undefined)
    set_endcode(undefined)
    if (t.topics.length === 0) {
      t.load_children()
    }
  }

  const select_topic = (t: Topic) => () => {
    set_topic(t)
    set_endcode(undefined)
    if (t.endcodes.length === 0) {
      t.load_children()
    }
  }

  const select_endcode = (e: Endcode) => () => {
    set_endcode(e)
  }

  const add_subject = () => {
    const new_subject = Subject.create({
      _id: 'new_unsaved_subject',
      name: 'New subject',
      max_score: 0,
      min_score: 0,
      question_count: 0,
      endcode_count: 0,
      topic_count: 0,
      theme_count: 0,
      question_distribution: [],
      themes: [],
    })
    store.add_subject(new_subject)
    select_subject(new_subject)()
  }
  const add_theme = () => {
    const new_theme = Theme.create({
      _id: 'new_unsaved_theme',
      name: 'New theme',
      max_score: 0,
      min_score: 0,
      question_count: 0,
      endcode_count: 0,
      topic_count: 0,
      question_distribution: [],
      topics: [],
    })
    subject.add_theme(new_theme)
    select_theme(new_theme)()
  }
  const add_topic = () => {
    const new_topic = Topic.create({
      _id: 'new_unsaved_topic',
      name: 'New topic',
      max_score: 0,
      min_score: 0,
      question_count: 0,
      endcode_count: 0,
      question_distribution: [],
      endcodes: [],
    })
    theme.add_topic(new_topic)
    select_topic(new_topic)()
  }
  const add_endcode = () => {
    const new_endcode = Endcode.create({
      _id: 'new_unsaved_endcode',
      name: 'New endcode',
      max_score: 0,
      min_score: 0,
      question_count: 0,
      question_distribution: [],
    })
    topic.add_endcode(new_endcode)
    select_endcode(new_endcode)()
  }

  return (
    <div className={styles.container}>
      <div className={styles.breadcrumb}>
        <div className={styles.crumb}>
          <h4>Subject</h4>
          {subjects.map((s) => (
            <div
              key={s._id}
              className={s === subject ? styles.selected : styles.selectable}
              onClick={select_subject(s)}
            >
              {s.name}
            </div>
          ))}
          <button type="button" onClick={add_subject}>
            Add Subject
          </button>
        </div>
        <div className={styles.crumb}>
          <h4>Theme</h4>
          {subject &&
            subject.themes.map((t) => (
              <div
                key={t._id}
                className={t === theme ? styles.selected : styles.selectable}
                onClick={select_theme(t)}
              >
                {t.name}
              </div>
            ))}
          <button
            type="button"
            disabled={
              subject === undefined || subject._id === 'new_unsaved_subject'
            }
            onClick={add_theme}
          >
            Add Theme
          </button>
        </div>
        <div className={styles.crumb}>
          <h4>Topic</h4>
          {theme &&
            theme.topics.map((t) => (
              <div
                key={t._id}
                className={t === topic ? styles.selected : styles.selectable}
                onClick={select_topic(t)}
              >
                {t.name}
              </div>
            ))}
          <button
            type="button"
            disabled={theme === undefined || theme._id === 'new_unsaved_theme'}
            onClick={add_topic}
          >
            Add Topic
          </button>
        </div>
        <div className={styles.crumb}>
          <h4>Endcode</h4>
          {topic?.endcodes.map((e) => (
            <div
              key={e._id}
              className={e === endcode ? styles.selected : styles.selectable}
              onClick={select_endcode(e)}
            >
              {e.name}
            </div>
          ))}
          <button
            type="button"
            disabled={topic === undefined || topic._id === 'new_unsaved_topic'}
            onClick={add_endcode}
          >
            Add Endcode
          </button>
        </div>
      </div>
      <div className={styles.detail}>
        { endcode !== undefined ? (
          <EndcodeDetail endcode={endcode} />
        ) : topic !== undefined ? (
          <TopicDetail topic={topic} />
        ) : theme !== undefined ? (
          <ThemeDetail theme={theme} />
        ) : subject !== undefined ? (
          <SubjectDetail subject={subject} />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
})
