import axios from 'axios'
import { SnapshotOut, SnapshotIn, IAnyType } from 'mobx-state-tree'
import { ApiError } from 'types/ApiError'
import { Ask } from 'types/Ask'
import { AskedQuestion as Question } from 'common/types/question/question'
import { Auth } from 'aws-amplify'
import { ci } from 'util/config'

const instance = axios.create({
  baseURL: '/api/'
})

instance.interceptors.request.use(async (config) => {
  const authSession = await Auth.currentSession().catch(e => {
    console.log('[plytime_auth] error getting auth session:' + e)
    return undefined
  })
  config.headers.Authorization = `Bearer ${authSession?.getIdToken()?.getJwtToken()}`
  if (ci) {
    config.headers['ci-user-id'] = '_id_aaaaaa'
    config.headers['ci-user-role'] = 'ADMIN'
    config.headers['ci-user-email'] = 'test@ci.com'
  }
  return config
})

const Methods = {
  PUT: instance.put,
  GET: instance.get,
  POST: instance.post,
  PATCH: instance.patch,
  DELETE: instance.delete,
}

const validate = (model: IAnyType) => (input: unknown): void => {
  const validation_errors = model.validate(input, [])
  if (validation_errors.length > 0) {
    console.log('validating model:' + JSON.stringify(model, null, 2))
    console.log('validation errors:' + JSON.stringify(validation_errors, null, 2))
    throw new ApiError(400, [
      'Invalid API props',
      ...validation_errors.map(
        e => `${e.message} [${e.value}] @ [${e.context.map(c => c.path).join('-')}]` || '???'
      )
    ])
  }
}

export const api = <Req extends IAnyType, Res extends IAnyType>(
  request_model: Req,
  response_model: Res,
  method: keyof typeof Methods,
  url_template: string
) => {
  const validate_request = validate(request_model)
  const validate_response = validate(response_model)
  return async (request: SnapshotIn<Req> = {} as SnapshotIn<Req>): Promise<SnapshotOut<Res>> => {
    validate_request(request)
    const url = url_template
      .split('/')
      .map(part => request?.params?.[part] || part)
      .join('/')

    try {
      const response = await instance({
        method,
        url,
        data: request.body,
        params: request.query
      })
      validate_response(response.data)
      return response.data
    } catch (e) {
      console.log(`[api-error=${url_template}] + ${JSON.stringify(e)}`)
      if (e instanceof ApiError) {
        throw e
      }
      throw new ApiError(
        e.response?.status || 500,
        e.response?.data?.errors || [e.message]
      )
    }
  }
}

const todo_api = async <M extends IAnyType>(props: {
  model: M;
  method: keyof typeof Methods;
  url: string;
  body?: any;
}): Promise<SnapshotOut<M>> => {
  let response: any
  try {
    const method = Methods[props.method] as any
    response = await method(props.url, props.body)
  } catch (e) {
    throw new ApiError(
      e.response?.status || 500,
      e.response?.data?.errors || [e.message]
    )
  }
  const errors = props.model.validate(response.data, [])
  if (errors && errors.length > 0) {
    console.error('Invalid response:')
    console.error(errors)
    throw new ApiError(500, [
      'Invalid response',
      ...errors.map((e) => e.message || '???'),
    ])
  }
  return response.data
}

export const ask = async (ask: SnapshotOut<typeof Ask>) =>
  todo_api({
    model: Question,
    method: 'PUT',
    url: 'ask',
    body: ask,
  })

export const getStudent = async (username) => {
  try {
    const { data } = await instance.get('student', { params: { username } })
    return data
  } catch (e) {
    console.error(e)
    if (e.response.status === 404) return null
    else {
      throw new ApiError(
        e.response?.status || 500,
        e.response?.data?.errors || [e.message]
      )
    }
  }
}

export const getTutorByEmail = async (email) => {
  const { data } = await instance.get('tutor', { params: { email } })
  return data
}

export const getTutorBy_id = async (_id) => {
  const { data } = await instance.get('tutor', { params: { _id } })
  return data
}

export const postEndcodeQuestion = async (endcode_id: string, question: unknown): Promise<unknown> => {
  const { data } = await instance.post(`endcode/${endcode_id}/question`, question)
  return data
}

interface IcheckAnswer {
  result: string;
  answers: [];
}

export const checkAnswer = async (question_id: string, game_summary_id: string, game_id: string, answer: any): Promise<IcheckAnswer> => {
  const { data } = await instance.post(`question/${question_id}/answer`, { ...answer, game_summary_id, game_id })
  return data
}

export const getStudentsUsageStats = async (student_id: string) => {
  const { data } = await instance.get(`student/${student_id}/usage_stats`)
  return data
}

export const getStudentsDifficultyDistribution = async (student_id: string) => {
  const { data } = await instance.get(`student/${student_id}/difficulty_distribution`)
  return data
}

export const getStudentsSubjectDistribution = async (student_id: string) => {
  const { data } = await instance.get(`student/${student_id}/subject_distribution`)
  return data
}

export const getFolder = async (folder?: string) => {
  const { data } = await instance.get(`import/folders${folder ? `?folder=${folder}` : ''}`)
  return data
}

export const importSubjects = async (file_id: string) => {
  const { data } = await instance.post(`import/subjects?file=${file_id}`)
  return data
}

export const importQuestions = async (file_id: string) => {
  const { data } = await instance.post(`import/questions?file=${file_id}`)
  return data
}

export const getActiveImports = async () => {
  const { data } = await instance.get('import')
  return data
}

export const getUserById = async (_id: string) => {
  const { data } = await instance.get('user', { params: { _id } })
  return data
}

export const getUserByEmail = async (email: string) => {
  const { data } = await instance.get('user', { params: { email } })
  return data
}

export const updateAdminVars = async (vars) => {
  const { data } = await instance.put('admin_variables', vars)
  return data
}

export const getAdminVars = async () => {
  const { data } = await instance.get('admin_variables')
  return data
}
