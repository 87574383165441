import { applySnapshot, flow, SnapshotOut, types } from 'mobx-state-tree'
import _ from 'lodash'

import { StudentId } from 'types/student/id'
import { SubjectId } from 'common/types/subject/id'
import { RelativeLevels } from 'common/types/student/relative_levels'
import { ApproxAvailabilities, TutorAvailabilitySlot } from 'common/types/tutor/availability_search'
import { ApproxTime, SessionOptions } from 'common/types/session/options'
import { Day } from 'common/types/basic/day'
import { search_tutor_availabilities} from 'util/api/tutor/availability_search'
import { createSubscription } from 'util/api/subscriptions'
import { Subscription } from 'common/types/subscription'
import { getTutorAvailability } from 'util/api/tutor/availabilty'
import { SelectedSlot, SelectedSlots } from 'common/types/subscription'
import { getSubscriptionProducts } from 'util/api/subscription_products'
import { SubscriptionProduct } from 'common/types/subscription_products/subscription_products'
import { SessionOptionEnum } from 'common/types/session/options'

const get_current_subscription_availability = async (existing_subscription) => {
  if (existing_subscription?.tutor._id) {
    const tutorAvailability = await getTutorAvailability(existing_subscription?.tutor._id)

    return existing_subscription?.slots.map((slot_id) => {
      const slot = Object
        .entries(tutorAvailability.availability)
        .map(([_, value]) => value.find(({ _id }) => _id === slot_id))
        .filter(Boolean)[0]

      return {
        _id: slot_id,
        day_of_week: slot.day_of_week,
        time: slot.time,
        tutor_id: existing_subscription?.tutor._id,
      }
    })
  }
  return []
}

export default types
  .model('SubscriptionWizardState', {
    step: types.optional(types.number, 0),
    student: types.maybe(types.model({
      _id: StudentId,
      first_name: types.string
    })),
    subject: types.maybe(types.model({
      _id: SubjectId,
      name: types.string
    })),
    relative_level: types.optional(RelativeLevels, 'On track'),
    selected_session_option: types.optional(types.string, SessionOptionEnum.OneFifteenMin),
    availability: ApproxAvailabilities,
    finding_availability: types.optional(types.boolean, false),
    tutor_availabilities: types.array(types.compose(TutorAvailabilitySlot, types.model({identifier: types.identifier}))),
    existing_subscription: types.maybe(types.reference(Subscription)),
    selected_slots: SelectedSlots,
    promo_code: types.optional(types.string, ''),
    referral_code: types.optional(types.string, ''),
    query_with_referral_code: types.optional(types.boolean, false),
    is_invalid_referral_code: types.optional(types.boolean, false),
    products: types.array(SubscriptionProduct)
  })
  .views(self => ({
    session_count: () => {
      const selected_product = self.products.find((product) => product.label === self.selected_session_option)
      return selected_product.sessions
    },
    cost: () => {
      const selected_product = self.products.find((product) => product.label === self.selected_session_option)
      if (!selected_product) {
        return 'Loading...'
      }
      return `£${(selected_product?.weekly_cost_in_pence / 100).toFixed(2)}`
    },
    is_available: (day: string, time: string) => self.availability.findIndex(a => a.day === day && a.approx_time === time) !== -1,
    available_slots: (tutor_id: string) => {
      if (!tutor_id) {
        const possible_tutors = Object.entries(_.groupBy(self.tutor_availabilities, 'tutor_id'))
          .filter(([_, slots]: any) =>
            self.selected_slots.every((selectedSlot) => slots.find((s) =>
              selectedSlot.day_of_week === s.day_of_week &&
                    selectedSlot.time === s.time
            )))
          .map(([key]) => key)

        const all_possible_slots = self
          .tutor_availabilities
          .filter((slot) => possible_tutors.includes(slot.tutor_id))
          .map(({time, day_of_week}) => ({
            time,
            day_of_week,
          }))

        return _.uniqWith(all_possible_slots, _.isEqual)
      }

      return self.tutor_availabilities
    },
  }))
  .actions(self =>
    ({
      next: () => {
        self.step = self.step + 1
      },
      back: () => {
        self.step = self.step - 1
      },
      update_student: (student: { _id: string, first_name: string }) => {
        self.student = student
      },
      update_subject: (subject: { _id: string, name: string }) => {
        self.subject = subject
      },
      update_relative_level: (level: SnapshotOut<typeof RelativeLevels>) => {
        self.relative_level = level
      },
      update_session_count: (option: SnapshotOut<SessionOptions>) => {
        self.selected_session_option = option

        if (self.selected_session_option === SessionOptionEnum.PlatformOnly) {
          self.selected_slots.replace([])
        }
      },
      update_availability: (day: SnapshotOut<Day>, approx_time: SnapshotOut<ApproxTime>, available: boolean) => {
        if (available) {
          self.availability = self.availability.filter(a => !(a.day === day && a.approx_time === approx_time)) as any
        } else {
          self.availability.push({day, approx_time})
        }

        self.selected_slots.replace([])
      },
      replace_selected_slots: (slots: SelectedSlot[]) => {
        self.selected_slots.replace(slots)
      },
      update_slot_selection: (slot: SelectedSlot) => {
        const isAlreadySelected = self.selected_slots.some(({day_of_week, time}) =>
          day_of_week === slot.day_of_week &&
              time === slot.time
        )

        if (!isAlreadySelected) {
          self.selected_slots.push(slot)
        } else {
          self.selected_slots.replace(
            self.selected_slots.filter(
              ({ day_of_week, time }) => !(slot.day_of_week === day_of_week && slot.time === time)
            )
          )
        }
      },
      update_existing_subscription: (existing_subscription) => {
        self.existing_subscription = existing_subscription
      },
      find_availability: flow(function* () {
        if (self.finding_availability || !self.subject) {
          return
        }
        self.finding_availability = true

        try {
          const availability = yield search_tutor_availabilities({
            body: {
              subject: self.subject._id,
              session_option: self.selected_session_option as SessionOptionEnum,
              // If we have a tutor, and therefore an existing subscription. We just want all that tutors availability.
              // Even slots which are busy (on the current subscription).
              availability: self.existing_subscription?.tutor._id ?
                undefined :
                self.availability,
              tutor_id: self.existing_subscription?.tutor._id,
            }
          })

          applySnapshot(
            self.tutor_availabilities,
            [
              ...availability,
              ...yield get_current_subscription_availability(self.existing_subscription)
            ].map((slot) => ({
              ...slot,
              identifier: `${slot.day_of_week} - ${slot.time}`
            })
            ),
          )
        } catch (e) {
          console.error(e)
        } finally {
          self.finding_availability = false
        }
      }),
      update_promo_code: (code: string) => {
        self.promo_code = code
      },
      update_referral_code: (code: string) => {
        self.referral_code = code
      },
      update_query_with_referral_code: (value: boolean) => {
        self.query_with_referral_code = value
      },
      subscribe: flow(function* (complete_url: string) {
        const result = yield createSubscription({
          body: {
            student: self.student._id,
            subject: self.subject._id,
            session_option: self.selected_session_option as SessionOptionEnum,
            slots: self.selected_slots,
            relative_level: self.relative_level,
            success_url: `${complete_url}?status=success`,
            error_url: `${complete_url}?status=error`,
            tutor_id: self.existing_subscription?.tutor._id,
            ...self.referral_code ? {referral_code: self.referral_code} : {},
          }
        })
        return result
      }),
      get_products: flow(function*(){
        self.products = (yield getSubscriptionProducts()).results
      })
    }))
