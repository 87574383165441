import { FC } from 'react'
import { IonIcon, IonButton } from '@ionic/react'
import { analytics, cash, gameController, pencil, pencilOutline, repeat, syncOutline, mail, shuffleOutline, cameraOutline } from 'ionicons/icons'
import focus15 from '../images/focus15_small.svg'
import styled from 'styled-components'

interface Props {
  ion_icon?: keyof typeof ionIconLookup
  ion_icon_color?: keyof typeof colorLookup
  text: string
  routerLink?: string
  onClick?: ()=>void
  embed?: ()=>any
}

export const ButtonWithImage: FC<Props> = ({ ion_icon, ion_icon_color = 'yellow', text, routerLink, onClick, embed }) => {
  return (
    <IonButtonRounded
      size='large'
      routerLink={routerLink}
      onClick={onClick}
      style={{ 'textTransform': 'capitalize' }}
    >
      <Container>
        <IonIconStyled icon={ionIconLookup[ion_icon]} style={{ fontSize: '3.0rem', color: `var(${colorLookup[ion_icon_color]})` }} />
        <Span>{text}</Span>
        {embed && embed()}
      </Container>
    </IonButtonRounded>
  )
}

const ionIconLookup = {
  'cash': cash,
  'analytics': analytics,
  'focus15': focus15,
  'gameController': gameController,
  'mail': mail,
  'pencil': pencil,
  'pencilOutline': pencilOutline,
  'repeat': repeat,
  'syncOutline': syncOutline,
  'switchUser': shuffleOutline,
  'cameraOutline': cameraOutline,
}

const colorLookup = {
  yellow: '--ion-color-tertiary',
  green: '--ion-color-success',
  blue: '--ion-color-secondary',
  red: '--ion-color-danger',
  grey: '--ion-color-medium',
  white: '--ion-color-light'
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const IonIconStyled = styled(IonIcon)`
  align-self: start;
`

const Span = styled.span`
  width: 100%
`

const Img = styled.img`
  height: 40px;
  margin: 15px;
`

const IonButtonRounded = styled(IonButton)`
  --border-radius: 1.5vh;
`
