import { FC } from 'react'
import styled from 'styled-components'

import { PlytimePageStudentBackground } from './plytime_page_student_background'
import { Roles } from 'util/auth/helper'
import { IonAlert } from '@ionic/react'
import { useHistory } from 'react-router-dom'

interface IProps {
    roles: Roles[]
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const SubscriptionLevelTooLow: FC<IProps> = ({ roles }) => {
  const history = useHistory()

  return (
    <PlytimePageStudentBackground roles={roles}>
      <Container>
        <IonAlert
          isOpen
          message="Upgrade to a PLYTIME Learning Subscription to view this page"
          backdropDismiss={false}
          buttons={[
            {
              text: 'Go Back',
              handler: () => {
                history.push('/student')
              },
            }
          ]}
        />
      </Container>
    </PlytimePageStudentBackground>
  )
}
