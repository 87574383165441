import { Instance, flow, applySnapshot, types, getRoot } from 'mobx-state-tree'
import { Tutor } from 'types/admin/tutor'
import { getTutor } from 'util/api/tutor'
import { Subscription } from 'common/types/subscription'
import { listSubscriptions } from 'util/api/subscriptions'

export const TutorData = types
  .model(
    'TutorData',
    {
      profile: types.maybe(Tutor),
      subscriptions: types.array(Subscription)
    }
  )
  .volatile(self => ({
    busy: false
  }))
  .actions(self => ({
    get_profile: flow(function* () {
      console.log('getting profile...')
      if (self.busy) {
        console.log('Cannot get profile when busy')
        throw new Error('Cannot get profile when busy')
      }
      const my_id = (getRoot(self) as any)?.auth?.user?._id
      if (my_id === undefined) {
        console.log('Cannot get profile for unknown user')
        throw new Error('Cannot get profile for unknown user')
      }
      self.busy = true
      try {
        const tutor = yield getTutor({ query: { _id: my_id } })
        if (self.profile === undefined) {
          self.profile = Tutor.create(tutor)
        } else {
          applySnapshot(self.profile, tutor)
        }
      } catch (error) {
        console.log('error fetching profile...' + error)
        console.error(error.errors || error.message || error)
      }
      finally {
        self.busy = false
      }
    }),
    get_subscriptions: flow(function* () {
      try {
        const subs = yield listSubscriptions()
        applySnapshot(self.subscriptions, subs)

      } catch (error) {
        console.error(`get_subscriptions: ${error}`)
        throw error
      }
    }),
  }))
export type TutorData = Instance<typeof TutorData>
