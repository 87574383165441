import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { useState, useEffect } from 'react'
import { DriveItem, DriveFolder, DriveFile } from 'types/import/drive'
import { getFolder, importSubjects, importQuestions, getActiveImports } from 'util/api'
import { DateTime } from 'luxon'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonList,
} from '@ionic/react'
import { LastImport } from 'common/types/import/LastImport'
import { SnapshotOut } from 'mobx-state-tree'
import styles from 'components/admin/import.module.css'

const instruction = 'Open folder or select file to import'

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  const [loading, setLoading] = useState(true)
  const [importing, setImporting] = useState(false)
  const [folders, set_folders] = useState<DriveItem[]>([])
  const [question_sheets, set_question_sheets] = useState<DriveFile[]>([])
  const [subject_sheets, set_subject_sheets] = useState<DriveFile[]>([])
  const [history, setHistory] = useState<DriveFolder[]>([])
  const [message, setMessage] = useState<string>('')
  const [last_import, set_last_import] = useState<SnapshotOut<LastImport> | undefined>()

  useEffect(() => { gohome() }, [])
  useEffect(() => {
    refreshImporting()
    const timer = setInterval(refreshImporting, 20000)
    return () => clearTimeout(timer)
  }, [])

  const set_file_state = (files: DriveItem[]) => {
    const subjects_files = files.filter(file =>
      file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      && file.name.endsWith('.subjects.xlsx')
    ) as DriveFile[]
    const questions_files = files.filter(file =>
      file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      && file.name.endsWith('.questions.xlsx')
    ) as DriveFile[]
    const folders = files.filter(file =>
      file.mimeType === 'application/vnd.google-apps.folder'
    )
    set_subject_sheets(() => subjects_files)
    set_question_sheets(() => questions_files)
    set_folders(() => folders)
  }

  const gohome = async () => {
    setLoading(true)
    setMessage('Loading...')
    setHistory([])
    try {
      const files = await getFolder()
      set_file_state(files)
      setMessage(instruction)
    }
    catch (e) {
      setMessage(`Error: ${e.message}`)
    }
    finally {
      setLoading(false)
    }
  }

  const goto = async (folder: DriveFolder) => {
    setLoading(true)
    setMessage('Loading...')
    const history_index = history.findIndex(h => h.id === folder.id)
    setHistory(history_index === -1 ? [...history, folder] : history.slice(0, history_index + 1))
    try {
      const files = await getFolder(folder.id)
      set_file_state(files)
      setMessage(instruction)
    }
    catch (e) {
      setMessage(`Error: ${e.message}`)
    }
    finally {
      setLoading(false)
    }
  }

  const subjectupload = async (file: DriveFile) => {
    setLoading(true)
    setImporting(true)
    setMessage('Importing Subjects, themes, Topics and Endcodes, this may take a while, please wait...')
    try {
      await importSubjects(file.id)
      setLoading(false)
      setMessage(`Import complete, see ${file.name} for results`)
    }
    catch (e) {
      setMessage(`Error: ${e.message}`)
    }
    finally {
      setLoading(false)
      refreshImporting()
    }
  }

  const questionupload = async (file: DriveFile) => {
    setLoading(true)
    setMessage('Importing Questions, this may take a while, please wait...')
    try {
      importQuestions(file.id)
      setLoading(false)
      setMessage(`Import complete, see ${file.name} for results`)
    }
    catch (e) {
      setMessage(`Error: ${e.message}`)
    }
    finally {
      setLoading(false)
      refreshImporting()
    }
  }

  const refreshImporting = async () => {
    try {
      setMessage('Refreshing...')
      const active_import = await getActiveImports()
      set_last_import(active_import)
      if (active_import === '') {
        setImporting(false)
      }
      else if (active_import.finished) {
        setMessage(`Last import complete at ${active_import.finished} see excel file for results,\n${instruction}`)
        setImporting(false)
      }
      else if (active_import.started) {
        if (DateTime.fromISO(active_import.started) < DateTime.now().minus({ minutes: 15 })) {
          setMessage('Error: Last import probably timed out, presume spreadsheet too big. Contact support if the problem persists.')
          setImporting(false)
        } else {
          setMessage(`Last import started at ${active_import.started}, please wait... up to 15 mins. Last updated at ${active_import.lastUpdate}: ${active_import.message}`)
          setImporting(true)
        }
      }
    }
    catch (e) {
      setMessage(`Error: ${e.message}`)
    }
  }

  const disabled = loading || importing

  return (
    <PlytimePage roles={roles}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Import
          </IonCardTitle>
          <IonCardSubtitle>
            {message} <br /><IonButton onClick={refreshImporting}>Refresh import status</IonButton>
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                Explore Google Drive Folder
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <div className={styles.breadcrumb}>
                <button
                  onClick={gohome}
                  disabled={disabled}
                  className={styles.goto}
                >
                  subjects
                </button>
                {history.map(file => (
                  <button
                    key={file.id}
                    onClick={() => goto(file)}
                    disabled={disabled}
                    className={styles.goto}
                  >
                    {file.name}
                  </button>
                ))}
              </div>
              <div className={styles.folder}>
                {folders.map(file =>
                  <button
                    key={file.id}
                    onClick={() => goto(file as DriveFolder)}
                    disabled={disabled}
                    className={styles.goto}
                  >
                    {file.name}
                  </button>
                )}
              </div>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                Select a subjects file to import
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <div className={styles.folder}>
                {subject_sheets.map(file =>
                  <button
                    key={file.id}
                    onClick={() => subjectupload(file)}
                    disabled={disabled}
                    className={styles.subjectupload}
                  >
                    {file.name}
                  </button>
                )}
              </div>
            </IonCardContent>
          </IonCard>
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                Select a questions file to import
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <div className={styles.folder}>
                {question_sheets.map(file =>
                  <button
                    key={file.id}
                    onClick={() => questionupload(file)}
                    disabled={disabled}
                    className={styles.questionupload}
                  >
                    {file.name}
                  </button>
                )}
              </div>
            </IonCardContent>
          </IonCard>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Last import results:
          </IonCardTitle>
          <IonCardSubtitle>
            Last started: {last_import?.started || ''} <br />
            Last finished:{last_import?.finished || '-'} <br />
            Last Update:{last_import?.lastUpdate || '-'}<br />
            Message :{last_import?.message || '-'}<br />
            <IonButton onClick={refreshImporting}>Refresh import status</IonButton>
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
            {last_import?.results?.map(({ type, detail, count }, i) => (
              <IonItem key={i} color={type === 'info' ? 'gray' : 'danger'}>
                {`${detail || '-'} - ${count || '-'}`}
              </IonItem>
            ))}
          </IonList>
        </IonCardContent>
      </IonCard>
    </PlytimePage >
  )
}
