import { PlytimePage } from 'components/plytime_page'
import { IonButton, IonToolbar, IonButtons } from '@ionic/react'
import { Roles } from 'util/auth/helper'
import { CreateTutor } from 'components/admin/CreateTutor'
import { TutorsList } from 'components/admin/TutorsList'

interface IProps {
    roles: Roles[]
}

export default function ({ roles }: IProps) {
  return (
    <PlytimePage roles={roles}>
      <CreateTutor/>
      <IonToolbar>
        <IonButtons slot='end'>
          <IonButton
            routerLink='/admin/tutors/all_availability'
            size='small'
            color='primary'
            fill='solid'
            className="ion-margin-top"
          >
            View availability for all tutors
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <TutorsList/>
    </PlytimePage>
  )
}
