import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import SubscriptionWizardState from './SubscriptionWizardState'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonToolbar
} from '@ionic/react'
import { SubjectSelector } from 'components/common/SubjectSelector'
import { StudentSelector } from './StudentSelector'
import { LevelSelection } from './LevelSelection'

interface Props {
  state: Instance<typeof SubscriptionWizardState>
  close: () => void
}

export const TutoringDetails: FC<Props> = observer(({ state, close }) => {
  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Which student is this subscription for?</IonCardTitle>
          <IonCardSubtitle>
            <p>Choose from the drop down list or add a new student</p>
            <p>A student can only have one subscription per subject.</p>
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem lines='inset'>
            <StudentSelector value={state.student} onChange={state.update_student} disabled={false} />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Subject</IonCardTitle>
          <IonCardSubtitle>What would they like to learn?</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem lines='inset'>
            <SubjectSelector value={state.subject} onChange={state.update_subject} disabled={false} />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Students level</IonCardTitle>
          <IonCardSubtitle>If your child completed the <a href="https://plytime.com/student-primary-maths-assessments/" target="_blank" rel="noreferrer">assessment</a>, please select the recommended level. If not, please choose your student&apos;s level from 1 to 7 (where 1 is significantly behind and 7 is significantly ahead in their school year).</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem lines='inset'>
            <LevelSelection state={state} />
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonToolbar>
        <IonButtons slot='end'>
          <IonButton
            fill='solid'
            size='small'
            color='primary'
            className="ion-margin-top"
            onClick={state.next}
            disabled={state.student === undefined || state.subject === undefined || state.relative_level === undefined}
          >
            Next
          </IonButton>
        </IonButtons>
        <IonButtons slot='start'>
          <IonButton
            fill='outline'
            size='small'
            color='tertiary'
            className="ion-margin-top"
            onClick={close}
          >
            Cancel
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </>
  )
})
