import { createContext, useContext } from 'react'
import { types, Instance, onSnapshot, applySnapshot } from 'mobx-state-tree'
import { AuthModel } from 'types/user/model'
import { GameModel } from 'types/game/model'
import { QuestionManagementModel } from 'types/question_management/model'
import { TutorList } from 'types/admin/tutor/tutor_list'
import { SubjectList } from 'types/reference/subjects/subject_list'
import { TutorData } from 'types/tutor'
import { AdminStore } from 'types/admin/store'
import { ParentData } from 'types/parent'
import { MixesList } from 'types/student/mixes'
import { StudentSubjectScoreList } from 'types/admin/student_subject_scores/student_subject_scores_list'
import SubscriptionWizardState from '../components/subscription/SubscriptionWizardState'
import { Tokens } from './student/tokens'
import { StudentSubscriptions } from './student/subscriptions'

export const Store = types.model({
  auth: types.optional(AuthModel, {}),
  game: types.optional(GameModel, {
    focus: { subjects: [] },
    selected_subjects: { subjects: [] },
    difficulty: 'MEDIUM',
  }),
  question_management: types.optional(QuestionManagementModel, {
    subjects: [],
    loading: true
  }),
  admin_tutor_list: types.optional(TutorList, {}),
  admin_student_score_list: types.optional(StudentSubjectScoreList, {}),
  subject_list: types.optional(SubjectList, {}),
  tutor_data: types.optional(TutorData, {}),
  admin: types.optional(AdminStore, {}),
  parent_data: types.optional(ParentData, {}),
  student_tokens: types.optional(Tokens, {
    isLoading: false,
    tokens: [],
    prizes: [],
    total_token_count: 0,
    total_pending_token_count: 0,
  }),
  student_mixes_list: types.optional(MixesList, {}),
  student_subscriptions: types.optional(StudentSubscriptions, {
    isLoading: false,
    subscriptions: []
  }),
  subscription_wizard: types.optional(SubscriptionWizardState, {})
})
  .actions(self => ({
    setSubscriptionWizard: (subscription_wizard) => {
      self.subscription_wizard = subscription_wizard
    },
    reset: () => {
      console.log('store reset')
      applySnapshot(self, { auth: {
        role: self.auth.role,
        username: self.auth.username
      }})
    }
  }))

export type Store = Instance<typeof Store>

const initialise = () => {
  try {
    const store = Store.create(JSON.parse(localStorage.getItem('store')))
    return store
  }
  catch {
    return Store.create({})
  }
}
export const InitialStore = initialise()

onSnapshot(InitialStore, snapshot => {
  // console.log('onSnapshot:' + JSON.stringify(snapshot, null, 2))
  localStorage.setItem('store', JSON.stringify(snapshot))
})

const StoreContext = createContext<Store>(InitialStore)

export const useStore = () => useContext<Store>(StoreContext)

export const StoreProvider = StoreContext.Provider
