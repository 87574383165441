import { flow, types } from 'mobx-state-tree'

import { DisplayToken, DisplayPrize } from 'common/types/student/tokens'
import { getPrizes, getTokens, postPrize } from 'util/api/student/tokens'

export const Tokens = types.model(
  'Tokens', {
    isLoading: types.boolean,
    tokens: types.array(DisplayToken),
    prizes: types.array(DisplayPrize),
    total_token_count: types.number,
    total_pending_token_count: types.number,
  }
).actions(self => ({
  fetch_tokens: flow(function* (student_id: string) {
    self.isLoading = true
    const tokenResponse = yield getTokens(student_id)
    self.tokens = tokenResponse.rows
    self.total_pending_token_count = tokenResponse.total_pending_token_count
    self.total_token_count = tokenResponse.total_token_count

    self.isLoading = false
  }),
  fetch_prizes: flow(function* () {
    self.isLoading = true
    const prizesResponse = yield getPrizes()
    self.prizes = prizesResponse.results
    self.isLoading = false
  }),
  redeem_prize: flow(function* (prize_id) {
    self.isLoading = true
    yield postPrize(prize_id)
    self.isLoading = false
  })
}))
