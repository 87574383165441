import { useEffect } from 'react'
import { useStore } from 'types/store'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { IonLoading } from '@ionic/react'

import { PlytimePageStudentBackground } from 'components/plytime_page_student_background'
import { Roles } from 'util/auth/helper'
import { useUser } from 'context/userContext'
import { ButtonWithImage } from 'generic_components/ButtonWithImage'
import { JoinFocus15Button } from 'components/JoinFocus15Button'
import { UnreadIndicator } from 'components/notes/unread_indicator'

const Container = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
`

interface IProps {
  roles: Roles[]
}

export default observer(({ roles }: IProps) => {
  const { user } = useUser()
  // Hack to get Math subject ID while Math is the only subject
  const { subject_list: { subjects, refresh }, student_tokens, student_subscriptions } = useStore()

  const math = subjects.find(subject => subject.name === 'Maths')
  useEffect(() => {
    refresh()
    student_tokens.fetch_tokens(user._id)
    student_subscriptions.get_subscriptions()
  }, [])

  return (
    <PlytimePageStudentBackground roles={roles}>
      {user &&
          <Container>
            <JoinFocus15Button />
            <ButtonWithImage
              routerLink='/game/step1_setup'
              text='Play'
              ion_icon='gameController'
              ion_icon_color='red'
            />
            <ButtonWithImage
              routerLink='/student/performance'
              text='Performance'
              ion_icon='analytics'
              ion_icon_color='blue'
            />
            <ButtonWithImage
              routerLink='/student/messages'
              text='Messages'
              ion_icon='mail'
              ion_icon_color='green'
              embed={() => math && <UnreadIndicator student={user._id} subject={math._id}/>}
            />
            <ButtonWithImage
              text='Audio/Video Check'
              routerLink='/student/diagnostics'
              ion_icon='cameraOutline'
              ion_icon_color='green'
            />
            {
              student_tokens.isLoading ?
                <IonLoading
                  isOpen
                  message={'Please wait...'}
                /> :

                student_tokens.total_token_count > 0 ?
                  <ButtonWithImage
                    text={`Tokens: ${student_tokens.total_token_count}`}
                    routerLink='/student/tokens'
                    ion_icon='cash'
                    ion_icon_color='green'
                  /> :
                  null
            }
          </Container>
      }
    </PlytimePageStudentBackground>
  )
})
