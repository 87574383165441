import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { useState, useEffect } from 'react'
import { listSubscriptions } from 'util/api/subscriptions'
import { CurrentStudentPerformance } from 'components/stats/CurrentStudentPerformance'
import { StudentTrendPerformance } from 'components/stats/StudentTrendPerformance'
import { QuestionReview, StudentDetailView } from 'components/question_review'
import { IonSegment, IonSegmentButton, IonLabel, IonIcon } from '@ionic/react'
import { trendingUpOutline, optionsOutline, bookOutline, accessibilityOutline } from 'ionicons/icons'
import styled from 'styled-components'

const StudentButtonsContainer = styled.div`
  padding-bottom: 2rem;
`

interface Student {
  _id: string
  name: string
}

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  const [students, setStudents] = useState<Student[]>()
  const [selectedStudent, setSelectedStudent] = useState<string>()
  const [tab, set_tab] = useState<'trend' | 'focus' | 'answers'>('trend')
  const [initialAnswerFilters, setInitialAnswerFilters] = useState([])

  useEffect(() => {
    (async () => {
      const subs = await listSubscriptions()
      const unique_students: Student[] = []
      subs.forEach(sub => {
        const student = sub.student
        if (!unique_students.find(s => s._id === student._id)) {
          unique_students.push(student)
        }
      })
      if (unique_students.length > 0) {
        setStudents(unique_students)
        setSelectedStudent(unique_students[0]._id)
      }
    })()
  }, [])

  return (
    <PlytimePage roles={roles}>
      <StudentButtonsContainer>
        <IonSegment value={selectedStudent} onIonChange={(e) => setSelectedStudent(e.detail.value as any)}>
          {students && students.map(s => (
            <IonSegmentButton key={s._id} value={s._id}>
              <IonIcon icon={accessibilityOutline} />
              <IonLabel>{s.name}</IonLabel>
            </IonSegmentButton>
          ))}
        </IonSegment>
      </StudentButtonsContainer>
      <IonSegment
        onIonChange={e => set_tab(e.detail.value as any)}
        value={tab}
      >
        <IonSegmentButton value={'trend'} style={{ minWidth: 0 }}>
          <IonIcon icon={trendingUpOutline} />
          <IonLabel>Trend</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={'focus'} style={{ minWidth: 0 }}>
          <IonIcon icon={optionsOutline} />
          <IonLabel>Focus</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value={'answers'} style={{ minWidth: 0 }}>
          <IonIcon icon={bookOutline} />
          <IonLabel>Answers</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {tab === 'trend' &&
        selectedStudent && <StudentTrendPerformance student_id={selectedStudent} changeTab={set_tab} setInitialAnswerFilters={setInitialAnswerFilters}/>
      }
      {tab === 'focus' &&
        selectedStudent && <CurrentStudentPerformance student_id={selectedStudent} />
      }
      {tab === 'answers' &&
        selectedStudent && <QuestionReview student_id={selectedStudent} AnswerDetailComponent={StudentDetailView} initialFilters={initialAnswerFilters}/>
      }
    </PlytimePage>
  )
}
