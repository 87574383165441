import { IonCard, IonCardHeader, IonCardContent, IonCardTitle } from '@ionic/react'
import Video from 'twilio-video'
import { useEffect, useRef, useState } from 'react'

import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { PermissionsDiag } from 'components/diagnostics/PermissionsDiag'
import { CameraDiag } from 'components/diagnostics/CameraDiag'
import { AudioDiag } from 'components/diagnostics/AudioDiag'
import { ScreenshareDiag } from 'components/diagnostics/ScreenshareDiag'

interface IProps {
  roles: Roles[]
}

export default  ({ roles }: IProps) => {
  const [stage,setStage] = useState(Video.isSupported ? 1 : 0)
  const cameraRef = useRef(null)
  const audioRef = useRef(null)
  const screenRef = useRef(null)
  const resultRef = useRef(null)
  const permissionsOk = () => {
    setStage(2)
  }

  const cameraOk = () =>{
    setStage(3)
  }
  const audioOk = () =>{
    setStage(4)
  }
  const screenOk = () =>{
    setStage(5)
  }
  useEffect(()=>{
    switch(stage){
      case 2:
        cameraRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      case 3:
        audioRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      case 4:
        screenRef.current.scrollIntoView({ behavior: 'smooth' })
        break
      case 5:
        resultRef.current.scrollIntoView({ behavior: 'smooth' })
        break
    }
  }, [stage])

  return (
    <PlytimePage roles={roles}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Video diagnostics
          </IonCardTitle>

        </IonCardHeader>
        <IonCardContent>
          {Video.isSupported ? 'Good news, your browser is compatible with Plytime video capability. Continue with diagnostics below.' : 'Oh no, you cannot use this browser with Plytime video capability, please open the platform in Chrome, Firefox or Safari'}
        </IonCardContent>

      </IonCard>

      {stage>=1 && <PermissionsDiag key='permissionDiag' onNext={permissionsOk}/>}
      {stage>=2 && <CameraDiag key='cameraDiag' onNext={cameraOk}/>}
      <div ref={cameraRef}></div>
      {stage>=3 && <AudioDiag key='audioDiag' onNext={audioOk}/>}
      <div ref={audioRef}></div>
      {stage>=4 && <ScreenshareDiag key='screenDiag' onNext={screenOk}/>}
      <div ref={screenRef}></div>
      {stage>=5 && <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Diagnostics Result
          </IonCardTitle>

        </IonCardHeader>
        <IonCardContent>
          Check completed - if all steps above are successful, you are ready to join Plytime session.
        </IonCardContent>

      </IonCard>}
      <div ref={resultRef}></div>
    </PlytimePage>
  )
}
