import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonIcon,
  IonImg,
  IonLabel,
  useIonToast,
} from '@ionic/react'
import styled from 'styled-components'
import React, { FC, useCallback, useEffect } from 'react'
import { cash } from 'ionicons/icons'
import { observer } from 'mobx-react-lite'

import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { useStore } from 'types/store'
import { DisplayPrize } from 'common/types/student/tokens'
import { QuestionTableHeader } from 'components/question_review'
import { useUser } from 'context/userContext'
import { DisplayToken } from 'common/types/student/tokens'

const permittedRoles = {
  [Roles.TEST]: false,
  [Roles.ADMIN]: false,
  [Roles.TUTOR]: false,
  [Roles.PARENT]: false,
  [Roles.STUDENT]: true,
}

const TableHeading = styled.th`
  padding: 1rem;
  text-align: left;
`

const TableData = styled.td`
  padding: 1rem 3rem 1rem 1rem;
  height: 3rem;
`

const TableRowContent = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`

interface IProps {
  roles: Roles[]
}

export default observer(function ({ roles }: IProps) {
  const { user } = useUser()

  const {
    student_tokens: {
      tokens,
      fetch_tokens,
      fetch_prizes,
      total_token_count,
      total_pending_token_count
    }
  } = useStore()

  useEffect(() => {
    fetch_prizes()
    fetch_tokens(user._id)
  }, [])

  return (
    <PlytimePage roles={roles}>
      <h1>
        Your Tokens:
        <IonChip color="success">
          <IonIcon icon={cash} color="success"></IonIcon>
          <IonLabel>{total_token_count}</IonLabel>
        </IonChip>
        {
          total_pending_token_count ? (
            <IonButton
              routerDirection="forward"
              routerLink={`/student/performance/answers?${QuestionTableHeader.Tokens}=pending`}
              color="tertiary"
              style={{ float: 'right' }}
              size="small"
            >
              Review your answers for {total_pending_token_count} tokens
            </IonButton>
          ) : null
        }
      </h1>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Your Rewards
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent
          style={{display: 'flex'}}
        >
          <RewardCards />
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Your History
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <table style={{width: '100%'}}>
            <thead>
              <tr>
                <TableHeading>Date</TableHeading>
                <TableHeading>Reason</TableHeading>
                <TableHeading>Amount</TableHeading>
              </tr>
            </thead>
            <tbody>
              {
                tokens
                  .map(({
                    _id,
                    game_summary_id,
                    created_at,
                    game,
                    prize,
                    token_count,
                    pending_token_count,
                  }: DisplayToken) => (
                    <TableRowContent key={`${game ? `${created_at}-${game_summary_id}` : _id}`}>
                      <TableData>{created_at}</TableData>
                      <TableData>{game || prize}</TableData>
                      <TableData>
                        {token_count}
                        {
                          pending_token_count === 0 ?
                            '' :
                            <IonButton
                              size="small"
                              routerLink={`/student/performance/answers?${QuestionTableHeader.Tokens}=pending`}
                              routerDirection="forward"
                              style={{ float: 'right', margin: 0 }}
                            >
                              {pending_token_count} pending tokens
                            </IonButton>
                        }
                      </TableData>
                    </TableRowContent>
                  ))
              }
            </tbody>
          </table>
        </IonCardContent>
      </IonCard>
    </PlytimePage>
  )
})

const RewardCards: FC = observer(() => {
  const {
    student_tokens: {
      fetch_tokens,
      prizes,
      redeem_prize,
      total_token_count
    }
  } = useStore()

  const [present] = useIonToast()

  return <>
    {
      prizes.map(({image, name, amount, _id, description}: DisplayPrize) => (
        <RewardCard
          key={_id}
          image={image}
          name={name}
          amount={amount}
          _id={_id}
          description={description}
          redeem_prize={redeem_prize}
          present={present}
          fetch_tokens={fetch_tokens}
          total_token_count={total_token_count}
        />
      ))
    }
  </>
})

const RewardCard = ({ image, name, amount, _id, description, redeem_prize, present, fetch_tokens, total_token_count }) => {
  const { user } = useUser()

  const onClickRedeem = useCallback(() => {
    redeem_prize(_id).then(() => {
      present({
        message: 'Woo-hoo your reward is on it\'s way!',
        duration: 15000,
        position: 'middle',
      })
      fetch_tokens(user._id)
    })
  }, [])

  return (
    <IonCard
      style={{
        width: '33%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <IonImg alt={`Image for ${name}`} src={image} style={{height: '154px'}}/>
      <IonCardHeader>
        <IonCardTitle>{name}</IonCardTitle>
        <IonCardSubtitle>{amount} tokens</IonCardSubtitle>
        <IonCardContent>{description}</IonCardContent>
      </IonCardHeader>
      <IonButton
        disabled={total_token_count < amount}
        color="success"
        expand="full"
        onClick={onClickRedeem}
        style={{
          marginTop: 'auto',
        }}
      >
          Redeem
      </IonButton>
    </IonCard>
  )
}

