import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'types/store'
import { IonButton } from '@ionic/react'
import disableBrowserBackButton from 'disable-browser-back-navigation'
import LeaderBoard from '../leaderboard'
import ScoreBoard from '../scoreboard'
import { Question } from 'components/question'
import { DifficultySelector } from 'components/difficulty'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const Content = styled.div`
  background-color: #000033;
  background-image: url("../images/stars.png");
  background-repeat: no-repeat;
  background-size: cover;
`

const PauseOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 2;
  cursor: pointer;
`

const PauseOverlayText = styled.p`
  color:white;
  font-size: 4rem;
  padding-top: 3.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
`

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;
  width: 100%;
`

const LeftContainer = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  margin-right: 20px;
  width: 57%;
  background: #FFFFFF;
  border-radius: 15px;
`

const RightContainer = styled.div`
  float: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  width: 40%;
  background: #FFFFFF;
  border-radius: 15px;
`

const GameOverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`

const GameButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 0.5rem;
`

const QuitAndPauseButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0.5rem;
`

interface Props {
  game_id: string,
  duration: number,
  game_mode: 'multiplayer' | 'singleplayer'
}

export const Game: FC<Props> = observer(({ duration, game_id, game_mode }) => {
  const { game, auth: { user } } = useStore()

  const history = useHistory()

  useEffect(() => {
    game.start_game(game_id, duration)
    game.ask_question(user._id)
    game.update_game_mode(game_mode)
    disableBrowserBackButton()
  },[])

  const { error, score,
    question_asked, total_questions, streakPoints,
    highestStreak, consecutiveCorrectAnswers, bonusPoints,
    opponents, loading } = game

  const gameDuration = duration

  const getLeaderBoardData = () => {
    const scores = []
    scores.push({
      id: '0',
      name: 'You',
      value: game.totalPoints(),
      colour: 'green'
    })
    opponents.forEach(o => {
      const opScore = {
        id: o.id,
        name: o.name,
        value: o.score + o.difficultyBonus * 5 + o.streakBonus * 20,
        colour: o.colour
      }
      scores.push(opScore)
    })

    return scores.sort((a, b) => a.value <= b.value ? 1 : -1)
  }

  useEffect(() => {
    if (game.over){
      game.stop_heartbeat()
      const redirectTimeout = setTimeout(() => {
        history.push('/game/step3_summary')
      }, 3000)
      return () => clearTimeout(redirectTimeout)
    }
  }, [game.over])

  if (user === null) {
    return <div>No user</div>
  }

  return (
    <Content>
      <PauseOverlay style={game.paused ? pauseOverlayOn : pauseOverlayOff} onClick={() => game.unpause_game()}>
        <PauseOverlayText>Game is paused</PauseOverlayText>
        <PauseOverlayText>Click anywhere to continue</PauseOverlayText>
      </PauseOverlay>
      <>
        <LeftContainer>
          {game.question
            ? <Question
              question={game.question}
              student_answer={game.student_answer}
              correct_answers={game.correct_answers}
              answer_decision={game.answer_decision}
              update_answer={game.update_answer}
            />
            : error
              ? <>
                <div id='error'>{error}</div>
                <div>Try again</div>
                <div>Try again with different difficulty</div>
                <div>Quit the game and try again with a different subject mix</div>
              </>
              : <div>Generating a question, please wait a moment</div>
          }
          <GameButtonsContainer>
            {question_asked && !game.over &&
              <>
                <IonButton
                  disabled={game.student_answer.size === 0 || !game.question_asked || game.loading}
                  onClick={(e) => {
                    e.preventDefault()
                    game.submit_answer(user._id)
                  }}
                >
                  Submit
                </IonButton>
                <IonButton
                  id="pass"
                  color='tertiary'
                  onClick={(e) => {
                    e.preventDefault()
                    game.student_answer.clear()
                    game.submit_answer(user._id)
                  }}
                  disabled={(Date.now() - game.lastQuestionStartTime) < (game.question.standard_time * 1000)}
                >
                  Pass
                </IonButton>
              </>
            }
            {(!question_asked || error) && !game.over && !loading &&
              <IonButton
                onClick={() => game.ask_question(user._id)}
                id={'Ask'}
                color='success'
              >
                Next question
              </IonButton>
            }
          </GameButtonsContainer>

          {!game.over &&
            <DifficultySelector
              disabled={question_asked}
              level={game.difficulty}
              select={game.set_difficulty}
              game_in_play={game.gameInPlay}
            />
          }

        </LeftContainer>
        <RightContainer>
          <ProgressContainer>
            <p style={progressElements}>
              Time remaining: <progress value={game.game_time_seconds} max={gameDuration}>{`${game.game_time_seconds / gameDuration * 100}%`}</progress>
            </p>
            <QuitAndPauseButtonsContainer>
              {!game.over && (
                <>
                  <IonButton disabled={question_asked} onClick={() => game.pause_game()}>Pause Game</IonButton>
                  {/* <IonButton color='danger' onClick={quitGameEarly}>Quit game</IonButton> */}
                </>
              )}
            </QuitAndPauseButtonsContainer>

            {game.over &&
              <GameOverContainer>
                <strong>Game finished!</strong>
                <IonButton routerLink={'/game/step3_summary'} color='success' id='display_game_stats'>See game stats</IonButton>
              </GameOverContainer>
            }

            {game_mode === 'multiplayer' && (<LeaderBoard data={getLeaderBoardData()} />)}
          </ProgressContainer>
          <ScoreBoard
            totalPoints={game.totalPoints()}
            score={score}
            bonusPoints={bonusPoints}
            highestStreak={highestStreak}
            streakPoints={streakPoints}
            consecutiveCorrectAnswers={consecutiveCorrectAnswers}
            totalQuestions={total_questions}
            rubyCount={game.rubyCount()}
          />
        </RightContainer>
      </>
    </Content>
  )
})

const pauseOverlayOn = {
  display: 'block'
}

const pauseOverlayOff = {
  display: 'none'
}

const progressElements = {
  marginBottom: '2px',
  marginTop: '2px',
}
