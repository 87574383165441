import { FC } from 'react'
import {
  IonButton,
  IonIcon,
  useIonActionSheet,
} from '@ionic/react'
import { ellipsisHorizontalCircleOutline } from 'ionicons/icons'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { Session } from 'components/sessions/useUpcomingSessions'
import { CancelSession } from 'components/tutor/session/cancel'
import { RescheduleSession } from 'components/tutor/session/reschedule'
import { SubstituteSession } from 'components/tutor/session/substitute'
import { RescheduleSubscription } from 'components/tutor/session/reschedule_subscription'
import { SubstituteSubscription } from 'components/tutor/session/substitute_subscription'
import { Messages } from 'components/tutor/session/messages'
import { useSessionActionModal } from 'components/tutor/session/useActionModal'

interface Props {
  session: Session
  refresh: () => Promise<void>
}

export const SessionActions: FC<Props> = ({ session, refresh }) => {

  const session_is_active = session._id &&
    !session.cancelled &&
    DateTime.fromISO(session.session_date_time).minus({ minutes: 2 }) < DateTime.now() &&
    DateTime.fromISO(session.session_date_time).plus({ minutes: session.session_length }) > DateTime.now()

  const is_past_session = session.session_date_time_end && DateTime.fromISO(session.session_date_time_end) < DateTime.now()

  const [open_actions] = useIonActionSheet()

  const router = useHistory()
  const open_session = () => {
    router.push(`tutor/session/${session._id}`)
  }

  const cancel_modal = useSessionActionModal(CancelSession, session, refresh)
  const reschedule_modal = useSessionActionModal(RescheduleSession, session, refresh)
  const substitute_modal = useSessionActionModal(SubstituteSession, session, refresh)
  const reschedule_subscription_modal = useSessionActionModal(RescheduleSubscription, session, refresh)
  const substitute_subscription_modal = useSessionActionModal(SubstituteSubscription, session, refresh)
  const messages_modal = useSessionActionModal(Messages, session, refresh)

  return session.cancelled
    ? null
    :session_is_active
      ? <IonButton onClick={open_session}>Open</IonButton>
      : <IonIcon
        icon={ellipsisHorizontalCircleOutline}
        onClick={() => open_actions({
          buttons: [
            ...session._id && !is_past_session ? [{
              text: 'Open',
              handler: open_session,
            }] : [],
            ...!is_past_session
              ? [
                {
                  text: 'Reschedule Single Session',
                  handler: reschedule_modal,
                },
                // ...DateTime.fromISO(session.session_date_time).plus({ hours: 48 }) > DateTime.now()
                //   ?[{
                //     text: 'Substitute Single Session',
                //     handler: substitute_modal,
                //   }]
                //   :[],
                {
                  text: 'Cancel Single Session',
                  handler: cancel_modal
                },
                {
                  text: 'Reschedule Future Sessions',
                  handler: reschedule_subscription_modal,
                },
                {
                  text: 'Substitute Subscription Tutor',
                  handler: substitute_subscription_modal,
                },
                {
                  text: 'Messages',
                  handler: messages_modal
                },
              ] : []
          ],
          header: 'Session actions',
          cssClass: 'session-actions',
        })}
      />
}
