import { useState, useEffect, FC } from 'react'
import { useUser } from 'context/userContext'
import { useRef } from 'react'
import { Tutor } from 'types/admin/tutor'
import { IonButton, IonImg, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react'
import { updateProfilePicture } from '../../../util/api/profile/admin'
import { updateMyProfilePicture } from '../../../util/api/profile/me'
import styled from 'styled-components'
import { useStore } from 'types/store'
import { AmplifyS3Image } from '@aws-amplify/ui-react'
import { profile_image_path, resize_image, defaultProfileImage } from 'util/image'

interface Props {
  tutor: Tutor
}

export const TutorProfilePictureUpload: FC<Props> = ({ tutor }) => {
  const { user } = useUser()
  const { auth } = useStore()
  const [profileImage, setProfileImage] = useState(undefined)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (tutor?._id === user._id) {
      setProfileImage(user.profile_picture)
      setLoading(false)

    } else {
      profile_image_path(tutor?._id).then(imagePath => {
        setProfileImage(imagePath)
        setLoading(false)
      })
    }

  }, [])
  const imageFileInput = useRef(null)

  const convertBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const apiCall = async (content_type, content_base64) => {
    if (tutor?._id === user._id) {
      return await updateMyProfilePicture({
        body: {
          content_type,
          content_base64
        }
      })
    } else {
      return await updateProfilePicture({
        params: {
          user_id: tutor._id,
        },
        body: {
          user_role: 'TUTOR',
          content_type,
          content_base64
        }
      })
    }
  }

  const onSelectFile = async (event) => {
    const file = event.target.files[0]

    const content_type: string = file.type
    const resized = await resize_image(file)
    const dataUrl: string = await convertBase64(resized) as string
    const content_base64 = dataUrl.replace('data:', '').replace(/^.+,/, '')
    // console.log(`Uploading file ${content_type} ${content_base64}`)

    const uploadResult = await apiCall(content_type, content_base64)
    console.log('profile picture upload result:' + JSON.stringify(uploadResult))
    setProfileImage(uploadResult.path)
    if (user?._id === tutor._id) {
      auth.set_profile_picture(uploadResult.path)
    }
  }

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Profile Picture</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {!loading && profileImage ?
          // <IonThumbnail slot="start">
          <AmplifyS3ImageProfilePicture imgKey={profileImage} key={profileImage} /> :
          <DefaultProfilePicture src={defaultProfileImage} />

          // </IonThumbnail>
        }
        {loading && 'Loading...'}


        <input
          ref={imageFileInput}
          hidden
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          onClick={() => {
            console.log('Trigger upload')
          }}
        />
        {user.role === 'ADMIN' ? <IonButton
          color="primary"
          onClick={() => {
            imageFileInput?.current?.click()
          }}>
          Change Picture
        </IonButton>
          : <p>Contact PLYTIME admin to change.</p>}
      </IonCardContent>
    </IonCard >
  )
}


const DefaultProfilePicture = styled(IonImg)`
   width: 15em;
   height: 15em;
`
const AmplifyS3ImageProfilePicture = styled(AmplifyS3Image)`
   --width:15em;
   --height: 15em;
`
