import { FC, useEffect, useState } from 'react'
import { getStudentCurrentStats } from 'util/api/student/current_stats'
import { EndcodeStats, TopicStats, ThemeStats, SubjectStats } from 'common/types/student/current_stats'
import { SnapshotOut } from 'mobx-state-tree'
import { Mix_Extended } from 'types/student/mixes'
import { Instance, getParent } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import { IonButton, IonCheckbox, IonInput, IonIcon, IonText } from '@ionic/react'
import { closeCircle, addCircleOutline, removeCircleOutline, reorderFour, reorderThree, reorderTwo } from 'ionicons/icons'
import { useStore } from 'types/store'
import styled from 'styled-components'

interface Props {
  student_id: string
}

const view_window = 6

export const CurrentStudentPerformance: FC<Props> = observer(({ student_id }) => {
  const { student_mixes_list: { refresh, hierarchy, set_hierarchy }, auth: { user } } = useStore()
  const [loading, set_loading] = useState(true)

  const load_stats = async () => {
    try {
      set_loading(true)
      const perf = await getStudentCurrentStats(student_id)
      if (user.role === 'TUTOR' || user.role === 'TEST') {
        await refresh(student_id)
      }
      set_hierarchy(perf)
    }
    finally {
      set_loading(false)
    }
  }

  useEffect(() => { load_stats() }, [student_id])

  return (
    <Container>
      {loading
        ? <p>Loading...</p>
        : hierarchy.length === 0
          ? <p>No performance data available</p>
          : hierarchy.map(subject => (
            <SubjectPerformance
              key={subject._id}
              subject={subject}
              min={subject.current_score - view_window}
              max={subject.current_score + view_window}
              student_id={student_id}
            />
          ))
      }
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding: 2em;
`

const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 0 0;
  white-space: nowrap;
  height: 50em;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 0 0;
`
const SubjectAndBars = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1 0 0;
`
const SubjectLabel = styled.div`
  order: 1;
  font-size: 1rem;
  flex: 0 0 35%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ThemeLabel = styled.div`
  order: 1;
  font-size: 1rem;
  flex: 0 0 35%;
  padding-left: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const TopicLabel = styled.div`
  order: 1;
  font-size: 1rem;
  flex: 0 0 35%;
  padding-left: 1.5em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const EndcodeLabel = styled.div`
  order: 1;
  font-size: 1rem;
  flex: 0 0 35%;
  padding-left: 3.25em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const AnswerCount = styled.div`
  order: 2;
  font-size: 1rem;
  flex: 0 0 10%;
  text-align: center;
`
const AnswerCountHeading = styled.div`
  order: 2;
  font-size: 0.8rem;
  flex: 0 0 10%;
  text-align: center;
  margin-bottom: 0.4em;
  padding-bottom: 0.25em;
  margin-top: auto;
  margin-bottom: auto;
`
const BarContainer = styled.div`
  order: 2;
  flex: 0 0 55%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding-left: 2em;
`
const KeyContainer = styled.div`
  order: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 55%;
  justify-content: center;
  margin-bottom: 0.3rem;
`
const KeyBar = styled.div`
  border-radius: 0.625rem;
  width: 28%;
  color: var(--ion-color-tertiary);
  text-align: center;
  padding: 4px 0;
`
const MixHeading = styled.div`
  order: 2;
  font-size: 0.8rem;
  text-align: center;
  padding-bottom: 5.75em;
  transform: rotate(-90deg);
  width: 8em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const MixCheckbox = styled.div`
  width: 2em;
  padding-left: 0.35em;
`
const CurserPointer = styled.div`
  cursor: pointer;
`
const CreateMix = styled.div`
  width: 8rem;
  padding-left: 0.35em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
`

interface BarsProps {
  min: number
  max: number
  start: number
  end: number
  point: number | undefined
  previous: number | undefined | null
}
const Bars: FC<BarsProps> = (props) => {
  const { min, max } = props
  const limit = (value: number | undefined) => {
    if (value == undefined) return undefined
    return Math.max(min, Math.min(max, value))
  }
  const start = limit(props.start)
  const end = limit(props.end)
  const point = limit(props.point)
  const previous = limit(props.previous)

  const first = Math.min(start, ...point ? [point] : [], ...previous ? [previous] : [])
  const last = Math.max(end, ...point ? [point] : [], ...previous ? [previous] : [])

  const before = first - min
  const achieved = point !== undefined
    ? Math.min(point, ...previous ? [previous] : []) - Math.max(min, start)
    : 0
  const improvement = previous !== undefined && previous !== null && previous < point
    ? point - previous
    : 0
  const regression = previous !== undefined && point < previous
    ? previous - point
    : 0
  const remaining = Math.max(
    end - Math.max(start, ...point !== undefined ? [point] : [], ...previous ? [previous] : []),
    0
  )
  const after = max - last

  return (
    <BarContainer>
      <Spacer length={before} />
      <Achieved length={achieved} />
      <Improvement length={improvement} />
      <Regression length={regression} />
      <Remaining length={remaining}/>
      <Spacer length={after} />
    </BarContainer>
  )
}

const Bar = styled.div`
  flex: ${props => Math.round(props.length * 10)} 0 0;
  margin-top: 1px;
  margin-bottom: 1px;
  border-radius: 0.625rem;
`
const Spacer = styled(Bar)`
  background-color: none;
`
const Achieved = styled(Bar)`
  background-color: var(--ion-color-primary);
`
const Improvement = styled(Bar)`
  background-color: var(--ion-color-success);
`
const Regression = styled(Bar)`
  background-color: var(--ion-color-danger);
`
const Remaining = styled(Bar)`
  background-color: grey;
`

type AllStats = SnapshotOut<typeof SubjectStats> | SnapshotOut<typeof ThemeStats> | SnapshotOut<typeof TopicStats> | SnapshotOut<typeof EndcodeStats>

const get_children = (subject: AllStats, level = 0) => {
  const key = Object.keys(subject).find(k => ['topics', 'endcodes'].includes(k))
  if (key === undefined) { // this is an endcode
    return level === 0 ? [] : [subject._id]
  }
  const children = subject[key].flatMap(sub => get_children(sub, level + 1))
  return level === 0 ? children : [subject._id, ...children]
}

interface MixCheckboxesProps {
  subject: AllStats
  display?: boolean
}
const MixCheckboxes: FC<MixCheckboxesProps> = observer(({ subject, display = true }) => {
  const { student_mixes_list: { mixes } } = useStore()
  const handle_change = (checked: boolean, mix: Instance<typeof Mix_Extended>) => {
    try {
      const children = get_children(subject)
      const parent: Instance<typeof SubjectStats> | Instance<typeof ThemeStats> | Instance<typeof TopicStats> = getParent(subject, 2)
      const grand_parent: Instance<typeof SubjectStats> | Instance<typeof ThemeStats> | Instance<typeof TopicStats> = getParent(parent, 2)
      if (checked) {
        mix.remove_subjects([grand_parent._id, parent._id, ...children])
        mix.add_subject(subject._id)
      } else {
        mix.remove_subjects([subject._id])
      }
      mix.save()
    } catch (err) {
      console.error('Unable to save mix', err)
    }
  }
  return (
    <>
      {mixes.map((mix, idx) => (
        <MixCheckbox key={`${mix._id}_${subject._id}_${idx}`}>
          {display && (
            <IonCheckbox
              id={subject._id}
              checked={mix.subjects.includes(subject._id)}
              onIonChange={(e) => handle_change(e.detail.checked, mix)}
            />
          )}
        </MixCheckbox>
      ))}
    </>
  )
})
const MixCheckboxSpacer: FC<MixCheckboxesProps> = observer(({ subject }) => {
  const { student_mixes_list: { mixes } } = useStore()
  return (
    <>
      {mixes.map((mix, idx) => (
        <MixCheckbox key={`${mix._id}_${subject._id}_${idx}_space`} />
      ))}
    </>
  )
})

interface SubjectProps {
  subject: SnapshotOut<typeof SubjectStats>
  min: number
  max: number
  student_id: string
}
const SubjectPerformance: FC<SubjectProps> = observer(({ subject, min, max, student_id }) => {
  const { student_mixes_list, student_mixes_list: { mixes, add_mix }, auth: { user } } = useStore()
  return (
    <>
      <HeadingRow>
        <SubjectAndBars>
          <SubjectLabel style={{ fontSize: '0.8rem', marginTop: 'auto', marginBottom: 'auto' }}>Performance over 4 weeks</SubjectLabel>
          <AnswerCountHeading>
            No of answers
          </AnswerCountHeading>
          <PerformanceKey />
        </SubjectAndBars>
        {(user.role === 'TUTOR' || user.role === 'TEST') && (
          <>
            {mixes.map(mix =>
              <div key={mix._id} style={{ width: '2em' }}>
                <MixHeading>
                  <IonInput type='text' value={mix.name} onIonChange={e => { mix.update_name(e.detail.value) }} onIonBlur={mix.save} />
                  <CurserPointer onClick={() => student_mixes_list.delete_mix(mix)}>
                    <IonIcon icon={closeCircle} style={{
                      fontSize: '1.5em',
                      color: 'var(--ion-color-danger)',
                      pointerEvents: 'none',
                      paddingTop: '0.25rem'
                    }} />
                  </CurserPointer>
                </MixHeading>
              </div>
            )}

            <CreateMix>
              {subject && <IonButton onClick={() => add_mix(student_id, subject._id)}>Create mix</IonButton>}
            </CreateMix>
          </>
        )}
      </HeadingRow>


      <Row>
        <SubjectAndBars>
          <SubjectLabel />
          <AnswerCount />
          <BarContainer>
            <p style={{ flex: 1, fontSize: '10px', textAlign: 'left' }}>{min.toFixed(1)}</p>
            <p style={{ flex: 1, fontSize: '10px', textAlign: 'center' }}>{subject.current_score.toFixed(1)}</p>
            <p style={{ flex: 1, fontSize: '10px', textAlign: 'right' }}>{max.toFixed(1)}</p>
          </BarContainer>
        </SubjectAndBars>
        {(user.role === 'TUTOR' || user.role === 'TEST') && (
          <>
            <MixCheckboxSpacer subject={subject} display={false} />
            <div style={{ width: '8rem', paddingLeft: '0.35em' }} />
          </>
        )}
      </Row>
      <Row>
        <SubjectAndBars>
          <SubjectLabel>{subject.name}</SubjectLabel>
          <AnswerCount>{subject.recent_answer_count}</AnswerCount>
          <Bars min={min} max={max} start={subject.min} end={subject.max} point={subject.current_score} previous={subject.previous_score} /*include_number={true}*/ />
        </SubjectAndBars>
        {(user.role === 'TUTOR' || user.role === 'TEST') && (
          <>
            <MixCheckboxes subject={subject} display={false} />
            <CreateMix />
          </>
        )}
      </Row>
      {subject.themes.map(theme => <ThemePerformance key={theme._id} theme={theme} min={min} max={max} />)}
    </>
  )
})

interface ThemeProps {
  theme: SnapshotOut<typeof ThemeStats>
  min: number
  max: number
}
const ThemePerformance: FC<ThemeProps> = observer(({ theme, min, max }) => {
  const { auth: { user } } = useStore()
  return (
    <>
      <Row>
        <SubjectAndBars>
          <ThemeLabel>{theme.name}</ThemeLabel>
          <AnswerCount>{theme.recent_answer_count}</AnswerCount>
          <Bars min={min} max={max} start={theme.min} end={theme.max} point={theme.current_score} previous={theme.previous_score} />
        </SubjectAndBars>
        {(user.role === 'TUTOR' || user.role === 'TEST') && (
          <>
            <MixCheckboxes subject={theme} />
            <CreateMix />
          </>
        )}
      </Row>
      {theme.topics.map(topic => <TopicPerformance key={topic._id} topic={topic} min={min} max={max} />)}
    </>
  )
})

interface TopicProps {
  topic: SnapshotOut<typeof TopicStats>
  min: number
  max: number
}
const TopicPerformance: FC<TopicProps> = observer(({ topic, min, max }) => {
  const { auth: { user } } = useStore()
  const [display_endcodes, set_display_endcodes] = useState(false)
  return (
    <>
      <Row>
        <SubjectAndBars>
          <TopicLabel>
            <>
              <IonIcon icon={display_endcodes ? removeCircleOutline : addCircleOutline} onClick={() => set_display_endcodes(curr_val => !curr_val)} />
              {topic.name}
            </>
          </TopicLabel>
          <AnswerCount>{topic.recent_answer_count}</AnswerCount>
          <Bars min={min} max={max} start={topic.min} end={topic.max} point={topic.current_score} previous={topic.previous_score} />
        </SubjectAndBars>
        {(user.role === 'TUTOR' || user.role === 'TEST') && (
          <>
            <MixCheckboxes subject={topic} />
            <CreateMix />
          </>
        )}
      </Row>
      {topic.endcodes.map(endcode => (
        <div key={endcode._id} style={{ display: !display_endcodes && 'none' }}>
          <EndcodePerformance endcode={endcode} min={min} max={max} />
        </div>
      ))}
    </>
  )
})
interface EndcodeProps {
  endcode: SnapshotOut<typeof EndcodeStats>
  min: number
  max: number
}
const EndcodePerformance: FC<EndcodeProps> = observer(({ endcode, min, max }) => {
  const { auth: { user } } = useStore()
  return (
    <Row>
      <SubjectAndBars>
        <EndcodeLabel title={endcode.description}>
          <IonIcon
            icon={endcode.priority === '1' ? reorderFour : endcode.priority === '2' ? reorderThree : reorderTwo}
            color={endcode.priority === '1' ? 'danger' : endcode.priority === '2' ? 'warning' : 'success'}
          />
          {endcode.description}
        </EndcodeLabel>
        <AnswerCount>{endcode.recent_answer_count}</AnswerCount>
        <Bars min={min} max={max} start={endcode.min} end={endcode.max} point={endcode.current_score} previous={endcode.previous_score} />
      </SubjectAndBars>
      {(user.role === 'TUTOR' || user.role === 'TEST') && (
        <>
          <MixCheckboxes subject={endcode} />
          <CreateMix />
        </>
      )}
    </Row>
  )
})

const PerformanceKey: FC = () => {
  return (
    <KeyContainer>
      <KeyBar style={{ backgroundColor: 'var(--ion-color-primary)' }}>Achieved</KeyBar>
      <KeyBar style={{ background: 'linear-gradient(90deg, var(--ion-color-danger), var(--ion-color-success))' }}>- Change +</KeyBar>
      <KeyBar style={{ backgroundColor: 'grey' }}>Remaining</KeyBar>
    </KeyContainer>
  )
}
