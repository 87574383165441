import styled from 'styled-components'
import { IonCard, IonCardContent } from '@ionic/react'
import { imagesBaseUrl, defaultProfileImage } from 'util/image'

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

export const Main = styled.div`
  flex: 4 0 0;
  display: block;
  overflow: auto;
  video {
    width: 100%;
    object-fit: contain;
  }
`

export const Sidebar = styled.div`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`

export const Time = styled.div`
  flex: 2 0 0;
  display: block;
  overflow: hidden;
  ion-card {
    height: calc(100% - 20px);
    ion-card-content {
      height: 100%;
    }
  }
`

export const Join = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Local = styled.div`
  flex: 3 0 0;
  display: block;
  overflow: hidden;
`

export const Screen = styled.div`
  flex: 3 0 0;
  display: block;
  overflow: hidden;
`

export const Remote = styled.div`
  flex: 3 0 0;
  display: block;
  overflow: hidden;
`

export const Messages = styled(IonCard)`
  flex: 5 0 0;
  display: block;
  ion-card-content {
    height: 100%;
  }
`

export const Card = styled(IonCard)`
  height: calc(100% - 20px);
  width: calc(100% - 20px);
`

export const Content = styled(IonCardContent)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: stretch;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  object-fit: cover;
`
export const ProfilePicture = () => <Image src={imagesBaseUrl + defaultProfileImage} />

export const Controls = styled.div`
  flex: 1 0 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
`

export const SideVideo = styled.div`
  flex: 1 0 auto;
  max-width: 75%;
  height: 100%;
`
