import { types, Instance, flow, getSnapshot, applySnapshot, getParent, SnapshotIn } from 'mobx-state-tree'
import { Endcode } from '../endcode/endcode'
import { getTopic, postThemeTopic } from 'util/api/subject/theme/topic'
import { getTopicEndcodes } from 'util/api/subject/theme/topic/endcode'
import { AdminTopic as BaseTopic } from 'common/types/subject/topic'
import { omit } from 'lodash'

export const Topic = BaseTopic.props({
  endcodes: types.array(Endcode)
})
  .actions(self => ({
    update_name (name:string) {
      self.name = name
    },
    update_min_score (score:number) {
      self.min_score = score
    },
    update_max_score (score:number) {
      self.max_score = score
    },
    load_children: flow(function*() {
      try {
        self.endcodes = yield getTopicEndcodes({ params: { topic_id: self._id } })
      } catch (error) {
        console.error('Failed to fetch topics', error)
      }
    }),
    add_endcode (endcode:Endcode) {
      self.endcodes.push(endcode)
    },
    save: flow(function*(update:SnapshotIn<BaseTopic>) {
      const old = getSnapshot(self)
      try {
        const parent = getParent<any>(self, 2) //TODO this is a risky approach there is nothing to stop someone instantiation a Question in another store
        applySnapshot(self, {
          ...update,
          endcodes: self.endcodes
        })
        const updated_self = yield postThemeTopic({
          params: {
            theme_id: parent._id 
          },
          body: omit(update, 'endcodes')
        })
        applySnapshot(self, {
          ...updated_self,
          endcodes: self.endcodes
        })
      } catch (error) {
        applySnapshot(self, old)
        throw error
      }
    }),
    refresh: flow(function*() {
      try {
        const updated_self = yield getTopic({ params: { topic_id: self._id }})
        applySnapshot(self, {
          ...updated_self,
          endcodes: self.endcodes
        })
        const theme = getParent<any>(self, 2) //TODO this is a risky approach there is nothing to stop someone instantiating a Question in another store
        yield theme.refresh()
      } catch (error) {
        console.error('Failed to refesh topic', error)
      }
    })
  }))

export type Topic = Instance<typeof Topic>
