import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonInput,
  IonItem,
  IonToolbar,
} from '@ionic/react'
import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import SubscriptionWizardState from './SubscriptionWizardState'
import { SessionOptionEnum } from 'common/types/session/options'

interface Props {
  state: Instance<typeof SubscriptionWizardState>
  close: () => void
}

export const Referral: FC<Props> = observer(({ state, close }) => {
  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Referral code</IonCardTitle>
          <IonCardSubtitle>If you have a referral code, please enter it here (e.g. PTR-Gina101). If not please click &quot;Next&quot;.</IonCardSubtitle>
          <IonCardSubtitle>Please note - discount codes are entered later at the checkout step.</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem lines='inset'>
            <IonInput
              disabled={false}
              value={state.referral_code}
              onIonChange={(value) => {
                state.update_referral_code(value.detail.value)
              }}
            />
          </IonItem>
        </IonCardContent>
      </IonCard>
      {
        state.selected_session_option !== SessionOptionEnum.PlatformOnly ? (
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>Tutor selection</IonCardTitle>
              <IonCardSubtitle>If you have a PLYTIME Tutor Referral code (PTR-) and would like your sessions to be with that tutor, please check this box.</IonCardSubtitle>
              <IonCardSubtitle>If left unchecked, you will be shown the available times of all tutors.</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem lines='inset'>
                <IonCheckbox
                  checked={state.query_with_referral_code}
                  onIonChange={e => {
                    state.update_query_with_referral_code(e.detail.checked)
                  }}
                />
              </IonItem>
            </IonCardContent>
          </IonCard>
        ) : null
      }
      <IonToolbar>
        <IonButtons slot='end'>
          <IonButton
            fill='solid'
            size='small'
            color='primary'
            className="ion-margin-top"
            onClick={state.next}
            disabled={false}
          >
            Next
          </IonButton>
        </IonButtons>
        <IonButtons slot='start'>
          <IonButton
            fill='outline'
            size='small'
            color='tertiary'
            className="ion-margin-top"
            onClick={state.back}
          >
            Back
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </>
  )
})
