import { IonSelect, IonSelectOption } from '@ionic/react'
import React, { useState, useEffect, useCallback } from 'react'
import useDevices from './useDevices'

const labels = {
  audioinput: {
    audioLevelText: 'Input level',
    headerText: 'Microphone',
  },
  audiooutput: {
    audioLevelText: 'Output level',
    headerText: 'Speaker',
  },
}

interface AudioDeviceProps {
  disabled: boolean;
  kind: 'audioinput' | 'audiooutput';
  onDeviceChange: (value: string) => void;
  setDeviceError: (value: string) => void;
  error?: string;
}

export function AudioDevice({ disabled, kind, onDeviceChange, setDeviceError, error }: AudioDeviceProps) {
  const devices = useDevices()
  const audioDevices = kind === 'audiooutput' ? devices.audioOutputDevices : devices.audioInputDevices
  const [selectedDevice, setSelectedDevice] = useState('')
  const { headerText } = labels[kind]
  const noAudioRedirect = !Audio.prototype.setSinkId && kind === 'audiooutput'

  const updateSelectedDevice = useCallback(
    (value: string) => {
      onDeviceChange(value)
      setSelectedDevice(value)
      setDeviceError('')
    },
    [onDeviceChange, setSelectedDevice, setDeviceError]
  )

  useEffect(() => {
    const hasSelectedDevice = audioDevices.some((device) => device.deviceId === selectedDevice)
    if (audioDevices.length && !hasSelectedDevice) {
      updateSelectedDevice(audioDevices[0].deviceId)
    }
  }, [audioDevices, devices, selectedDevice, updateSelectedDevice])

  return (
    <>
      <strong>{headerText}</strong>

      {noAudioRedirect && (
        <div>
          <div>System Default Audio Output</div>
        </div>
      )}

      {!noAudioRedirect && (
        <IonSelect
          value={selectedDevice}
          onIonChange={(e) => updateSelectedDevice(e.detail.value as string)}
        >
          {audioDevices.map((device) => (
            <IonSelectOption value={device.deviceId} key={device.deviceId}>
              {device.label}
            </IonSelectOption>
          ))}
        </IonSelect>
       
      )}

      {error && (
        <div>
          {error === 'No audio detected' ? 'No audio detected.' : 'Unable to connect.'}
        </div>
        
      )}
    </>
  )
}

export default React.memo(AudioDevice)
