import { types, Instance } from 'mobx-state-tree'
import { TutorId } from '../tutor/id'

export const ReferralCode = types.model('ReferralCode', {
  _id: types.string,
  code: types.string,
  tutor_id: types.maybeNull(TutorId),
  notes: types.maybeNull(types.string)
})
export type ReferralCode = Instance<typeof ReferralCode>
