import { Instance, types, flow } from 'mobx-state-tree'
import { Subject } from './subject/subject'
import { getAllSubjects } from '../../util/api/subject'

export const QuestionManagementModel = types.model('QuestionManagementStore', {
  subjects: types.array(Subject),
  loading: types.boolean
})
  .actions(self => ({
    refresh: flow(function*() {
      self.loading = true
      try {
        self.subjects = yield getAllSubjects()
        self.loading = false
      } catch (error) {
        console.error('Failed to fetch subjects', error)
        self.loading = false
      }
    }),
    add_subject (subject:Subject) {
      self.subjects.push(subject)
    }
  }))

export type QuestionManagement = Instance<typeof QuestionManagementModel>
