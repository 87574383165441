import { FC, useEffect, useState, FormEvent } from 'react'
import { applySnapshot, getSnapshot, IAnyModelType, Instance, onSnapshot } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { FormInputError } from 'generic_components/FormInputError'
import { chain } from 'lodash'
import styled from 'styled-components'
import { StringSelector } from 'generic_components/MstFormStringSelector'
import { NumberInput } from 'generic_components/MstFormNumberInput'
import { StringInput } from 'generic_components/MstFormStringInput'
import { QuestionEditor } from 'components/admin/questions/QuestionEditor'
import { AnswerEditor } from 'components/admin/questions/AnswerEditor'
import { Status } from 'common/types/question/status'

interface Props<M extends IAnyModelType> {
  title?: string
  subtitle?: string
  model: M
  initial: Instance<M>
  submit: () => Promise<unknown>
  force_edit_mode?: boolean
  submit_text?: string
  cancel_text?: string
  success_text?: string
  reset_draft: () => void
  dismiss: () => void
}

export const QuestionForm : FC<Props<IAnyModelType>> = observer(({
  initial, model, submit, dismiss,
  force_edit_mode=false, submit_text='Save', cancel_text='Cancel', success_text='Saved', title, subtitle, reset_draft
}) => {

  const [draft, set_draft] = useState<Record<string, unknown>>({})
  const [errors, set_errors] = useState<Record<string, string[]>>({})
  const [has_errors, set_has_errors] = useState(false)
  const [dirty, set_dirty] = useState(false)
  const [editting, set_editting] = useState(force_edit_mode)
  const [toast, set_toast] = useState<{ success: boolean, message: string } | undefined>()

  const reset = () => {
    set_draft(getSnapshot(initial))
    set_errors(() => ({}))
    set_has_errors(false)
    set_dirty(false)
    set_editting(force_edit_mode)
  }

  useEffect(reset, [initial._id])
  useEffect(() => {
    onSnapshot(initial.lines, () => set_dirty(true))
    onSnapshot(initial.answers, () => set_dirty(true))
  }, [initial])

  const onCancel = (e: FormEvent) => {
    e.stopPropagation()
    e.preventDefault()
    reset()
    reset_draft()
  }

  const reconstituted_draft = {
    ...draft,
    lines: getSnapshot(initial.lines),
    answers: getSnapshot(initial.answers) // Spagetti hack but we updated intial (which is draft in parent question_detail) directly to see the live preview so setting is back here
  }

  const validate = () => {
    const errors = model.validate(reconstituted_draft, [])

    const has_errors = errors.length > 0

    const errors_per_field = chain(errors)
      .groupBy(e => e.context[0].path)
      .mapValues(errors => errors.map(
        error => error.message
      ))
      .value()

    set_errors(errors_per_field)
    set_has_errors(has_errors)

    return !has_errors
  }

  const onSubmit = async (e: FormEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (validate()) {
      try {
        applySnapshot(initial, reconstituted_draft)
        await submit()
        set_toast({
          success: true,
          message: success_text
        })
        reset()
      }
      catch (e) {
        console.error(e)
        set_toast({
          success: false,
          message: `Error: ${e.errors?.join(', ')}`
        })
      }
    }
  }

  const onChangeStatus = (value: unknown) => {
    set_draft(() => ({
      ...draft,
      status: value
    }))
    // initial.update_status(value)
    set_dirty(true)
    // Dont show errors until they have tried submitting once
    if (has_errors) {
      validate()
    }
  }
  const onChangeStdTime = (value: unknown) => {
    set_draft(() => ({
      ...draft,
      standard_time: value
    }))
    // initial.update_std_time(value)
    set_dirty(true)
    // Dont show errors until they have tried submitting once
    if (has_errors) {
      validate()
    }
  }
  const onChangeMaxTime = (value: unknown) => {
    set_draft(() => ({
      ...draft,
      maximum_time: value
    }))
    // initial.update_max_time(value)
    set_dirty(true)
    // Dont show errors until they have tried submitting once
    if (has_errors) {
      validate()
    }
  }
  const onChangeScore = (value: unknown) => {
    set_draft(() => ({
      ...draft,
      score: value
    }))
    // initial.update_score(value)
    set_dirty(true)
    // Dont show errors until they have tried submitting once
    if (has_errors) {
      validate()
    }
  }
  const onChangeRef = (value: unknown) => {
    set_draft(() => ({
      ...draft,
      maximum_time: value
    }))
    // initial.update_max_time(value)
    set_dirty(true)
    // Dont show errors until they have tried submitting once
    if (has_errors) {
      validate()
    }
  }

  return (
    <Form onSubmit={onSubmit} onReset={onCancel}>
      <IonCard>
        {(title || subtitle) &&
          <IonCardHeader>
            {title && <IonCardTitle>{title}</IonCardTitle>}
            {subtitle && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
          </IonCardHeader>
        }
        <IonCardContent>
          <IonToast
            color={toast?.success ? 'success' : 'danger'}
            isOpen={toast !== undefined}
            onDidDismiss={() => set_toast(undefined)}
            message={toast?.message}
            position="middle"
            duration={2000}
          />
          <FormFields>
            <FormField lines='inset' width_percent={100}>
              <IonLabel position='floating'>Ref</IonLabel>
              <StringInput value={draft.ref as string} onChange={onChangeRef} disabled={true}/>
            </FormField>
            <FormField lines='inset' width_percent={25}>
              <IonLabel position='floating'>Status</IonLabel>
              <StatusSelector value={draft.status as string} onChange={onChangeStatus} disabled={!editting}/>
            </FormField>
            <FormField lines='inset' width_percent={25}>
              <IonLabel position='floating'>Score</IonLabel>
              <NumberInput value={draft.score as number} onChange={onChangeScore} disabled={!editting}/>
              {errors.score
                ?.map((error, i) => {
                  const error_text = error !== 'Value is not a plain object'
                    ? error
                    : 'Value is not allowed'
                  return <FormInputError key={i} error={error_text} field='score' />
                }
                )}
            </FormField>
            <FormField lines='inset' width_percent={25}>
              <IonLabel position='floating'>Standard Time (s)</IonLabel>
              <NumberInput value={draft.standard_time as number} onChange={onChangeStdTime} disabled={!editting}/>
              {errors.standard_time
                ?.map((error, i) => {
                  const error_text = error !== 'Value is not a plain object'
                    ? error
                    : 'Value is not allowed'
                  return <FormInputError key={i} error={error_text} field='standard_time' />
                }
                )}
            </FormField>
            <FormField lines='inset' width_percent={25}>
              <IonLabel position='floating'>Maximum Time (s)</IonLabel>
              <NumberInput value={draft.maximum_time as number} onChange={onChangeMaxTime} disabled={!editting}/>
              {errors.maximum_time
                ?.map((error, i) => {
                  const error_text = error !== 'Value is not a plain object'
                    ? error
                    : 'Value is not allowed'
                  return <FormInputError key={i} error={error_text} field='maximum_time' />
                }
                )}
            </FormField>
            {editting && <>
              <FormField lines='inset' width_percent={100}>
                <IonLabel position='floating'>Question</IonLabel>
                <QuestionEditor question={initial}/>
              </FormField>
              <FormField lines='inset' width_percent={100}>
                <IonLabel position='floating'>Answers</IonLabel>
                <AnswerEditor question={initial}/>
              </FormField>
            </>
            }
          </FormFields>
          <FormErrors>
            {has_errors && Object.entries(errors)
              .filter(([field, errors]) => ['status','score','standard_time','maximum_time', 'ref'].find(f => f === field) === undefined)
              .flatMap(([field, errors], i) =>
                errors.map((error, j) =>
                  <IonItem key={`${i}.${j}`}>
                    <FormInputError error={`${field}: ${error}`} field={''} />
                  </IonItem>
                ))
            }
          </FormErrors>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton
                fill='solid'
                size='small'
                color='secondary'
                className="ion-margin-top"
                type="reset"
                style={editting ? {} : {display:'none'}}
              >
                {cancel_text}
              </IonButton>
              <IonButton
                fill='solid'
                size='small'
                color='primary'
                className="ion-margin-top"
                type="submit"
                disabled={!dirty || has_errors}
                style={editting ? {} : {display:'none'}}
              >
                {submit_text}
              </IonButton>
              <IonButton
                fill='solid'
                size='small'
                color='secondary'
                className="ion-margin-top"
                type="button"
                onClick={dismiss}
                style={editting ? {display:'none'} : {}}
              >
                Close
              </IonButton>
              <IonButton
                fill='solid'
                size='small'
                color='primary'
                className="ion-margin-top"
                type="button"
                onClick={() => set_editting(true)}
                style={editting ? {display:'none'} : {}}
              >
                Edit
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCardContent>
      </IonCard>
    </Form>
  )
})

const StatusSelector = StringSelector((Status as any)._types)

const Form = styled.form`
  max-width: 60em;
`

const FormErrors = styled.div`
  display: flex;
  flex-direction: column;
`

const FormFields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const FormField = styled(IonItem)`
  flex: 0 0 ${props => props.width_percent}%;
`
