import { FC, useEffect } from 'react'
import { PlytimePageStudentBackground } from 'components/plytime_page_student_background'
import { Roles } from 'util/auth/helper'
import { observer } from 'mobx-react-lite'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react'
import { Notes } from 'components/notes'
import { useUser } from 'context/userContext'
import { useStore } from 'types/store'
import styled from 'styled-components'

interface IProps {
  roles: Roles[]
}

const MessagesPage: FC<IProps> = observer(({ roles }) => {
  const { user } = useUser()

  // Hack to get Math subject ID while Math is the only subject
  const { subject_list: { subjects, refresh } } = useStore()
  const math = subjects.find(subject => subject.name === 'Maths')
  useEffect(() => {
    refresh()
  }, [])

  return (
    <PlytimePageStudentBackground roles={roles}>
      {user &&
        <Container>
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                Messages
              </IonCardTitle>
            </IonCardHeader>
            <Content>
              {user === undefined || math === undefined
                ? <p>Loading...</p>
                : <Notes student={user._id} subject={math._id} />
              }
            </Content>
          </IonCard>
        </Container>
      }
    </PlytimePageStudentBackground>
  )
})

const Content = styled(IonCardContent)`
  height: 75vh;
`
export default MessagesPage

const Container = styled.div`
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      width: 60%;
      min-width: 640px;
`
