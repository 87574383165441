import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { ParentDetails } from 'components/parent/ParentDetails'
import { observer } from 'mobx-react-lite'
import { useStore } from 'types/store'

interface IProps {
  roles: Roles[]
}

export default observer(({roles}: IProps) => {
  const { parent_data: { profile } } = useStore()

  return (
    <PlytimePage roles={roles}>
      <div>
        <ParentDetails initial_profile={profile} />
      </div>
    </PlytimePage>
  )
})
