import { FC, ChangeEvent, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { AnswerElement } from 'common/types/question/question'
import { AllowedAnswers } from 'common/types/question/answer/answer'
import { useStore } from 'types/store'
import { TimeInputElement } from 'common/types/question/elements/time'
import { Container, ColumnContainer, Input } from 'generic_components/AnswerInputContainers'

interface Props {
  element: Instance<TimeInputElement>,
  handleChange: (e: ChangeEvent) => void,
  answer_decision: string,
  correct_answers: Instance<AllowedAnswers>,
  value: string,
  element_id: string,
  auto_focus_element?: Instance<AnswerElement>,
}

export const TimeInputComponent: FC<Props> = observer(({ element, handleChange, answer_decision, correct_answers, value, element_id, auto_focus_element }) => {
  const { game, auth: { user } } = useStore()
  const input_ref = useRef(null)
  
  useEffect(() => {
    if( element === auto_focus_element ) {
      input_ref.current.focus()
    }
  }, [element])

  const size = 5

  const handleInputChange = (e) => {
    if(e.target.value.length <= 5){
      const input = e.target.value.replaceAll(/[^0-9:]/g, '')
      const numbers = input.replaceAll(':','')
      const reformatted = numbers.length <= 2 
        ? input :
        `${numbers.slice(0,-2)}:${numbers.slice(-2)}`
      e.target.value = reformatted
      handleChange(e)
    }
  }
  
  return (
    <Container>
      <ColumnContainer>
        <Input
          id={element_id}
          onInput={(e) => handleInputChange(e)}
          answer_decision={answer_decision}
          placeholder={'__:__'}
          readOnly={!!(answer_decision)}
          value={value}
          size={size}
          ref={input_ref}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              game.submit_answer(user._id)
            }
          }}
        />
        {answer_decision !== 'CORRECT' && correct_answers.length > 0 && 
          <Input
            value={correct_answers[0].find(answerPart => answerPart.ref === element.ref)?.values[0] || 'error'}
            answer_decision="CORRECT"
            readOnly={true}
            size={size}
          />
        }
      </ColumnContainer>
      
    </Container>
  )
})
