import { types } from 'mobx-state-tree'

export const ImageElement = types
  .model('Image',
    {
      type: types.literal('Image'),
      image_id: types.optional(types.string, '')
    })

export type ImageElement = typeof ImageElement
