import { Tutor as BaseTutor } from 'common/types/tutor/tutor'
import { Instance, flow, SnapshotIn, applySnapshot, getSnapshot } from 'mobx-state-tree'
import { updateTutor } from 'util/api/tutor'

export const Tutor = BaseTutor
  .actions(self => ({
    save: flow(function* (tutor:SnapshotIn<BaseTutor>) {
      const old = getSnapshot(self)
      try {
        applySnapshot(self, tutor)
        const saved = yield updateTutor({body: tutor})
        applySnapshot(self, saved)
      } catch (error) {
        applySnapshot(self, old)
        throw error
      }
    })
  }))
export type Tutor = Instance<typeof Tutor>
