import { FC, ChangeEvent, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { AnswerElement } from 'common/types/question/question'
import { AllowedAnswers } from 'common/types/question/answer/answer'
import { useStore } from 'types/store'
import { Container, ColumnContainer, BorderWrapper, NoBorderInput } from 'generic_components/AnswerInputContainers'
import { MixedFractionInputElement } from 'common/types/question/elements/mixed_fraction'

interface Props {
  element: Instance<MixedFractionInputElement>,
  handleChange: (e: ChangeEvent) => void,
  answer_decision: string,
  correct_answers: Instance<AllowedAnswers>,
  value: string,
  element_id: string,
  auto_focus_element?: Instance<AnswerElement>,
}

type MixedFraction = {
  whole: string,
  numerator: string,
  denominator: string,
}

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

const parseWholeNumberInput = (value: string): string => value.replaceAll(/[^0-9-]/g,'')
const parseFractionNumberInput = (value: string): string => value.replaceAll(/[^0-9]/g, '')

const parseWholeNumberValue = (value: string): MixedFraction => ({whole: value, numerator: '', denominator: ''})

const parseFractionValue = (value: string): MixedFraction => {
  const [numerator, denominator] = value.trim().split('/')
  return {whole: '', numerator, denominator}
}
const parseMixedFractionValue = (value: string): MixedFraction => {
  const [whole, fraction] = value !== '' ? value.split(' ') : ['','']
  const [numerator, denominator] = fraction !== '' ? fraction.split('/') : ['','']
  return {whole, numerator, denominator}
}

const parseMixedFraction = (value:string): MixedFraction => {
  if (value.match(/^ *[+-]?[0-9]+ *$/)){
    return parseWholeNumberValue(value)
  } else if(value.match(/^ *[0-9]+\/[0-9]+ *$/)){
    return parseFractionValue(value)
  } else {
    return parseMixedFractionValue(value)
  }
}

const drawLine = (numeratorSize: number, denominatorSize: number) => 
  '\u2501'.repeat(Math.max(numeratorSize, denominatorSize))

export const MixedFractionInputComponent: FC<Props> = observer(({element, handleChange, answer_decision, correct_answers, value, element_id, auto_focus_element}) => {
  const {game, auth: { user }} = useStore()
  const wholeInputRef = useRef(null)
  const numeratorInputRef = useRef(null)
  const denominatorInputRef = useRef(null)

  useEffect(() => {
    if( element === auto_focus_element ) {
      wholeInputRef.current.focus()
    }
  }, [element])

  const {whole, numerator, denominator} = parseMixedFraction(value)
  const [wholeSize, numeratorSize, denominatorSize] = [whole, numerator, denominator].map(calculateLength)

  const answer : MixedFraction = (correct_answers.length > 0 && answer_decision !== 'CORRECT')
    ? parseMixedFraction(correct_answers[0].find(answerPart => answerPart.ref === element.ref).values[0])
    : { whole: '', numerator: '', denominator: ''}

  const [wholeAnswerSize, numeratorAnswerSize, denominatorAnswerSize] = 
    [answer.whole, answer.numerator, answer.denominator].map(calculateLength)
    
  const inputChangeWhole = (e) => {
    e.target.value = `${parseWholeNumberInput(e.target.value)} ${numerator}/${denominator}`
    handleChange(e)
  }
  
  const inputChangeNumerator = (e) => {
    e.target.value = `${whole} ${parseFractionNumberInput(e.target.value)}/${denominator}`
    handleChange(e)
  }

  const inputChangeDenominator = (e) => {
    e.target.value = `${whole} ${numerator}/${parseFractionNumberInput(e.target.value)}`
    handleChange(e)
  }

  const drawInputLine = drawLine(numeratorSize, denominatorSize)
  const drawAnswerLine = drawLine(numeratorAnswerSize, denominatorAnswerSize)  

  return (
    <ColumnContainer>
      <BorderWrapper answer_decision={answer_decision}>
        <Container>
          <NoBorderInput
            id={`${element_id}_whole`}
            onChange={(e) =>inputChangeWhole(e) }
            readOnly={!!(answer_decision)}
            value={whole}
            size={wholeSize}
            ref={wholeInputRef}
            onKeyPress={e => {
              if (e.key === 'Enter' && !game.over) {
                if ( whole !== '' && numerator !== '' && denominator !== '' ) {
                  game.submit_answer(user._id)
                } else {
                  numeratorInputRef.current.focus()
                }
              }
            }}
          />
          <ColumnContainer>
            <NoBorderInput
              id={`${element_id}_numerator`}
              onChange={(e) =>inputChangeNumerator(e) }
              readOnly={!!(answer_decision)}
              style={{marginBottom: 0}}
              value={numerator}
              size={numeratorSize}
              ref={numeratorInputRef}
              onKeyPress={e => {
                if (e.key === 'Enter' && !game.over) {
                  if ( whole !== '' && numerator !== '' && denominator !== '' ) {
                    game.submit_answer(user._id)
                  } else {
                    denominatorInputRef.current.focus()
                  }
                }
              }}
            />
            <p style={{margin: '0px', fontSize: '0.75em'}}>{drawInputLine}</p>
            <NoBorderInput
              id={`${element_id}_denominator`}
              onChange={(e) => inputChangeDenominator(e)}
              readOnly={!!(answer_decision)}
              value={denominator}
              size={denominatorSize}
              ref={denominatorInputRef}
              onKeyPress={e => {
                if (e.key === 'Enter' && !game.over) {
                  if ( whole !== '' && numerator !== '' && denominator !== '' ) {
                    game.submit_answer(user._id)
                  } else {
                    wholeInputRef.current.focus()
                  }
                }
              }}
            />
          </ColumnContainer>
        </Container>
      </BorderWrapper>
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 && (
        <BorderWrapper answer_decision={'CORRECT'}>
          <Container>
            {answer.whole !== '' && (
              <NoBorderInput
                readOnly={true}
                value={answer.whole}
                size={wholeAnswerSize}
              />
            )}
            {answer.numerator !== '' && answer.denominator !== '' && (
              <ColumnContainer>
                <NoBorderInput
                  readOnly={true}
                  value={answer.numerator}
                  style={{marginBottom: 0}}
                  size={numeratorAnswerSize}
                />
                <p style={{margin: '0px', fontSize: '0.75em'}}>{drawAnswerLine}</p>
                <NoBorderInput
                  readOnly={true}
                  value={answer.denominator}
                  size={denominatorAnswerSize}
                />
              </ColumnContainer>
            )}
          </Container>
        </BorderWrapper>
      )}
    </ColumnContainer>
  )
})
