import { flow, types, getSnapshot, SnapshotOut } from 'mobx-state-tree'
import { HydratedSession } from 'common/types/session'
import { DateTime } from 'luxon'
import { getSession } from 'util/api/sessions'
import { RoomToken } from 'common/types/session/room'
import { getSessionRoomToken } from 'util/api/sessions/room'
import { putSessionRegister } from 'util/api/sessions/register'
import {
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
  connect,
} from 'twilio-video'
import { SessionEventType } from 'common/types/session/register'

export const StudentSession = HydratedSession
  .views( self => ({
    get start () {
      return DateTime.fromISO(self.session_date_time)
    },
    get end () {
      return DateTime.fromISO(self.session_date_time).plus({minutes: self.session_length})
    }
  }))
  .volatile( self => ({
    joinable: false
  }))

export type StudentSession = typeof StudentSession

export enum Status {
  LOADING,
  EARLY,
  READY,
  CONNECTING,
  AWAITING_TUTOR,
  ACTIVE,
  FINISHED,
  ERROR_NO_SESSION,
}

export const StudentSessionStore = types
  .model('StudentSessionStore',{
    session: types.maybe(StudentSession),
    room_token: types.maybe(RoomToken),
  })
  .volatile(self => ({
    loading: false,
    room: undefined as Room | undefined,
    local_audio: undefined as LocalAudioTrack | undefined,
    local_audio_enabled: false,
    local_video: undefined as LocalVideoTrack | undefined,
    local_video_enabled: false,
    local_screen: undefined as LocalVideoTrack | undefined,
    local_screen_enabled: false,
    remote_video: undefined as RemoteVideoTrack | undefined,
    remote_screen: undefined as RemoteVideoTrack | undefined,
    remote_audio: undefined as RemoteAudioTrack | undefined,
  }))
  .views(self => ({
    get student_status():Status {
      if( self.loading ) return Status.LOADING
      if( self.session === undefined ) return Status.ERROR_NO_SESSION
      if( DateTime.now() < DateTime.fromISO(self.session.session_date_time).minus({minutes: 2}) ) {
        return Status.EARLY
      }
      if( self.remote_audio !== undefined || self.remote_video !== undefined || self.remote_screen !== undefined ) {
        return Status.ACTIVE
      }
      if( DateTime.now() > DateTime.fromISO(self.session.session_date_time).plus({minutes: self.session.session_length}) ) {
        return Status.FINISHED
      }
      if( self.local_audio !== undefined || self.local_video !== undefined ) {
        return Status.AWAITING_TUTOR
      }
      if( self.room_token !== undefined ) {
        return Status.CONNECTING
      }
      return Status.READY
    }
  }))
  .actions(self => ({
    register_event: (type:SnapshotOut<SessionEventType>) => {
      if(self.room_token){
        putSessionRegister(self.session._id, {
          time: DateTime.now().toISO(),
          type
        })
      }
    }
  }))
  .actions(self => ({
    heartbeat() {
      self.register_event('user_still_connected')
    },
    add_local_track: (track:LocalTrack) => {
      console.log(`add_local_track ${track === null ? 'null' : `${track.kind} ${(track as any).isEnabled} ${track.name} ${track.id}`}`)
      if( track === null ) return
      switch( track.kind ) {
        case 'video':
          self.local_video = track
          self.local_video_enabled = track.isEnabled
          self.register_event('add_local_video')
          break
        case 'audio':
          self.local_audio = track
          self.local_audio_enabled = track.isEnabled
          self.register_event('add_local_audio')
          break
        default:
          console.log(`Unhandled add local track for ${track.kind}`)
      }
    },
    remove_local_track: (track:LocalTrack) => {
      console.log(`remove_local_track ${track === null ? 'null' : `${track.kind} ${(track as any).isEnabled} ${track.name} ${track.id}`}`)
      if( track === null ) return
      switch( track.kind ) {
        case 'video':
          self.local_video = undefined
          self.local_video_enabled = false
          self.register_event('remove_local_video')
          break
        case 'audio':
          self.local_audio = undefined
          self.local_audio_enabled = false
          self.register_event('remove_local_audio')
          break
        default:
          console.log(`Unhandled remove local track for ${track.kind}`)
      }
    },
    add_remote_track: (track:RemoteTrack) => {
      console.log(`add_remote_track ${track === null ? 'null' : `${track.kind} ${track.isEnabled} ${track.name} ${track.sid}`}`)
      if( track === null ) return
      switch( track.kind ) {
        case 'video':
          if( track.name === 'screen' ) {
            self.remote_screen = track
          } else {
            self.remote_video = track
          }
          break
        case 'audio':
          self.remote_audio = track
          break
        default:
          console.log(`Unhandled add remote track for ${track.kind}`)
      }
    },
    remove_remote_track: (track:RemoteTrack) => {
      console.log(`remove_remote_track ${track === null ? 'null' : `${track.kind} ${track.isEnabled} ${track.name} ${track.sid}`}`)
      if( track === null ) return
      switch( track.kind ) {
        case 'video':
          if( track.sid === self.remote_video?.sid) {
            self.remote_video = undefined
          }
          if( track.sid === self.remote_screen?.sid) {
            self.remote_screen = undefined
          }
          break
        default:
          console.log(`Unhandled remove track for ${track.kind}`)
      }
    },
    toggle_local_video: (value:'on'|'off') => {
      console.log(`${value} => ${self.local_video} ${self.local_video?.isEnabled} ${self.local_video_enabled}`)
      if( self.local_video === undefined ) {
        if( self.local_video_enabled !== false ) {
          self.local_video_enabled = false
        }
        return
      }
      if( self.local_video.isEnabled ) {
        if( value === 'off' ) {
          self.local_video.disable()
          self.register_event('pause_local_video')
        }
      } else {
        if( value === 'on' ) {
          self.local_video.enable()
          self.register_event('unpause_local_video')
        }
      }
      self.local_video_enabled = self.local_video.isEnabled
    },
    toggle_local_audio: (value:'on'|'off') => {
      console.log(`${value} => ${self.local_audio} ${self.local_audio?.isEnabled} ${self.local_audio_enabled}`)
      if( self.local_audio === undefined ) {
        if( self.local_audio_enabled !== false ) {
          self.local_audio_enabled = false
        }
        return
      }
      if( self.local_audio.isEnabled ) {
        if( value === 'off' ) {
          self.local_audio.disable()
          self.register_event('pause_local_audio')
        }
      } else {
        if( value === 'on' ) {
          self.local_audio.enable()
          self.register_event('unpause_local_audio')
        }
      }
      self.local_audio_enabled = self.local_audio.isEnabled
    },
    toggle_screen_share: flow(function* () {
      console.log('toggle screen')
      if( self.local_screen === undefined ) {
        console.log('toggle screen on')
        try{
          const mediaDevices = navigator.mediaDevices as any
          const stream = yield mediaDevices.getDisplayMedia()
          console.log('screen created')
          const screen_track = new LocalVideoTrack(
            stream.getTracks()[0],
            { name: 'screen', logLevel: 'error' }
          )
          self.room.localParticipant.publishTrack(screen_track)
          self.local_screen = screen_track
          self.register_event('add_local_screen')
        } catch (e) {
          console.log(`Error sharing screen ${e.message}`)
        }
      } else {
        console.log('toggle screen off')
        self.room.localParticipant.unpublishTrack(self.local_screen)
        self.local_screen.stop()
        self.local_screen = undefined
        self.register_event('remove_local_screen')
      }
    }),
    get_session: flow(function*(session_id:string) {
      self.loading = true
      try{
        const session = yield getSession(session_id)
        self.session = session
      } finally {
        self.loading = false
      }
    })
  }))
  .actions(self => {
    let heartbeat_timer: NodeJS.Timeout | undefined = undefined
    return ({
      join_session: flow(function*(session_id:string) {
        self.loading = true
        try{
          yield self.get_session(session_id)
          const room_token = yield getSessionRoomToken(session_id)
          self.room_token = room_token
          self.register_event('connect')
          const room:Room = yield connect(room_token.token, {
            name: room_token.room_id,
            automaticSubscription: true,
          })
          self.room = room

          if (heartbeat_timer !== undefined) {
            clearInterval(heartbeat_timer)
            heartbeat_timer = undefined
          }
          heartbeat_timer = setInterval(() => self.heartbeat(), 120000)
          
          room.localParticipant.tracks.forEach(t => self.add_local_track(t.track))
          room.participants.forEach(p => {
            console.log(`Add existing participant ${p.sid}`)
            p.tracks.forEach(t => {
              console.log(`Add existing track ${t.trackSid} ${t.track!==null}`)
              if( t.isSubscribed ) {
                self.add_remote_track(t.track)
              }
              p.on('trackSubscribed', self.add_remote_track)
              p.on('trackUnsubscribed', self.remove_remote_track)
            })
          })
          room.on('participantDisconnected', (p:RemoteParticipant) => {
            console.log(`Participant disconnected ${p.sid}`)
            p.removeAllListeners()
            p.tracks.forEach(t => {
              self.remove_remote_track(t.track)
            })
          })
          room.on('participantConnected', (p:RemoteParticipant) => {
            console.log(`Participant connected ${p.sid}`)
            p.tracks.forEach(t => {
              console.log(`Add new participants existing track ${t.trackSid} ${t.track!==null}`)
              if( t.isSubscribed ) {
                self.add_remote_track(t.track)
              }
            })
            p.on('trackSubscribed', self.add_remote_track)
            p.on('trackUnsubscribed', self.remove_remote_track)
          })
          room.once('disconnect', (room:Room) => {
            console.log('disconnected from room')
            room.localParticipant.tracks.forEach(t => {
              (t.track as any).stop() // Why is this hidden from the type, grrrr.
            })
          })
        }
        catch(e) {
          console.log(e)
        }
        finally {
          self.loading = false
        }
      }),
      disconnect: function () {
        console.log('Left 1-2-1')
        self.room?.disconnect()

        if(heartbeat_timer !== undefined) {
          clearInterval(heartbeat_timer)
          heartbeat_timer = undefined
        }

        if( self.room_token ) {
          self.register_event('disconnect')
          self.room_token = undefined
        }
      }
    })
  })
export type StudentSessionStore = typeof StudentSessionStore
