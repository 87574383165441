import { FC, useEffect, useState, useRef } from 'react'
import { SnapshotOut } from 'mobx-state-tree'
import { useUser } from 'context/userContext'
import { Note } from 'common/types/notes'
import { list_notes } from 'util/api/notes'
import { useChannel, useEvent } from '@harelpls/use-pusher'
import styled from 'styled-components'
import { Props } from './props'
import { SendNote } from './send_note'
import { Incoming } from './incoming'
import { Outgoing } from './outgoing'
import { set_last_read_time } from 'util/api/notes/read'
import { DateTime } from 'luxon'

export const NotesCard:FC<Props> = ({ student, subject }) => {
  const { user } = useUser()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [total, setTotal] = useState(0)
  const [count, setCount] = useState(0)
  const [notes, setNotes] = useState<SnapshotOut<Note>[]>([])
  const listref = useRef<HTMLDivElement>()

  const update_read_timestamp = (timestamp?:string) => set_last_read_time({
    params:{
      student,
      subject
    },
    body: {
      timestamp: timestamp ? timestamp : DateTime.now().toISO()
    }
  })
  const channel = useChannel(`${student}-${subject}`)
  useEvent(channel, 'message', (data: SnapshotOut<Note>) => {
    //TODO validate
    setNotes((notes) => [data, ...notes])
    update_read_timestamp(data.timestamp)
  })

  useEffect(() => {
    (async () => {
      setLoading(true)
      try{
        const list = await list_notes(student, subject)
        setNotes(list.notes)
        setTotal(list.total)
        setCount(list.count)
        await update_read_timestamp()
      }
      catch(e){
        console.error('Error getting notes:')
        e.errors?.forEach(e => console.error(e))
        setError(true)
      }
      setLoading(false)
    })()
  }, [student, subject])

  const load_more = async () => {
    if( total > count &&
        !loading &&
        listref.current.scrollHeight <= listref.current.clientHeight - listref.current.scrollTop + 100
    )
    {
      setLoading(true)
      try{
        const oldest_note = notes[notes.length - 1]
        const list = await list_notes(student, subject, oldest_note.timestamp)
        setNotes(notes => [...notes, ...list.notes])
        setTotal(list.total)
        setCount(count => count + list.count)
      }
      catch(e){
        console.error('Error getting notes:')
        e.errors?.forEach(e => console.error(e))
        setError(true)
      }
      setLoading(false)
    }
  }

  return (
    <Content>
      { loading && <p>Loading...</p> }
      { error && <p>Error getting notes</p> }
      <List
        ref={listref}
        onScroll={load_more}
      >
        {notes.map(n => n.sender_id === user._id
          ? <Outgoing key={n._id} note={n}/>
          : <Incoming key={n._id} note={n}/>
        )}
      </List>
      <SendNote student={student} subject={subject}/>
    </Content>
  )
}

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
`

const List = styled.div`
  overflow: hidden;
  overflow-y: auto;
  padding-top: 0.3rem;
  margin-bottom: 0.3rem;
  display: flex;
  flex: 1 0 0;
  flex-direction: column-reverse;
`
