import { FC } from 'react'
import { Instance } from 'mobx-state-tree'
import { ImageElement } from 'common/types/question/elements/image'
import styled from 'styled-components'

import { AmplifyS3Image } from '@aws-amplify/ui-react'
interface Props {
  image: Instance<ImageElement>
}

const Container = styled.div`
  margin: 1em;
  max-width: 25em;
  --width: 100%;
`

const Image = styled.img`
`

export const ImageComponent: FC<Props> = ({ image }) => {
  return (
    <Container>
      <AmplifyS3Image imgKey={`static-data/attachments/${image.image_id}`} key={image.image_id} />
    </Container>
  )
}
