import { Instance, types, SnapshotOut } from 'mobx-state-tree'
import { NonEmptyString } from 'common/types/basic/non_empty_string'
import { Email } from 'common/types/basic/email'
import { Country } from 'common/types/basic/country'

export const StudentRef = types.model('StudentRef', {
  _id: NonEmptyString,
  username: NonEmptyString,
  first_name: NonEmptyString,
})
export type StudentRef = Instance<typeof StudentRef>

export const CreateParentRequest = types.model('CreateParentRequest', {
  first_name: types.string,
  last_name: types.string,
  country: types.optional(Country, 'United Kingdom'),
  postcode: types.maybeNull(types.string),
  phone_number: types.maybeNull(types.string),
  email_opt_in: types.boolean,
})
export type CreateParentRequest = Instance<typeof CreateParentRequest>

export const Parent = CreateParentRequest
  .named('Parent')
  .props({
    _id: NonEmptyString,
    uid: NonEmptyString,
    email: Email,
    students: types.array(StudentRef)
  })
export type Parent = Instance<typeof Parent>

export const ParentPostRequest = types.model(
  'Parent',
  {
    body: CreateParentRequest
  }
)
export type ParentPostRequest = SnapshotOut<typeof ParentPostRequest>

export const GetParentRequest = types.model(
  'GetParentRequest', {
  }
)
export type GetParentRequest = SnapshotOut<typeof GetParentRequest>
