import { useEffect } from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from '@ionic/react'
import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { BlockButtonWithImage } from 'generic_components/BlockButtonWithImage'
import { useUpcomingSessions } from 'components/sessions/useUpcomingSessions'
import { SessionActions } from 'components/tutor/session/actions'
import styled from 'styled-components'
import { RelativeLevel } from 'common/types/student/relative_levels'
import { usePastSessions } from 'components/sessions/usePastSessions'
import { useStore } from 'types/store'

interface IProps {
  roles: Roles[]
}

const shorthands:Record<RelativeLevel,string> = {
  'A year behind': '-12',
  '6 Months behind': '-6',
  '3 Months behind': '-3',
  'On track': '=',
  '3 months ahead': '+3',
  '6 Months ahead': '+6',
  'A year ahead': '+12'
}

export default function ({ roles }: IProps) {
  const { sessions, busy, refresh, load_next_week } = useUpcomingSessions()
  const { past_sessions, past_busy: loading_past_sessions,  load_previous_week } = usePastSessions()
  const { tutor_data: { get_subscriptions, subscriptions } } = useStore()

  useEffect( () => { get_subscriptions() }, [])

  return (
    <PlytimePage roles={roles}>
      <ToolbarContainer>
        <BlockButtonWithImage text='View/edit profile' routerLink='/tutor/profile' ion_icon='pencil' ion_icon_color='blue' />
        <BlockButtonWithImage text='Manage Availability' routerLink='/tutor/availability' ion_icon='calendarOutline' ion_icon_color='green' />
        <BlockButtonWithImage text='Audio/Video Check' routerLink='/tutor/diagnostics' ion_icon='cameraOutline' ion_icon_color='green' />
      </ToolbarContainer>

      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Upcoming sessions
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow style={{ height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast' }}>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Date </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Time </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Length </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Subject </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Student </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Year / level </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Status </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Actions </IonLabel>
              </IonCol>
            </IonRow>
            {sessions.map(session => {
              return (
                <IonRow key={session.session_date_time} className='alternate-light-medium'>
                  <IonCol size='2'>
                    {session.day}
                  </IonCol>
                  <IonCol size='1'>
                    {session.time}
                  </IonCol>
                  <IonCol size='1'>
                    {session.session_length}
                  </IonCol>
                  <IonCol size='2'>
                    {session.subject.name}
                  </IonCol>
                  <IonCol size='2'>
                    {session.student.first_name}
                  </IonCol>
                  <IonCol size='1'>
                    {`${session.student.school_year} (${shorthands[session.relative_level]})`}
                  </IonCol>
                  <IonCol size='2'>
                    {session.sessionStatus}
                  </IonCol>
                  <IonCol size='1'>
                    <SessionActions
                      session={session}
                      scheduledSession={
                        sessions.find(({sessionStatus, recurring_session_id}) =>
                          sessionStatus === 'Scheduled' &&
                          recurring_session_id === session.recurring_session_id
                        )
                      }
                      subscriptions={subscriptions}
                      refresh={refresh}
                    />
                  </IonCol>
                </IonRow>
              )
            })}
            {busy
              ? <IonRow className='alternate-light-medium'>
                Loading...
              </IonRow>
              : <IonRow
                className='alternate-light-medium'
                onClick={load_next_week}
              >
                {sessions.length === 0 ? 'No upcoming sessions this week, click here to try loading the next week' : 'Load Next Week'}
              </IonRow>
            }
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
                Past sessions
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow style={{ height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast' }}>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Date </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Time </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Length </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Subject </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Student </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Year / level </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Status </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Actions </IonLabel>
              </IonCol>
            </IonRow>
            {past_sessions.map(session => {
              return (
                <IonRow key={session.session_date_time} className='alternate-light-medium'>
                  <IonCol size='2'>
                    {session.day}
                  </IonCol>
                  <IonCol size='1'>
                    {session.time}
                  </IonCol>
                  <IonCol size='1'>
                    {session.session_length}
                  </IonCol>
                  <IonCol size='2'>
                    {session.subject.name}
                  </IonCol>
                  <IonCol size='2'>
                    {session.student.first_name}
                  </IonCol>
                  <IonCol size='1'>
                    {`${session.student.school_year} (${shorthands[session.relative_level]})`}
                  </IonCol>
                  <IonCol size='2'>
                    {session.sessionStatus}
                  </IonCol>
                  <IonCol size='1'>
                    <SessionActions session={session} refresh={refresh} subscriptions={subscriptions}/>
                  </IonCol>
                </IonRow>
              )
            })}
            {loading_past_sessions
              ? <IonRow className='alternate-light-medium'>
                    Loading...
              </IonRow>
              : <IonRow
                className='alternate-light-medium'
                onClick={load_previous_week}
              >
                {sessions.length === 0 ? 'No past sessions this week, click here to try loading the previous week' : 'Load Previous Week'}
              </IonRow>
            }
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </PlytimePage >
  )
}

const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
