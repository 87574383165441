import { useEffect, useState, FC, useRef, useMemo, useCallback } from 'react'
import { SnapshotOrInstance } from 'mobx-state-tree'
import { DateTime } from 'luxon'
import { IonIcon, IonText, IonToolbar, IonButtons, IonButton, IonModal, IonContent } from '@ionic/react'
import {
  squareOutline,
  checkboxOutline,
  flag,
  chevronDownCircleOutline,
  chevronUpCircleOutline,
  videocam,
  reorderFour,
  reorderThree,
  reorderTwo,
  checkmarkOutline,
  closeOutline
} from 'ionicons/icons'
import styled from 'styled-components'

import { putResolvedStatus, putReviewStatus } from 'util/api/student/answers'
import { Question } from '../question'
import { Table } from './table'
import { StudentId } from 'common/types/student/id'
import { SubmittedAnswer } from 'common/types/question/answer/answer'
import { useGetAnswers } from './useGetAnswers'
import { VideoHelp } from '../video_help'

interface AnswerProps {
  row: any,
  update: any
}

interface QuestionReviewProps {
  student_id: SnapshotOrInstance<StudentId>
  AnswerDetailComponent: FC<AnswerProps>
  initialFilters?: IInitialFilter[]
}

interface GameReviewProps {
  student_id: SnapshotOrInstance<StudentId>
  AnswerDetailComponent: FC<AnswerProps>
  game_start_time: DateTime
}

export interface IInitialFilter {
  id: QuestionTableHeader,
  value: string,
}

interface QuestionTableProps {
  answers: any
  isLoading: boolean
  update_answer: (update: any) => void
  AnswerDetailComponent: FC<AnswerProps>
  showDate?: boolean
  initialFilters?: IInitialFilter[]
}

export enum QuestionTableHeader {
  Date='Date',
  Theme='Theme',
  Topic='Topic',
  Endcode='Endcode',
  Difficulty='Difficulty',
  Performance='Performance',
  P='P',
  Reviewed='Reviewed',
  Tokens='Tokens',
}

const QuestionTable: FC<QuestionTableProps> = ({ answers, isLoading, update_answer, AnswerDetailComponent, showDate, initialFilters=[] }) => {
  const pending_tokens_cutoff_date = DateTime.now().minus({days: 14}).toISODate()

  const columns = [
    ...showDate ? [{
      Header: QuestionTableHeader.Date,
      accessor: answer => DateTime.fromISO(answer.timestamp),
      Cell: ({ cell }) => cell.value.toFormat('dd/MM/yy HH:mm'),
      disableFilters: true
    }] : [],
    {
      Header: QuestionTableHeader.Theme,
      accessor: 'theme.name',
      filter: 'includes',
    },
    {
      Header: QuestionTableHeader.Topic,
      accessor: 'topic.name',
    },
    {
      Header: QuestionTableHeader.Endcode,
      accessor: 'endcode.description',
      Cell: ({ cell }) => <div title={cell.value}>{cell.value.length <= 10 ? cell.value : cell.value.substr(0, 9) + '...'}</div>
    },
    {
      Header: QuestionTableHeader.Difficulty,
      accessor: 'difficulty',
    },
    {
      Header: QuestionTableHeader.Performance,
      accessor: (answer) => {
        if (answer.result === 'INCORRECT') return 'wrong'
        if (answer.result === 'PASSED') return 'pass'
        if (answer.result === 'CORRECT') {
          if (answer.response_time_pc <= 100) return 'perfect'
          if (answer.response_time <= answer.question.maximum_time) return 'okay'
          if (answer.response_time > answer.question.maximum_time) return 'good effort'
        }
      },
      Cell: ({ cell }) => <IonText
        color={
          cell.value.includes('perfect') ? 'success'
            : cell.value.includes('good effort') ? 'warning'
              : cell.value.includes('okay') ? 'secondary'
                : cell.value.includes('wrong') ? 'danger'
                  : 'primary'
        }
      >
        {cell.value}
      </IonText>
    },
    {
      Header: QuestionTableHeader.Tokens,
      accessor: (answer) => {
        if (answer.timestamp < pending_tokens_cutoff_date) return 'n/a'
        if (answer.timestamp < '2022-12-21T15:00:00') return 'n/a'
        if (answer.result === 'INCORRECT' && !answer.reviewed) return 'pending'
        if (answer.result === 'PASSED' && !answer.reviewed) return 'pending'

        return 'awarded'
      },
      Cell: ({ cell }) => {
        if (cell.value === 'n/a') return 'n/a'

        return (
          cell.value === 'awarded' ?
            <IonIcon icon={checkmarkOutline}/> :
            <IonIcon icon={closeOutline}/>
        )
      }
    },
    {
      Header: 'P',
      accessor: 'endcode.priority',
      Cell: ({ cell }) => <IonIcon
        icon={cell.value === '1' ? reorderFour : cell.value === '2' ? reorderThree : reorderTwo}
        color={cell.value === '1' ? 'danger' : cell.value === '2' ? 'warning' : 'success'}
      />
    },
    {
      Header: 'Reviewed',
      accessor: ({ reviewed, help_requested, resolved }) =>
        ( help_requested && !resolved ) ? 'help' :
          ( !reviewed && !help_requested ) ? 'todo' :
            'okay',
      Cell: ({ cell }) => <IonIcon
        icon={cell.value === 'help' ? flag : cell.value === 'okay' ? checkboxOutline : squareOutline}
        color={cell.value === 'help' ? 'danger' : 'primary'}
      />
    },
    {
      Header: () => null,
      id: 'expander',
      accessor: (answer) => answer,
      Cell: ({ row }) => (
        <IonIcon icon={row.isExpanded ? chevronUpCircleOutline : chevronDownCircleOutline} />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  return (
    <div>
      {answers !== undefined && (
        <>
          {(answers === undefined || isLoading) && <div>Loading...</div>}
          {answers.length === 0 && !isLoading && <div>No answers found. Please load another weeks data.</div>}
          {
            answers.length > 0 && (
              <Table
                columns={columns}
                data={answers}
                RowSubComponent={AnswerDetailComponent}
                update={update_answer}
                initialFilters={initialFilters}
              />
            )
          }
        </>
      )}
    </div>
  )
}

export const GameReview: FC<GameReviewProps> = ({ student_id, AnswerDetailComponent, game_start_time }) => {
  const { answers, isLoading, update_answer } = useGetAnswers(student_id, game_start_time)
  return (
    <>
      {answers && (
        <QuestionTable
          answers={answers.filter(answer => answer.result !== 'CORRECT')}
          isLoading={isLoading}
          update_answer={update_answer}
          AnswerDetailComponent={AnswerDetailComponent}
        />
      )}
    </>
  )
}

export const QuestionReview: FC<QuestionReviewProps> = ({ student_id, AnswerDetailComponent, initialFilters= [] }) => {
  const { answers, isLoading, fetchDataSince, load_next_week, update_answer } = useGetAnswers(student_id, DateTime.now().minus({weeks: 1}))
  return (
    <>
      <QuestionTable
        answers={answers}
        isLoading={isLoading}
        update_answer={update_answer}
        AnswerDetailComponent={AnswerDetailComponent}
        showDate
        initialFilters={initialFilters}
      />
      <LoadBtnContainer>
        <IonButton
          size='small'
          className="ion-margin-top"
          color='primary'
          type="button"
          disabled={isLoading}
          onClick={load_next_week}
        >{`Load data from ${Math.floor(DateTime.now().diff(fetchDataSince).as('weeks') + 1)} weeks ago`}</IonButton>
      </LoadBtnContainer>
    </>
  )
}

const markReviewed = async (answer, update) => {
  console.log(`mark reviewed ${answer.reviewed}`)
  if (answer.reviewed) { return }
  try {
    const result = await putReviewStatus({
      answer_id: answer._id,
      help_requested: answer.help_requested,
      reviewed: true,
    })
    if (!result.success) {
      throw new Error('Failed to update reviewed flag')
    }
    update({
      _id: answer._id,
      reviewed: true
    })
  } catch (e) {
    console.error('Failed to update reviewed flag, undoing local change')
    update({
      _id: answer._id,
      reviewed: false,
    })
  }
}

export const StudentDetailView = ({ row: { values: { expander: answer } }, update }) => {
  const [watchVideos, setWatchVideos] = useState<boolean>(false)
  const [hasEnoughTimePassed, setHasEnoughTimePassed] = useState<boolean>(false)

  const timeout = useRef<NodeJS.Timeout>()
  const onClickMarkReviewedCallback = useCallback(
    () => {
      markReviewed(answer, update).then(() => {
        if (timeout.current !== undefined) {
          clearTimeout(timeout.current)
        }
      })
    },
    [answer, timeout, update]
  )


  useEffect(() => {
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current)
    }
    if (!answer.reviewed) {
      timeout.current = setTimeout(() => {
        setHasEnoughTimePassed(true)
      }, 5000)
    }
    return () => {
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current)
      }
    }
  }, [answer])

  const requestHelp = async () => {
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current)
    }
    const help_requested = !answer.help_requested
    update({
      _id: answer._id,
      help_requested,
      reviewed: true
    })
    try {
      const result = await putReviewStatus({
        answer_id: answer._id,
        help_requested,
        reviewed: true,
      })
      if (!result.success) {
        throw new Error('Failed to update help flag')
      }
    } catch (e) {
      console.error('Failed to update help flag, undoing local change')
      update({
        _id: answer._id,
        help_requested: !help_requested,
        reviewed: answer.reviewed
      })
    }
  }

  const student_answer = useMemo(() => SubmittedAnswer.create(answer.answer), [answer.answer])

  return (
    <>
      {watchVideos && <IonModal isOpen={true} onDidDismiss={() => setWatchVideos(false)} cssClass="help-modal">
        <IonContent>
          <VideoHelp name={answer.endcode.name} help_videos={answer.endcode.help_videos} />
        </IonContent>
      </IonModal>}
      <QuestionDetail>
        <Question
          question={answer.question}
          answer_decision={answer.result}
          student_answer={student_answer}
          correct_answers={answer.question.answers}
          update_answer={noop}
        />
        <IonToolbar style={{ ['--background']: 'transparent' }}>
          <IonButtons slot='end'>
            {answer.endcode.help_videos && answer.endcode.help_videos.length > 0 &&
              <IonButton
                fill='outline'
                size='small'
                className="ion-margin-top"
                color='primary'
                type="button"
                disabled={!answer.endcode.help_videos || answer.endcode.help_videos.length === 0}
                onClick={() => setWatchVideos(true)}
              >
                Help
                <IonIcon icon={videocam} />
              </IonButton>
            }

            <IonButton
              fill='outline'
              size='small'
              color='danger'
              className="ion-margin-top"
              type="button"
              // disabled={answer.length < 2}
              onClick={requestHelp}
            >
              Request help
              <IonIcon icon={flag} />
            </IonButton>
            {
              !answer.reviewed ? (
                <IonButton
                  fill='outline'
                  size='small'
                  color='success'
                  className="ion-margin-top"
                  type="button"
                  disabled={!hasEnoughTimePassed}
                  onClick={onClickMarkReviewedCallback}
                >
                  Mark as reviewed
                  <IonIcon icon={checkmarkOutline} />
                </IonButton>
              ) : null
            }
          </IonButtons>
        </IonToolbar>
      </QuestionDetail >
    </>
  )
}

export const TutorDetailView = ({ row: { values: { expander: answer } }, update }) => {
  const student_answer = useMemo(() => SubmittedAnswer.create(answer.answer), [answer.answer])

  const [watchVideos, setWatchVideos] = useState<boolean>(false)
  const onClickMarkReviewedCallback = useCallback(
    () => markReviewed(answer, update),
    [answer, update]
  )

  const markResolved = async () => {
    if (answer.resolved) { return }
    try {
      const result = await putResolvedStatus({
        answer_id: answer._id,
        resolved: true
      })
      if (!result.success) {
        throw new Error('Failed to update reviewed flag')
      }
      update({
        _id: answer._id,
        resolved: true
      })
    } catch (e) {
      console.error('Failed to update reviewed flag, undoing local change')
      update({
        _id: answer._id,
        resolved: false
      })
    }
  }

  return (
    <>
      {watchVideos && <IonModal isOpen={true} onDidDismiss={() => setWatchVideos(false)} cssClass="help-modal">
        <IonContent>
          <VideoHelp name={answer.endcode.name} help_videos={answer.endcode.help_videos} />
        </IonContent>
      </IonModal>}
      <QuestionDetail>
        <Question
          question={answer.question}
          answer_decision={answer.result}
          student_answer={student_answer}
          correct_answers={answer.question.answers}
          update_answer={noop}
        />
        <IonToolbar style={{ ['--background']: 'transparent' }}>
          <IonButtons slot='end'>
            {(answer.help_requested && !answer.resolved) &&
              <IonButton
                fill='outline'
                size='small'
                color='danger'
                className="ion-margin-top"
                type="button"
                onClick={markResolved}
              >
                  Resolve
                <IonIcon icon={flag} />
              </IonButton>
            }
            {answer.endcode.help_videos && answer.endcode.help_videos.length > 0 &&
              <IonButton
                fill='outline'
                size='small'
                className="ion-margin-top"
                color='primary'
                type="button"
                disabled={!answer.endcode.help_videos || answer.endcode.help_videos.length === 0}
                onClick={() => setWatchVideos(true)}
              >
                Help
                <IonIcon icon={videocam} />
              </IonButton>
            }
            {
              !answer.reviewed ? (
                <IonButton
                  fill='outline'
                  size='small'
                  color='success'
                  className="ion-margin-top"
                  type="button"
                  onClick={onClickMarkReviewedCallback}
                >
                    Mark as reviewed
                  <IonIcon icon={checkmarkOutline} />
                </IonButton>
              ) : null
            }
          </IonButtons>
        </IonToolbar>
      </QuestionDetail>
    </>

  )
}

const noop = (line_elem_key: string, ref: string, value: string, selected: boolean) => undefined

const QuestionDetail = styled.div`
  padding: 1rem;
`

const LoadBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
