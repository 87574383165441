import { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { SnapshotOut } from 'mobx-state-tree'

import { getUpcomingSessions } from 'util/api/sessions'
import { HydratedSession } from 'common/types/session'
import { useUser } from 'context/userContext'
import { Roles } from 'util/auth/helper'

export interface Session extends SnapshotOut<HydratedSession> {
  day: string,
  time: string,
  sessionStatus: string
}

export const decorate = (session:SnapshotOut<HydratedSession>):Session => {
  const sessionTime = DateTime.fromISO(session.session_date_time)
  const day = sessionTime.toFormat('ccc d MMM')
  const time = sessionTime.toFormat('HH:mm')

  let sessionStatus =
    session.cancelled
      ? session.rescheduled ? 'Rescheduled' :
        session.substituted ? 'Substituted' :
          'Cancelled'
      : session.paid ? 'Booked' :
        'Scheduled'

  if((sessionStatus === 'Booked' || sessionStatus === 'Scheduled')
    && session.session_date_time_end && DateTime.fromISO(session.session_date_time_end) < DateTime.now()
  ) {
    if (session.register) {
      const uniqueUsers = session.register.map((event) => {
        return event.user
      }).filter((value, index, self) => self.indexOf(value) === index)
      if (!uniqueUsers || uniqueUsers.length === 0) {
        sessionStatus = 'Missed(both)'
      } else {
        sessionStatus = uniqueUsers.find((user) => user === session.student_id)
          ? uniqueUsers.find((user) => user === session.tutor_id)
            ? 'Attended'
            : 'Missed(tutor)'
          : 'Missed(student)'
      }
    } else {
      sessionStatus = 'Missed (both)'
    }
  }

  return {
    ...session,
    day,
    time,
    sessionStatus
  }
}
export function useUpcomingSessions (tutor:string = undefined) {
  const { user } = useUser()
  const [sessions, set_sessions] = useState<Session[]>([])
  const [busy, set_busy] = useState(true)
  const [weeks, set_weeks] = useState(2)

  const refresh = async () => {
    if( user === undefined ) {
      set_sessions([])
      return
    }
    const selector = user.role === Roles.STUDENT ? { student_id: user._id }
      : user.role === Roles.PARENT ? {parent_id: user._id }
        : user.role === Roles.ADMIN ? {tutor_id: tutor }
          : { tutor_id: user._id }
    try{
      set_busy(true)
      const sessions = await Promise.all(Array.from({length: weeks}).map((_,week) =>
        getUpcomingSessions(selector, DateTime.now().plus({week}).toISO())
      ))
      set_sessions(sessions.flat().map(decorate))
    } finally {
      set_busy(false)
    }
  }

  useEffect(
    () => { refresh() },
    [user, weeks]
  )

  const load_next_week = async () => {
    if( busy ) { throw new Error('busy') }
    set_weeks(weeks => weeks + 1)
  }

  return { sessions, refresh, busy, load_next_week }
}
