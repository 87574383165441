import { useMemo, FC } from 'react'
import { useTable, useExpanded, useSortBy, useFilters } from 'react-table'
import { IonIcon, IonList, IonRadioGroup, IonRadio, IonItem, IonLabel, useIonPopover, IonButton } from '@ionic/react'
import { swapVerticalOutline, arrowDownOutline, arrowUpOutline, caretDownOutline } from 'ionicons/icons'
import styled from 'styled-components'

interface PopoverProps {
  onHide: () => void,
  options: string[],
  filterValue: string | undefined,
  setFilter: (string) => void
}

const PopoverList: FC<PopoverProps> = ({ onHide, options, filterValue, setFilter }) => (
  <IonList>
    <IonRadioGroup value={filterValue ?? 'All'} onIonChange={e => {
      setFilter(e.detail.value === 'All' ? undefined : e.detail.value)
      onHide()
    }}>
      <IonItem key='All' title='All'>
        <IonLabel>All</IonLabel>
        <IonRadio slot='start' value='All' />
      </IonItem>
      {options.map(option => (
        <IonItem key={option} title={option}>
          <IonRadio slot='start' value={option} />
          <IonLabel>{option}</IonLabel>
        </IonItem>
      ))}
    </IonRadioGroup>
  </IonList >
)

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    const values: any = options.values()
    return [...values]
  }, [id, preFilteredRows])

  const [present, dismiss] = useIonPopover(PopoverList, { onHide: () => dismiss(), options, filterValue, setFilter })

  return (
    <IonButton expand='block' onClick={e => present({ event: e.nativeEvent })}>
      {filterValue ?
        filterValue.length <= 10 ? filterValue : filterValue.substr(0, 9) + '...' :
        null}
      <IonIcon icon={caretDownOutline} />
    </IonButton>

  )
}

export const Table = ({ columns, data, RowSubComponent, update, initialFilters = [] }) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: SelectColumnFilter,
      filter: 'exactText'
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetExpanded: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      initialState: {
        filters: initialFilters,
      },
    }, useFilters, useSortBy, useExpanded)

  return (
    <Styled>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <>
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map(column => (
                  <th key={column.getHeaderProps().key} {...column.getHeaderProps()} >
                    <div className='label'>
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
              <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.getHeaderGroupProps().key}_filter`}>
                {headerGroup.headers.map(column => (
                  <th key={column.getHeaderProps().key} {...column.getHeaderProps()} >
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
              <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.getHeaderGroupProps().key}_sort`}>
                {headerGroup.headers.map(column => (
                  <th key={column.getHeaderProps().key} {...column.getHeaderProps()} {...column.getSortByToggleProps()}>
                    {column.canSort && <IonIcon icon={
                      column.isSorted
                        ? column.isSortedDesc
                          ? arrowDownOutline
                          : arrowUpOutline
                        : swapVerticalOutline
                    } />}
                  </th>
                ))}
              </tr>
            </>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map(row => {
              prepareRow(row)
              return (
                <>
                  <tr {...row.getRowProps()} {...row.getToggleRowExpandedProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                        {cell.render('Cell')}
                      </td>
                    )
                    )}
                  </tr>
                  {row.isExpanded &&
                    <tr {...row.getRowProps()} key={`${row.getRowProps().key}_detail`}>
                      <td colSpan={visibleColumns.length}>
                        <RowSubComponent row={row} update={update} />
                      </td>
                    </tr>
                  }
                </>
              )
            })
          }
        </tbody>
      </table>
    </Styled>
  )
}

const Styled = styled.div`
  --horizontal-padding: 1rem;
  overflow-x: scroll;
  padding-top: 1rem;
  height: 100%;
  overflow-y: hidden;
  table {
    th {
      background: var(--ion-color-primary);
      color: var(--ion-color-tertiary);
      padding-left: var(--horizontal-padding);
      :last-child {
        padding-right: var(--horizontal-padding);
      }
    }
    tbody {
      overflow-y: scroll;
      height: 100%;
      tr:nth-of-type(even) {
        background: var(--ion-color-light);
      }
      tr:nth-of-type(odd) {
        background: var(--ion-color-light-shade);
      }
      td {
        text-align: center;
        padding-left: var(--horizontal-padding);
        :last-child {
          padding-right: var(--horizontal-padding);
        }
      }
    }
  }
`
