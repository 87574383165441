import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { useEffect, useState } from 'react'
import { IonButton, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonToolbar, IonButtons, IonCardSubtitle, IonItem, IonInput, IonLabel } from '@ionic/react'
import { Availability } from 'components/tutor_availability/Availability'
import { AvailabilityLegend } from 'components/tutor_availability/AvailabilityLegend'
import { DateTime } from 'luxon'
import { getTutorAvailability, setTutorAvailability } from 'util/api/tutor/availabilty'
import { useUser } from 'context/userContext'
import { AvailabilitySnapshot } from 'common/types/tutor/availability_status'
import { mapValues } from 'lodash'
import { AvailabilityPicker } from 'components/tutor_availability/Picker'

const empty_availability = {
  0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [],
}

export type TimeSlotMap = { [key: number]: DateTime[] }

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  const { user } = useUser()

  const [ideal_hours, set_ideal_hours] = useState(2)
  const [availability, set_availability] = useState<AvailabilitySnapshot>({
    0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [],
  })
  const [editting, set_editting] = useState(false)
  const [adder_visible, set_adder_visible] = useState(false)

  const reset = () => {
    if (!user) { return }
    set_editting(false)
    set_adder_visible(false)

    const f = async () => {
      try {
        const { availability, ideal_hours } = await getTutorAvailability(user._id)
        set_ideal_hours(ideal_hours || 2)
        set_availability(availability)
      } catch (err) {
        set_ideal_hours(2)
        set_availability(empty_availability)
        console.error(err)
      }
    }
    f()
  }

  useEffect(reset, [user])

  const add_availability = ({ day, from_time, until_time }: {
    day: number,
    from_time: number,
    until_time: number,
  }) => {
    set_adder_visible(false)
    set_availability(availability => {
      const existing_day_availability = availability[day]
      const new_availability = []
      for (let t = 0; t < 24 * 60; t += 15) {
        const existing_slot = existing_day_availability.find(slot => slot.time === t)
        if (t >= from_time && t < until_time) {
          if (existing_slot !== undefined) {
            new_availability.push({
              ...existing_slot,
              deleted: false
            })
          } else {
            new_availability.push({
              day_of_week: day,
              time: t,
              busy: false
            })
          }
        }
        else {
          if (existing_slot !== undefined) {
            new_availability.push(existing_slot)
          }
        }
      }
      return ({
        ...availability,
        [day]: new_availability
      })
    })
  }

  const removeTimeSlot = (day: number, time: number) => () => {
    set_availability(availability => ({
      ...availability,
      [day]: availability[day].map(a => ({
        ...a,
        deleted: a.time === time ? true : a.deleted
      }))
    }))
  }

  const saveAvailability = async () => {
    try {
      await setTutorAvailability(user._id, { availability, ideal_hours })
      set_editting(false)
    } catch (err) {
      console.error(err.errors)
    }
  }

  const availabilities = mapValues(availability, slots => slots.filter(slot => !slot.deleted))

  return (
    <PlytimePage roles={roles}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Your availability
          </IonCardTitle>
          <IonCardSubtitle>
            <AvailabilityLegend />
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <Availability availability_times={availabilities} remove_handler={editting ? removeTimeSlot : undefined} />
          <IonItem lines='inset'>
            <IonLabel position='stacked'>Ideal hours</IonLabel>
            <IonInput
              type='number'
              value={ideal_hours}
              readonly={!editting}
              max={'40'}
              min={'2'}
              onIonInput={e => set_ideal_hours(Number.parseInt((e.target as any).value))}
              id='ideal_hours'
            />
          </IonItem>
          <IonToolbar>
            {editting && <>
              <IonButtons slot='start'>
                <IonButton
                  size='small'
                  color='secondary'
                  fill='solid'
                  className='ion-margin-top'
                  onClick={() => set_adder_visible(true)}
                >
                  Add
                </IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton
                  size='small'
                  color='secondary'
                  fill='solid'
                  className='ion-margin-top'
                  onClick={reset}
                >
                  Reset
                </IonButton>
                <IonButton
                  size='small'
                  color='primary'
                  fill='solid'
                  className='ion-margin-top'
                  onClick={saveAvailability}
                  id='save'
                >
                  Save
                </IonButton>
              </IonButtons>
            </>}
            {!editting && <>
              <IonButtons slot='end'>
                <IonButton
                  size='small'
                  color='secondary'
                  fill='solid'
                  className='ion-margin-top'
                  onClick={() => set_editting(true)}
                  id='edit'
                >
                  Edit
                </IonButton>
              </IonButtons>
            </>}
          </IonToolbar>
          {adder_visible && <AvailabilityPicker submit={add_availability} cancel={() => set_adder_visible(false)} />}
        </IonCardContent>
      </IonCard>
    </PlytimePage>
  )
}
