import { FC, useState, useEffect } from 'react'
import { IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react'
import { getTutorAvailability } from 'util/api/tutor/availabilty'
import { Availability } from './Availability'
import { AvailabilityLegend } from 'components/tutor_availability/AvailabilityLegend'
import { Tutor } from 'common/types/tutor/tutor'
import { SnapshotOut } from 'mobx-state-tree'
import { AvailabilitySnapshot } from 'common/types/tutor/availability_status'
import { mapValues } from 'lodash'

const empty_availability = {
  0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [],
}

interface Props {
  tutor: SnapshotOut<typeof Tutor>
  filter_day: number
  filter_start_time: number
  filter_end_time: number
  filter_session_status?: boolean
}

export const FilteredAvailability:FC<Props> = ({ tutor, filter_day, filter_start_time, filter_end_time, filter_session_status }) => {
  const [tutor_availability, set_tutor_availability] = useState<AvailabilitySnapshot>(empty_availability)

  useEffect(() => {
    const f = async () => {
      try {
        const { availability } = await getTutorAvailability(tutor._id)
        set_tutor_availability(availability)
      } catch (e) {
        console.dir(e)
        set_tutor_availability(empty_availability)
      }
    }
    f()
  }, [tutor._id])

  const filtered_availability = mapValues(
    tutor_availability,
    availability => availability.filter(
      slot => 
        (filter_day === -1 || filter_day === slot.day_of_week) &&
        filter_start_time <= slot.time && 
        filter_end_time > slot.time &&
        (filter_session_status === undefined || filter_session_status === slot.busy)
    )
  )

  const has_slots: boolean = Object.values<any>(filtered_availability).reduce((acc, slots) => acc || slots.length > 0, false)
  if (!has_slots) return null
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          {`${tutor.first_name} ${tutor.last_name}`}
        </IonCardTitle>
        <IonCardSubtitle>
          <AvailabilityLegend />
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <Availability availability_times={filtered_availability} />
      </IonCardContent>
    </IonCard>
  )
}
