import { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Redirect } from 'react-router-dom'
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent } from '@ionic/react'

import { PublicPlytimePage } from 'components/public_plytime_page'
import { useUser } from 'context/userContext'
import { Roles } from 'util/auth/helper'

const StyledIonCard = styled(IonCard)`
  cursor: pointer;
  height: 15em;
  width: 20em;
`
const FlowContainer = styled.div`
  maxWidth: 50em;
  display: flow;
  justify-content: center;
  flex-wrap: wrap;
`

interface PageProps {
  location?: {
    search?: string
  }
}
const SelectAccountTypePage: FC<PageProps> = observer(({ location }) => {

  const { role, change_role, in_progress } = useUser()

  const login = (role: Roles) => {
    change_role(role)
  }
  const admin = location?.search && new URLSearchParams(location.search).get('admin')

  return role !== undefined && !in_progress
    ? <Redirect to={`/signin/${role.toLowerCase()}`} />
    : <PublicPlytimePage>
      <FlowContainer>
        <StyledIonCard button={true} color='primary' onClick={() => login(Roles.PARENT)}>
          <IonCardHeader>
            <IonCardSubtitle>Parents</IonCardSubtitle>
            <IonCardTitle>Parent Login</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Are you a parent looking to help your children achieve academic goals? Click here to sign up and login
          </IonCardContent>
        </StyledIonCard>
        <StyledIonCard button={true} color='primary' onClick={() => login(Roles.STUDENT)}>
          <IonCardHeader>
            <IonCardSubtitle>Students</IonCardSubtitle>
            <IonCardTitle>Student Login</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Have a student account? Click here to play and learn.
          </IonCardContent>
        </StyledIonCard>
        <StyledIonCard button={true} color='primary' onClick={() => login(Roles.TUTOR)}>
          <IonCardHeader>
            <IonCardSubtitle>Tutors</IonCardSubtitle>
            <IonCardTitle>Tutor Login</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
            Are you a tutor? Login here.
          </IonCardContent>
        </StyledIonCard>
        {admin &&
          <StyledIonCard button={true} color='primary' onClick={() => login(Roles.ADMIN)}>
            <IonCardHeader>
              <IonCardSubtitle>Staff</IonCardSubtitle>
              <IonCardTitle>Admin Login</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
              PLYTIME staff login
            </IonCardContent>
          </StyledIonCard>
        }
      </FlowContainer>
    </PublicPlytimePage>
})

export default SelectAccountTypePage

