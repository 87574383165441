import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { ScoreDistribution } from 'common/types/question/score_distribution'
import { range } from 'lodash'
import styles from './question_distribution.module.css'

interface Props {
  score_distribution: ScoreDistribution
}

export const ScoreDistributionPlot: FC<Props> = observer(({ score_distribution }) => {
  const [first, last, peak] = score_distribution.reduce(
    ([first, last, peak], sd) => [
      first > 0 ? Math.min(first, sd.score) : sd.score,
      Math.max(last, sd.score),
      Math.max(peak, sd.occurs)
    ],
    [0, 0, 1]
  )

  const scores = range(first, last + 1).map(i => {
    const score = score_distribution.find(s => s.score === i) || { score: i, occurs: 0 }
    return {
      ...score,
      percent: 100 * score.occurs / peak
    }
  })

  return (
    <label>
      Score Distribution
      <div className={styles.plot_container}>
        {scores.map(s =>
          <div key={s.score} className={styles.plot}>
            <div className={styles.label}>{s.occurs > 0 && s.score}</div>
            <div className={styles.bar_container}>
              <div className={styles.bar} style={{ flex: `0 0 ${s.percent}px` }} />
            </div>
          </div>
        )
        }
      </div>
    </label>
  )
}) 
