import { IonSelect, IonSelectOption, IonButton, IonText } from '@ionic/react'
import { FC, FormEvent, useState } from 'react'
import { reset_student_score } from 'util/api/admin/resetStudentScore'
import { SupportedSchoolYearRange } from 'common/types/basic/school_year_int'
import { relative_levels, RelativeLevel } from 'common/types/student/relative_levels'
import { SchoolYearInteger } from 'common/types/basic/school_year_int'

type ResetStudentScoreModalProps = {
  studentId: string,
  subjectId: string,
  school_year: SchoolYearInteger,
  relative_level: RelativeLevel
  onScoreReset: () => void,
}

const ResetStudentScoreModal: FC<ResetStudentScoreModalProps> = ({studentId, subjectId, school_year, relative_level, onScoreReset }) => {
  
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SchoolYearInteger>()
  const [selectedRelativeLevel, setSelectedRelativeLevel] = useState<RelativeLevel>()

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await reset_student_score(studentId, subjectId, 
      selectedRelativeLevel, selectedSchoolYear)
    onScoreReset()
  }

  return (
    <div style={{justifyContent: 'normal'}}>
      <form onSubmit={handleSubmit}>
        <IonText>School year</IonText>
        <IonSelect
          placeholder={school_year.toString()}
          onIonChange={(e) => setSelectedSchoolYear(e.detail.value)}>
          {SupportedSchoolYearRange.map((year) =>
            <IonSelectOption
              key={year}
              value={year}
            >
              {year}
            </IonSelectOption>
          )}
        </IonSelect>
        <IonText>Students relative level</IonText>
        <IonSelect
          placeholder={relative_level}
          onIonChange={(e) => setSelectedRelativeLevel(e.detail.value)}
        >
          {relative_levels.map((level) => 
            <IonSelectOption key={level} value={level}>{level}</IonSelectOption>
          )}
        </IonSelect>
        <IonButton
          type='submit'
          disabled={!selectedSchoolYear || !selectedRelativeLevel}
        >
          Reset Score
        </IonButton>
      </form>
    </div>
  )

}

export default ResetStudentScoreModal
