import { Instance, types, SnapshotOut } from 'mobx-state-tree'
import { NonEmptyString } from 'common/types/basic/non_empty_string'
import { Gender } from 'common/types/basic/gender'
import { DateString } from 'common/types/basic/date_string'
import { SchoolYearInteger } from 'common/types/basic/school_year_int'

export const NewStudent = types.model('Student',{
  username: NonEmptyString,
  first_name: NonEmptyString,
  last_name: NonEmptyString,
  dob: DateString,
  gender: types.maybe(Gender),
  school: types.string,
  school_year: SchoolYearInteger,
})
export type NewStudent = Instance<typeof NewStudent>

export const StudentPostRequest = types.model(
  'Student',
  {
    body: NewStudent
  }
)
export type StudentPostRequest = SnapshotOut<typeof StudentPostRequest>

export const Student = NewStudent
  .named('Student')
  .props({
    _id: NonEmptyString,
    uid: NonEmptyString,
    parent_id: NonEmptyString,
  })
export type Student = SnapshotOut<typeof Student>
