
import { FC } from 'react'
import { IonIcon, IonSegment, IonSegmentButton } from '@ionic/react'
import { LocalVideoTrack } from 'twilio-video'
import { Card, Content, ProfilePicture, Controls, SideVideo } from 'components/sessions/focus15'
import { micOutline, micOffOutline, videocamOutline, videocamOffOutline } from 'ionicons/icons'
import { observer } from 'mobx-react-lite'
import { Video } from 'components/sessions/focus15/Video'

interface Props {
  track: LocalVideoTrack,
  audio_enabled: boolean,
  toggle_audio: (value:'on'|'off') => void,
  video_enabled: boolean,
  toggle_video: (value:'on'|'off') => void,
}

export const LocalVideoCard: FC<Props> = observer(({track, video_enabled, toggle_video, audio_enabled, toggle_audio}) => {
  return (
    <Card>
      <Content>
        <SideVideo>
          {track ? <Video track={track} style={{ borderRadius:'50%', objectFit:'contain', maxHeight: '100%', maxWidth: '100%'}}/> : <ProfilePicture/> }
        </SideVideo>
        <Controls>
          <IonSegment 
            onIonChange={(e) => {
              console.log(`video controls changed ${video_enabled} ${e.detail.value}`)
              if( e.detail.value === 'on' || e.detail.value === 'off') {
                toggle_video(e.detail.value)
              }
            }}
            value={video_enabled ? 'on' : 'off'}
            disabled={!track}
          >
            <IonSegmentButton value={'on'} style={{minWidth: 0}}>
              <IonIcon icon={videocamOutline}/>
            </IonSegmentButton>
            <IonSegmentButton value={'off'} style={{minWidth: 0}}>
              <IonIcon icon={videocamOffOutline}/>
            </IonSegmentButton>
          </IonSegment>
          <IonSegment 
            onIonChange={(e) => {
              console.log(`audio controls changed ${audio_enabled} ${e.detail.value}`)
              if( e.detail.value === 'on' || e.detail.value === 'off') {
                toggle_audio(e.detail.value)
              }
            }}
            value={audio_enabled ? 'on' : 'off'}
            disabled={!track}
          >
            <IonSegmentButton value={'on'} style={{minWidth: 0}}>
              <IonIcon icon={micOutline}/>
            </IonSegmentButton>
            <IonSegmentButton value={'off'} style={{minWidth: 0}}>
              <IonIcon icon={micOffOutline}/>
            </IonSegmentButton>
          </IonSegment>
        </Controls>
      </Content>
    </Card>
  )
})

