import { FC, useState } from 'react'
import { IonModal, IonButton, IonRow, IonCol} from '@ionic/react'
import ResetStudentScoreModal from './ResetStudentScoreModal'
import { ListStudentSubjectScoreItem } from 'common/types/student/resetStudentScore'

type StudentScoreRowProps = {
  studentScore: ListStudentSubjectScoreItem,
  resetCallback: () => void,
}

const StudentScoreRow: FC<StudentScoreRowProps> = ({studentScore, resetCallback}) => {
  
  const [showResetScoreModal, setShowResetScoreModal] = useState(false)

  const onResetClick = () => {
    setShowResetScoreModal(false)
    resetCallback()
  }
  
  return (
    <IonRow key={studentScore._id} className='alternate-light-medium'>
      <IonCol size='2'>{studentScore.student_id}</IonCol>  
      <IonCol size='1.5'>
        {studentScore.student_first_name} {studentScore.student_last_name}
      </IonCol>  
      <IonCol size='2'>{studentScore.parent_email}</IonCol> 
      <IonCol size='2'>{studentScore.tutor_first_name} {studentScore.tutor_last_name}</IonCol> 
      <IonCol size='1.5'>{studentScore.subject_name}</IonCol>
      <IonCol size='1'>{studentScore.score}</IonCol>  
      <IonCol size='2'>
        <IonButton
          onClick={() => setShowResetScoreModal(true)}
          size='small'
          color='light'>
                Reset Score
        </IonButton>
      </IonCol>
      <IonModal
        isOpen={showResetScoreModal}
        onDidDismiss={() => setShowResetScoreModal(false)}
      >
        <ResetStudentScoreModal 
          studentId={studentScore.student_id}
          subjectId={studentScore.subject_id}
          school_year={studentScore.school_year}
          relative_level={studentScore.relative_level}   
          onScoreReset={onResetClick}
        />
      </IonModal>
    </IonRow>
  )
}

export default StudentScoreRow
