import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Layout, Main, Sidebar, Time, Join, Local, Remote, Screen, Messages } from 'components/sessions/focus15'
import { useState, useEffect, FC } from 'react'
import { IonCardContent, IonSegment, IonSegmentButton, IonIcon, IonLabel, IonButton } from '@ionic/react'
import { Status, StudentSessionStore } from 'types/session/student_session'
import { SessionTimer } from 'components/sessions/focus15/Timer'
import { LocalVideoCard } from 'components/sessions/focus15/LocalVideoCard'
import { ScreenShareCard } from 'components/sessions/focus15/ScreenShareCard'
import { Audio } from 'components/sessions/focus15/Audio'
import { RemoteVideoCard } from 'components/sessions/focus15/RemoteVideoCard'
import { trendingUpOutline, optionsOutline, bookOutline, easelOutline } from 'ionicons/icons'
import { Notes } from 'components/notes'
import { CurrentStudentPerformance } from 'components/stats/CurrentStudentPerformance'
import { StudentTrendPerformance } from 'components/stats/StudentTrendPerformance'
import { QuestionReview, TutorDetailView } from 'components/question_review'

interface IProps {
  roles: Roles[]
}

export const TutorSessionPage: FC<IProps> = observer(function ({ roles }) {

  const { session_id } = useParams<{ session_id: string }>()
  const [tab, set_tab] = useState<'trend' | 'focus' | 'answers' | 'whiteboard'>('trend')
  const [initialAnswerFilters, setInitialAnswerFilters] = useState([])


  const [{
    disconnect,
    get_session,
    join_session,
    local_audio_enabled,
    local_video,
    local_video_enabled,
    local_screen,
    remote_audio,
    remote_video,
    session,
    student_status,
    toggle_local_audio,
    toggle_local_video,
    toggle_screen_share,
  }] = useState(StudentSessionStore.create({}))

  useEffect(() => {
    console.log('Tutor opening session')
    get_session(session_id)
    window.addEventListener('beforeunload', disconnect)
    return () => {
      console.log('Tutor disconnecting')
      disconnect()
      window.removeEventListener('beforeunload', disconnect)
    }
  }, [session_id])
  return (
    <PlytimePage roles={roles} fullwidth>
      <Layout>
        <Main>
          {session !== undefined
            ? <>
              <IonSegment
                onIonChange={e => set_tab(e.detail.value as any)}
                value={tab}
              >
                <IonSegmentButton value={'trend'} style={{ minWidth: 0 }}>
                  <IonIcon icon={trendingUpOutline} />
                  <IonLabel>Trend</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={'focus'} style={{ minWidth: 0 }}>
                  <IonIcon icon={optionsOutline} />
                  <IonLabel>Focus</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={'answers'} style={{ minWidth: 0 }}>
                  <IonIcon icon={bookOutline} />
                  <IonLabel>Answers</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value={'whiteboard'} style={{ minWidth: 0 }}>
                  <IonIcon icon={easelOutline} />
                  <IonLabel>Whiteboard</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              {tab === 'trend' &&
                <StudentTrendPerformance student_id={session.student_id} subject_id={session.subject._id} changeTab={set_tab} setInitialAnswerFilters={setInitialAnswerFilters}/>
              }
              {tab === 'focus' &&
                <CurrentStudentPerformance student_id={session.student_id} />
              }
              {tab === 'answers' &&
                <QuestionReview student_id={session.student_id} AnswerDetailComponent={TutorDetailView} initialFilters={initialAnswerFilters}/>
              }
              {tab === 'whiteboard' &&
                <iframe src='https://sketch.io/sketchpad/' width='100%' height='100%' />
              }
            </>
            : student_status === Status.LOADING
              ? <p>Loading...</p>
              : <p>Error: No such session</p>
          }
        </Main>
        <Sidebar>
          <Time>
            <SessionTimer session={session} remote_name={session => session.student.first_name} />
          </Time>
          <Remote>
            {student_status === Status.READY && session.paid
              ? <Join><IonButton onClick={() => join_session(session_id)}>Join</IonButton></Join>
              : <RemoteVideoCard
                name={session ? session.student.first_name : 'Loading...'}
                track={remote_video}
              />
            }
          </Remote>
          <Local>
            <LocalVideoCard
              track={local_video}
              audio_enabled={local_audio_enabled}
              toggle_audio={toggle_local_audio}
              toggle_video={toggle_local_video}
              video_enabled={local_video_enabled}
            />
          </Local>
          <Screen>
            <ScreenShareCard
              track={local_screen}
              toggle_screen={toggle_screen_share}
              disabled={student_status !== Status.ACTIVE}
            />
          </Screen>
          <Audio
            track={remote_audio}
          />
          <Messages>
            <IonCardContent>
              {session && <Notes student={session.student_id} subject={session.subject._id} />}
            </IonCardContent>
          </Messages>
        </Sidebar>
      </Layout>
    </PlytimePage>
  )
})

export default TutorSessionPage
