import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonText,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { FC, useState, MouseEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import SubscriptionWizardState from './SubscriptionWizardState'
import { ApiError } from 'types/ApiError'

interface Props {
  state: Instance<typeof SubscriptionWizardState>
  on_success: () => void
  close: () => void
}

export const Payment: FC<Props> = observer(({ state, on_success, close }) => {
  const [status, set_status] = useState<'ready' | 'subscribing' | 'success' | 'error'>('ready')
  const [error_message, set_error_message] = useState('')

  const checkout = async (event: MouseEvent<HTMLIonButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (status !== 'ready') {
      console.error('Double call to subscribe')
      return
    }
    set_status('subscribing')
    try {
      const { redirect_url } = await state.subscribe(window.location.href)
      window.location.assign(redirect_url)
    } catch (err) {
      if ( err instanceof ApiError ) {
        set_error_message(err.errors.join('\n'))
      } else {
        set_error_message('Oops! something went wrong. Please check your bank statement and contact support if you have been charged')
      }
      set_status('error')
      console.error(err)
    }
  }

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Review</IonCardTitle>
          <IonCardSubtitle>Please double check your subscription details</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <p>You are subscribing <b><IonText color='primary'>{state.student.first_name}</IonText></b> to PLYTIME Learning for <b><IonText color='primary'>{state.subject.name}</IonText></b></p>
          <p>You have added <b><IonText color='primary'>{state.session_count()}</IonText></b> X <b><IonText color='primary'>15</IonText></b> minutes Focus 15{state.session_count() === 1 ? '' : '\'s'}® per week</p>
          <p>Normally costing <b><IonText color='primary'>{state.cost()}</IonText></b> per week</p>
          <p>By subscribing you agree to the</p>
          <ul>
            <li><a href='https://plytime.com/terms-and-conditions/' target='_blank' rel='noreferrer'>Terms and Conditions</a></li>
            <li><a href='https://plytime.com/privacy-policy/' target='_blank' rel='noreferrer'>Privacy policy</a></li>
            <li><a href='https://plytime.com/safeguarding-policy/' target='_blank' rel='noreferrer'>Safeguarding policy</a></li>
          </ul>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Payment</IonCardTitle>
          <IonCardSubtitle>Your payment will be processed securely by <a href='https://stripe.com' target='_blank' rel='noreferrer'>Stripe</a></IonCardSubtitle>
        </IonCardHeader>
      </IonCard>
      <IonToolbar>
        <IonButtons slot='end'>
          <IonButton
            fill='solid'
            size='small'
            color='primary'
            className="ion-margin-top"
            onClick={checkout}
            disabled={status !== 'ready'}
          >
            Confirm and Pay
          </IonButton>
        </IonButtons>
        <IonButtons slot='start'>
          <IonButton
            fill='outline'
            size='small'
            color='tertiary'
            className="ion-margin-top"
            onClick={state.back}
          >
            Back
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonToast
        color={'success'}
        isOpen={status === 'success'}
        onDidDismiss={() => {
          on_success()
        }}
        message="Thankyou, you have subscribed"
        position="middle"
        buttons={[{
          text: 'dismiss',
          role: 'cancel'
        }]}
      />
      <IonToast
        color={'danger'}
        isOpen={status === 'error'}
        onDidDismiss={() => {
          set_status('ready')
          set_error_message('')
        }}
        message={`Error:\n${error_message}`}
        position="middle"
        buttons={[{
          text: 'dismiss',
          role: 'cancel'
        }]}
      />
    </>
  )
})
