import { FC, ChangeEvent, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { AnswerElement } from 'common/types/question/question'
import { AllowedAnswers } from 'common/types/question/answer/answer'
import { useStore } from 'types/store'
import { IonText } from '@ionic/react'
import { CoordinateInputElement } from 'common/types/question/elements/coordinate'
import { ColumnContainer, Container, Input } from 'generic_components/AnswerInputContainers'

interface Props {
  element: Instance<CoordinateInputElement>,
  handleChange: (e: ChangeEvent) => void,
  answer_decision: string,
  correct_answers: Instance<AllowedAnswers>,
  value: string,
  element_id: string,
  auto_focus_element?: Instance<AnswerElement>,
}

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

const parseNumber = (value: string): string => value.replaceAll(/[^0-9.-]/g, '')

const parseCoordinate = (coordinate: string): [string, string] => {
  const [x,y] = (coordinate !== '') ? coordinate.replaceAll(/[( )]/g, '').split(',') : ['','']
  return [x,y]
}

export const CoordinateInputComponent: FC<Props> = observer(({ element, handleChange, answer_decision, correct_answers, value, element_id, auto_focus_element }) => {
  const {game, auth: { user }} = useStore()
  
  const input_x_ref = useRef(null)
  const input_y_ref = useRef(null)

  useEffect(() => {
    if( element === auto_focus_element ) {
      input_x_ref.current.focus()
    }
  }, [element])
  
  const [x_value, y_value] = parseCoordinate(value)

  const [sizeX, sizeY] = [calculateLength(x_value), calculateLength(y_value) ]

  const [x_answer, y_answer] = (correct_answers.length > 0 && answer_decision !== 'CORRECT') 
    ? parseCoordinate(correct_answers[0].find(answerPart => answerPart.ref === element.ref).values[0])
    : ['','']

  const inputChangeX = (e) => {
    e.target.value = `(${parseNumber(e.target.value)},${y_value})`
    handleChange(e)
  }

  const inputChangeY = (e) => {
    e.target.value = `(${x_value},${parseNumber(e.target.value)})`
    handleChange(e)
  }

  return (
    <ColumnContainer>
      <Container>
        <IonText>(</IonText>
        <Input
          id={`${element_id}_x`}
          onChange={(e) => inputChangeX(e) }
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={x_value}
          size={sizeX}
          ref={input_x_ref}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if(x_value !== '' && y_value !== '') {
                game.submit_answer(user._id)
              } else {
                input_y_ref.current.focus()
              }
            }
          }}
        />
        <IonText> , </IonText>
        <Input
          id={`${element_id}_y`}
          onChange={(e) => inputChangeY(e)}
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={y_value}
          size={sizeY}
          ref={input_y_ref}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if(x_value !== '' && y_value !== '') {
                game.submit_answer(user._id)
              } else {
                input_x_ref.current.focus()
              }
            }
          }}
        />
        <IonText>)</IonText>

      </Container>
      {answer_decision !== 'CORRECT' && x_answer !== '' && y_answer !== '' && (
        <Container>
          <IonText>(</IonText>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={x_answer}
            size={x_answer.length}
          />
          <IonText> , </IonText>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={y_answer}
            size={y_answer.length}
          />
          <IonText>)</IonText>
        </Container>
      )}
    </ColumnContainer>
  )

})
