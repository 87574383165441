import { FC } from 'react'
import { IonButton } from '@ionic/react'

import { useUser } from '../context/userContext'
import { Roles } from 'util/auth/helper'

const link_lookup = {
  [Roles.STUDENT]: 'https://plytime.com/student-plytime-help-videos/',
  [Roles.PARENT]: 'https://plytime.com/plytime-parent-help-videos/',
  [Roles.TUTOR]: 'https://plytime.com/tutor-plytime-help-videos/'
}

const HowToButton: FC = () => {
  const { user } = useUser()
  if (!Object.keys(link_lookup).includes(user.role)) return null
  return <IonButton href={link_lookup[user.role]} target='_blank'>How-To</IonButton>
}

export default HowToButton
