import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { StudentId } from './id'
import { SubjectId } from '../subject/id'
import { NonEmptyString } from '../basic/non_empty_string'
import { NumberFromStringOrNumber } from '../basic/number_from_string_or_number'
import { RelativeLevels } from './relative_levels'
import { SchoolYearInteger } from '../basic/school_year_int'

export const ResetStudentScoreRequest = types.model({
  body: types.model({
    student_id: StudentId,
    subject_id: SubjectId,
    school_year: SchoolYearInteger,
    relative_level: RelativeLevels
  })
})
export type ResetStudentScoreRequest = typeof ResetStudentScoreRequest

export const ResetStudentScoreResponse = types.model({
  student_id: StudentId,
  subject_id: SubjectId,
  score: types.number,
})
export type ResetStudentScoreResponse = typeof ResetStudentScoreResponse

export const ListStudentSubjectScoreItem = types.model(
  'StudentScore',{
    _id: NonEmptyString,
    student_id: StudentId,
    student_first_name: NonEmptyString,
    student_last_name: NonEmptyString,
    school_year: SchoolYearInteger,
    relative_level: RelativeLevels,
    score: types.number,
    tutor_first_name: NonEmptyString,
    tutor_last_name: NonEmptyString,
    parent_email: NonEmptyString,
    subject_id: SubjectId,
    subject_name: NonEmptyString,
  }
)
export type ListStudentSubjectScoreItem = Instance<typeof ListStudentSubjectScoreItem>

export const StudentScoreSearchFilter = types.model('StudentScoreSearchFilter', {
  _from: NumberFromStringOrNumber,
  _limit: NumberFromStringOrNumber,
  studentId: types.optional(types.string, ''),
})
export type StudentScoreSearchFilter = SnapshotOut<typeof StudentScoreSearchFilter>


export const StudentScoreListRequest = types.model('StudentScoreListRequest',{
  query: StudentScoreSearchFilter
})
export type StudentScoreListRequest = SnapshotOut<typeof StudentScoreListRequest>

export const StudentScoreListResponse = types.array(ListStudentSubjectScoreItem)
export type StudentScoreListResponse = Instance<typeof StudentScoreListResponse>
