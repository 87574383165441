import { Instance, types } from 'mobx-state-tree'
import { ScoreDistribution } from 'common/types/question/score_distribution'
import { PositiveInteger } from 'common/types/basic/positive_int'
import { Score } from 'common/types/question/score'
import { SubjectId } from 'common/types/subject/id'
import { ThemeTree } from 'common/types/subject/theme'
import { StudentId } from 'common/types/student/id'

export const BaseSubject = types
  .model('BaseSubject', {
    _id: SubjectId,
    name: types.string,
  })
export type BaseSubject = typeof BaseSubject

export const SubjectTree = BaseSubject
  .named('SubjectTree')
  .props({
    themes: types.array(ThemeTree),
  })
export type SubjectTree = Instance<typeof SubjectTree>

export const SubjectForest = types.array(SubjectTree)

export const AdminSubject = BaseSubject
  .named('AdminSubject')
  .props({
    min_score: Score,
    max_score: Score,
    question_distribution: ScoreDistribution,
    question_count: PositiveInteger,
    endcode_count: PositiveInteger,
    topic_count: PositiveInteger,
    theme_count: PositiveInteger,
  })
export type AdminSubject = Instance<typeof AdminSubject>

export const AdminSubjects = types.array(AdminSubject)
export type AdminSubjects = Instance<typeof AdminSubjects>

export const SubjectsGetRequest = types.model({
})
export type SubjectsGetRequest = typeof SubjectsGetRequest

export const SubjectGetRequest = types.model({
  params: types.model({
    subject_id: SubjectId
  })
})
export type SubjectGetRequest = typeof SubjectGetRequest

export const AchievableSubjectRequest = types.model({
  query: types.model({
    student: StudentId
  })
})
export type AchievableSubjectRequest = typeof AchievableSubjectRequest

export const AchievableSubjectResponse = types.array(BaseSubject)
export type AchievableSubjectResponse = typeof AchievableSubjectResponse

export const SubjectPostRequest = types.model({
  body: AdminSubject
})
export type SubjectPostRequest = typeof SubjectPostRequest
