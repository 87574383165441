import { FC } from 'react'
import { IonImg, IonLabel, IonContent, IonHeader, IonPage, IonToolbar, IonButtons, IonButton, IonTitle, IonLoading } from '@ionic/react'
import { Unauthorised } from 'components/unauthorised'
import { observer } from 'mobx-react-lite'
import { checkPermission, Roles, useSignout } from 'util/auth/helper'
import { Logo } from 'components/Logo'
import styled from 'styled-components'
import { defaultProfileImage } from 'util/image'
import { env_message } from 'util/config'
import HomeButton from './HomeButton'
import HowToButton from './HowToButton'
import { AmplifyS3Image } from '@aws-amplify/ui-react'
import { useStore } from 'types/store'

interface Props {
  roles: Roles[]
  title?: string
  fullwidth?: boolean
  content_padding?: string
}

export const PlytimePage: FC<Props> = observer(({ roles = [], title = 'Welcome to PLYTIME', children, fullwidth = false, content_padding = 'ion-padding' }) => {
  const { auth: { user, in_progress } } = useStore()

  const logout = useSignout()

  const { permitted } = checkPermission(user, roles)

  return <Page>
    <IonHeader>
      <IonToolbar color='primary'>
        <IonButtons slot='start' color='primary'>
          <Logo />
        </IonButtons>
        {env_message &&
          <IonTitle class='ion-text-center'>
            {env_message}
          </IonTitle>
        }
        <IonTitle class='ion-text-center'>{title}</IonTitle>
        <IonButtons slot='end' color='primary'>
          <IonLabel>{user.displayName}</IonLabel>
          {user.profile_picture ? <AmplifyS3ImageProfilePicture imgKey={user.profile_picture} key={user.profile_picture} /> :
            <DefaultProfilePicture src={defaultProfileImage} />
          }
          <HomeButton />
          {user.role === 'ADMIN' && <IonButton routerLink='/admin/tutors'>Tutors</IonButton>}
          <HowToButton />
          <IonButton onClick={logout}>Sign out</IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonLoading
      cssClass='my-custom-class'
      isOpen={in_progress}
      message={'Please wait...'}
    />
    <Content className={content_padding} fullwidth={fullwidth}>
      {permitted && children}
      {!permitted && !in_progress && <Unauthorised />}
    </Content>
  </Page >
})

const Page = styled(IonPage)`
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;

  background-color: #000033;
  background-image: url("./images/stars.png");
  background-repeat: no-repeat;
  background-size: cover;
`

const Content = styled(IonContent)`
  max-width: ${props => props.fullwidth ? '100vw' : '60em'};
`

const DefaultProfilePicture = styled(IonImg)`
   width:2em;
   height: 2em;
   padding: 1px;
   margin-left: 10px;
`
const AmplifyS3ImageProfilePicture = styled(AmplifyS3Image)`
   --width:2em;
   --height: 2em;
   padding: 1px;
   margin-left: 10px;
`
