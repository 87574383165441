import { PlytimePageStudentBackground } from 'components/plytime_page_student_background'
import { Roles } from 'util/auth/helper'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from '@ionic/react'
import { useUpcomingSessions } from 'components/sessions/useUpcomingSessions'
import styled from 'styled-components'

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  const { sessions, busy, load_next_week } = useUpcomingSessions()

  return (
    <PlytimePageStudentBackground roles={roles}>
      <Container>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Upcoming sessions
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow style={{ height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast' }}>
                <IonCol size='2'>
                  <IonLabel style={{ height: '100%' }}> Date </IonLabel>
                </IonCol>
                <IonCol size='1'>
                  <IonLabel style={{ height: '100%' }}> Time </IonLabel>
                </IonCol>
                <IonCol size='2'>
                  <IonLabel style={{ height: '100%' }}> Subject </IonLabel>
                </IonCol>
                <IonCol size='2'>
                  <IonLabel style={{ height: '100%' }}> Tutor </IonLabel>
                </IonCol>
                <IonCol size='2'>
                  <IonLabel style={{ height: '100%' }}> Status </IonLabel>
                </IonCol>
              </IonRow>
              {sessions.map(session => {
                return (
                  <IonRow key={session.session_date_time} className='alternate-light-medium'>
                    <IonCol size='2'>
                      {session.day}
                    </IonCol>
                    <IonCol size='1'>
                      {session.time}
                    </IonCol>
                    <IonCol size='2'>
                      {session.subject.name}
                    </IonCol>
                    <IonCol size='2'>
                      {session.tutor.first_name}
                    </IonCol>
                    <IonCol size='2'>
                      {session.sessionStatus}
                    </IonCol>
                  </IonRow>
                )
              })}
              {busy
                ? <IonRow className='alternate-light-medium'>
                  Loading...
                </IonRow>
                : <IonRow
                  className='alternate-light-medium'
                  onClick={load_next_week}
                >
                  {sessions.length === 0 ? 'No upcoming sessions this week, click here to try loading the next week' : 'Load Next Week'}
                </IonRow>
              }
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </Container>
    </PlytimePageStudentBackground>
  )
}

const Container = styled.div`
      padding-top: 1rem;
      display: flex;
      flex-direction: column;
      width: 55%;
      min-width: 640px;
      `
