import { observer } from 'mobx-react-lite'
import { IonCheckbox } from '@ionic/react'
import { FieldComponent } from 'generic_components/MstForm'
import { CheckboxChangeEventDetail } from '@ionic/core'

export const Checkbox: FieldComponent<boolean> = observer(({ value, onChange, disabled }) => {
  const onIonChange = (e: CustomEvent<CheckboxChangeEventDetail>) =>
    onChange(e.detail.checked)

  return (
    <IonCheckbox
      checked={value}
      onIonChange={onIonChange}
      disabled={disabled}
    />)
})
