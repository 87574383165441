import { FC, useState, useRef, useEffect } from 'react'
import { IonIcon, IonButton } from '@ionic/react'
import focus15 from '../images/focus15_small.svg'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { getUpcomingSessions } from 'util/api/sessions'
import { useUser } from 'context/userContext'
import { pick } from 'lodash'
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IonIconStyled = styled(IonIcon)`
  align-self: start;
  margin-top: auto;
  margin-bottom: auto;
`

const Span = styled.span`
  width: 100%
`

const DateSpan = styled.span`
  font-size: 0.9rem;
  margin-top: 10px;
`

const Img = styled.img`
  height: 40px;
  margin: 15px;
`

const IonButtonRounded = styled(IonButton)`
  --border-radius: 1.5vh;
`

interface Session {
  _id: string,
  session_date_time: string
}

export const JoinFocus15Button: FC = () => {
  const { user } = useUser()
  const [nextSession, setNextSession] = useState<Session | undefined>()
  const [joinable, setJoinable] = useState(false)
  const timer = useRef<NodeJS.Timeout>()

  const watch_sessions_time = async () => {
    const now = DateTime.now()
    const sessions = await getUpcomingSessions({ student_id: user._id }, now.toISO()) // Sessions can be upto an hour long

    const session_check = () => {
      const now = DateTime.now()

      const current_session = sessions.find(s => {
        const start = DateTime.fromISO(s.session_date_time)
        const end = start.plus({ minutes: s.session_length })
        console.log(`Current session = ${now.minus({ minutes: 2 })} >= ${start} = ${now.minus({ minutes: 2 }) >= start} && ${now} < ${end} = ${now < end} && ${!s.cancelled}`)
        return now.plus({ minutes: 2 }) >= start && now < end && !s.cancelled
      })
      if (current_session && current_session.paid) {
        setNextSession(pick(current_session, ['_id', 'session_date_time']))
        setJoinable(true)
      } else {
        setJoinable(false)
        const next_session = sessions.find(s => {
          const start = DateTime.fromISO(s.session_date_time)
          return start > now && !s.cancelled
        })
        if (next_session) {
          setNextSession(pick(next_session, ['_id', 'session_date_time']))
        } else {
          setNextSession(undefined)
        }
      }
    }
    session_check()
    if (timer.current) {
      clearInterval(timer.current)
    }
    timer.current = setInterval(session_check, 10000)
  }

  useEffect(() => {
    watch_sessions_time()
    return () => {
      if (timer.current) {
        clearInterval(timer.current)
      }
    }
  }, [user._id])

  return (
    <IonButtonRounded size='large' routerLink={joinable ? `/student/session/${nextSession._id}` : '/student/sessions'} style={{ 'textTransform': 'capitalize' }}>
      <IonIconStyled icon={focus15} style={{ fontSize: '3.0rem' }} />
      <Container>
        <InnerContainer>
          <Span>{joinable ? 'Join' : 'Sessions'}</Span>
          {nextSession && <DateSpan>{DateTime.fromISO(nextSession.session_date_time).toFormat('ccc d MMM HH:mm')}</DateSpan>}
        </InnerContainer>
      </Container>
    </IonButtonRounded>
  )
}
