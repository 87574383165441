import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { TutorDetails } from 'components/TutorDetails'
import { TutorProfilePictureUpload } from 'components/tutor/profile/TutorProfilePictureUpload'
import { useStore } from 'types/store'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'

interface IProps {
  roles: Roles[]
}

const TutorProfile: FC<IProps> = observer(({ roles }) => {
  const { tutor_data: { profile, get_profile, busy } } = useStore()

  useEffect(
    () => {
      if (profile === undefined && !busy) {
        get_profile()
      }
    },
    []
  )
  return (
    <PlytimePage roles={roles}>
      { profile !== undefined
        ? <>
          <TutorDetails tutor={profile} />
          <TutorProfilePictureUpload tutor={profile} />
        </>
        : busy
          ? <p>Loading...</p>
          : <p>No such tutor</p>
      }
    </PlytimePage >
  )
})

export default TutorProfile
