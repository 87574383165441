import { FC, useState } from 'react'
import { IonModal, IonButton, IonSelect, IonSelectOption, IonToolbar, IonButtons, IonLabel, IonItem, IonCard, IonCardContent, IonCardHeader, IonCardTitle} from '@ionic/react'
import { Hours, Minutes } from 'components/tutor_availability/SlotTimes'
import styled from 'styled-components'

interface Props {
  submit: (value:{
    day:number,
    from_time:number,
    until_time:number,
  }) => void
  cancel: () => void
}

export const AvailabilityPicker:FC<Props> = ({submit, cancel}) => {
  const [day, set_day] = useState<number|undefined>()
  const [from_hour, set_from_hour] = useState<number|undefined>()
  const [from_minute, set_from_minute] = useState<number|undefined>()
  const [until_hour, set_until_hour] = useState<number|undefined>()
  const [until_minute, set_until_minute] = useState<number|undefined>()

  const from_time = from_hour !== undefined && from_minute !== undefined ? 60 * from_hour + from_minute : undefined
  const until_time = until_hour !== undefined && until_minute !== undefined ? 60 * until_hour + until_minute : undefined

  const valid = day !== undefined &&
    from_time !== undefined &&
    until_time !== undefined && 
    from_time < until_time

  console.log(`${from_time} ${until_time} ${valid}`)
  const add_availability = () => {
    submit({
      day, from_time, until_time
    })
  }

  const change_from_hour = (hour:number) => {
    set_from_hour(hour)
    if( from_minute === undefined ) {
      set_from_minute(0)
    }
  }

  const change_until_hour = (hour:number|undefined) => {
    set_until_hour(hour)
    if( until_minute === undefined ) {
      set_until_minute(0)
    }
  }

  return (
    <IonModal
      onDidDismiss={cancel}
      isOpen={true}
    >
      <IonCard id='availability_picker'>
        <IonCardHeader>
          <IonCardTitle>Add some availability</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <Column>
            <IonItem lines='inset' id='day_selector'>
              <IonLabel position='stacked'>Day</IonLabel>
              <IonSelect
                value={day}
                onIonChange={e => set_day(e.detail.value)}
                interfaceOptions={{cssClass: 'day_selector'}}
              >
                <IonSelectOption value={0}>Monday</IonSelectOption>
                <IonSelectOption value={1}>Tuesday</IonSelectOption>
                <IonSelectOption value={2}>Wednesday</IonSelectOption>
                <IonSelectOption value={3}>Thursday</IonSelectOption>
                <IonSelectOption value={4}>Friday</IonSelectOption>
                <IonSelectOption value={5}>Saturday</IonSelectOption>
                <IonSelectOption value={6}>Sunday</IonSelectOption>
              </IonSelect>
            </IonItem>
            <Row>
              <IonItem lines='inset' id='from_hour_selector'>
                <IonLabel
                  position='stacked'
                  color={from_time !== undefined && until_time !== undefined && until_time <= from_time ? 'danger' : 'dark'}
                >
                  From Hour
                </IonLabel>
                <IonSelect
                  value={from_hour}
                  onIonChange={e => change_from_hour(e.detail.value)}
                  interfaceOptions={{cssClass: 'from_hour_selector'}}
                >
                  <Hours/>
                </IonSelect>
              </IonItem>
              <IonItem lines='inset' id='from_minute_selector'>
                <IonLabel
                  position='stacked'
                  color={from_time !== undefined && until_time !== undefined && until_time <= from_time ? 'danger' : 'dark'}
                >
                  Minute
                </IonLabel>
                <IonSelect
                  value={from_minute}
                  onIonChange={e => set_from_minute(e.detail.value)}
                  interfaceOptions={{cssClass: 'from_minute_selector'}}
                >
                  <Minutes/>
                </IonSelect>
              </IonItem>
            </Row>
            <Row>
              <IonItem lines='inset' id='until_hour_selector'>
                <IonLabel
                  position='stacked'
                  color={from_time !== undefined && until_time !== undefined && until_time <= from_time ? 'danger' : 'dark'}
                >
                  Until Hour
                </IonLabel>
                <IonSelect
                  value={until_hour}
                  onIonChange={e => change_until_hour(e.detail.value)}
                  interfaceOptions={{cssClass: 'until_hour_selector'}}
                >
                  <Hours/>
                </IonSelect>
              </IonItem>
              <IonItem lines='inset' id='until_minute_selector'>
                <IonLabel
                  position='stacked'
                  color={from_time !== undefined && until_time !== undefined && until_time <= from_time ? 'danger' : 'dark'}
                >
                  Minute
                </IonLabel>
                <IonSelect
                  value={until_minute}
                  onIonChange={e => set_until_minute(e.detail.value)}
                  interfaceOptions={{cssClass: 'until_minute_selector'}}
                >
                  <Minutes/>
                </IonSelect>
              </IonItem>
            </Row>
          </Column>
          <IonToolbar>
            <IonButtons slot='end'>
              <IonButton
                fill='solid'
                size='small'
                color='secondary'
                onClick={cancel}
              >
                Cancel
              </IonButton>
              <IonButton
                size='small'
                color='primary'
                fill='solid'
                onClick={add_availability}
                disabled={!valid}
                id='add_availability'
              >
                Add
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonCardContent>
      </IonCard>
    </IonModal>
  )
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`
