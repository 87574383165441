import { PusherProvider } from '@harelpls/use-pusher'
import { FC } from 'react'
import { Props } from './props'
import { NotesCard } from './notes_card'

const pusherConfig = {
  clientKey: process.env.REACT_APP_PUSHER_CLIENT,
  cluster: process.env.REACT_APP_PUSHER_CLUSTER
}

export const Notes:FC<Props> = ({student, subject}:Props) => (
  <PusherProvider {...pusherConfig}>
    <NotesCard student={student} subject={subject}/>
  </PusherProvider>
)
