import { types, Instance, flow, applySnapshot, SnapshotIn, SnapshotOut, cast, getSnapshot, getRoot, destroy } from 'mobx-state-tree'
import { Focus } from './focus'
import { Difficulty } from '../Difficulty'
import { AskedQuestion } from 'common/types/question/question'
import { AllowedAnswers, SubmittedAnswer } from 'common/types/question/answer/answer'
import { ask, checkAnswer } from 'util/api'
import { ApiError } from 'types/ApiError'
import { Ask } from 'types/Ask'
import { Opponent } from './opponent'
import { GhostPlayerGameSummary, HumanGamePlayerSummary } from 'common/types/game/game_summary'
import { GAME_TYPE } from 'common/types/game'
import { shuffle } from 'lodash'
import { Selected_subjects } from './selected_subjects'
import { v4 as uuid } from 'uuid'
import { putGameSummary, getGameHighScore } from 'util/api/game'
import * as log from 'util/api/log'
import student from 'pages/student'

type PreQuestion = {
  question: SnapshotIn<AskedQuestion>
} | {
  error: any
}
const pre_ask: (args: SnapshotOut<typeof Ask>) => Promise<PreQuestion> = (args) => ask(args)
  .then(question => ({ question }))
  .catch(error => ({ error }))

const male_names = [
  'Oliver',
  'George',
  'Noah',
  'Arthur',
  'Harry',
  'Leo',
  'Muhammad',
  'Jack',
  'Charlie',
  'Oscar',
  'Theo'
]

const female_names = [
  'Olivia',
  'Amelia',
  'Isla',
  'Ava',
  'Mia',
  'Sophia',
  'Grace',
  'Lily',
  'Freya',
  'Becky',
  'Aimee',
  'Rose',
  'Jess',
  'Holly',
  'Chloe',
  'Jane',
  'Mary'
]

export const GameModel = types.model({
  _id: types.maybe(types.string),
  focus: Focus,
  selected_subjects: Selected_subjects,
  difficulty: Difficulty,
  question: types.maybe(AskedQuestion),
  student_answer: SubmittedAnswer,
  correct_answers: AllowedAnswers,
  score: 0,
  game_id: types.maybe(types.string),
  gameInPlay: false,
  game_start_time: types.maybeNull(types.Date),
  game_duration: 0,
  question_asked: false,
  total_questions: 0,
  questions_correct: 0,
  answer_decision: types.maybe(types.string),
  error: types.maybe(types.string),
  //used by time-based speed game
  consecutiveCorrectAnswers: 0,
  streakPoints: 0,
  highestStreak: 0,
  bonusPoints: 0,
  lastQuestionStartTime: Date.now(),
  opponents: types.array(Opponent),
  game_time_seconds: 0,
  question_time_seconds: 0,
  loading: false,
  paused: false,
  over: false,
  game_mode: 'multiplayer',
  highscore: 0
})
  .volatile(_ => ({
    next_medium_promise: undefined as Promise<PreQuestion> | undefined,
    next_hard_promise: undefined as Promise<PreQuestion> | undefined,
    next_super_hard_promise: undefined as Promise<PreQuestion> | undefined,
  }))
  .views(self => {
    function rubyCount() {
      return self.consecutiveCorrectAnswers % 5
    }
    function totalPoints() {
      return self.score + self.bonusPoints * 5 + self.streakPoints * 20
    }
    function getGameSummaryPlayerData(): Array<HumanGamePlayerSummary | GhostPlayerGameSummary> {
      const root: any = getRoot(self)
      const { role, _id } = root.auth.user
      const scores = new Array<HumanGamePlayerSummary | GhostPlayerGameSummary>()
      scores.push({
        player: _id as string,
        playerType: role as string,
        totalPoints: totalPoints() as number,
        coins: self.score as number,
        difficultyBonus: self.bonusPoints as number,
        streakBonus: self.streakPoints as number,
        streak: self.consecutiveCorrectAnswers as number,
        highestStreak: self.highestStreak as number,
        questionCnt: self.total_questions as number,
      })
      self.opponents.forEach(o => {
        const opScore = {
          player: o.id,
          playerType: 'GHOST',
          totalPoints: o.score,
        }
        scores.push(opScore)
      })
      return scores
    }
    function getFullGameSummary() {
      const game_summary = {
        body: {
          _id: self._id,
          game_id: self.game_id,
          focus: getSnapshot(self.selected_subjects.subjects),
          players: getGameSummaryPlayerData(),
          started_at: self.game_start_time.toISOString(),
          finished_at: new Date().toISOString(),
          game_type: GAME_TYPE.SPEED_STANDARD,
          duration: self.game_duration
        }
      }
      return game_summary
    }
    function nextQuestionPromise() {
      return {
        'SUPER_HARD': self.next_super_hard_promise,
        'HARD': self.next_hard_promise,
        'MEDIUM': self.next_medium_promise,
        'EASY': self.next_medium_promise, // EASY not used ATM
      }[self.difficulty]
    }
    return {
      rubyCount,
      totalPoints,
      getGameSummaryPlayerData,
      getFullGameSummary,
      nextQuestionPromise,
    }
  })
  .actions(self => {
    const save_game_summary = () => {
      const game_data = self.getFullGameSummary()
      putGameSummary(game_data)
    }
    const end_game = () => {
      self.gameInPlay = false
      self.over = true
      save_game_summary()
    }
    const updateOpponent = (opponent: Opponent) => {
      self.opponents = cast(self.opponents.map(o => {
        if (o.id === opponent.id) {
          return opponent
        } else {
          return o
        }
      }))
    }
    const addOpponent = (opponent) => {
      self.opponents.push(opponent)
    }
    const setupGhostOpponents = () => {
      const ghost_player_multipliers = shuffle([0.5, 0.8, 1.1])
      console.log('[Shuffled multipliers]:', ghost_player_multipliers)
      const names = shuffle([...female_names, ...male_names])

      addOpponent({
        id: '1',
        score_multiplier: ghost_player_multipliers[0],
        name: names[0],
        score: 0,
        streak: 0,
        difficultyBonus: 0,
        streakBonus: 0,
        colour: 'red',
        highestStreak: 0,
        questionCnt: 0
      })
      addOpponent({
        id: '2',
        score_multiplier: ghost_player_multipliers[1],
        name: names[1],
        score: 0,
        streak: 0,
        difficultyBonus: 0,
        streakBonus: 0,
        colour: 'orange',
        highestStreak: 0,
        questionCnt: 0,
        highscore: 0
      })
      addOpponent({
        id: '3',
        score_multiplier: ghost_player_multipliers[2],
        name: names[2],
        score: 0,
        streak: 0,
        difficultyBonus: 0,
        streakBonus: 0,
        colour: 'blue',
        highestStreak: 0,
        questionCnt: 0
      })
    }
    const set_new_question_loaded = () => {
      self.lastQuestionStartTime = Date.now()
      self.question_asked = true
      self.total_questions++
      self.error = undefined
      self.loading = false
      self.student_answer.clear()
      self.correct_answers.clear()
      self.answer_decision = undefined
    }
    const pre_load_question = (student: string, difficulty: Difficulty = self.difficulty, endcodes_to_exclude: string[] = []) => {
      const focus = self.selected_subjects.subjects

      switch (difficulty) {
        case 'EASY':
        case 'MEDIUM':
          self.next_medium_promise = pre_ask({ student, difficulty: 'MEDIUM', focus, endcodes_to_exclude })
          break
        case 'HARD':
          self.next_hard_promise = pre_ask({ student, difficulty: 'HARD', focus, endcodes_to_exclude })
          break
        case 'SUPER_HARD':
          self.next_super_hard_promise = pre_ask({ student, difficulty: 'SUPER_HARD', focus, endcodes_to_exclude })
          break
      }
    }
    const update_game_mode = (mode: 'multiplayer' | 'singleplayer') => {
      self.game_mode = mode
    }
    return ({
      end_game,
      updateOpponent,
      setupGhostOpponents,
      save_game_summary,
      update_game_mode,
      heartbeat() {
        if (self.game_time_seconds === 0) {
          end_game()
        } else {
          self.game_time_seconds -= 1
          console.log('[ghostplay] opponents:' + self.opponents.length)
          //update ghost opponent answers
          self.opponents.forEach(x => {
            const update_score_probability = Math.random()
            if (update_score_probability <= 0.1 && self.game_duration - self.game_time_seconds > 10) {
              const oppToUpdate = {
                ...x,
                questionCnt: (x.questionCnt + 1),
                score: (self.game_duration - self.game_time_seconds) * x.score_multiplier
              }
              updateOpponent(oppToUpdate)
            }
          })
        }
      },
      set_difficulty(difficulty: SnapshotOut<typeof Difficulty>) {
        self.difficulty = difficulty
      },
      ask_initial_questions(student_id: string) {
        console.log('asking initial questions for each difficulty')
        pre_load_question(student_id, 'MEDIUM')
        pre_load_question(student_id, 'HARD')
        pre_load_question(student_id, 'SUPER_HARD')
      },
      ask_question: flow(function* (student_id: string) {
        console.log('asking next question')
        self.loading = true
        const focus = self.selected_subjects.subjects
        const difficulty = self.difficulty
        let endcode_to_exclude = undefined
        try {
          if (self.question !== undefined) {
            endcode_to_exclude = self.question._id
            destroy(self.question)
          }
          if (self.nextQuestionPromise() === undefined) {
            endcode_to_exclude ?  // this block trys again if an error occured previously
              pre_load_question(student_id, self.difficulty, [endcode_to_exclude])
              : pre_load_question(student_id)
          }
          const next_q = yield self.nextQuestionPromise()
          if (next_q.question) {
            self.question = AskedQuestion.create(next_q.question)
            // exlcude this question when preloading the next one
            endcode_to_exclude = self.question._id
          } else {
            throw next_q.error
          }
          set_new_question_loaded()
          log.debug(`Asked question=${self.question._id}`)
        } catch (e) {
          if (e instanceof ApiError) {
            log.error(`AskAPI ApiError during ask: ${e.errors?.join(',\n')} student=${student_id} diff=${self.difficulty} focus=${focus}`)
            self.error = e.errors?.join(',\n')
          } else {
            console.dir(e)
            log.error(`AskAPI Unexpected error: ${e.errors?.join(',\n') || e.message || 'Oops!'} student=${student_id} diff=${self.difficulty} focus=${focus}`)
            self.error = e.errors?.join(',\n') || e.message || 'Oops!'
          }
        } finally {
          save_game_summary()
          self.loading = false
          endcode_to_exclude ?
            pre_load_question(student_id, self.difficulty, [endcode_to_exclude])
            : pre_load_question(student_id)
        }
      }),
      update_answer(line_element: string, ref: string, value: string, selected: boolean) {
        if (value === '' || selected) {
          self.student_answer.delete(line_element)
        } else {
          self.student_answer.set(line_element, { ref, value })
        }
      },
      submit_answer: flow(function* (student_id: string) {
        if (self.loading || !self.question_asked) {
          console.log('Answer submission ignored for loading, potentially double submitted')
          return
        }
        self.loading = true
        const answer = getSnapshot(self.student_answer)
        try {
          const endTime = Date.now()
          const time = Math.round((endTime - self.lastQuestionStartTime) / 1000)
          const APIObject = { student_id, time, difficulty: self.difficulty, answer }
          const { result, answers } = yield checkAnswer(self.question._id, self._id, self.game_id, APIObject)
          if (answers.length > 0) {
            applySnapshot(self.correct_answers, answers)
          } else {
            self.error = 'Oh no! Sorry, I can`t show the correct answer'
          }
          self.answer_decision = result
          self.question_asked = false
          if (result === 'CORRECT') {
            const standard_time = self.question.standard_time
            const ratio = (standard_time - time) / standard_time
            const calc_score = Math.round((((1 + ratio) * time)))
            const score = Math.max(calc_score, 1)//if you answer very fast you should get a point

            self.questions_correct++
            self.consecutiveCorrectAnswers++
            self.score += score
            self.bonusPoints += difficulty_points(self.difficulty)
            if (self.consecutiveCorrectAnswers > self.highestStreak) {
              self.highestStreak = self.consecutiveCorrectAnswers
            }
            //every 5 streak, increase score by 20 and reset rubies
            if (self.consecutiveCorrectAnswers > 0 && self.consecutiveCorrectAnswers % 5 === 0) {
              self.score += 20
              self.streakPoints++
            }
          } else {
            self.consecutiveCorrectAnswers = 0
          }
          if (self.difficulty === 'EASY') {
            self.consecutiveCorrectAnswers = 0
          }

        } catch (e) {
          if (e instanceof ApiError) {
            self.error = e.errors?.join(',\n')
          } else {
            console.dir(e)
            self.error = e.errors?.join(',\n') || e.message || 'Oops!'
          }
        } finally {
          self.loading = false
          self.difficulty = 'MEDIUM'
        }
      }),
      update_highscore: flow(function* () {
        const highScore = yield getGameHighScore({
          query: {
            game_type: GAME_TYPE.SPEED_STANDARD,
            duration: self.game_duration
          }
        })
        self.highscore = highScore.score
      }),
      reset() {
        self.question = undefined
        self.paused = false
        self.over = false
        self.score = 0
        self.game_start_time = undefined
        self.game_duration = 0
        self.gameInPlay = false
        self.question_asked = false
        self.total_questions = 0
        self.questions_correct = 0
        self.answer_decision = undefined
        self.error = undefined
        self.consecutiveCorrectAnswers = 0
        self.streakPoints = 0
        self.highestStreak = 0
        self.bonusPoints = 0
        self.lastQuestionStartTime = Date.now()
        self.opponents = cast([])
      }
    })
  })
  .actions(self => {
    let game_timer: NodeJS.Timeout | undefined = undefined
    return ({
      start_game(game_id: string, max_time_seconds: number) {
        self.reset()
        self._id = uuid()
        self.game_id = game_id
        self.gameInPlay = true
        self.game_time_seconds = max_time_seconds
        self.game_duration = max_time_seconds
        self.setupGhostOpponents()
        self.game_start_time = new Date()
        if (game_timer !== undefined) {
          clearInterval(game_timer)
          game_timer = undefined
        }
        game_timer = setInterval(() => self.heartbeat(), 1000)
        self.update_highscore()
      },
      terminate_game() {
        if (game_timer !== undefined) {
          clearInterval(game_timer)
          game_timer = undefined
        }
        self.end_game()
      },
      stop_heartbeat() {
        if (self.over && game_timer !== undefined) {
          clearInterval(game_timer)
          game_timer = undefined
        }
      },
      pause_game() {
        self.paused = true
        if (game_timer !== undefined) {
          clearInterval(game_timer)
          game_timer = undefined
        }
      },
      unpause_game() {
        self.paused = false
        if (game_timer !== undefined) {
          clearInterval(game_timer)
          game_timer = undefined
        }
        game_timer = setInterval(() => self.heartbeat(), 1000)
      },
    })
  })

export type Game = Instance<typeof GameModel>

function difficulty_points(difficulty: Difficulty) {
  switch (difficulty) {
    case 'EASY':
      return 0
    case 'MEDIUM':
      return 1
    case 'HARD':
      return 2
    case 'SUPER_HARD':
      return 3
  }
}
