import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from '@ionic/react'
import { ButtonWithImage } from 'generic_components/ButtonWithImage'
import { FC, useEffect, useRef, useState } from 'react'
import { checkmarkCircleSharp, alertCircle } from 'ionicons/icons'
import useAudioTest from './useAudioTest'
import { AudioDevice } from './AudioDevice'


interface Props {
  onNext: ()=>void
}
export const AudioDiag:FC<Props> = ({onNext}) => { 
  const [success,setSuccess] = useState(false)
  const [inputDeviceId, setInputDeviceId] = useState('')
  const [outputDeviceId, setOutputDeviceId] = useState('')
  const [recording, setRecording] = useState(false)
  const [playing, setPlaying] = useState(false)
  const previousInputDeviceIdRef = useRef('')
  
  const {
    error,
    setError,
    isRecording,
    isAudioInputTestRunning,
    isAudioOutputTestRunning,
    playAudio,
    playbackURI,
    readAudioInput,
    stopAudioTest,
    inputLevel,
    outputLevel,
  } = useAudioTest()

  const volumeLevel = isAudioOutputTestRunning ? outputLevel : inputLevel

  const disableAll = isRecording || isAudioOutputTestRunning || (!!error && error !== 'No audio detected')

  const handleRecordClick = () => {
    setRecording(true)
    setPlaying(false)
    readAudioInput({ deviceId: inputDeviceId, enableRecording: true })
  }

  const handlePlayClick = () => {
    setRecording(false)
    setPlaying(true)
    playAudio({ deviceId: outputDeviceId, testURI: playbackURI })
  }

  // start audio test when on AudioTest and deviceId changes
  useEffect(() => {
    
    const newInputDeviceSelected = previousInputDeviceIdRef.current !== inputDeviceId
    previousInputDeviceIdRef.current = inputDeviceId

    // Restarts the test to continuously capture audio input
    if (!error && (newInputDeviceSelected || (!isRecording && !isAudioInputTestRunning))) {
      console.log('audio reading', inputDeviceId)
      readAudioInput({ deviceId: inputDeviceId })
    }
    
    if (error) {
      console.log('audio error', error)
      stopAudioTest()
    }
  }, [
    error,
    inputDeviceId,
    isRecording,
    isAudioInputTestRunning,
    readAudioInput,
    stopAudioTest,
  ])
  return (<IonCard>
    <IonCardHeader>
      <IonCardTitle>
        {error ? <IonIcon icon={alertCircle} style={{color:'red'}}/> : success ? <IonIcon icon={checkmarkCircleSharp} style={{color:'green'}}/> : null}Test audio
      </IonCardTitle>
      <IonCardSubtitle> Record an audio clip and play it back to check that your microphone and speaker are working. If they aren&apos;t,
            make sure your volume is turned up, try a different speaker or microphone, or check your bluetooth settings.
      </IonCardSubtitle>
    </IonCardHeader>

    <IonCardContent>

      {isAudioOutputTestRunning && playing && <div><strong>Playing, ensure volume is on.</strong></div>}
      {isAudioInputTestRunning && isRecording && <div><strong>Recording, speak into the microphone!</strong></div>}
 
      <IonButton
        color="primary"
        style={{ marginRight: '1em' }}
        size="small"
        disabled={disableAll}
        onClick={handleRecordClick}
      >
                  Record
      </IonButton>
      <IonButton  color="primary" size="small" disabled={!playbackURI || disableAll} onClick={handlePlayClick}>
                  Play back
      </IonButton>
      <div>
        <AudioDevice
          disabled={disableAll}
          kind="audiooutput"
          onDeviceChange={setOutputDeviceId}
          setDeviceError={setError}
        />
        <AudioDevice
          disabled={disableAll}
          kind="audioinput"
          onDeviceChange={setInputDeviceId}
          setDeviceError={setError}
          error={error}
        />
      </div>
      <div>
        <strong>Does your audio sound ok?</strong>
      </div>
      <IonButton color="primary" onClick={()=>{
        setSuccess(true)
        setError(undefined)
        onNext()
      }}>
            Yes
      </IonButton>
      <IonButton color="primary" onClick={()=>{
        setError('true')
        setSuccess(false)
        onNext()
      }}>
            Skip for now
      </IonButton>
      <div>
        {error && <strong>Could not validate audio.</strong>}
      </div>
    </IonCardContent>
  </IonCard>)
}
