import Amplify from 'aws-amplify'
import auth_admin_config from './amplify-config-admin'
import auth_parent_config from './amplify-config-parent'
import auth_student_config from './amplify-config-student'
import auth_tutor_config from './amplify-config-tutor'
import { Roles } from '../helper'

export const loadRole = () => {
  const role = localStorage.getItem('plytime_role')
  if( Object.values(Roles).includes(role as Roles) ) {
    return role as Roles
  }
  return undefined
}

export const saveRole = (role: string) => {
  if(role === undefined){
    localStorage.removeItem('plytime_role')
  } else {
    localStorage.setItem('plytime_role', role)
  }
}

export const loadUsername = () => {
  const username = localStorage.getItem('plytime_username')
  if( typeof username === 'string' ) return username
  return undefined
}

export const saveUsername = (role: string) => {
  if(role === undefined){
    localStorage.removeItem('plytime_username')
  } else {
    localStorage.setItem('plytime_username', role)
  }
}

export const configureAuth = (role: string) => {
  let amplifyConfig
  switch (role) {
    case Roles.STUDENT:
      amplifyConfig = auth_student_config
      break
    case Roles.TUTOR:
      amplifyConfig = auth_tutor_config
      break
    case Roles.ADMIN:
      amplifyConfig = auth_admin_config
      break
    case Roles.PARENT:
      amplifyConfig = auth_parent_config
      break
    default:
      console.log('Unsupported login role:' + role)
      throw new Error('Unsupported login role:' + role)
  }

  console.log('Configured Auth:' + JSON.stringify(amplifyConfig))

  Amplify.configure(amplifyConfig)
}
