import { useEffect, FC } from 'react'
import { IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react'
import { observer } from 'mobx-react-lite'

import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { TutorDetails } from 'components/TutorDetails'
import { TutorProfileAvailability } from 'components/tutor/profile/TutorProfileAvailability'
import { useStore } from 'types/store'
import { AvailabilityLegend } from 'components/tutor_availability/AvailabilityLegend'
import { TutorProfilePictureUpload } from 'components/tutor/profile/TutorProfilePictureUpload'
import { TutorUpcommingSessions } from 'components/admin/tutor_sessions'

interface IProps {
  match: {
    params: {
      _id: string
    }
  }
  roles: Roles[]
}


const TutorDetailsComponent: FC<IProps> = observer(function ({ match, roles }) {

  const { admin_tutor_list: { tutors, refresh, busy, get_tutor } } = useStore()

  const tutor = get_tutor(match.params._id)

  useEffect(() => {
    if (tutors.length === 0 && !busy) {
      refresh()
    }
  }, [])

  return (
    <PlytimePage roles={roles}>
      { tutor &&
        <>
          <TutorDetails tutor={tutor}/>
          <TutorProfilePictureUpload tutor={tutor} />
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                Availability
              </IonCardTitle>
              <IonCardSubtitle>
                <AvailabilityLegend />
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <TutorProfileAvailability tutor_id={tutor._id}/>
            </IonCardContent>
          </IonCard>
          <TutorUpcommingSessions tutor={tutor._id}/>
        </>
      }
    </PlytimePage>
  )
})

export default TutorDetailsComponent
