import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { useLocation, useParams } from 'react-router-dom'
import { IonSegment, IonSegmentButton, IonIcon, IonLabel } from '@ionic/react'
import { trendingUpOutline, optionsOutline, bookOutline } from 'ionicons/icons'

import { PlytimePageStudentBackground } from 'components/plytime_page_student_background'
import { Roles } from 'util/auth/helper'
import { CurrentStudentPerformance } from 'components/stats/CurrentStudentPerformance'
import { StudentTrendPerformance } from 'components/stats/StudentTrendPerformance'
import { QuestionReview, QuestionTableHeader, StudentDetailView } from 'components/question_review'
import { useUser } from 'context/userContext'

type Tab = 'trend' | 'focus' | 'answers'
interface IParams {
  tab: Tab
}

function useQuery(): URLSearchParams {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  const { user } = useUser()
  const params = useParams<IParams>()
  const query = useQuery()
  const [tab, set_tab] = useState<Tab>(params.tab ?? 'trend')

  const filtersFromQuery = Array.from(query.keys()).map(key => ({
    id: key as QuestionTableHeader,
    value: query.get(key),
  }))
  const [initialAnswerFilters, setInitialAnswerFilters] = useState(filtersFromQuery)

  return (
    <PlytimePageStudentBackground roles={roles}>
      {user &&
        <Container>
          <IonSegment
            onIonChange={e => set_tab(e.detail.value as any)}
            value={tab}
          >
            <IonSegmentButton value={'trend'} style={{ minWidth: 0 }}>
              <IonIcon icon={trendingUpOutline} />
              <IonLabel>Trend</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={'focus'} style={{ minWidth: 0 }}>
              <IonIcon icon={optionsOutline} />
              <IonLabel>Focus</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={'answers'} style={{ minWidth: 0 }}>
              <IonIcon icon={bookOutline} />
              <IonLabel>Answers</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {tab === 'trend' &&
            user && <StudentTrendPerformance student_id={user._id}  changeTab={set_tab} setInitialAnswerFilters={setInitialAnswerFilters}/>
          }
          {tab === 'focus' &&
            user && <CurrentStudentPerformance student_id={user._id} />
          }
          {tab === 'answers' &&
            user &&
            <QuestionReviewContainer>
              <QuestionReview
                initialFilters={initialAnswerFilters}
                student_id={user._id}
                AnswerDetailComponent={StudentDetailView}
              />
            </QuestionReviewContainer>

          }
        </Container>
      }
    </PlytimePageStudentBackground>
  )
}

const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 80%;
  background: white;
`

const QuestionReviewContainer = styled.div`
  margin: 0 auto;
  height: 500px;
  overflow-y: scroll;
`
