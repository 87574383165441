import { useState, useEffect } from 'react'

import { PlytimePage } from '../../components/plytime_page'
import { Roles } from 'util/auth/helper'
import { updateAdminVars, getAdminVars } from 'util/api'

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  const [desiredCapacity, setDesiredCapacity] = useState('')
  const [minimumCapacity, setMinimumCapacity] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    updateAdminVars({ desiredCapacity, minimumCapacity })
  }

  useEffect(() => {
    const f = async () => {
      try {
        const adminVars = await getAdminVars()
        if (adminVars) {
          setDesiredCapacity(adminVars.desiredCapacity)
          setMinimumCapacity(adminVars.minimumCapacity)
        }
      } catch (err) {
        console.error(err)
      }
    }
    f()
  }, [])

  return (
    <PlytimePage roles={roles}>
      <h2>Admin variables</h2>
      <form onSubmit={handleSubmit}>
        <h3>Capacity thresholds</h3>
        <ul style={{ listStyle: 'none' }}>
          <li><label>Desired capacity threshold:<input onChange={(e) => setDesiredCapacity(e.target.value)} value={desiredCapacity}></input></label></li>
          <li><label>Minimum capacity threshold:<input onChange={(e) => setMinimumCapacity(e.target.value)} value={minimumCapacity}></input></label></li>
        </ul>
        <button type='submit'>Submit</button>
      </form>
    </PlytimePage>
  )
}
