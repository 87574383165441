import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { getStudentAnswers } from 'util/api/student/answers'

export const useGetAnswers = (student_id: string, fetchDataSinceDate: DateTime) => {
  const [answers, setAnswers] = useState<any>()
  const [fetchDataSince, setFetchDataSince] = useState<DateTime>(fetchDataSinceDate)
  const [isLoading, setIsLoading] = useState(true)

  const refresh = async () => {
    try {
      setIsLoading(true)
      const answers = await getStudentAnswers(student_id, fetchDataSince.toISO())
      setAnswers(answers)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setAnswers(undefined)
  }, [student_id])
  
  useEffect(() => {
    refresh()
  }, [student_id, fetchDataSince])

  const load_next_week = () => {
    if (isLoading) throw new Error('loading')
    setFetchDataSince(current => current.minus({ weeks: 1 }))
  }

  const update_answer = (update: any) => {
    setAnswers(answers => answers.map(answer => {
      if (answer._id === update._id) {
        console.log(`Updating ${answer._id}`)
      }
      return answer._id === update._id
        ? { ...answer, ...update }
        : answer
    }))
  }

  return { answers, isLoading, fetchDataSince, load_next_week, update_answer }
}
