import { FC, useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonText,
  IonToolbar,
} from '@ionic/react'
import { substituteSubscription } from 'util/api/subscriptions'
import { Session } from 'components/sessions/useUpcomingSessions'

interface Props {
  session: Session
  close: () => void
}

export const SubstituteSubscription: FC<Props> = ({ session, close }) => {
  const [busy, set_busy] = useState(false)
  const [error, set_error] = useState('')

  const substitute_subscription = async () => {
    set_busy(true)
    try {
      await substituteSubscription({
        params: { subscription_id: session.subscription_id },
      })
      close()
    } catch (e) {
      set_error(e.message || 'Failed to substiute subscription')
    } finally {
      set_busy(false)
    }
  }

  return (
    <IonCard style={{ margin: 0, height: '25rem' }}>
      <IonCardHeader>
        <IonCardTitle>Substitute future sessions</IonCardTitle>
        <IonCardSubtitle>{session.subject.name} with {session.student.first_name}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <ul>
          <li>
            <p>We will try to find an alternative tutor to take over this subscription but if no other tutor has availability, please contact us directly.</p>
          </li>
          <li>
            <p>Would you like us to try to find a alternative tutor for this entire subscription?</p>
          </li>
        </ul>
        {error === ''
          ? <p>Would you like to substitute these sessions?</p>
          : <IonText color='danger'><p>{error}</p></IonText>
        }
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton
              fill='solid'
              size='small'
              color='primary'
              className="ion-margin-top"
              onClick={substitute_subscription}
              disabled={busy || error !== ''}
            >
              Yes
            </IonButton>
          </IonButtons>
          <IonButtons slot='start'>
            <IonButton
              fill='outline'
              size='small'
              color='tertiary'
              className="ion-margin-top"
              onClick={close}
              disabled={busy}
            >
              No
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardContent>
    </IonCard>
  )
}
