import { Log } from 'common/types/log'
import { DateTime } from 'luxon'
import { types } from 'mobx-state-tree'
import { api } from '..'

export const LogRequest = types.model({
  body: Log
})
export const LogResponse = types.model({
  success: types.boolean
})

export const debug = (message: string) => log_auth('DEBUG', message)
export const info = (message: string) => log_auth('INFO', message)
export const warn = (message: string) => log_auth('WARN', message)
export const error = (message: string) => log_auth('ERROR', message)

const _log_auth = api(LogRequest, LogResponse, 'POST', 'util/log')
export const log_auth = async (level: string, message: string) => {
  console.log(`[remotelog] ${message}`)
  try {
    const data = await _log_auth({
      body: {
        message,
        level,
        timestamp: DateTime.now().toISO()
      }
    })
    return data.success
  } catch (e) {
    console.error('[remotelog=error]', e)
    return false
  }
}
