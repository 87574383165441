import { types } from 'mobx-state-tree'

export const Country = types.enumeration( 'Country', [
  'United Kingdom',
  'Australia',
  'Belgium',
  'Canada',
  'France',
  'Germany',
  'Isle of Man',
  'Italy',
  'Netherlands',
  'New Zealand',
  'Poland',
  'Portugal',
  'Republic of Ireland',
  'Spain',
  'Sweden',
  'Switzerland',
  'United States',
])
export type Country = typeof Country
