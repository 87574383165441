import { PlytimePage } from 'components/plytime_page'
import { Roles } from 'util/auth/helper'
import { GameSummary } from 'components/game/summary'

interface IProps {
  roles: Roles[]
}

export default ({ roles }: IProps) => {
  return (
    <PlytimePage roles={roles} >
      <GameSummary />
    </PlytimePage>
  )
}
