import { PlytimePage } from 'components/plytime_page'
import { IonButton, IonToolbar, IonButtons } from '@ionic/react'
import { Roles } from 'util/auth/helper'

interface IProps {
  roles: Roles[]
}

export default function ({ roles }: IProps) {
  return (
    <PlytimePage roles={roles}>
      <IonToolbar>
        <IonButtons slot='end'>
          <IonButton
            routerLink='/admin/tutors'
            size='small'
            color='primary'
            fill='solid'
            className="ion-margin-top"
          >
            Tutors
          </IonButton>
          <IonButton
            routerLink='/admin/student_scores'
            size='small'
            color='primary'
            fill='solid'
            className="ion-margin-top"
          >
            Student Scores
          </IonButton>
          <IonButton
            routerLink='/admin/subjects'
            size='small'
            color='primary'
            fill='solid'
            className="ion-margin-top"
          >
            Subjects
          </IonButton>
          <IonButton
            routerLink='/admin/questions'
            size='small'
            color='primary'
            fill='solid'
            className="ion-margin-top"
          >
            Questions
          </IonButton>
          <IonButton
            routerLink='/admin/subjects_import'
            size='small'
            color='primary'
            fill='solid'
            className="ion-margin-top"
          >
            Import Subjects and Questions
          </IonButton>
          <IonButton
            routerLink='/admin/variables'
            size='small'
            color='primary'
            fill='solid'
            className="ion-margin-top"
          >
            Admin variables
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </PlytimePage>
  )
}
