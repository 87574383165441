import {
  Instance,
  SnapshotIn,
  applySnapshot,
  destroy,
  flow,
  getParent,
} from 'mobx-state-tree'
import { postEndcodeQuestion } from '../../../util/api'
import { AdminQuestion, Element, Lines } from 'common/types/question/question'

export const Question = AdminQuestion
  .named('Question')
  .actions((self) => ({
    update_score(score: number) {
      self.score = score
    },
    update_max_time(seconds: number) {
      self.maximum_time = seconds
    },
    update_std_time(seconds: number) {
      self.standard_time = seconds
    },
    remove_element(element: Instance<Element>) {
      const line = getParent<Instance<Lines>>(element)
      if( line.length === 1 ) {
        destroy(line)
      } else {
        destroy(element)
      }
    },
    replace_element(line_index: number, element_index: number, element: Instance<Element>) {
      self.lines[line_index].splice(element_index, 1, element)
    },
    insert_element(line_index: number, element_index: number, element: Instance<Element>) {
      self.lines[line_index].splice(element_index, 0, element)
    },
    insert_new_line_element(line: number, element: Instance<Element>) {
      self.lines.splice(line, 0, [element])
    },
    save: flow(function* (update:SnapshotIn<AdminQuestion>) {
      const updated_self = yield postEndcodeQuestion(
        self.endcode._id,
        update
      )
      applySnapshot(self, updated_self)
    }),
    add_answer() {
      self.answers.push([{ ref: 'change ref', values: ['change value'] }])
    },
    add_answer_part(answer: number) {
      self.answers[answer].push({ ref: 'change ref', values: ['change value']})
    },
    add_answer_value(answer: number, part: number) {
      self.answers[answer][part].values.push('change value')
    },
    remove_answer(answer:number) {
      if( self.answers.length < 2 ) {
        throw new Error('Cannot remove the last answer')
      }
      destroy(self.answers[answer])
    },
    remove_answer_part(answer:number, part: number) {
      if( self.answers[answer].length < 2 ) {
        throw new Error('Cannot remove the last answer part')
      }
      destroy(self.answers[answer][part])
    },
    update_answer_ref(answer:number, part:number, ref:string){
      self.answers[answer][part].ref = ref
    },
    update_answer_value(answer:number, part:number, index:number, value:string){
      if( value === '' && self.answers[answer][part].values.length > 1 ) {
        self.answers[answer][part].values.splice(index,1)
      } else {
        self.answers[answer][part].values.splice(index,1,value)
      }
    },
    delete() {
      self.status = 'archived'
    }
  }))
export type Question = Instance<typeof Question>;
