import { api } from 'util/api'
import { PostStudentMixRequest, Mix, GetStudentMixesRequest, GetStudentMixesResponse, PutStudentMixRequest, DeleteStudentMixRequest, DeleteStudentMixResponse  } from 'common/types/student/mixes'
import { Mix_Extended } from 'types/student/mixes'

const url = 'student/student_id/mixes'
const put_delete_url = url + '/mix_id'

export const postMix = async(student_id: string, name: string, subject: string) => 
  api(PostStudentMixRequest, Mix_Extended, 'POST', url)(
    { 
      params: { student_id },
      body: {
        name,
        subject,
        subjects: [],
      }
    }
  )

export const getMixes = async(student_id: string) =>
  api(GetStudentMixesRequest, GetStudentMixesResponse, 'GET', url)({
    params: { student_id }
  })

export const putMix = async(student_id: string, mix_id: string, name: string, subject: string, subjects: string[]) =>
  api(PutStudentMixRequest, Mix_Extended, 'PUT', put_delete_url)({
    params: {
      student_id,
      mix_id
    },
    body: {
      name,
      subject,
      subjects
    }
  })

export const deleteMix = async(student_id: string, mix_id: string) =>
  api(DeleteStudentMixRequest, DeleteStudentMixResponse, 'DELETE', put_delete_url)({
    params: {
      student_id,
      mix_id
    }
  })
