import { FC, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react'
import { observer } from 'mobx-react-lite'

import { PublicPlytimePage } from 'components/public_plytime_page'
import { useUser } from 'context/userContext'
import { Roles } from 'util/auth/helper'

export const SignInPage: FC = observer(() => {
  const { role, username, in_progress, change_role } = useUser()

  const subtitle = () => {
    if (role === 'ADMIN') {
      return `Not an ${role?.toLowerCase()}?`
    } else {
      return `Not a ${role?.toLowerCase()}?`
    }
  }

  const switch_role = () => change_role(undefined)

  const page_role = useLocation().pathname.split('/').slice(-1)[0]
  useEffect(() => {
    change_role(page_role.toUpperCase() as any)
  },[])

  return !in_progress &&
    <PublicPlytimePage>
      <AmplifyAuthenticator usernameAlias={role === Roles.STUDENT ? 'username' : 'email'}>
        {role === Roles.STUDENT ? <AmplifySignIn slot="sign-in" headerText={`Sign In to your PLYTIME ${role?.toLowerCase()} account`}
          formFields={[
            {
              type: 'username',
              label: 'Username *',
              placeholder: 'Enter your username',
              required: true,
              value: username !== undefined ? username : '',
            },
            {
              type: 'password',
            }
          ]}>
          <div slot="header-subtitle">{subtitle()} <Link onClick={switch_role} to={'/select_account_type'}>Click here to login as different user.</Link></div>
          <div slot="federated-buttons"></div>
          <div slot="secondary-footer-content"></div>
          <div slot="federated-buttons"></div>
        </AmplifySignIn> : <AmplifySignIn slot="sign-in" headerText={`Sign In to your PLYTIME ${role?.toLowerCase()} account`}>
          <div slot="header-subtitle">{subtitle()} <Link onClick={switch_role} to={'/select_account_type'}>Click here to login as different user.</Link></div>
          <div slot="federated-buttons"></div>
          {role !== 'PARENT' ? <div slot="secondary-footer-content"></div> : null}
          <div slot="federated-buttons"></div>
        </AmplifySignIn>}

        <AmplifySignUp
          slot="sign-up"
          headerText={`Create PLYTIME ${role?.toLowerCase()} account`}
          formFields={[
            { type: 'username', label: 'Email' },
            {
              type: 'password',
              label: 'Password',
              placeholder: 'Password rules here'
            }
          ]}
        />

      </AmplifyAuthenticator>
    </PublicPlytimePage>
})

export default SignInPage
