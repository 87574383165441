import { FC, useState } from 'react'
import styled from 'styled-components'
import { send_note } from 'util/api/notes'
import { Props } from './props'
import { IonButton } from '@ionic/react'

export const SendNote:FC<Props> = ({ student, subject }) => {
  const [message, set_message] = useState('')
  const [sending, set_sending] = useState(false)

  const submit = async () => {
    set_sending(true)
    try {
      await send_note(student, subject, message)
      set_message('')
    } finally {
      set_sending(false)
    }
  }

  return (
    <Send>
      <Msg>
        <Input
          onChange={evt => set_message(evt.target.value)}
          value={message}
          placeholder="Type message here..."
        />
      </Msg>
      <Submit
        disabled={sending || message === ''}
        onClick={submit}
        size='small'
        color='primary'
      >
        Send
      </Submit>
    </Send>
  )
}

const Input = styled.textarea`
  outline: none;
  resize: none;
  border: none;
  padding: 0;
`

const Send = styled.div`
  height: 66px;
  display: flex;
  flex-direction: row;
`

const Msg = styled.div`
  align-self: flex-start;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0.1rem solid var(--ion-color-tertiary);
  border-radius: 1rem 1rem 1rem 0rem;
  padding: 0.3rem;
  &:focus-within {
    border: 0.1rem solid var(--ion-color-secondary);
  }
`

const Submit = styled(IonButton)`
  width: 20%;
  height: 100%;
`
