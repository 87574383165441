import { FC, ChangeEvent, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { AnswerElement } from 'common/types/question/question'
import { AllowedAnswers } from 'common/types/question/answer/answer'
import { useStore } from 'types/store'
import { GbpInputElement } from 'common/types/question/elements/gbp'
import { Container, ColumnContainer, Input } from 'generic_components/AnswerInputContainers'

interface Props {
  element: Instance<GbpInputElement>,
  handleChange: (e: ChangeEvent) => void,
  answer_decision: string,
  correct_answers: Instance<AllowedAnswers>,
  value: string,
  element_id: string,
  auto_focus_element?: Instance<AnswerElement>,
}

const calculateLength = (x: string): number => x.length <= 4 ? 4 : x.length + 1

export const GbpInputComponent: FC<Props> = observer(({ element, handleChange, answer_decision, correct_answers, value, element_id, auto_focus_element }) => {
  const { game, auth: { user } } = useStore()
  const input_ref = useRef(null)
  
  useEffect(() => {
    if( element === auto_focus_element ) {
      input_ref.current.focus()
    }
  }, [element])


  const answer = correct_answers.length > 0 && answer_decision != 'CORRECT'
    ? correct_answers[0].find(answerPart => answerPart.ref === element.ref).values.toString()
    : ''

  const size = Math.max(calculateLength(value), calculateLength(answer))

  const handleInputChange = (e) => {
    const input = e.target.value.replaceAll(/[^0-9.]/g, '')
    const numbers = input.replaceAll('.','')
    const reformatted = numbers.length <= 2 
      ? input :
      `${numbers.slice(0,-2)}.${numbers.slice(-2)}`
    e.target.value = reformatted
    handleChange(e)
  }
  
  return (
    <ColumnContainer>
      <Container>
        <Input
          id={element_id}
          onChange={(e) => handleInputChange(e)}
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          placeholder='_.__'
          value={value}
          size={size}
          ref={input_ref}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              game.submit_answer(user._id)
            }
          }}
        />
        {answer_decision !== 'CORRECT' && correct_answers.length > 0 && 
          <>
            <Input
              value={correct_answers[0].find(answerPart => answerPart.ref === element.ref)?.values[0] || 'error'}
              answer_decision="CORRECT"
              readOnly={true}
              size={size}
            />
          </>
        }
      </Container>
    </ColumnContainer>
  )
})
