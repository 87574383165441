import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonSelect, IonSelectOption } from '@ionic/react'
import { ButtonWithImage } from 'generic_components/ButtonWithImage'
import { FC, useEffect, useRef, useState } from 'react'
import { checkmarkCircleSharp, alertCircle } from 'ionicons/icons'
import useDevices from './useDevices'
import { useCameraTest } from './useCameraTest'

interface Props {
  onNext: ()=>void
}

export const CameraDiag:FC<Props> = ({onNext}) => { 
  const [error,setError] = useState<any|undefined>()
  const [success,setSuccess] = useState(false)
  const { videoInputDevices } = useDevices()
  const { videoElementRef, startVideoTest, stopVideoTest, videoTest, videoTestError } = useCameraTest()

  const prevVideoDeviceID = useRef('')
  const [videoInputDeviceID, setVideoInputDeviceID] = useState('')


  useEffect(() => {
   
    const newDeviceSelected = prevVideoDeviceID.current !== videoInputDeviceID
    prevVideoDeviceID.current = videoInputDeviceID

    if (videoInputDeviceID && newDeviceSelected) {
      startVideoTest(videoInputDeviceID)
    }

    if (videoTestError) {
      stopVideoTest()
    }
    
  }, [videoInputDeviceID, startVideoTest, stopVideoTest, videoTestError])

  useEffect(() => {
    // If no device is select, set the first available device as the active device.
    const hasSelectedDevice = videoInputDevices.some((device) => device.deviceId === videoInputDeviceID)
    if (videoInputDevices.length && !hasSelectedDevice) {
      setVideoInputDeviceID(videoInputDevices[0].deviceId)
    }
  }, [videoInputDevices, videoInputDeviceID])
  return (<IonCard>
    <IonCardHeader>
      <IonCardTitle>
        {error ? <IonIcon icon={alertCircle} style={{color:'red'}}/> : success ? <IonIcon icon={checkmarkCircleSharp} style={{color:'green'}}/> : null}Camera Diagnostics
      </IonCardTitle>
      <IonCardSubtitle>Wait until camera starts then check your video. Move in front of your camera to make sure it&apos;s working. If you don&apos;t see your video, try changing the
            selected camera. If the camera isn&apos;t part of your computer, check your settings to make sure your system
            recognizes it.
      </IonCardSubtitle>
    </IonCardHeader>

    <IonCardContent>
     
      
      <IonSelect
        onIonChange={(e) => setVideoInputDeviceID(e.detail.value as string)}
        value={videoInputDeviceID}
       
        disabled={!!videoTestError}
      >
        {videoInputDevices.map((device) => (
          <IonSelectOption value={device.deviceId} key={device.deviceId}>
            {device.label}
          </IonSelectOption>
        ))}
      </IonSelect>
      
      <div >
        <video height={300} autoPlay playsInline ref={videoElementRef} />
      </div>
      <div>
        <strong>Does your video look ok?</strong>
      </div>
      <IonButton color="primary" onClick={()=>{
        setSuccess(true)
        setError(undefined)
        // stopVideoTest()
        onNext()
      }}>
            Yes
      </IonButton>
      <IonButton color="primary" onClick={()=>{
        setError('true')
        setSuccess(false)
        onNext()
      }}>
            Skip for now
      </IonButton>
      <div>
        {(error || videoTestError) && <strong>Could not verify video.</strong>}
      </div>
    </IonCardContent>
  </IonCard>)
}
