import { types, Instance, flow, applySnapshot } from 'mobx-state-tree'
import { AdminSubjects } from 'common/types/subject/subject'
import { getAllSubjects } from 'util/api/subject'

export const SubjectList = types
  .model({
    subjects: AdminSubjects,
  })
  .actions(self => ({
    refresh: flow(function* () {
      try {
        const subjects = yield getAllSubjects()
        applySnapshot(self.subjects, subjects)
      } catch (error) {
        console.error('Failed list Subjects', error)
      }
    })
  })
  )

export type SubjectList = Instance<typeof SubjectList>
