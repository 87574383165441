import { Instance, flow, getSnapshot, applySnapshot, getParent, SnapshotIn } from 'mobx-state-tree'
import { getEndcode, postTopicEndcode } from 'util/api/subject/theme/topic/endcode'
import { AdminEndcode as BaseEndcode } from 'common/types/subject/endcode'

export const Endcode = BaseEndcode
  .actions(self => ({
    update_name (name:string) {
      self.name = name
    },
    update_min_score (score:number) {
      self.min_score = score
    },
    update_max_score (score:number) {
      self.max_score = score
    },
    save: flow(function*(update:SnapshotIn<BaseEndcode>) {
      const old = getSnapshot(self)
      try {
        const parent = getParent<any>(self, 2) //TODO this is a risky approach there is nothing to stop someone instantiation a Question in another store
        applySnapshot(self, update)
        const updated_self = yield postTopicEndcode({
          params: {
            topic_id: parent._id 
          },
          body: update
        })
        applySnapshot(self, updated_self)
      } catch (error) {
        applySnapshot(self, old)
        throw error
      }
    }),
    refresh: flow(function*() {
      try {
        const updated_self = yield getEndcode(self._id)
        applySnapshot(self, updated_self)
        const topic = getParent<any>(self, 2) //TODO this is a risky approach there is nothing to stop someone instantiating a Question in another store
        yield topic.refresh()
      } catch (error) {
        console.error('Failed to refesh endcode', error)
      }
    })
  }))

export type Endcode = Instance<typeof Endcode>
