import { FC } from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
} from '@ionic/react'
import { Session } from 'components/sessions/useUpcomingSessions'
import { Notes } from 'components/notes'
import styled from 'styled-components'
import { closeCircleOutline } from 'ionicons/icons'

interface Props {
  session: Session
  close: () => void
}

export const Messages:FC<Props> = ({session, close}) => {
  return (
    <Card>
      <IonCardHeader>
        <DismissTitleBar>Messages <IonIcon onClick={close} icon={closeCircleOutline} size='large'/></DismissTitleBar>
        <IonCardSubtitle>for {session.subject.name} with {session.student.first_name}</IonCardSubtitle>
      </IonCardHeader>
      <Content>
        <Notes student={session.student_id} subject={session.subject._id}/>
      </Content>
    </Card>
  )
}

const Card = styled(IonCard)`
  margin: 0;
  height: 25rem
`

const Content = styled(IonCardContent)`
  height: 75%;
`

const DismissTitleBar = styled(IonCardTitle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
