import { FC, useState, useRef, useEffect } from 'react'
import { Instance } from 'mobx-state-tree'
import styled from 'styled-components'
import { useIonViewDidEnter, useIonViewWillLeave, IonCard, IonCardContent, IonProgressBar } from '@ionic/react'
import { StudentSession } from 'types/session/student_session'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'

interface Props {
  session: Instance<StudentSession>
  remote_name: (session:Instance<StudentSession>) => string
}

export const SessionTimer: FC<Props> = observer(({session, remote_name}) => {
  const [time_remaining, set_time_remaining] = useState(0)
  const [time_till_start, set_time_till_start] = useState(0)
  const [color, set_color] = useState('primary')
  const [countdown, set_countdown] = useState(0)
  const [progress, set_progress] = useState(0)
  const interval = useRef<NodeJS.Timeout|undefined>()

  const setup = () => {
    cleanup()
    if( session === undefined ) {
      return
    }
    const update_time_remaining = () => {
      const start = DateTime.fromISO(session.session_date_time) 
      const end = start.plus({minutes: session.session_length})
      const early = Math.max(start.diffNow().as('minutes'), 0)
      const remain = Math.min(Math.max(end.diffNow().as('minutes'), 0), session.session_length)
      set_time_remaining(Math.ceil(remain))
      set_time_till_start(Math.ceil(early))
      set_color(remain < 1 ? 'danger' : remain < 2 ? 'warning' : 'primary')
      set_progress(1 - (remain / session.session_length))
      set_countdown(1 - (Math.min(early, 5) / 5))
    }
    update_time_remaining()
    if( interval.current !== undefined ) {
      clearInterval(interval.current)
    }
    interval.current = setInterval(update_time_remaining, 1000)
  }

  const cleanup = () => {
    if( interval.current !== undefined ) {
      clearInterval(interval.current)
    }
  }
  useIonViewDidEnter(setup) // I expected these to fire but looks like they dont in this scenario, Ionic is effing annoying
  useIonViewWillLeave(cleanup)
  useEffect(() => {
    setup()
    return cleanup
  }, [session])

  return (
    <IonCard>
      <IonCardContent>
        <Row>
          <Text>{!session ? 'Loading...' : `${session.subject.name} with ${remote_name(session)}`}</Text>
        </Row>
        <Row>
          {session === undefined
            ? <Text>Loading...</Text>
            : time_till_start > 60
              ? <Text>Starts on {DateTime.fromISO(session.session_date_time).toFormat('ccc d MMM @ HH:mm')}</Text>
              : time_till_start > 1
                ? <Text>Starts in {time_till_start} mins</Text>
                : time_till_start > 0
                  ? <Text>Starting now</Text>
                  : time_remaining > 1
                    ? <Text>{`${time_remaining} mins remaining`}</Text>
                    : time_remaining > 0
                      ? <Text>Last minute</Text>
                      : <Text>Was on {DateTime.fromISO(session.session_date_time).toFormat('ccc d MMM @ HH:mm')}</Text>
          }
        </Row>
        <Row>
          <Intro value={countdown} color={'medium'}/>
          <Progress value={progress} color={color}/>
        </Row>
      </IonCardContent>
    </IonCard>
  )
})

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Intro = styled(IonProgressBar)`
  flex: 1 1
`

const Progress = styled(IonProgressBar)`
  flex: 3 3
`

const Text = styled.p`
  white-space: nowrap;
`
