import { FC, useEffect, useState, FormEvent } from 'react'
import { getSnapshot, IAnyModelType, Instance, SnapshotIn } from 'mobx-state-tree'
import { observer } from 'mobx-react-lite'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonToast,
  IonToolbar,
} from '@ionic/react'
import { FormInputError } from 'generic_components/FormInputError'
import { chain } from 'lodash'
import styled from 'styled-components'

export interface FieldComponentProps<Value extends unknown> {
  value: Value,
  onChange: (value: unknown) => void,
  disabled: boolean,
  required?: boolean
}
export type FieldComponent<Value extends unknown> = FC<FieldComponentProps<Value>>

interface FieldSpec<Value extends unknown> {
  name: string
  label: string
  Component: FieldComponent<Value>
  width_percent?: number
  disabled?: boolean
  required?: boolean
}

interface Props<M extends IAnyModelType> {
  title?: string
  subtitle?: string
  model: M
  initial: Instance<M>
  submit: (out: SnapshotIn<M>) => Promise<unknown>
  on_reset?: () => void
  fields: FieldSpec<any>[]
  force_edit_mode?: boolean
  submit_text?: string
  cancel_text?: string
  success_text?: string
  right_buttons?: any
  hide_cancel?: boolean
}

export const MSTForm: FC<Props<IAnyModelType>> = observer(({
  initial, model, fields, submit, on_reset = () => undefined,
  force_edit_mode = false, submit_text = 'Save', cancel_text = 'Cancel', success_text = 'Saved', title, subtitle,
  hide_cancel = false, right_buttons
}) => {

  const [draft, set_draft] = useState<Record<string, unknown>>(getSnapshot(initial))
  const [errors, set_errors] = useState<Record<string, string[]>>({})
  const [has_errors, set_has_errors] = useState(false)
  const [dirty, set_dirty] = useState(false)
  const [editting, set_editting] = useState(force_edit_mode)
  const [toast, set_toast] = useState<{ success: boolean, message: string } | undefined>()

  const reset = () => {
    set_draft(() => getSnapshot(initial))
    set_errors(() => ({}))
    set_has_errors(false)
    set_dirty(false)
    set_editting(force_edit_mode)
  }

  useEffect(reset, [getSnapshot(initial)]) // need to serialise the whole object to update state on field changes

  const onCancel = (e: FormEvent) => {
    e.stopPropagation()
    e.preventDefault()
    reset()
    on_reset()
  }

  const validate = () => {
    const errors = model.validate(draft, [])

    const has_errors = errors.length > 0

    const errors_per_field = chain(errors)
      .groupBy(e => e.context[0].path)
      .mapValues(errors => errors.map(
        error => error.message
      ))
      .value()

    set_errors(errors_per_field)
    set_has_errors(has_errors)

    return !has_errors
  }

  const onSubmit = async (e: FormEvent) => {
    e.stopPropagation()
    e.preventDefault()

    if (validate()) {
      try {
        await submit(draft)
        if (success_text !== '') {
          set_toast({
            success: true,
            message: success_text
          })
        }
        reset()
      }
      catch (e) {
        set_toast({
          success: false,
          message: `Error: ${e.errors?.join(', ')}`
        })
      }
    }
  }

  const onChange = (field: string) => (value: unknown) => {
    set_draft(draft => ({
      ...draft,
      [field]: value
    }))
    set_dirty(true)
    // Dont show errors until they have tried submitting once
    if (has_errors) {
      validate()
    }
  }

  return (
    <Form onSubmit={onSubmit} onReset={onCancel}>
      <IonCard>
        {(title || subtitle) &&
          <IonCardHeader>
            {title && <IonCardTitle>{title}</IonCardTitle>}
            {subtitle && <IonCardSubtitle>{subtitle}</IonCardSubtitle>}
          </IonCardHeader>
        }
        <IonCardContent>
          <IonToast
            color={toast?.success ? 'success' : 'danger'}
            isOpen={toast !== undefined}
            onDidDismiss={() => set_toast(undefined)}
            message={toast?.message}
            position="middle"
            duration={2000}
          />
          <FormFields>
            {fields.map(({ name, label, Component, width_percent = 50, disabled = false, required = false }) =>
              <FormField key={name} lines='inset' width_percent={width_percent}>
                <IonLabel position='stacked'>{label}</IonLabel>
                <Component value={draft[name]} onChange={onChange(name)} disabled={!editting || disabled} required={required} />
                {errors[name]
                  ?.map((error, i) => {
                    const error_text = error !== 'Value is not a plain object'
                      ? error
                      : 'Value is not allowed'
                    return <FormInputError key={i} error={error_text} field={name} />
                  }
                  )}
              </FormField>
            )
            }
          </FormFields>
          <FormErrors>
            {has_errors && Object.entries(errors)
              .filter(([field, errors]) => fields.find(f => f.name === field) === undefined)
              .flatMap(([field, errors], i) =>
                errors.map((error, j) =>
                  <IonItem key={`${i}.${j}`}>
                    <FormInputError error={`${field}: ${error}`} field={''} />
                  </IonItem>
                ))
            }
          </FormErrors>
          <IonToolbar>
            <IonButtons slot='start'>
              {!hide_cancel &&
                <IonButton
                  fill='solid'
                  size='small'
                  color='secondary'
                  className="ion-margin-top"
                  type="reset"
                  style={editting ? {} : { display: 'none' }}
                >
                  {cancel_text}
                </IonButton>
              }
              <IonButton
                fill='solid'
                size='small'
                color='primary'
                className="ion-margin-top"
                type="submit"
                disabled={!dirty || has_errors}
                style={editting ? {} : { display: 'none' }}
              >
                {submit_text}
              </IonButton>
              {!force_edit_mode &&
                <IonButton
                  fill='solid'
                  size='small'
                  color='primary'
                  className="ion-margin-top"
                  type="button"
                  style={editting ? { display: 'none' } : {}}
                  onClick={() => set_editting(true)}
                >
                  Edit
                </IonButton>
              }
            </IonButtons>
            <IonButtons slot='end'>
              {right_buttons}
            </IonButtons>
          </IonToolbar>
        </IonCardContent>
      </IonCard>
    </Form>
  )
})

const Form = styled.form`
  max-width: 60em;
`

const FormErrors = styled.div`
  display: flex;
  flex-direction: column;
`

const FormFields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const FormField = styled(IonItem)`
  flex: 0 0 ${props => props.width_percent}%;
`
