import { FC } from 'react'
import { IonIcon, IonSegment, IonSegmentButton } from '@ionic/react'
import { LocalVideoTrack } from 'twilio-video'
import { desktopOutline, closeCircleOutline } from 'ionicons/icons'
import { Card, Content, Controls, SideVideo } from 'components/sessions/focus15'
import { Video } from 'components/sessions/focus15/Video'

interface Props {
  track: LocalVideoTrack,
  toggle_screen: () => void,
  disabled: boolean
}

export const ScreenShareCard: FC<Props> = ({track, toggle_screen, disabled}) => {
  const enabled = track !== undefined && track.isEnabled ? 'on' : 'off'
  return (
    <Card>
      <Content>
        <SideVideo>
          {track && <Video track={track} style={{ borderRadius:'50%', objectFit:'contain', maxHeight: '100%', maxWidth: '100%'}}/>}
        </SideVideo>
        <Controls>
          <IonSegment 
            onIonChange={toggle_screen}
            value={enabled}
            disabled={disabled}
          >
            <IonSegmentButton value={'on'} style={{minWidth: 0}}>
              <IonIcon icon={desktopOutline}/>
            </IonSegmentButton>
            <IonSegmentButton value={'off'} style={{minWidth: 0}}>
              <IonIcon icon={closeCircleOutline}/>
            </IonSegmentButton>
          </IonSegment>
        </Controls>
      </Content>
    </Card>
  )
}
