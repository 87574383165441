import { types, SnapshotOut } from 'mobx-state-tree'
import { AdminQuestion } from './question'
import { NumberFromStringOrNumber } from 'common/types/basic/number_from_string_or_number'

export const QuestionSearchFilter = types.model('QuestionSearchFilter',{
  status: types.array(types.string),
  ref: types.optional(types.string, ''),
  _from: NumberFromStringOrNumber,
  _limit: NumberFromStringOrNumber
})
export type QuestionSearchFilter = SnapshotOut<typeof QuestionSearchFilter>

export const QuestionSearchRequest = types.model({
  query: QuestionSearchFilter
})
export type QuestionSearchRequest = SnapshotOut<typeof QuestionSearchRequest>

export const ListMetadata = types.model('ListMetadata',{
  from: types.number,
  to: types.number,
  of: types.number
})
export type ListMetadata = SnapshotOut<typeof ListMetadata>

export const QuestionSearchResponse = types.model('QuestionSearchResponse',{
  data: types.array(AdminQuestion),
  metadata: ListMetadata,
})
export type QuestionSearchResponse = SnapshotOut<typeof QuestionSearchResponse>
