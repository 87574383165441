import { FC, useState, useEffect } from 'react'
import { getTutorAvailability } from 'util/api/tutor/availabilty'
import { Availability } from 'components/tutor_availability/Availability'

const empty_availability = {
  0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [],
}

interface Props {
  tutor_id: string
}

export const TutorProfileAvailability:FC<Props> = ({tutor_id}) => {

  const [selectedTimes, setSelectedTimes] = useState(empty_availability)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const f = async () => {
      try {
        const { availability } = await getTutorAvailability(tutor_id)
        setSelectedTimes(availability)
      } catch (e) {
        console.dir(e)
        setSelectedTimes(empty_availability)
      }
      setIsLoading(false)
    }
    f()
  }, [tutor_id])

  const totalNumberOfTimes: number = Object.keys(selectedTimes).reduce((acc, day) => acc + selectedTimes[day].length, 0)

  return (
    <>
      {isLoading && <p>Loading...</p>}
      {totalNumberOfTimes > 0 && <Availability availability_times={selectedTimes}/>}
      {totalNumberOfTimes === 0 && !isLoading && <p>Not yet set.</p>}
    </>
  )
}
