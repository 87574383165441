import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px;
  align-items: center;
`

export const Input = styled.input`
  border-color: ${props => !props.answer_decision ? 'grey' : props.answer_decision === 'CORRECT' ? 'green' : 'red'};
  margin: 4px;
  margin-top: 0;
  text-align: center;
  ${props => props.answer_decision && '&:focus { outline: none }'};
`

export const NoBorderInput = styled.input` 
  border: 0;
  margin: 4px;
  margin-top: 0;
  text-align: center;
  ${props => props.answer_decision && '&:focus { outline: none }'};
`

export const BorderWrapper = styled.div`
  border-color: ${props => !props.answer_decision ? 'grey' : props.answer_decision === 'CORRECT' ? 'green' : 'red'};
  border-style: solid;
  margin-bottom: 4px;
`
