import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { SubjectSelector } from './common/SubjectSelector'
import { Tutor } from 'types/admin/tutor'
import { MSTForm } from 'generic_components/MstForm'
import { StringInput } from 'generic_components/MstFormStringInput'
import { StringSelector } from 'generic_components/MstFormStringSelector'
import { DateInput } from 'generic_components/MstFormDate'
import { Gender } from 'common/types/basic/gender'
import { Country } from 'common/types/basic/country'

interface Props {
  tutor: Tutor
}

const GenderSelector = StringSelector((Gender as any)._types)
const CountrySelector = StringSelector((Country as any)._types)

export const TutorDetails: FC<Props> = observer(({ tutor }) => {

  return (
    <MSTForm
      title='Profile Details'
      initial={tutor}
      model={Tutor}
      submit={tutor.save}
      fields={[{
        name: 'first_name',
        label: 'First Name',
        Component: StringInput,
        width_percent: 100,
        required: true
      }, {
        name: 'last_name',
        label: 'Last Name',
        Component: StringInput,
        width_percent: 100,
        required: true
      }, {
        name: 'email',
        label: 'Email Address',
        Component: StringInput,
        width_percent: 100,
        disabled: true
      }, {
        name: 'subject',
        label: 'Subject',
        Component: SubjectSelector,
        width_percent: 50,
      }, {
        name: 'phone_number',
        label: 'Phone Number',
        Component: StringInput,
        width_percent: 50,
        required: true
      }, {
        name: 'dob',
        label: 'Date of Birth',
        Component: DateInput,
        width_percent: 50,
      }, {
        name: 'gender',
        label: 'Gender',
        Component: GenderSelector,
        width_percent: 50,
      }, {
        name: 'country',
        label: 'Country',
        Component: CountrySelector,
        width_percent: 50,
      }, {
        name: 'postcode',
        label: 'Post Code',
        Component: StringInput,
        width_percent: 50,
        required: true
      }, {
        name: 'bank_account',
        label: 'Bank Account',
        Component: StringInput,
        width_percent: 50,
      }, {
        name: 'sort_code',
        label: 'Sort Code',
        Component: StringInput,
        width_percent: 50,
      }, {
        name: 'dbs_issue_date',
        label: 'DBS Issue date',
        Component: DateInput,
        width_percent: 50,
      }]}
    />
  )
})
