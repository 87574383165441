import { Instance, types } from 'mobx-state-tree'

export const SchoolYearInteger = types.refinement(
  'SchoolYearInteger',
  types.number,
  (snapshot:number) => Number.isInteger(snapshot) && snapshot > 0 && snapshot <= 6,
  () => 'Must be a whole number from 1 to 6. Content for other years coming soon.'
)
export type SchoolYearInteger = Instance <typeof SchoolYearInteger>
export const SupportedSchoolYearRange = [1,2,3,4,5,6]
