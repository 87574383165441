import { types, Instance } from 'mobx-state-tree'
import { DateTimeString } from '../basic/date_time_string'


export const Log = types.model({
  message: types.string,
  timestamp: DateTimeString,
  level: types.string,
})
export type Log = Instance<typeof Log>
