import { types, Instance, getParent } from 'mobx-state-tree'
import { ThemeId } from '../question_management/theme/id'
import { Topic } from './topic'

export const Theme = types
  .model('Theme', {
    _id: ThemeId,
    name: types.string,
    topics: types.array(Topic),
    selected: types.optional(types.boolean, false)
  })
  .actions(self => ({
    select(selected: boolean) {
      self.selected = selected
      if (selected) {
        self.topics.forEach(topic => {
          topic.select(false)
        })
        const subject = getParent(self, 2) as any
        subject?.select?.call(subject, false)
      }
    }
  }))

export type Theme = Instance<typeof Theme>