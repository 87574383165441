import { IonItem, IonText } from '@ionic/react'

export const AvailabilityLegend = () => {
  return (
    <div>
      <IonText color={'secondary'}>
        <span>Booked</span>
      </IonText>
      <span> </span>
      <IonText color={'success'}>
        <span>Available</span>
      </IonText>
    </div>
  )
}
