import { types } from 'mobx-state-tree'

const Stats = types.model({
  _id: types.string,
  name: types.string,
  min: types.number,
  max: types.number,
  current_score: types.maybe(types.number),
  previous_score: types.maybe(types.maybeNull(types.number)),
  recent_answer_count: types.maybe(types.number),
})
export const EndcodeStats = Stats.props({
  description: types.string,
  priority: types.enumeration(['1','2','3'])
})
export type EndcodeStats = typeof EndcodeStats

export const TopicStats = Stats.props({
  endcodes: types.array(EndcodeStats)
})
export type TopicStats = typeof TopicStats

export const ThemeStats = Stats.props({
  topics: types.array(TopicStats)
})
export type ThemeStats = typeof ThemeStats

export const SubjectStats = Stats.props({
  themes: types.array(ThemeStats)
})
export type SubjectStats = typeof SubjectStats

export const StudentStats = types.array(SubjectStats)
export type StudentStats = typeof StudentStats

export const StudentStatsRequest = types.model({
  params: types.model({
    student_id: types.string
  }),
})
export type StudentStatsRequest = typeof StudentStatsRequest
