import { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from 'types/store'
import { IonSelect, IonSelectOption, IonItem, IonLabel } from '@ionic/react'
import styles from './style.module.css'
import { getSnapshot } from 'mobx-state-tree'

interface SubjectSelectorProps {
  user_id: string
}

export const SubjectSelector: FC<SubjectSelectorProps> = observer(({ user_id }: SubjectSelectorProps) => {
  const { focus: { subjects, refresh }, selected_subjects } = useStore().game
  const { student_mixes_list: { mixes, refresh: refresh_mixes } } = useStore()
  const [selectedSubject, setSelectedSubject] = useState('')

  useEffect(() => {
    refresh()
    refresh_mixes(user_id)
    if (subjects.length > 0) {
      setSelectedSubject(subjects[0]._id)
      selected_subjects.set_subjects([subjects[0]._id])
    }
  }, [])

  const onChange = (e) => {
    const subject = subjects.find(subject => subject._id === e.detail.value)
    if (subject) {
      setSelectedSubject(subject._id)
      selected_subjects.set_subjects([subject._id])
    } else {
      // mix selected
      const selected_mix = mixes.find(mix => mix._id === e.detail.value)
      if (selected_mix) {
        setSelectedSubject(selected_mix._id)
        console.log(selected_mix.subjects)
        selected_subjects.set_subjects(getSnapshot(selected_mix.subjects))
      }
    }
  }
  return (
    <div className={styles.selector} id={'subject'}>
      {subjects &&
        <IonItem lines='none' cy-data='subject_selector'>
          <IonLabel style={{ fontSize: '1.25rem' }}>Select the subject to get questions on:</IonLabel>
          <IonSelect onIonChange={onChange} value={selectedSubject}>
            {[...subjects, ...mixes].map(subject => <IonSelectOption key={subject._id} value={subject._id}>{subject.name}</IonSelectOption>)}
          </IonSelect>
        </IonItem>
      }
    </div >
  )
})
