import { types } from 'mobx-state-tree'

export enum SessionOptionEnum {
  PlatformOnly = 'Platform Only',
  OneFifteenMin = '1 X 15 mins',
  TwoFifteenMin = '2 X 15 mins',
  ThreeFifteenMin = '3 X 15 mins',
  FourFifteenMin = '4 X 15 mins',
  FiveFifteenMin = '5 X 15 mins',
  SixFifteenMin = '6 X 15 mins',
  SevenFifteenMin = '7 X 15 mins'
}

export const SessionOptions = types.enumeration( 'SessionOptions', Object.values(SessionOptionEnum))
export type SessionOptions = typeof SessionOptions

export const ApproxTime = types.enumeration( 'ApproxTimes', [
  'Morning (08:00-11:45)',
  'Early Afternoon (12:00-14:45)',
  'Late Afternoon (15:00-17:45)',
  'Early Evening (18:00-20:45)',
  'Late Evening (21:00-23:45)',
])
export type ApproxTime = typeof ApproxTime
