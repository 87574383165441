import { FC } from 'react'
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
} from '@ionic/react'
import { useUpcomingSessions } from 'components/sessions/useUpcomingSessions'
import { SessionActions } from 'components/admin/tutor_sessions/actions'
import { usePastSessions } from 'components/sessions/usePastSessions'

interface Props {
  tutor: string
}
export const TutorUpcommingSessions: FC<Props> = ({ tutor }) => {
  const { sessions, busy, refresh } = useUpcomingSessions(tutor)
  const { past_sessions, past_busy, past_refresh,  load_previous_week } = usePastSessions(tutor)
  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
          Upcoming sessions
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow style={{ height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast' }}>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Date </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Time </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Subject </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Student </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Status </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Actions </IonLabel>
              </IonCol>
            </IonRow>
            {busy &&
            <IonRow className='alternate-light-medium'>
              Loading...
            </IonRow>
            }
            {!busy && sessions.length === 0 &&
            <IonRow className='alternate-light-medium'>
              No upcoming sessions
            </IonRow>
            }
            {sessions.map(session => {
              return (
                <IonRow key={session.session_date_time} className='alternate-light-medium'>
                  <IonCol size='2'>
                    {session.day}
                  </IonCol>
                  <IonCol size='1'>
                    {session.time}
                  </IonCol>
                  <IonCol size='2'>
                    {session.subject.name}
                  </IonCol>
                  <IonCol size='2'>
                    {session.student.first_name}
                  </IonCol>
                  <IonCol size='2'>
                    {session.sessionStatus}
                  </IonCol>
                  <IonCol size='1'>
                    <SessionActions session={session} refresh={refresh} />
                  </IonCol>
                </IonRow>
              )
            })}
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            Past sessions
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow style={{ height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast' }}>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Date </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Time </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Subject </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Student </IonLabel>
              </IonCol>
              <IonCol size='2'>
                <IonLabel style={{ height: '100%' }}> Status </IonLabel>
              </IonCol>
              <IonCol size='1'>
                <IonLabel style={{ height: '100%' }}> Actions </IonLabel>
              </IonCol>
            </IonRow>
            {past_busy &&
            <IonRow className='alternate-light-medium'>
              Loading...
            </IonRow>
            }
            {!past_busy && sessions.length === 0 &&
            <IonRow className='alternate-light-medium'>
              No upcoming sessions
            </IonRow>
            }
            {past_sessions.map(session => {
              return (
                <IonRow key={session.session_date_time} className='alternate-light-medium'>
                  <IonCol size='2'>
                    {session.day}
                  </IonCol>
                  <IonCol size='1'>
                    {session.time}
                  </IonCol>
                  <IonCol size='2'>
                    {session.subject.name}
                  </IonCol>
                  <IonCol size='2'>
                    {session.student.first_name}
                  </IonCol>
                  <IonCol size='2'>
                    {session.sessionStatus}
                  </IonCol>
                  <IonCol size='1'>
                    <SessionActions session={session} refresh={refresh} />
                  </IonCol>
                </IonRow>
              )
            })}
            {past_busy
              ? <IonRow className='alternate-light-medium'>
                Loading...
              </IonRow>
              : <IonRow
                className='alternate-light-medium'
                onClick={load_previous_week}
              >
                {sessions.length === 0 ? 'No past sessions this week, click here to try loading the previous week' : 'Load Previous Week'}
              </IonRow>
            }
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </>
  )
}
