import { FC, useState } from 'react'
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from '@ionic/react'
import { rescheduleSubscription } from 'util/api/subscriptions'
import { Session } from 'components/sessions/useUpcomingSessions'
import { Days } from 'common/types/basic/day'
import { Hours, Minutes } from 'components/tutor_availability/SlotTimes'
import styled from 'styled-components'

interface Props {
  session: Session
  close: () => void
}

export const RescheduleSubscription:FC<Props> = ({session, close}) => {
  const hour = Math.floor(session.session_time / 60)
  const minute = session.session_time % 60
  const [busy, set_busy] = useState(false)
  const [new_minute, set_new_minute] = useState(minute)
  const [new_hour, set_new_hour] = useState(hour)
  const [new_day, set_new_day] = useState(session.day_of_week)

  const reschedule_subscription = async () => {
    set_busy(true)
    try{
      await rescheduleSubscription({
        params: { subscription_id: session.subscription_id },
        body: {
          old_day_of_week: session.day_of_week,
          old_time: session.session_time,
          new_day_of_week: new_day,
          new_time: 60*new_hour + new_minute,
          session_length: session.session_length,
        }
      })
      close()
    } finally {
      set_busy(false)
    }
  }

  return (
    <IonCard style={{margin: 0, height: '25rem'}}>
      <IonCardHeader>
        <IonCardTitle>Reschedule future sessions</IonCardTitle>
        <IonCardSubtitle>{session.subject.name} with {session.student.first_name} on {Days[session.day_of_week]} at {hour}:{Math.floor(minute/10)}{minute%10}</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <ul>
          <li>
            <p>Whenever rescheduling sessions ensure you have confirmed the new time is acceptable with the Parent in a message</p>
          </li>
          <li>
            <p>This only reschedules sessions from the next payment period. If any sessions have been individually rescheduled they will not be updated which may result in excess sessions. therefore you must review the sessions list and use the individual reschedule and cancel actions to resolve any discrepencies.</p>
          </li>
        </ul>
        <Row>
          <IonItem lines='inset' id='day_selector'>
            <IonLabel position='stacked'>Day</IonLabel>
            <IonSelect
              value={new_day}
              onIonChange={e => set_new_day(e.detail.value)}
              interfaceOptions={{cssClass: 'day_selector'}}
            >
              <IonSelectOption value={0}>Monday</IonSelectOption>
              <IonSelectOption value={1}>Tuesday</IonSelectOption>
              <IonSelectOption value={2}>Wednesday</IonSelectOption>
              <IonSelectOption value={3}>Thursday</IonSelectOption>
              <IonSelectOption value={4}>Friday</IonSelectOption>
              <IonSelectOption value={5}>Saturday</IonSelectOption>
              <IonSelectOption value={6}>Sunday</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem lines='inset' id='from_hour_selector'>
            <IonLabel
              position='stacked'
            >
              Hour
            </IonLabel>
            <IonSelect
              value={new_hour}
              onIonChange={e => set_new_hour(e.detail.value)}
              interfaceOptions={{cssClass: 'from_hour_selector'}}
            >
              <Hours/>
            </IonSelect>
          </IonItem>
          <IonItem lines='inset' id='from_minute_selector'>
            <IonLabel
              position='stacked'
            >
              Minute
            </IonLabel>
            <IonSelect
              value={new_minute}
              onIonChange={e => set_new_minute(e.detail.value)}
              interfaceOptions={{cssClass: 'from_minute_selector'}}
            >
              <Minutes/>
            </IonSelect>
          </IonItem>
        </Row>
        <p>Would you like to reschedule these sessions?</p>
        <IonToolbar>
          <IonButtons slot='end'>
            <IonButton
              fill='solid'
              size='small'
              color='primary'
              className="ion-margin-top"
              onClick={reschedule_subscription}
              disabled={busy || (new_day === session.day_of_week && new_hour === hour && new_minute === minute)}
            >
          Yes
            </IonButton>
          </IonButtons>
          <IonButtons slot='start'>
            <IonButton
              fill='outline'
              size='small'
              color='tertiary'
              className="ion-margin-top"
              onClick={close}
              disabled={busy}
            >
            No
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonCardContent>
    </IonCard>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`
