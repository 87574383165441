import { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import SubscriptionWizardState from './SubscriptionWizardState'
import {
  IonSelect,
  IonSelectOption,
  IonItem,
} from '@ionic/react'

interface Props {
  state: Instance<typeof SubscriptionWizardState>
}

export const LevelSelection: FC<Props> = observer(({ state }) => {
  return (
    <IonItem lines='none' cy-data='level_select'>
      <IonSelect
        name="level_select"
        id="level_select"
        value={state.relative_level}
        onIonChange={(selection) => state.update_relative_level(selection.detail.value)}
      >
        <IonSelectOption key={'y_behind'} value={'A year behind'} class='wide'>1</IonSelectOption>
        <IonSelectOption key={'6_m_behind'} value={'6 Months behind'} class='wide'>2</IonSelectOption>
        <IonSelectOption key={'3_m_behind'} value={'3 Months behind'} class='wide'>3</IonSelectOption>
        <IonSelectOption key={'on_track'} value={'On track'} class='wide'>4</IonSelectOption>
        <IonSelectOption key={'3_m_ahead'} value={'3 months ahead'} class='wide'>5</IonSelectOption>
        <IonSelectOption key={'6_m_ahead'} value={'6 Months ahead'} class='wide'>6</IonSelectOption>
        <IonSelectOption key={'y_ahead'} value={'A year ahead'} class='wide'>7</IonSelectOption>
      </IonSelect>
    </IonItem>
  )
})
