import { IonButton, IonButtons, IonCard, IonCardContent, IonToolbar } from '@ionic/react'
import { Instance } from 'mobx-state-tree'
import { FC } from 'react'

import SubscriptionWizardState from './SubscriptionWizardState'

interface Props {
    state: Instance<typeof SubscriptionWizardState>
}

export const AlreadyPlatformOnly:FC<Props> = ({ state }) => (
  <>
    <IonCard>
      <IonCardContent>
        <p>Your subscription is currently platform only, did you mean to select a subscription with direct access to a tutor?</p>
      </IonCardContent>
    </IonCard>
    <IonToolbar>
      <IonButtons slot='start'>
        <IonButton
          fill='outline'
          size='small'
          color='tertiary'
          className="ion-margin-top"
          onClick={state.back}
        >
                    Back
        </IonButton>
      </IonButtons>
    </IonToolbar>
  </>
)
