import { Component } from 'react'
import NodeGroup from 'react-move/NodeGroup'
import styles from './index.module.css'

const barHeight = 30
const barPadding = 2
const widthScale = d => d * 3

interface Data {
  id: string
  name: string
  value: number,
  y: number,
  colour: string,
  opacity: number,
}
interface BarGroupProps {
  state: Data
  data: Data
  ratio: number
}

function BarGroup(props: BarGroupProps) {
  const width = widthScale(props.state.value) * props.ratio
  const yMid = barHeight * 0.5
  return (
    <g className={styles.bargroup} transform={`translate(0, ${props.state.y})`}>
      <rect
        y={barPadding * 0.5}
        x={14}
        width={width}
        height={barHeight - barPadding}
        style={{ fill: props.data.colour, opacity: props.state.opacity }}
      />
      <text
        className={styles.valuelabel}
        x={width+2}
        y={yMid}
        alignmentBaseline="middle"
      >
        {props.state.value.toFixed(0)}
      </text>
      <text
        className={props.data.id === '0' ? styles.namelabel_player : styles.namelabel}
        x={10}
        y={yMid}
        alignmentBaseline="middle"
        style={{ opacity: props.state.opacity }}
      >
        
        {props.data.name}
      </text>
    </g>
  )
}

interface Props {
  data: { value: number }[]
}

class LeaderBoard extends Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  startTransition(d, i) {
    return { value: 0, y: i * barHeight, opacity: 0 }
  }

  enterTransition(d) {
    return { value: [d.value], opacity: [1], timing: { duration: 250 } }
  }

  updateTransition(d, i) {
    return { value: [d.value], y: [i * barHeight], timing: { duration: 300 } }
  }

  leaveTransition() {
    return { y: [-barHeight], opacity: [0], timing: { duration: 250 } }
  }

  ratio() {
    const limit = 100
    const maxValue = this.props.data[0].value
    if (maxValue < limit) {
      return 1.0
    } else {
      return limit / maxValue
    }
  }



  render() {
    return (
      <div style={{alignSelf: 'flex-start'}}>
        <svg width="400" height="150">
          <g className={styles.chart} transform="translate(80,10)">
            <NodeGroup
              data={this.props.data}
              keyAccessor={d => d.name}
              start={this.startTransition}
              enter={this.enterTransition}
              update={this.updateTransition}
              leave={this.leaveTransition}
            >
              {nodes => (
                <g>
                  {nodes.map(({ key, data, state }) => (
                    <BarGroup key={key} data={data} state={state} ratio={this.ratio()} />
                  ))}
                </g>
              )}
            </NodeGroup>
          </g>
        </svg>
      </div>
    )
  }
}

export default LeaderBoard
