import { FC, ChangeEvent, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { AnswerElement } from 'common/types/question/question'
import { AllowedAnswers } from 'common/types/question/answer/answer'
import { useStore } from 'types/store'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Input = styled.input`
  border-color: ${props => !props.answer_decision ? 'grey' : props.answer_decision === 'CORRECT' ? 'green' : 'red'};
  margin: 5px 10px 5px;
  text-align: center;
  ${props => props.answer_decision && '&:focus { outline: none }'};
`

interface Props {
  element: Instance<AnswerElement>,
  handleChange: (e: ChangeEvent) => void,
  answer_decision: string,
  correct_answers: Instance<AllowedAnswers>,
  value: string,
  element_id: string,
  auto_focus_element?: Instance<AnswerElement>,
}

export const InputComponent: FC<Props> = observer(({ element, handleChange, answer_decision, correct_answers, value, element_id, auto_focus_element }) => {
  const { game, auth: { user } } = useStore()
  const input_ref = useRef(null)

  useEffect(() => {
    if( element === auto_focus_element ) {
      input_ref.current.focus()
    }
  }, [element])

  const size = value.length <= 3 ? 4 : value.length + 1
  return (
    <Container>
      <Input
        id={element_id}
        onChange={(e) => handleChange(e)}
        answer_decision={answer_decision}
        readOnly={Boolean(answer_decision)}
        value={value}
        size={size}
        ref={input_ref}
        onKeyPress={e => {
          if (e.key === 'Enter' && !game.over) {
            game.submit_answer(user._id)
          }
        }}
      />
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 &&
        <Input
          value={correct_answers[0].find(answerPart => answerPart.ref === element.ref)?.values[0] || 'error'}
          answer_decision="CORRECT"
          readOnly={true}
          size={size}
        />
      }
    </Container>
  )
})
