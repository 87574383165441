import { types, Instance } from 'mobx-state-tree'
import { NonEmptyString } from 'common/types/basic/non_empty_string'
import { DateFromString } from 'common/types/basic/date_time_string'
import { GAME_TYPE } from 'common/types/game/'
import { SubjectId } from 'common/types/subject/id'
import { NumberFromStringOrNumber } from 'common/types/basic/number_from_string_or_number'

export const GhostPlayerGameSummary = types.model('GamePlayerSummary', {
  player: types.string,
  playerType: types.string,
  totalPoints: types.number,
})
export type GhostPlayerGameSummary = Instance<typeof GhostPlayerGameSummary>

export const HumanGamePlayerSummary = GhostPlayerGameSummary
  .named('HumanGamePlayerSummary')
  .props({
    coins: types.number,
    difficultyBonus: types.number,
    streakBonus: types.number,
    streak: types.number,
    highestStreak: types.number,
    questionCnt: types.number,
  })
export type HumanGamePlayerSummary = Instance<typeof HumanGamePlayerSummary>

export const NewGameSummary = types.model('GameSummary', {
  _id: NonEmptyString,
  game_id: types.maybe(types.string),
  focus: types.maybe(types.array(SubjectId)),
  players: types.array(types.union(HumanGamePlayerSummary, GhostPlayerGameSummary)),
  started_at: DateFromString,
  finished_at: DateFromString,
  game_type: types.enumeration<GAME_TYPE>(Object.values(GAME_TYPE)),
  duration: types.number
})
export type NewGameSummary = Instance<typeof NewGameSummary>

export const GameSummaryPostRequest = types.model('GameSummaryPostRequest', {
  body: NewGameSummary
})
export type GameSummaryPostRequest = Instance<typeof GameSummaryPostRequest>

export const GameSummary = NewGameSummary
  .named('GameSummary')
  .props({
    _id: NonEmptyString
  })
export type GameSummary = Instance<typeof GameSummary>

export const HighScoreGetRequest = types.model('HighScoreGetRequest', {
  query: types.model({
    game_type: types.enumeration<GAME_TYPE>(Object.values(GAME_TYPE)),
    duration: NumberFromStringOrNumber
  })
})
export type HighScoreGetRequest = Instance<typeof HighScoreGetRequest>

export const HighScoreGetResponse = types.model('HighScoreGetResponse', {
  score: types.number
})
export type HighScoreGetResponse = typeof HighScoreGetResponse


