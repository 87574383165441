import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { IonButton, IonCard, IonCardHeader, IonCardContent, IonCardTitle } from '@ionic/react'
import styled from 'styled-components'
import { useStore } from 'types/store'

const TableHeading = styled.th`
  padding: 1rem;
  text-align: left;
`

const TableData = styled.td`
  padding: 1rem 3rem 1rem 1rem;
  height: 3rem;
`

const TableRowContent = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`

export const TutorsList: FC = observer(() => {
  const { admin_tutor_list: { tutors, refresh } } = useStore()

  useEffect(() => {
    refresh()
  }, [])

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
                Tutors
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <table>
          <thead>
            <tr>
              <TableHeading>First name</TableHeading>
              <TableHeading>Last name</TableHeading>
              <TableHeading>Email</TableHeading>
              <TableHeading>Subject</TableHeading>
              <TableHeading></TableHeading>
            </tr>
          </thead>
          <tbody>
            {tutors.map(t => {
              return (
                <TableRowContent key={t._id}>
                  <TableData>{t.first_name}</TableData>
                  <TableData>{t.last_name}</TableData>
                  <TableData>{t.email}</TableData>
                  <TableData>{t.subject.name}</TableData>
                  <TableData>
                    {t._id && 
                      <IonButton routerLink={`/admin/tutors/${t._id}`}size='small' color='light'>View profile</IonButton>
                    }
                  </TableData>
                </TableRowContent>
              )
            })}
          </tbody>
        </table>
      </IonCardContent>
    </IonCard>
  )
})
