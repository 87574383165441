import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon } from '@ionic/react'
import { ButtonWithImage } from 'generic_components/ButtonWithImage'
import { FC, useState } from 'react'
import { checkmarkCircleSharp, alertCircle } from 'ionicons/icons'


interface Props {
  onNext: ()=>void
}
export const PermissionsDiag:FC<Props> = ({onNext}) => { 
  const [error,setError] = useState()
  const [success,setSuccess] = useState(false)
  const handleClick = async () => {
    try {
      // get audio and video permissions then stop the tracks
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(async (mediaStream) => {
        mediaStream.getTracks().forEach((track) => {
          track.stop()
        })
      })
    
      // The devicechange event is not fired after permissions are granted, so we fire it
      // ourselves to update the useDevices hook. The 100 ms delay is needed so that device labels are available
      // when the useDevices hook updates.
      setTimeout(() => navigator.mediaDevices.dispatchEvent(new Event('devicechange')), 500)
      setSuccess(true)
      onNext()
      setError(undefined)
    } catch (error) {
      console.log(error)
      setError(error)
    }
  }
  return (<IonCard>
    <IonCardHeader>
      <IonCardTitle>
        {error ? <IonIcon icon={alertCircle} style={{color:'red'}}/> : success ? <IonIcon icon={checkmarkCircleSharp} style={{color:'green'}}/> : null}Camera/Microphone Permissions
      </IonCardTitle>
      <IonCardSubtitle>If you haven&apos;t already, you&apos;ll see a pop-up to grant Twilio permissions to access your camera and
            microphone.
      </IonCardSubtitle>
    </IonCardHeader>

    <IonCardContent>
     
      <div>
        <strong>Press button below and allow all permissions.</strong>
      </div>
      <IonButton color="primary" onClick={handleClick}>
            Request permissions
      </IonButton>
      <div>
        {error && <strong>Could not set permissions correctly - check your browser settings. If you accedentaly denied permissions, go to browser settings, reset permissions for this site and refresh this page.</strong>}
      </div>
    </IonCardContent>
  </IonCard>)
}
