import { FC, useEffect, useRef } from 'react'
import { AudioTrack } from 'twilio-video'

interface Props {
  track: AudioTrack,
}

export const Audio: FC<Props> = ({track}) => {
  const audio = useRef<HTMLVideoElement>()

  useEffect(() => {
    console.log(`Audio track effect ${track}`)
    if(track){
      track.attach(audio.current)
      return () => {
        track.detach()
      }
    }
  },[track])
  return (
    <audio
      ref={audio}
    />
  )
}
