import React, { FC, useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import { sum, sumBy, zip } from 'lodash'
import { IonButton } from '@ionic/react'

import { getStudentTrendStats } from 'util/api/student/trend_stats'
import { getStudentAnswers } from 'util/api/student/answers'
import { getMixes } from 'util/api/student/mixes'
import { IInitialFilter, QuestionTableHeader } from '../question_review'
import { useStore } from 'types/store'

function findEndcodePairInPreviousWeeks(stats) {
  const currentWeek = stats[0]
  if ( currentWeek === undefined ) {
    return 0
  }
  const previousWeeks = stats.slice(1,9)
  const matchingEndcodes = currentWeek.scores.map(current_endcode_score =>
    previousWeeks.reduce((score, week) =>
      score !== undefined ? score : week.scores.find(previous_endcode_score =>
        current_endcode_score.endcode === previous_endcode_score.endcode
      ),
    undefined
    )
  )
  const maybePairedWeeks = zip(currentWeek.scores, matchingEndcodes)
  const pairedWeeks = maybePairedWeeks.filter(([_, previous]) => previous !== undefined)
  const improvement = pairedWeeks.map(([current, previous]) => current.score - previous.score)
  const total = sum(improvement)
  return total
}

function getWaterfallPlotStartAndEnd(diffArr) {
  const sumArr = diffArr.reduce((acc, diff) => {
    const sum = acc.at(-1).sum + diff
    console.log('Sum: ', sum)
    return [
      ...acc,
      {
        sum,
        maxSum: Math.max(acc.at(-1).maxSum, sum)
      }
    ]
  },[{ sum: 0, maxSum: 0 }])
  const result = sumArr.slice(1).map(({maxSum},i) => [sumArr[i].maxSum, maxSum]).flat()
  console.log('result: ', result)

  return result
}

interface Props {
  student_id: string,
  subject_id?: string,
  changeTab:(string)=>void
  setInitialAnswerFilters?: (input:IInitialFilter[]) => void
}

export const StudentTrendPerformance: FC<Props> = ({ student_id, subject_id, changeTab, setInitialAnswerFilters }) => {
  const [timePlot, setTimePlot] = useState<[Date,number][]>([])

  const {
    student_tokens: {
      fetch_tokens,
      total_token_count,
      total_pending_token_count
    }
  } = useStore()
  useEffect(() => {
    fetch_tokens(student_id)
  }, [student_id])
  const [questionsPlot, setQuestionsPlot] = useState<[Date, number, number][]>([])
  const [performancePlot, setPerformancePlot] = useState<[Date, number, number, number, number, string][]>([])
  const [loading, set_loading] = useState(true)
  const [lastImprovement, setLastImprovement] = useState<string>('0')
  const [toReview, setToReview] = useState<number>(0)
  const [tutorFlags, setTutorFlags] = useState<number>(0)
  const [mixesCount, setMixesCount] = useState<number>(0)

  console.log('--time_plot', timePlot)
  console.log('--questions_plot', questionsPlot)
  console.log('--performance_plot', performancePlot)
  const load_stats = async () => {
    try {
      set_loading(true)

      const week8Start:DateTime = DateTime.utc().startOf('week')
      const week7Start:DateTime = week8Start.minus({week:1})
      const week6Start:DateTime = week8Start.minus({week:2})
      const week5Start:DateTime = week8Start.minus({week:3})
      const week4Start:DateTime = week8Start.minus({week:4})
      const week3Start:DateTime = week8Start.minus({week:5})
      const week2Start:DateTime = week8Start.minus({week:6})
      const week1Start:DateTime = week8Start.minus({week:7})
      const referenceWeekStart:DateTime = week8Start.minus({week:8})

      console.log('----studentid', student_id)

      const week1Answers = await getStudentAnswers(student_id, week5Start.toJSDate().toISOString(), week5Start.plus({day:7}).toJSDate().toISOString())
      const week2Answers = await getStudentAnswers(student_id, week6Start.toJSDate().toISOString(), week6Start.plus({day:7}).toJSDate().toISOString())
      const week3Answers = await getStudentAnswers(student_id, week7Start.toJSDate().toISOString(), week7Start.plus({day:7}).toJSDate().toISOString())
      const week4Answers = await getStudentAnswers(student_id, week8Start.toJSDate().toISOString(), week8Start.plus({day:7}).toJSDate().toISOString())

      const mixes = await getMixes(student_id)

      setMixesCount(mixes.length)

      const stats = await getStudentTrendStats(student_id)
      console.log('--stats sorted', stats)

      const diffStats1 = findEndcodePairInPreviousWeeks(stats.slice(0,9)).toFixed(2)
      const diffStats2 = findEndcodePairInPreviousWeeks(stats.slice(1,10)).toFixed(2)
      const diffStats3 = findEndcodePairInPreviousWeeks(stats.slice(2,11)).toFixed(2)
      const diffStats4 = findEndcodePairInPreviousWeeks(stats.slice(3,12)).toFixed(2)
      console.log('diffStats1: ', diffStats1)
      console.log('diffStats2: ', diffStats2)
      console.log('diffStats3: ', diffStats3)
      console.log('diffStats4: ', diffStats4)

      const answersFlagged = [
        ...week1Answers,
        ...week2Answers,
        ...week3Answers,
        ...week4Answers
      ].reduce((acc, curr) => {
        if (curr.help_requested) {
          return acc['yes'] ? ++acc['yes'] : acc['yes'] = 1, acc
        }
        return acc['no'] ? ++acc['no'] : acc['no'] = 1, acc
      }, {})
      setTutorFlags(answersFlagged['yes'])

      const answersToReview = [
        ...week1Answers,
        ...week2Answers,
        ...week3Answers,
        ...week4Answers
      ].reduce((acc, curr) => {
        if (curr.result!=='CORRECT' && !curr.reviewed) {
          return acc['yes'] ? ++acc['yes'] : acc['yes'] = 1, acc
        }
        return acc['no'] ? ++acc['no'] : acc['no'] = 1, acc
      }, {})
      setToReview(answersToReview['yes'])

      const improvementPoints = getWaterfallPlotStartAndEnd([ Number(diffStats4), Number(diffStats3), Number(diffStats2), Number(diffStats1) ])

      setPerformancePlot([
        [week5Start.toJSDate(), improvementPoints[0], improvementPoints[0], improvementPoints[1], improvementPoints[1], `Improvement of ${(improvementPoints[1]).toFixed(2)}`],
        [week6Start.toJSDate(), improvementPoints[2], improvementPoints[2], improvementPoints[3], improvementPoints[3], `Improvement of ${improvementPoints[3] - improvementPoints[2] > 0 ? (improvementPoints[3] - improvementPoints[2]).toFixed(2) : 0}`],
        [week7Start.toJSDate(), improvementPoints[4], improvementPoints[4], improvementPoints[5], improvementPoints[5], `Improvement of ${improvementPoints[5] - improvementPoints[4] > 0 ? (improvementPoints[5] - improvementPoints[4]).toFixed(2) : 0}`],
        [week8Start.toJSDate(), improvementPoints[6], improvementPoints[6], improvementPoints[7], improvementPoints[7], `Improvement of ${improvementPoints[7] - improvementPoints[6] > 0 ? (improvementPoints[7] - improvementPoints[6]).toFixed(2) : 0}`],
      ])
      setLastImprovement(`${improvementPoints[7].toFixed(2)}`)

      setTimePlot([
        [week5Start.toJSDate(), +(sumBy(week1Answers?week1Answers:[], (a)=>{return a.response_time})/60).toFixed(2)],
        [week6Start.toJSDate(), +(sumBy(week2Answers?week2Answers:[], (a)=> {return a.response_time})/60).toFixed(2)],
        [week7Start.toJSDate(), +(sumBy(week3Answers?week3Answers:[], (a)=>{return a.response_time})/60).toFixed(2)],
        [week8Start.toJSDate(), +(sumBy(week4Answers?week4Answers:[], (a)=>{return a.response_time})/60).toFixed(2)],
      ])
      setQuestionsPlot([
        [week5Start.toJSDate(), sumBy(week1Answers?week1Answers:[], (a)=>1), sumBy(week1Answers?week1Answers:[], (a)=>a.result === 'CORRECT' ? 1 : 0)],
        [week6Start.toJSDate(), sumBy(week2Answers?week2Answers:[], (a)=>1), sumBy(week2Answers?week2Answers:[], (a)=>a.result === 'CORRECT' ? 1 : 0)],
        [week7Start.toJSDate(), sumBy(week3Answers?week3Answers:[], (a)=>1), sumBy(week3Answers?week3Answers:[], (a)=>a.result === 'CORRECT' ? 1 : 0)],
        [week8Start.toJSDate(), sumBy(week4Answers?week4Answers:[], (a)=>1), sumBy(week4Answers?week4Answers:[], (a)=>a.result === 'CORRECT' ? 1 : 0)],
      ])

    }
    finally {
      set_loading(false)
    }
  }

  useEffect(() => { load_stats() }, [student_id])

  return (<>
    {loading
      ? <p>Loading...</p>
      : (<List>
        <ListItem>
          <ListContent>
            <h2>Time on Questions</h2>
            {timePlot.length === 0
              ? <p>No trend data available</p>
              : <Chart
                chartType='LineChart'
                data={[
                  [
                    { type: 'date', label: 'Date'},
                    { type: 'number', label: 'Time spent' }
                  ],
                  ...timePlot
                ]}
                width='100%'
                height='100%'
                options={{
                  vAxis: {
                    title: 'Minutes'
                  },
                  hAxis: {
                    title: 'Week commencing',
                    format: 'M/d/yy'
                  },
                  colors: ['#F5CB24'],
                  legend: 'none',
                }}
              />
            }
          </ListContent>
        </ListItem>
        <ListItem>
          <ListContent>
            <h2>Questions</h2>
            {questionsPlot.length === 0
              ? <p>No trend data available</p>
              : <Chart
                chartType='ColumnChart'
                data={[
                  [
                    { type: 'date', label: 'Date'},
                    { type: 'number', label: 'Answered' },
                    { type: 'number', label: 'Correct' }
                  ],
                  ...questionsPlot
                ]}
                width='100%'
                height='100%'
                options={{
                  hAxis: {
                    title: 'Week commencing',
                    format: 'M/d/yy'
                  },
                  colors: ['#0000FE', '#D95F02'],
                  legend: 'bottom',
                }}
              />
            }
          </ListContent>
        </ListItem>
        <ListItem>
          <ListContent>
            <h2>Improvement: {lastImprovement}</h2>
            {performancePlot.length === 0
              ? <p>No trend data available</p>
              : <Chart
                chartType='CandlestickChart'
                data={[
                  [
                    { type: 'date', label: 'Date'},
                    { type: 'number', label: 'Score Improvement' },
                    { type: 'number', label: 'Score Improvement' },
                    { type: 'number', label: 'Score Improvement' },
                    { type: 'number', label: 'Score Improvement' },
                    { type: 'string', role: 'tooltip' }
                  ],
                  ...performancePlot
                ]}
                width='100%'
                height='100%'
                options={{
                  hAxis: {
                    title: 'Week commencing',
                    format: 'M/d/yy'
                  },
                  bar: { groupWidth: '100%' },
                  candlestick: {
                    fallingColor: { strokeWidth: 0, fill: '#0f9d58' },
                    risingColor: { strokeWidth: 0, fill: '#0f9d58' }
                  },
                  legend: 'none',
                }}
              />
            }
          </ListContent>
        </ListItem>
        <ListItem>
          <ListContent>
            <h2>Current Mixes</h2>
            <IonButton
              style={{cursor: 'pointer'}}
              color="tertiary"
              onClick={()=>{
                changeTab('focus')
              }}
            >
              See {mixesCount} mixes
            </IonButton>
          </ListContent>
        </ListItem>
        <ListItem>
          <ListContent>
            <h2>Tokens</h2>
            <p>
              Earned:
              {
                total_token_count
              }
            </p>
            {
              total_pending_token_count > 0 ? (
                <IonButton
                  style={{cursor: 'pointer'}}
                  color="tertiary"
                  onClick={()=>{
                    changeTab('answers')
                    setInitialAnswerFilters([{id: QuestionTableHeader.Tokens, value: 'pending'}])
                  }}
                >
                    Pending:
                  {
                    total_pending_token_count
                  }
                </IonButton>
              ) :
                null
            }

          </ListContent>
        </ListItem>
        <ListItem>
          <ListContent>
            <h2>Tutor Flags</h2>
            {
              tutorFlags ? (
                <IonButton
                  style={{cursor: 'pointer'}}
                  color="tertiary"
                  onClick={()=>{
                    changeTab('answers')
                    setInitialAnswerFilters([{id: QuestionTableHeader.Reviewed, value: 'help'}])
                  }}
                >
                    See {tutorFlags} flag{tutorFlags > 1 ? 's' : ''}
                </IonButton>
              ) : null
            }
          </ListContent>
        </ListItem>
      </List>)
    }
  </>)
}

const List = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-left: -30px;
  width: 100%;
`
const ListItem = styled.li`
  background-color: #000033;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
`
const ListContent = styled.div`
  width: 100%;
  text-align: center;
  color: #F5CB24;
`
