import { FC } from 'react'

import { IonGrid, IonCol, IonRow } from '@ionic/react'
import ReactPlayer from 'react-player/vimeo'


interface Props {
  name: string
  help_videos: string[]
}

export const VideoHelp: FC<Props> = ({ name, help_videos }) => {

  return (
    <IonGrid className="ion-align-self-center">
      <IonRow style={{ height: '100%', backgroundColor: 'var(--ion-color-primary)', color: 'var(--ion-color-primary-contrast' }}>
        <IonCol>Watch following video(s) to learn more about {name}</IonCol>
      </IonRow>
      {help_videos.map(v => {
        return (
          <IonRow key={v}>
            <IonCol size='3'><ReactPlayer url={v} controls={true} /></IonCol>
          </IonRow>
        )
      })}

    </IonGrid>
  )
}
