import { types } from 'mobx-state-tree'
import { StudentId } from '../student/id'
import { DateTimeString } from '../basic/date_time_string'
import { NonEmptyString } from '../basic/non_empty_string'
import { SubjectId } from '../subject/id'
import { NumberFromStringOrNumber } from '../basic/number_from_string_or_number'

export const NewNote = types.model('NewNote', {
  message: NonEmptyString,
})
export type NewNote = typeof NewNote

export const Note = types.model('Note', {
  _id: types.string,
  subject: SubjectId,
  student: StudentId,
  sender_id: types.string,
  sender_name: types.string,
  timestamp: DateTimeString,
  message: NonEmptyString,
})
export type Note = typeof Note

export const PostNoteRequest = types.model({
  params: types.model({
    student: StudentId,
    subject: SubjectId
  }),
  body: NewNote
})
export type PostNoteRequest = typeof PostNoteRequest

export const GetNotesRequest = types.model('GetNotesRequest',{
  params: types.model({
    student: StudentId,
    subject: SubjectId
  }),
  query: types.optional(
    types.model({
      before: types.maybe(DateTimeString),
      limit: types.optional(NumberFromStringOrNumber, 10)
    }),
    {}
  )
})
export type GetNotesRequest = typeof GetNotesRequest

export const GetNotesResponse = types.model('GetNotesResponse',{
  notes: types.array(Note),
  count: types.number,
  total: types.number,
})
export type GetNotesResponse = typeof GetNotesResponse
