import { useState } from 'react'
import { PlytimePage } from 'components/plytime_page'
import { useStore } from 'types/store'
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react'
import { observer } from 'mobx-react-lite'
import { QuestionDetail } from 'components/manage_questions/question_detail'
import { Roles } from 'util/auth/helper'

interface IProps {
  roles: Roles[]
}

export default observer<IProps>(function ({ roles }) {
  const { questions, questions_filter, metadata, busy, changed, search, set_filter_status, set_filter_ref } = useStore().admin
  const [selected_question, select_question] = useState(-1)

  return (
    <PlytimePage roles={roles} title='Question management'>
      <IonCard color='tertiary'>
        <IonCardHeader>
          <IonCardTitle>
            Question Search
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size='5'>
                <IonItem>
                  <IonLabel position='stacked'>
                    Ref
                  </IonLabel>
                  <IonInput
                    type='text'
                    value={questions_filter.ref }
                    onIonChange={e => set_filter_ref(e.detail.value)}
                  />
                </IonItem>
              </IonCol>
              <IonCol size='5'>
                <IonItem>
                  <IonLabel position='stacked'>
                    Status
                  </IonLabel>
                  <IonSelect
                    multiple
                    value={questions_filter.status}
                    onIonChange={e => set_filter_status(e.detail.value)}
                  >
                    <IonSelectOption value={'live'}>Live</IonSelectOption>
                    <IonSelectOption value={'draft'}>Draft</IonSelectOption>
                    <IonSelectOption value={'archived'}>Archived</IonSelectOption>
                    <IonSelectOption value={'review'}>Review</IonSelectOption>
                    <IonSelectOption value={'approved'}>Approved</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonButton
                  fill='solid'
                  size='small'
                  color='primary'
                  className="ion-margin-top"
                  disabled={busy || changed || metadata === undefined || metadata.from <= 0}
                  onClick={() => search(Math.max(metadata.from - 50, 0))}
                >
                Previous
                </IonButton>
                <IonButton
                  fill='solid'
                  size='small'
                  color='primary'
                  className="ion-margin-top"
                  disabled={busy || !changed}
                  onClick={() => search()}
                >
                Search
                </IonButton>
                <IonButton
                  fill='solid'
                  size='small'
                  color='primary'
                  className="ion-margin-top"
                  disabled={busy || changed || metadata === undefined || metadata.to === metadata.of}
                  onClick={() => search(metadata.to)}
                >
                Next
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonItem>{
        metadata === undefined
          ? 'Enter parameters and press search'
          : metadata.of === 0
            ? 'No matching questions'
            : `Showing questions ${metadata.from} to ${metadata.to} of ${metadata.of}`
      }</IonItem>
      {metadata !== undefined && metadata.of > 0 &&
      <IonGrid>
        <IonRow color={'light'}>
          <IonCol size='2'>
            <h4>Theme</h4>
          </IonCol>
          <IonCol size='2'>
            <h4>Topic</h4>
          </IonCol>
          <IonCol size='3'>
            <h4>Endcode</h4>
          </IonCol>
          <IonCol size='3'>
            <h4>Ref</h4>
          </IonCol>
          <IonCol size='2'>
            <h4>Status</h4>
          </IonCol>
        </IonRow>
        {questions.map((question, i) =>
          <IonRow key={question._id} className='alternate-light-medium' onClick={() => select_question(i)}>
            <IonCol size='2'>
              {question.theme.name}
            </IonCol>
            <IonCol size='2'>
              {question.topic.name}
            </IonCol>
            <IonCol size='3'>
              {question.endcode.name}
            </IonCol>
            <IonCol size='3'>
              {question.ref}
            </IonCol>
            <IonCol size='2'>
              {question.status}
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
      }
      {questions && selected_question >= 0 &&
      <IonModal isOpen={true} onDidDismiss={() => select_question(-1)} cssClass="admin-modal">
        <IonContent>
          <QuestionDetail question={questions[selected_question]} dismiss={() => select_question(-1)}/>
        </IonContent>
      </IonModal>
      }
    </PlytimePage>
  )
})
