import { FC, useEffect } from 'react'
import { Route, Redirect, Switch} from 'react-router-dom'
import { useStore } from 'types/store'
import { observer } from 'mobx-react-lite'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import './css/Font.css'
import './css/ColourScheme.css'
import './css/AmplifyTheme.css'
import './css/DatePicker.css'
import './css/ActionSheet.css'
import './css/AlternateRows.css'
import './css/admin-modal.css'
import './css/card-modal.css'
import './css/help-modal.css'
import './css/student-selector-options.css'
import './css/game-type-selector-options.css'
import './css/single-select-alert.css'

import admin from 'pages/admin'
import admin_questions from 'pages/admin/questions'
import admin_subjects from 'pages/admin/subjects'
import admin_subjects_import from 'pages/admin/subjects_import'
import admin_tutors from 'pages/admin/tutors'
import admin_student_scores from 'pages/admin/student_subject_scores'
import admin_tutors_all_availability from 'pages/admin/tutors/all_availability'
import admin_tutors_tutor from 'pages/admin/tutors/[_id]'
import admin_variables from 'pages/admin/variables'
import game_step1_setup from 'pages/game/step1_setup'
import game_step2_speed_standard from 'pages/game/step2_speed_standard'
import game_step3_summary from 'pages/game/step3_summary'
import { Home } from 'pages/home'
import parent from 'pages/parent'
import parent_student_performance from 'pages/parent/student_performance'
import parent_subscriptions from 'pages/parent/subscriptions'
import select_account_type from 'pages/select_account_type'
import signin from 'pages/signin'
import student from 'pages/student'
import student_performance from 'pages/student/performance'
import student_session_page from 'pages/student/session/[session_id]'
import student_sessions from 'pages/student/sessions'
import student_messages from 'pages/student/messages'
import tutor from 'pages/tutor'
import tutor_availability from 'pages/tutor/availability'
import tutor_profile from 'pages/tutor/profile'
import tutor_session_page from 'pages/tutor/session/[session_id]'
import tutor_scheduled_session_page from 'pages/tutor/session/[subject_id]'
import parent_profile from 'pages/parent/profile'
import diagnostics from 'pages/util/diagnostics'
import tokens from './pages/student/tokens'
import gold from './pages/student/gold'
import { SessionOptionEnum } from 'common/types/session/options'
import { Roles } from './util/auth/helper'
import { SubscriptionLevelTooLow } from './components/SubscriptionLevelTooLow'

const routes: IProtectedRouteProps[] = [
  {
    Component: signin,
    path: '/signin/parent',
    roles: [undefined],
  },
  {
    Component: signin,
    path: '/signin/student',
    roles: [undefined],
  },
  {
    Component: signin,
    path: '/signin/tutor',
    roles: [undefined],
  },
  {
    Component: signin,
    path: '/signin/admin',
    roles: [undefined],
  },
  {
    Component: admin_questions,
    path: '/admin/questions',
    roles: [Roles.ADMIN],
  },
  {
    Component: admin_subjects,
    path: '/admin/subjects',
    roles: [Roles.ADMIN],
  },
  {
    Component: admin_subjects_import,
    path: '/admin/subjects_import',
    roles: [Roles.ADMIN],
  },
  {
    Component: admin_tutors_tutor,
    path: '/admin/tutors/:_id',
    roles: [Roles.ADMIN],
  },
  {
    Component: admin_tutors,
    path: '/admin/tutors',
    roles: [Roles.ADMIN],
  },
  {
    Component: admin_student_scores,
    path: '/admin/student_scores',
    roles: [Roles.ADMIN],
  },
  {
    Component: admin_tutors_all_availability,
    path: '/admin/tutors/all_availability',
    roles: [Roles.ADMIN],
  },
  {
    Component: admin_variables,
    path: '/admin/variables',
    roles: [Roles.ADMIN],
  },
  {
    Component: admin,
    path: '/admin',
    roles: [Roles.ADMIN],
  },
  {
    Component: tutor_availability,
    path: '/tutor/availability',
    roles: [Roles.TUTOR],
  },
  {
    Component: tutor_profile,
    path: '/tutor/profile',
    roles: [Roles.TUTOR],
  },
  {
    Component: tutor_scheduled_session_page,
    path: '/tutor/session/:student_id/:subject_id',
    roles: [Roles.TUTOR],
  },
  {
    Component: tutor_session_page,
    path: '/tutor/session/:session_id',
    roles: [Roles.TUTOR],
  },
  {
    Component: diagnostics,
    path: '/tutor/diagnostics',
    roles: [Roles.TUTOR],
  },
  {
    Component: tutor,
    path: '/tutor',
    roles: [Roles.TUTOR],
  },
  {
    Component: parent_profile,
    path: '/parent/profile',
    roles: [Roles.PARENT],
  },
  {
    Component: parent_student_performance,
    path: '/parent/student_performance',
    roles: [Roles.PARENT],
  },
  {
    Component: parent_subscriptions,
    path: '/parent/subscriptions',
    roles: [Roles.PARENT],
  },
  {
    Component: diagnostics,
    path: '/parent/diagnostics',
    roles: [Roles.PARENT],
  },
  {
    Component: parent,
    path: '/parent',
    roles: [Roles.PARENT],
  },
  {
    Component: game_step1_setup,
    path: '/game/step1_setup',
    roles: [Roles.STUDENT],
  },
  {
    Component: game_step2_speed_standard,
    path: '/game/step2_speed_standard',
    roles: [Roles.STUDENT],
  },
  {
    Component: game_step3_summary,
    path: '/game/step3_summary',
    roles: [Roles.STUDENT],
  },
  {
    Component: student_performance,
    path: '/student/performance/:tab',
    roles: [Roles.STUDENT],
    minimumSubscriptionLevel: SessionOptionEnum.PlatformOnly,
  },
  {
    Component: student_performance,
    path: '/student/performance',
    roles: [Roles.STUDENT],
    minimumSubscriptionLevel: SessionOptionEnum.PlatformOnly,
  },
  {
    Component: student_session_page,
    path: '/student/session/:session_id',
    roles: [Roles.STUDENT],
    minimumSubscriptionLevel: SessionOptionEnum.PlatformOnly,
  },
  {
    Component: student_sessions,
    path: '/student/sessions',
    roles: [Roles.STUDENT],
    minimumSubscriptionLevel: SessionOptionEnum.PlatformOnly,
  },
  {
    Component: student_messages,
    path: '/student/messages',
    roles: [Roles.STUDENT],
    minimumSubscriptionLevel: SessionOptionEnum.PlatformOnly,
  },
  {
    Component: diagnostics,
    path: '/student/diagnostics',
    roles: [Roles.STUDENT],
  },
  {
    Component: tokens,
    path: '/student/tokens',
    roles: [Roles.STUDENT],
    minimumSubscriptionLevel: SessionOptionEnum.PlatformOnly,
  },
  {
    Component: gold,
    path: '/student/gold',
    roles: [Roles.STUDENT],
    minimumSubscriptionLevel: SessionOptionEnum.PlatformOnly,
  },
  {
    Component: student,
    path: '/student',
    roles: [Roles.STUDENT],
  },
  {
    Component: select_account_type,
    path: '/select_account_type',
    roles: [undefined],
  },
  {
    Component: Home,
    path: '/',
    roles: [Roles.ADMIN],
  },
]

const App: FC = observer(() => {
  const {
    auth: {user, role, initialise},
  } = useStore()

  useEffect(() => {
    initialise()
  }, [])

  return (
    <Switch>
      {routes.map((props) => <ProtectedRoute {...props} key={props.path}/>)}
      <Route render={() => {
        if (user?.role !== undefined) {
          return <Redirect to={`/${user?.role.toLowerCase()}`}/>
        }
        if (user === undefined && role !== undefined) {
          return <Redirect to={`/signin/${role.toLowerCase()}`}/>
        }

        return <Redirect to="/select_account_type"/>
      }}/>
    </Switch>
  )
})

interface IProtectedRouteProps {
    path: string,
    Component: FC<any>,
    roles: Roles[],
    minimumSubscriptionLevel?: SessionOptionEnum
}

const ProtectedRoute: FC<IProtectedRouteProps> = observer(({path, Component, roles, minimumSubscriptionLevel}) => {
  const {
    auth: {user, role, in_progress},
    student_subscriptions
  } = useStore()

  return (
    <Route
      path={path}
      exact
      render={
        // useCallback won't work here - https://stackoverflow.com/questions/62682662/react-router-and-usecallback
        props => {
          const requiredSubscriptionLevel = Object.values(SessionOptionEnum).indexOf(minimumSubscriptionLevel)
          const currentSubscriptionLevel = Object.values(SessionOptionEnum).indexOf(student_subscriptions.current_subscription?.session_option)

          if (requiredSubscriptionLevel > currentSubscriptionLevel) {
            return <SubscriptionLevelTooLow roles={roles} />
          }
          if ([Roles.TEST, ...roles].includes(user?.role)) {
            return (
              <Component
                {...props}
                roles={[Roles.TEST, ...roles]}
                minimumSubscriptionLevel={minimumSubscriptionLevel}
              />
            )
          }
          if (in_progress) {
            return <p>Loading</p>
          }
          if (user?.role) {
            return <Redirect to={`/${user?.role.toLowerCase()}`}/>
          }

          if (user && role) {
            return <Redirect to={`/signin/${role.toLowerCase()}`}/>
          }

          return <Redirect to="/select_account_type"/>
        }
      }
    />
  )
})

export default App
