import { PlytimePageStudentBackground } from 'components/plytime_page_student_background'
import { Roles } from 'util/auth/helper'
import { GameSetup } from 'components/game/setup'
import styled from 'styled-components'
import { useStore } from 'types/store'

const Container = styled.div`
margin-top: 3rem;
display: flex;
flex-direction: row;
min-width: 300px;
border-radius: 5px;
background-color:rgba(255, 255, 255, 1);
padding: 1rem;
`

interface IProps {
  roles: Roles[]
}

export default ({ roles }: IProps) => {
  return (
    <PlytimePageStudentBackground roles={roles}>
      <Container>
        <GameSetup />
      </Container>
    </PlytimePageStudentBackground>
  )
}
