import { DateTime } from 'luxon'
import styled from 'styled-components'

export const DayOrTime = (timestamp:string) => {
  const t = DateTime.fromISO(timestamp)
  const today = DateTime.now().startOf('day')
  return t < today
    ? t.toFormat('ccc d MM')
    : t.toFormat('HH:mm')
}

export const Head = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`
export const Name = styled.div`
  text-align: left;
  order: 1;
  font-size: x-small;
  color: var(--ion-color-medium)
`
export const Time = styled.div`
  text-align: right;
  order: 2;
  font-size: x-small;
  color: var(--ion-color-medium)
`
export const Msg = styled.div`
  align-self: flex-start;
  text-align: left;
  width: 80%;
  font-size: small;
  white-space: pre-wrap;
`
