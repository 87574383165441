import { types } from 'mobx-state-tree'
import { DateTimeString } from '../basic/date_time_string'
import { Difficulty } from 'common/types/question/difficulty'
import { AllowedAnswers, SubmittedAnswer } from 'common/types/question/answer/answer'
import { QuestionId } from 'common/types/question/id'
import { Score } from '../question/score'
import { Lines } from '../question/question'

export const Result = types.enumeration([
  'CORRECT',
  'INCORRECT',
  'PASSED',
])
export const GetStudentAnswersRequest = types.model({
  params: types.model({
    student_id: types.string,
  }),
  query: types.model({
    since_date_iso: DateTimeString,
    to_date_iso: types.maybe(DateTimeString)
  })
})
export type GetStudentAnswersRequest = typeof GetStudentAnswersRequest

export const StudentAnswer = types.model({
  _id: types.string,
  reviewed: types.boolean,
  help_requested: types.boolean,
  resolved: types.boolean,
  question: types.model({
    _id: QuestionId,
    ref: types.string,
    answers: AllowedAnswers,
    lines: Lines,
    maximum_time: types.number
  }),
  answer: SubmittedAnswer,
  result: Result,
  score: Score,
  timestamp: DateTimeString,
  subject: types.model({
    _id: types.string,
    name: types.string,
  }),
  theme: types.model({
    _id: types.string,
    name: types.string,
  }),
  topic: types.model({
    _id: types.string,
    name: types.string,
  }),
  endcode: types.model({
    _id: types.string,
    name: types.string,
    description: types.string,
    help_videos: types.array(types.string),
    priority: types.enumeration(['1','2','3'])
  }),
  response_time_pc: types.number,
  response_time: types.number,
  difficulty: Difficulty
})
export type StudentAnswer = typeof StudentAnswer

export const GetStudentAnswersResponse = types.array(StudentAnswer)
export type GetStudentAnswersResponse = typeof GetStudentAnswersResponse

export const PutReviewStatusRequest = types.model('PutReviewStatusRequest', {
  params: types.model({
    answer_id: types.string,
  }),
  body: types.model({
    reviewed: types.boolean,
    help_requested: types.boolean,
  }),
})
export type PutReviewStatusRequest = typeof PutReviewStatusRequest

export const PutReviewStatusResponse = types.model('PutReviewStatusResponse', {
  success: types.boolean,
})
export type PutReviewStatusResponse = typeof PutReviewStatusResponse

export const PutResolvedStatusRequest = types.model('PutResolvedStatusRequest', {
  params: types.model({
    answer_id: types.string
  }),
  body: types.model({
    resolved: types.boolean
  })
})
export type PutResolvedStatusRequest = typeof PutResolvedStatusRequest

export const PutResolvedStatusResponse = types.model('PutResolvedStatusResponse', {
  success: types.boolean,
})
export type PutResolvedStatusResponse = typeof PutResolvedStatusResponse
