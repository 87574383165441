import { flow, types } from 'mobx-state-tree'
import { DateTime } from 'luxon'

import { Subscription } from 'common/types/subscription'
import { listSubscriptions } from 'util/api/subscriptions'

export const StudentSubscriptions = types.model(
  'StudentSubscriptions', {
    isLoading: types.boolean,
    subscriptions: types.array(Subscription),
  }
).actions(self => ({
  get_subscriptions: flow(function* () {
    self.isLoading = true
    const subscriptionsResponse = yield listSubscriptions()
    self.subscriptions = subscriptionsResponse

    self.isLoading = false
  }),
})).views(
  (self) => ({
    get current_subscription(): Subscription|undefined {
      return self.subscriptions.find(({ payment_date }) => {
        const now = DateTime.now().toISODate()
        const subValidUntil = DateTime.fromISO(payment_date).plus({days: 7}).toISODate()

        return now < subValidUntil
      })
    }
  })
)
