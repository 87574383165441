import { FC, ChangeEvent, useRef, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { AnswerElement } from 'common/types/question/question'
import { AllowedAnswers } from 'common/types/question/answer/answer'
import { useStore } from 'types/store'
import { Container, ColumnContainer, Input  } from 'generic_components/AnswerInputContainers'
import { FractionInputElement } from 'common/types/question/elements/fraction'

interface Props {
  element: Instance<FractionInputElement>,
  handleChange: (e: ChangeEvent) => void,
  answer_decision: string,
  correct_answers: Instance<AllowedAnswers>,
  value: string,
  element_id: string,
  auto_focus_element?: Instance<AnswerElement>,
}

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

const parseNumber = (value: string): string => value.replaceAll(/[^0-9]/g, '')

const parseFraction = (fraction: string): [string, string] => {
  const [numerator, denominator] = (fraction !== '') ? fraction.replaceAll(/[ ]/g, '').split('/') : ['','']
  return [numerator, denominator]
}

const drawLine = (numeratorSize: number, denominatorSize: number) => 
  '\u2501'.repeat(Math.max(numeratorSize, denominatorSize))

export const FractionInputComponent: FC<Props> = observer(({element, handleChange, answer_decision, correct_answers, value, element_id, auto_focus_element}) => {
  const {game, auth: { user }} = useStore()
  const numeratorInputRef = useRef(null)
  const denominatorInputRef = useRef(null)

  useEffect(() => {
    if( element === auto_focus_element ) {
      numeratorInputRef.current.focus()
    }
  }, [element])

  const [numerator, denominator] = parseFraction(value)
  const [numeratorSize, denominatorSize] = [calculateLength(numerator), calculateLength(denominator)]
  
  const [numeratorAnswer, denominatorAnswer] = (correct_answers.length > 0 && answer_decision !== 'CORRECT') 
    ? parseFraction(correct_answers[0].find(answerPart => answerPart.ref === element.ref).values[0])
    : ['','']

  const [numeratorAnswerSize, denominatorAnswerSize] = 
    [numeratorAnswer, denominatorAnswer].map(calculateLength)

  const inputChangeNumerator = (e) => {
    e.target.value = `${parseNumber(e.target.value)}/${denominator}`
    handleChange(e)
  }
  
  const inputChangeDenominator = (e) => {
    e.target.value = `${numerator}/${parseNumber(e.target.value)}`
    handleChange(e)
  }

  const drawInputLine = drawLine(numeratorSize, denominatorSize)
  const drawAnswerLine = drawLine(numeratorAnswerSize, denominatorAnswerSize)  

  return (
    <Container>
      <ColumnContainer>
        <Input
          id={`${element_id}_numerator`}
          onChange={(e) =>inputChangeNumerator(e) }
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={numerator}
          size={numeratorSize}
          style={{marginBottom: 0}}
          ref={numeratorInputRef}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if ( numerator !== '' && denominator !== '' ) {
                game.submit_answer(user._id)
              } else {
                denominatorInputRef.current.focus()
              }
            }
          }}
        />
        <p style={{margin: '0px', fontSize: '0.75em'}}>{drawInputLine}</p>
        <Input
          id={`${element_id}_denominator`}
          onChange={(e) => inputChangeDenominator(e)}
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={denominator}
          size={denominatorSize}
          ref={denominatorInputRef}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if ( numerator !== '' && denominator !== '' ) {
                game.submit_answer(user._id)
              } else {
                numeratorInputRef.current.focus()
              }
            }
          }}
        />
      </ColumnContainer>
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 && (
        <ColumnContainer>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            style={{marginBottom: 0}}
            value={numeratorAnswer}
            size={numeratorAnswerSize}
          />
          <p style={{margin: '0px', fontSize: '0.75em'}}>{drawAnswerLine}</p>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={denominatorAnswer}
            size={denominatorAnswerSize}
          />
        </ColumnContainer>
      )}
    </Container>
  )
})
