import { FC, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import coins_svg from 'images/speed_game/coins.svg'
import jewels_svg from 'images/speed_game/jewels.svg'
import ruby from 'images/speed_game/rubies/ruby.png'
import ruby_count_0 from 'images/speed_game/rubies/0.png'
import ruby_count_1 from 'images/speed_game/rubies/1.png'
import ruby_count_2 from 'images/speed_game/rubies/2.png'
import ruby_count_3 from 'images/speed_game/rubies/3.png'
import ruby_count_4 from 'images/speed_game/rubies/4.png'
import ruby_count_5 from 'images/speed_game/rubies/5.png'

interface Props {
  totalPoints: number,
  score: number,
  bonusPoints: number,
  highestStreak: number,
  streakPoints: number,
  consecutiveCorrectAnswers: number,
  totalQuestions: number,
  rubyCount: number
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    // margin-left: 0.5rem;
    width: 93%;
`

const TextContainer = styled.div`
    margin-right: 1rem;
`

const IconsContainer = styled.div`
    display: flex;
    flex-direction: column;
  `

const PointsIcon = styled.img`
  width: 1.56rem;
  height: 1.56rem;
  margin-top: 1.25rem;
  max-width: 1.56rem;
  max-height: 1.56rem;
`

const CoinsIcon = styled(PointsIcon)`
  margin-top: 1rem;
`

const StreakPointsIcon = styled.img`
  height: 1.56rem;
  margin-top: 1.2rem;
  max-height: 1.56rem;
`

const PointsText = styled.p`
  margin-bottom: 28px;
`

const IconAndExtraPointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100;
`

const PointsChangeTextAnimation = keyframes`
  from {opacity: 1;}
  to {opacity: 0;}
`

const PointsChangeText = styled.p`
  margin-bottom: 0;
  margin-top: 1.2rem;
  margin-left: 0.75rem;
  color: var(--ion-color-primary-tint);
  font-weight: 800;
  animation: 8s ${PointsChangeTextAnimation} ease-out;
  animation-fill-mode: forwards;
  }
`

const ScoreBoard: FC<Props> = (props) => {
  const {
    totalPoints,
    score,
    bonusPoints,
    highestStreak,
    streakPoints,
    consecutiveCorrectAnswers,
    totalQuestions,
    rubyCount
  } = props

  const [bonusJewels, setBonusJewels] = useState(bonusPoints)
  const [bonusJewelsUpdated, setBonusJewelsUpdated] = useState(0)
  useEffect(() => {
    setBonusJewels(previousBonusPoints => {
      const jewelsIncrease = bonusPoints - previousBonusPoints
      setBonusJewelsUpdated(jewelsIncrease)
      return bonusPoints
    })
  }, [bonusPoints])

  useEffect(() => {
    if (bonusPoints === bonusJewels) {
      setBonusJewelsUpdated(0)
    }
  }, [totalQuestions])

  const [streakRedJewels, setStreakRedJewels] = useState(streakPoints)
  const [streakRedJewelsUpdated, setStreakRedJewelsUpdated] = useState(0)
  useEffect(() => {
    setStreakRedJewels(previous => {
      const jewelsIncrease = streakPoints - previous
      setStreakRedJewelsUpdated(jewelsIncrease)
      return streakPoints
    })
  }, [streakPoints])

  useEffect(() => {
    if (streakPoints === streakRedJewels) {
      setStreakRedJewelsUpdated(0)
    }
  }, [totalQuestions])

  let totalPointsBarMax = 40
  if ((totalPoints / totalPointsBarMax) >= 0.8) {
    totalPointsBarMax = totalPointsBarMax * 2
  }

  const streakImageLookup = {
    0: ruby_count_0,
    1: ruby_count_1,
    2: ruby_count_2,
    3: ruby_count_3,
    4: ruby_count_4,
    5: ruby_count_5
  }

  return (
    <>
      <h4>Total points: {totalPoints} </h4>
      <Container>
        <TextContainer>
          <PointsText>Gold coins:</PointsText>
          <PointsText>Jewels:</PointsText>
          <PointsText>Streak points:</PointsText>
          <PointsText>Current Streak:</PointsText>
          <PointsText>Highest Streak:</PointsText>
          <PointsText>Questions asked:</PointsText>

        </TextContainer>
        <TextContainer>
          <PointsText>{score}</PointsText>
          <PointsText>{bonusPoints}</PointsText>
          <PointsText>{streakPoints}</PointsText>
          <PointsText>{consecutiveCorrectAnswers}</PointsText>
          <PointsText>{highestStreak}</PointsText>
          <PointsText>{totalQuestions}</PointsText>
        </TextContainer>
        <IconsContainer>
          <IconAndExtraPointsContainer>
            <CoinsIcon src={coins_svg} />
            {((consecutiveCorrectAnswers != 0) && (consecutiveCorrectAnswers % 5 == 0)) &&
              <PointsChangeText>+20 coins!</PointsChangeText>}
          </IconAndExtraPointsContainer>

          <IconAndExtraPointsContainer>
            <PointsIcon src={jewels_svg} />
            {bonusJewelsUpdated > 0 && <PointsChangeText>{`+${bonusJewelsUpdated}!`}</PointsChangeText>}
          </IconAndExtraPointsContainer>

          <IconAndExtraPointsContainer>
            <PointsIcon src={ruby} />
            {streakRedJewelsUpdated > 0 && <PointsChangeText>{`+${streakRedJewelsUpdated}!`}</PointsChangeText>}
          </IconAndExtraPointsContainer>
          <StreakPointsIcon src={streakImageLookup[rubyCount]} />
        </IconsContainer>
      </Container>
    </>
  )
}

export default ScoreBoard
