import { observer } from 'mobx-react-lite'
import { IonInput } from '@ionic/react'
import { FieldComponent } from 'generic_components/MstForm'

export const DateInput:FieldComponent<string> = observer(({value, onChange, disabled}) => {
  const onIonChange = (e:CustomEvent<KeyboardEvent>) => {
    onChange((e.target as any).value?.trim())
  }
  return (
    <IonInput
      type='date'
      value={value}
      onIonInput={onIonChange}
      disabled={disabled}
    />) 
})
