import { types } from 'mobx-state-tree'
import { DateTimeString } from '../basic/date_time_string'
import { SubjectId } from '../subject/id'
import { StudentId } from './id'

export const GetStudentTrendStatsRequest = types.model(
  'GetStudentTrendStatsRequest',
  {
    params: types.model({
      student_id: StudentId,
    })
  }
)
export type GetStudentTrendStatsRequest = typeof GetStudentTrendStatsRequest

export const EndcodeWeeklyScore = types.model(
  'WeekAverageScore',
  {
    endcode: SubjectId,
    score: types.number
  }
)
export type EndcodeWeeklyScore = typeof EndcodeWeeklyScore

export const StudentSubjectTrend = types.model(
  'StudentSubjectTrend',
  {
    timestamp: DateTimeString,
    scores: types.array(EndcodeWeeklyScore)
  }
)
export type StudentSubjectTrend = typeof StudentSubjectTrend

export const GetStudentTrendStatsResponse = types.array(StudentSubjectTrend)
export type GetStudentTrendStatsResponse = typeof GetStudentTrendStatsResponse
