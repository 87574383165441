import { types, Instance, flow, applySnapshot, getSnapshot } from 'mobx-state-tree'
import { StudentScoreSearchFilter, StudentScoreListResponse } from 'common/types/student/resetStudentScore'
import { listStudentSubjectScores } from 'util/api/student/subject_scores'

export const StudentSubjectScoreList = types.model('StudentScoreList', {
  studentSubjectScores: StudentScoreListResponse,
  filter: types.optional(StudentScoreSearchFilter, {
    _from: 0,
    _limit: 50,
  })
})
  .volatile(self => ({
    busy: false,
    changed: true,
  }))
  .actions(self => ({
    refresh: flow(function* (_from=0) {
      if( self.busy ){
        throw new Error('Cannot refresh StudentSubjectScore when busy')
      }
      try {
        self.filter._from = _from
        self.busy = true
        self.changed = false
        const studentSubjectScores = yield listStudentSubjectScores({ query: getSnapshot(self.filter)})
        applySnapshot(self.studentSubjectScores, studentSubjectScores)
      } catch (error) {
        console.error('Failed list students subject scores', error)
      }
      finally {
        self.busy = false
      }
    }),
    set_student_id_filter: (ref: string) => {
      self.filter.studentId = ref
      self.changed = true
    }
  })
  )

export type StudentSubjectScoreList = Instance<typeof StudentSubjectScoreList>

