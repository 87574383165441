import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonSelect, IonSelectOption } from '@ionic/react'
import { ButtonWithImage } from 'generic_components/ButtonWithImage'
import { FC, useEffect, useRef, useState } from 'react'
import { checkmarkCircleSharp, alertCircle } from 'ionicons/icons'
import useDevices from './useDevices'
import { useCameraTest } from './useCameraTest'
import { LocalVideoTrack } from 'twilio-video'

interface Props {
  onNext: ()=>void
}

export const ScreenshareDiag:FC<Props> = ({onNext}) => { 
  const [error,setError] = useState<any|undefined>()
  const [success,setSuccess] = useState(false)
  const [sharing,setSharing] = useState(false)
  const videoElementRef = useRef<HTMLVideoElement>()
  const screenTrack = useRef<LocalVideoTrack>()
  
 
  useEffect(() => {
    if(sharing){
      const mediaDevices:any = navigator.mediaDevices
      const getScreens = () => mediaDevices.getDisplayMedia().then((stream) => {

        const track = new LocalVideoTrack(
          stream.getTracks()[0],
          { name: 'screen', logLevel: 'error' }
        )
        track.attach(videoElementRef.current)
        screenTrack.current=track
      })
      getScreens()
    }else{
      screenTrack && screenTrack.current && screenTrack.current.stop()
      screenTrack.current=null
    }
  }, [sharing])

  
  return (<IonCard>
    <IonCardHeader>
      <IonCardTitle>
        {error ? <IonIcon icon={alertCircle} style={{color:'red'}}/> : success ? <IonIcon icon={checkmarkCircleSharp} style={{color:'green'}}/> : null}Screenshare Diagnostics
      </IonCardTitle>
      <IonCardSubtitle>Check your screen share. Select the screen to share then check it displays correctly. To avoid infite mirror effect, select a single window to share, avoiding sharing this browser.
      </IonCardSubtitle>
    </IonCardHeader>

    <IonCardContent>
     
      {!sharing &&<IonButton color="primary" onClick={()=>{
        setSharing(true)
      }}>
            Start Sharing
      </IonButton>}
      <div >
        {sharing && <video height={300} autoPlay playsInline ref={videoElementRef} />}
      </div>
      <div>
        <strong>Does your screenshare look ok?</strong>
      </div>
      <IonButton color="primary" onClick={()=>{
        setSuccess(true)
        setError(undefined)
        setSharing(false)
        onNext()
      }}>
            Yes
      </IonButton>
      <IonButton color="primary" onClick={()=>{
        setError('true')
        setSuccess(false)
        setSharing(false)
        onNext()
      }}>
            Skip for now
      </IonButton>
      <div>
        {(error) && <strong>Could not verify video.</strong>}
      </div>
    </IonCardContent>
  </IonCard>)
}
