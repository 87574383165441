import { FC, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import { IonContent } from '@ionic/react'

import { TutoringDetails } from './TutoringDetails'
import { Availability } from './Availability'
import { Referral } from './Referral'
import { SlotSelection } from './SlotSelection'
import { Payment } from './Payment'
import SubscriptionWizardState from './SubscriptionWizardState'
import { SessionOptionEnum } from 'common/types/session/options'

interface Props {
  close_create_modal: () => void
}

export const CreateSubscriptionWizard: FC<Props> = observer(({ close_create_modal }) => {
  const state = useRef<Instance<typeof SubscriptionWizardState>>(SubscriptionWizardState.create({})).current

  useEffect(() => {
    state.get_products()
  }, [])

  const getStepComponent = ({ step, selected_session_option }) => {
    if (step === 0) {
      return (
        <TutoringDetails
          state={state}
          close={close_create_modal}
        />
      )
    }

    if (step === 1) {
      return (
        <Availability
          state={state}
          close={close_create_modal}
          shouldShowBack
        />
      )
    }

    if (step === 2) {
      return (
        <Referral
          state={state}
          close={close_create_modal}
        />
      )
    }

    if (step === 3 && selected_session_option === SessionOptionEnum.PlatformOnly) {
      return (
        <Payment
          state={state}
          on_success={close_create_modal}
          close={close_create_modal}
        />
      )
    }

    if (step === 3) {
      return (
        <SlotSelection
          state={state}
          close={close_create_modal}
          onClickConfirm={state.next}
          shouldShowBack
        />
      )
    }

    if (step === 4) {
      return (
        <Payment
          state={state}
          on_success={close_create_modal}
          close={close_create_modal}
        />
      )
    }
  }

  return (
    <IonContent>
      { getStepComponent(state) }
    </IonContent>
  )
})
