import { types, Instance, SnapshotOut, applySnapshot, flow } from 'mobx-state-tree'
import { Hub, Storage } from 'aws-amplify'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { getMe } from 'util/api/profile/me'
import { loadRole, configureAuth, saveRole, loadUsername, saveUsername } from 'util/auth/amplify'
import { ci } from 'util/config'
import { Roles } from 'util/auth/helper'

const RolesType = types.enumeration(Object.values(Roles))

export const UserModel = types.model({
  uid: types.string,
  _id: types.maybe(types.string),
  displayName: types.maybe(types.string),
  email: types.maybe(types.string),
  role: RolesType,
  profile_picture: types.maybe(types.string),
})

const CI_User = {
  uid: '_id_aaaaaa',
  _id: '_id_aaaaaa',
  displayName: 'test mctestson',
  email: 'test@ci.com',
  role: 'TEST' as SnapshotOut<typeof RolesType>,
  profile_picture: undefined
}

export const AuthModel = types.model({
  user: types.maybe(UserModel),
  role: types.maybe(RolesType),
  username: types.maybe(types.string),
})
  .volatile(() => ({
    in_progress: true,
    unsubscribe: () => { return }
  }))
  .actions(self => ({
    clear_user: () => {
      self.user = undefined
    },
    update_me: flow(function*() {
      self.in_progress = true
      try {
        const loggedInUser = yield getMe()

        const user = {
          uid: loggedInUser.uid,
          _id: loggedInUser.user_data?._id,
          displayName: loggedInUser.user_data?.display_name,
          email: loggedInUser.user_data?.email,
          username: loggedInUser.username,
          role: loggedInUser.role,
          profile_picture: loggedInUser.user_data?.profile_picture,
        }
        if( self.user === undefined ){
          self.user = UserModel.create(user)
        }
        else {
          applySnapshot(self.user, user)
        }
      } catch (e) {
        console.log('Auth...error fetching me:' + JSON.stringify(e))
        self.user = undefined
      } finally {
        self.in_progress = false
      }
    }),
    change_role: (role:SnapshotOut<typeof RolesType>) => {
      saveRole(role)
      self.role = role
      if( role ) {
        configureAuth(self.role)
      }
    },
    set_username: (username:string) => {
      saveUsername(username)
      self.username = username
    },
  }))
  .actions(self => ({
    initialise: flow(function*() {
      console.log('Auth...initialise')
      self.unsubscribe()
      self.role = loadRole()
      self.username = loadUsername()
      if (ci) {
        self.user = CI_User
        self.in_progress = false
        return
      }
      if (self.role) {
        configureAuth(self.role)
        Storage.configure({
          customPrefix: {
            public: '',
            protected: 'protected/',
            private: 'private/'
          }
        })
        yield self.update_me()
      } else {
        self.user = undefined
        self.in_progress = false
      }
      const tokenwatch = Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'tokenRefresh': {
            console.log('useCognitoAuth...New token has been processed')
            break
          }
          case 'tokenRefresh_failure': {
            console.log('useCognitoAuth...Failed to retrieve new token')
            self.clear_user()
            break
          }
        }
      })
      const authwatch = onAuthUIStateChange((nextAuthState, authData) => {
        console.log(`useCognitoAuth...Cognito auth state change, nextAuthState=${nextAuthState}, authData: ${authData}`)
        switch (nextAuthState) {
          case AuthState.SignedIn: {
            console.log('useCognitoAuth...signedin')
            self.set_username(undefined)//Resets temp field used to pass childs username from parent
            self.update_me()
          }
            break
          case AuthState.SignedOut:
            console.log('useCognitoAuth...signedout')
            // store.reset()
            // clear_cookies()
            break
          case AuthState.SignOut:
            console.log('useCognitoAuth...signout')
            // store.reset()
            // clear_cookies()
            break
          case AuthState.SignIn:
            console.log('useCognitoAuth...signin')
            //   store.reset()
            break
          default:
            console.log('useCognitoAuth...otherevent')
            // {
            //   if (store.auth.user) {
            //     store.auth.set_cognito_auth_state(nextAuthState)
            //   }
            // }
            break
        }
      })
      self.unsubscribe = () => {
        tokenwatch()
        authwatch()
      }
    }),
    set_profile_picture: (profile_picture: string) => {
      self.user.profile_picture = profile_picture
    },
  }))

export type AuthModel = Instance<typeof AuthModel>
